.list-pagination {
  tr {
    width: 100%;
    border-top: 1px solid map-get($grey, "lighten-3");
    td {
      text-align: center;
      @media #{$medium-and-down} {
        padding: 0.75em 0 !important;
      }
    }
  }
  ul {
    &.pagination {
      margin: 0;
    }
  }
}