.store-hdr {
	@media #{$small-and-down} {
		min-height: 150px;
		height: 150px;
	}
    min-height: 300px;
    height: 300px;
    .carousel-item {
    	@media #{$small-and-down} {
    		min-height: 150px !important;
    		display: flex;
    		justify-content: center;
    		align-items: center;
    	}
        min-height: 300px !important;
    }
}

.store-page-title {
	font-size: 1.5rem;
}

.iapp-store {
	flex: 1 0 auto;
	.store-table {
		table {
			thead {
				tr {
					td {
						.pricing-hdr {
							position: relative;
							margin: 1.1rem 0 1.168rem 0;
							&:before {
								content: "Member";
								position: absolute;
								font-size: 0.8rem;
								font-weight: normal;
								bottom: -1.5rem;
							}
							&:after {
								content: "Nonmember";
								position: absolute;
								font-size: 0.8rem;
								font-weight: normal;
								bottom: -1.5rem;
								left: 3.5rem;
							}
							&.m-price {
								&:before {
									font-weight: bold;
									color: map-get($green, "base");
								}
								&:after {
									text-decoration: line-through;
									color: map-get($grey, "base");
									left: 3.6rem;
								}
							}
							&.nm-price {
								&:before {
									text-decoration: line-through;
									color: map-get($grey, "base");
								}
								&:after {
									// font-weight: bold;
								}
							}
							&.standard-price {
								&:before,
								&:after {
									display: none;
								}
							}
						}
					}
				}
			}
			tbody {
				@media #{$large-and-up} {
					&:nth-child(odd) {
						background-color: map-get($grey, "lighten-3");
					}
					&:nth-child(even) {
						background-color: #fff;
					}
				}
				.store-table-separator {
					@media #{$small-and-down} {
						width: 100%;
						td {
							padding: 0.5em!important;
						}
					}
					@extend .blue-grey, .lighten-4, .blue-grey-text, .text-darken-2;
					font-weight: bold;
					text-transform: capitalize;
				}
				&.collapsible {
					position: relative;
					border: none;
					margin: inherit;
					box-shadow: none;
					min-height: 0;
					.collapsible-header {
						display: table-row;
						min-height: 0;
						line-height: inherit;
						padding: inherit;
						background-color: inherit;
						border: none;
						font-size: 0.8rem; 
						line-height: 1rem;
						cursor: initial;
					}
					.collapsible-body {
						border-top: 1px solid #ddd;
						p {
							padding: 0;
						}
					}
				}
				&.sold-out {
					.sold-out-badge {
						@include sold-out-badge;
					}
					.new-mode-badge {
						@include sold-out-badge;
						background-color: map-get($green, "accent-2");
					}
					.pre-sale-badge {
						@include sold-out-badge;
						background-color: map-get($blue, "accent-1");
					}
				}
					&.live-online {
					.live-online-badge {
						@include live-online-badge;
					}
					.new-mode-badge {
						@include live-online-badge;
						background-color: map-get($blue, "accent-2");
					}
					.pre-sale-badge {
						@include live-online-badge;
						background-color: map-get($green, "accent-1");
					}
				}
				tr {
					td {
						font-size: 0.8rem; 
						line-height: 1rem;
						background-color: inherit;
						&.provider {
							text-align: right; 
							height: 100%;
							.provider-type {
								width: 3rem; 
								float: left; 
								font-size: 0.8rem;
								line-height: inherit; 
								display: flex; 
								// align-items: center; 
								justify-content: center; 
								height: 100%;
								padding: 0.3em 0 0;
								img {
									width: 60px;
									height: 22px;
									max-width: 60px;
									max-height: 22px;
									margin-top: 0.3em;
								}
							}
						}
						.m-price {
							span {
								&:nth-child(1) {
									font-weight: bold;
									color: map-get($green, "base");
								}
								&:nth-child(2) {
									text-decoration: line-through;
									color: map-get($grey, "base");
								}
							}
						}
						.nm-price {
							span {
								&:nth-child(1) {
									text-decoration: line-through;
									color: map-get($grey, "base");
								}
								&:nth-child(2) {
									// font-weight: bold;
								}
							}
						}
						&.prod-name {
							position: relative;
							a {
								@extend .grey-text, .text-darken-4;
								transition: color .28s ease;
								&:hover {
									color: map-get($cyan, "darken-1") !important;
								}
							}
						}
						.otp-badge {
							float: left;
							width: 48px;
						}
					}
				}
			}
			td {
				@media #{$medium-and-up} {
					padding: 0.5rem 1rem !important;	
				}
	    		float: none !important;
	    		border-radius: initial;
				.checkout-btn {
					max-width: 0;
					padding: 0 1.5rem;
					margin-bottom: 0.25rem;
					.material-icons {
						transform: translateY(0);
						line-height: inherit;
						text-align: left;
						margin-left: -0.75rem;
					}
				}
			}
		}
		.grid-sort {
			padding: 1rem 0.75rem 0;
			.sort-trigger {
				margin-right: 3rem;
				&:last-child {
					margin-right: 0;
				}
			}
		}
		.grid-item {
			@media #{$medium-only} {
				&:nth-child(2n+1) {
					clear: both;
					float: left;
				}
			}
			@media #{$large-and-up} {
				&:nth-child(3n+1) {
					clear: both;
					float: left;
				}
			}
		}
	}

	.store-filters {
		.card-title {
			font-size: 1.1rem;
			font-weight: bold;
	    	line-height: inherit;
		}
		.filters {
			.filter {
				margin-bottom: 1rem;
				.filter-form {
					overflow: visible;
					.filter-set {
						margin-top: 0.5rem;
						margin-bottom: 0.5rem;
						height: auto;
						max-height: 0;
						opacity: 0;
						overflow: hidden;
						transition: all .5s ease;
						&.active {
							max-height: 750px;
							opacity: 1;
							transition: all .5s ease .28s;
						}
						label {
							color: #222;
						}
					}
				}
			}
		}
	}

	.store-main {
		@media #{$small-and-down} {
			padding: 0;
		}
		position: relative;
		.store-search-active {
			.global-store-search,
			label,
			.material-icons {
				@extend .grey-text, .text-darken-4;
				background-color: #fff;
			}
		}
		.store-search-results {
			@media #{$medium-and-down} {
				z-index: 22;
				.result {
    				flex-direction: column;
    				.result-category {
						margin-left: 0;
						float: left;
    				}
				}
			}
			@extend .card;
			position: absolute;
			background-color: #fff;
			z-index: 5;
			left: 0.75rem;
			right: 0.75rem;
			.result {
				padding: 0.75rem;
				border-bottom: 1px solid map-get($grey, "lighten-3");
				display:flex;
				justify-content: space-between;
				&:last-child {
					border-bottom: none;
				}
				.result-category {
					@extend .grey;
					display: inline-block;
					margin-top: inherit;
					right: 0.5rem;
					&:after {
						display: none;
					}
				}
			}
		}
		.card {
			.card-content {		
				.sold-out {
					.sold-out-badge {
						@include sold-out-badge;
					}
					.new-mode-badge {
						@include sold-out-badge;
						background-color: map-get($green, "accent-2");
					}
					.pre-sale-badge {
						@include sold-out-badge;
						background-color: map-get($blue, "accent-1");
					}
				}
				&.live-online {
					.live-online-badge {
						@include live-online-badge;
					}
					.new-mode-badge {
						@include live-online-badge;
						background-color: map-get($blue, "accent-2");
					}
					.pre-sale-badge {
						@include live-online-badge;
						background-color: map-get($green, "accent-1");
					}
				}
				&.valign-wrapper {
					@media #{$small-and-down} {
						display: block;
					}
					.card-title {
						@media #{$small-and-down} {
							display: block;
							line-height: 2rem;
						    margin-bottom: 15px;
						}
					}
					.btn {
						&.valign {
							&.right {
								position: absolute;
								right: 25px;
								@media #{$small-and-down} {
									position: relative;
								    width: 100%;
								    display: block;
								    right: 0;
								    margin-bottom: 25px;
								}
							}
						}
					}
				}
			}
			.card-action {
				.add-to-cart {
					margin-right: 0 !important;
					margin-top: -0.5rem;
					i {
						@extend .green-text;
					}
				}
				.remove-from-cart {
					margin-right: 0 !important;
					margin-top: -0.5rem;
					i {
						@extend .orange-text;
					}
				}
			}
			&.card-merchandise,
			&.prod-checkout-card {
				.m-price {
					text-align: right;
					&:before {
						content: "Member Price";
						position: absolute;
						left: 20px;
						color: map-get($green, "base");
					}
					span {
						&:nth-child(1) {
							font-weight: bold;
							color: map-get($green, "base");
						}
						&:nth-child(2) {
							text-decoration: line-through;
							color: map-get($grey, "base");
						}
					}
					&.single {
						&:before {
							content: "Price";
							color: map-get($grey, "darken-4");
						}
						&.knet {
							&:before {
								content: "Member Price";
								color: map-get($green, "base");
							}
						}
					}
				}
				.nm-price {
					text-align: right;
					&:before {
						content: "Nonmember Price";
						position: absolute;
						left: 20px;
					}
					span {
						&:nth-child(1) {
							text-decoration: line-through;
							color: map-get($grey, "base");
						}
						&:nth-child(2) {
							// font-weight: bold;
						}
					}
					&.single {
						&:before {
							content: "Price";
						}
					}
				}
			}
			&.card-merchandise {
				&.large {
					@media #{$small-and-down} {
						height: auto;
						.card-content {
							max-height: none;
							height: auto;
						}
						.card-action {
							position: relative;
						}
					}
					@media #{$medium-only} {
						height: 450px;
					}
					@media #{$large-and-up} {
						height: 475px;
						.card-content {
							.card-head {
								font-size: 1.2rem;
		    					line-height: 1.5rem;
							}
						}
					}
					@media #{$xlarge-and-up} {
						height: 450px;
						.card-content {
							.card-head {
								font-size: 1.3rem;
		    					line-height: 1.7rem;
							}
						}
					}
					.card-image {
						max-height: 12rem;
						a {
							display: block;
							overflow: hidden;
							max-height: inherit;
							img {
								max-height: 10rem;
								height: 100%;
								width: auto;
								max-width: 100%;
								margin: 1rem auto 0;
							}
						}
					}
				}
			}
			&.card-merchandise-exam {
				&.large {
					@media #{$small-and-down} {
						height: auto;
						.card-content {
							max-height: none;
							height: auto;
						}
						.card-action {
							position: relative;
						}
					}
					@media #{$medium-only} {
						height: 400px;
						.card-content {
							min-height: 150px;
							.card-head {
								font-size: 1.2rem;
		    					line-height: 1.5rem;
		    					min-height: 75px;
							}
						}
					}
					@media #{$large-and-up} {
						height: 400px;
						.card-content {
							min-height: 150px;
							.card-head {
								font-size: 1.2rem;
		    					line-height: 1.5rem;
		    					min-height: 75px;
							}
						}
					}
					@media #{$xlarge-and-up} {
						height: 425px;
						.card-content {
							min-height: 150px;
							.card-head {
								font-size: 1.3rem;
		    					line-height: 1.7rem;
		    					min-height: 75px;
							}
						}
					}
					.card-image {
						max-height: 12rem;
						a {
							display: block;
							overflow: hidden;
							max-height: inherit;
							img {
								max-height: 10rem;
								height: 100%;
								width: auto;
								max-width: 100%;
								margin: 1rem auto 0;
								padding-bottom: 20px;
							}
						}
					}
				}
			}
			&.prod-checkout-card {
				.m-price,
				.nm-price {
					&:before {
						left: 0;
					}
				}
				.btn {
					padding: 0 15px;
					line-height: 38px;
					width: 100%;
					i {
						transform: translateY(0);
					}
				}
				.modal {
					.modal-footer {
						.btn {
							padding: 0 2rem;
							line-height: 36px;
							width: auto;
						}
					}
				}
			}
		}
	}

	.product-page {
		.sold-out {
			.sold-out-badge {
				@include sold-out-badge;
			}
			.new-mode-badge {
				@include sold-out-badge;
				background-color: map-get($green, "accent-2");
			}
			.pre-sale-badge {
				@include sold-out-badge;
				background-color: map-get($blue, "accent-1");
			}
		}
		.live-online {
			.live-online-badge {
				@include live-online-badge;
			}
			.new-mode-badge {
				@include live-online-badge;
				background-color: map-get($blue, "accent-2");
			}
			.pre-sale-badge {
				@include live-online-badge;
				background-color: map-get($green, "accent-1");
			}
		}
		.prod-page-img {
			float: left;
			margin-right: 2rem;
			margin-bottom: 2rem;
		}
		ul, ol {
			display: block;
			overflow: hidden;
			padding-left: 20px;
		}
		ul li {
			list-style-type: disc;
		}
		.product-quantity {
			padding-bottom: 2.75rem;
			.label {
				float: left;
			}
			.input {
				@extend .grey, .lighten-2;
				float: right;
				border-radius: 2px;
				span {
					float: left;
					font-size: 1rem;
					line-height: 1.5rem;
					padding: 0 0.15rem;
					transition: color .28s ease;
					&.decrease-count {
						@extend .grey-text, .text-darken-1;
						cursor: pointer;
						&:hover {
							color: map-get($orange, "base") !important;
						}
					}
					&.count {
						@extend .grey, .lighten-4;
						padding: 0 0.35rem;
					}
					&.increase-count {
						@extend .grey-text, .text-darken-1;
						cursor: pointer;
						&:hover {
							color: map-get($green, "base") !important;
						}
					}
				}
			}
		}
		.prod-cart-adjust {
			position: relative;
			height: 1.75rem;
			.btn {
				position: absolute;
				left: 0;
			}
		}

	}

	.featured-content {
	    margin: 0.75rem -0.75rem;
		.card {
			padding: 0;
			margin: 0 0 1.5rem;
			@media #{$small-and-down} {
				margin-bottom: 0.75rem;
			}
			.btn-floating {
				position: absolute;
				right: 0.75rem;
				bottom: 2.6rem;
				i {
					transform: none;
				}
			}
		}
	}
}

.price-inner {
	display: inline !important;
}

.single-price {
	color: map-get($grey, "darken-4") !important;
	text-decoration: none !important;
	font-weight: normal !important;
	.price-inner {
		display: block !important;
	}
}

.placeholder-img {
	text-align: center;
	.fa {
		@extend .grey-text;
		font-size: 7rem;
		padding: 2rem 0;
	}
}