.dropdown-trigger {
	&.hover-trigger {
	}
}

.dropdown-content {
	overflow: hidden;
	 white-space: normal;
	li {
		width: 300px;
		padding: 14px 16px;
		cursor: default;
	 white-space: normal;
	}
}