.algolia-search {
	position: relative;
	height: 64px;
	background-color: transparent !important;
	z-index: 1;
	form {
		@extend .container;
		height: inherit;
		overflow: visible;
		width: 100% !important;
		max-width: 100% !important;
		.input-field {
			@extend .white, .z-depth-1;
			position: relative;
			margin-top: 0; 
			border-radius: 32px;
			transition: all 100ms ease-in-out;
			input[type="search"],
			.algolia-search-field {
				@extend .flow-text;
				height: inherit;
				line-height: 64px;
				border-bottom: none;
				border-radius: 32px;
				font-family: $serif;
				margin-left: 3rem;
			    margin-right: 3rem;
			    width: calc(100% - 6rem);
				input:not([type]),
				input:not([type]):focus {
					border-bottom: none !important;
					box-shadow: none !important;
					width: inherit;
					height: inherit;
    				font-size: 1.3rem;
				}
			}
			button[type="submit"] {
			    position: absolute;
			    right: 0.75em;
			    top: 0.75em;
			    border: 2px solid map-get($green, 'base') !important;
			    background-color: #fff;
			    opacity: 0;
			    border-radius: 50%;
			    height: 42px;
			    width: 42px;
			    padding: 0;
			    transition: all 100ms ease-in-out;
				.material-icons {
					color: map-get($green, 'base') !important;
					line-height: 40px !important;
					&.arrow {
						position: absolute;
					    top: 0;
					    left: 0.15em;
					}
				}
				&.arrow-btn {
					top: 0.5em;
					.material-icons {
						position: absolute;
					    top: 0;
					    left: 0.15em;
					}
				}
				&.active {
					opacity: 1;
				}
				&:hover {
					background-color: map-get($green, 'base') !important;
					.material-icons {
						color: #fff !important;
					}
				}
			}
			label {
				top: 0;
				left: 0.75rem;
			}
			.material-icons {
				@extend .grey-text, .text-darken-4;
				font-size: 30px;
				line-height: 64px !important;
				transition: transform 200ms ease-in-out;
			}
		}
	}
	&.hp-search {
		margin: -32px 0;
		form {
			width: 40em !important;
			max-width: 90% !important;
			.input-field {
				input[type="search"] {
					margin-left: 0;
					margin-right: 0;
					padding-left: 4rem;
				    padding-right: 4rem;
				    width: calc(100% - 8rem);
					&::-webkit-input-placeholder {
						line-height: 64px !important;
						opacity: 1;
						transition: opacity 100ms ease-in-out;
					}
					&::-moz-placeholder {
						line-height: 64px !important;
						opacity: 1;
						transition: opacity 100ms ease-in-out;
					}
					&:-ms-input-placeholder {
						line-height: 64px !important;
						opacity: 1;
						transition: opacity 100ms ease-in-out;
					}
					&:-moz-placeholder {
						line-height: 64px !important;
						opacity: 1;
						transition: opacity 100ms ease-in-out;
					}
					&:focus {
						border-bottom: none !important;
						box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
									0 1px 10px 0 rgba(0, 0, 0, 0.12),
									0 2px 4px -1px rgba(0, 0, 0, 0.3) !important;
						&::-webkit-input-placeholder {
							opacity: 0;
						}
						&::-moz-placeholder {
							opacity: 0;
						}
						&:-ms-input-placeholder {
							opacity: 0;
						}
						&:-moz-placeholder {
							opacity: 0;
						}
						& + label.active {
							.material-icons {
								// color: map-get($green, 'base') !important;
								transform: scale(1.25) translateX(-0.3em);
							}
						}
						& + label.active + button.active {
							transform: translateX(0.85em);
						}
					}
				}
				label {
					top: 0;
					left: 1rem;
					&.active {
						transform: translateY(0);
					}
				}
				:focus {
					transform: scale(1.05);
				}
			}
		}
	}
}

#global-search {
	margin-top: 1em;
}

#iapp-search-results {
	.collection {
		border: none;
		@media #{$medium-and-down} {
			.collection-item {
				padding: 0;
			}
		}
	}
}

#news-search-results {
	.collection-item {
		padding: 10px;
		@media #{$medium-and-down} {
			.collection-item {
				padding: 0;
			}
		}
	}
}

.search-result-meta {
	max-width: 475px; 
	margin-bottom: 0.5em; 
	flex-wrap: wrap;
	&:last-child {
		margin-bottom: 1em;
	}
}

.search-filters-button {
	@extend .chip;
	margin-top: 1em;
	transition: background-color .2s ease-in-out, color .2s ease-in-out;
	@media #{$large-and-up} {
		&:hover {
			color: #fff;
			background-color: map-get($cyan, "darken-1");
		}
	}
	@media #{$medium-and-down} {
		display: block;
		margin: 0;
	}
}

.sort-filters-button {
	@extend .chip;
	margin-top: 1em;
	transition: background-color .2s ease-in-out, color .2s ease-in-out;
	@media #{$large-and-up} {
		&:hover {
			color: #fff;
			background-color: map-get($cyan, "darken-1");
		}
	}
	@media #{$medium-and-down} {
		display: inline-block;
		margin: 5px;
		width: fit-content;
	}
}

.search-sort {
	margin: 0;
	float: left;
	position: inherit;
	@media #{$large-and-up} {
		margin-left: 2.5rem;
		margin-top: 1.25rem;
	}
	@media #{$medium-and-down} {
		margin-top: 0.25rem;
	}
}

.search-go {
	@extend .ghost-button, .ghost-green, .circle;
	margin-top: 15px; 
	height: 2em; 
	padding: 0; 
	font-size: 1em;
	margin-left: auto;
	.material-icons {
		margin-top: 1px;
		margin-left: 2px;
	}
}

.search-image {
	margin: 15px 0;
	@media #{$medium-and-down} {
		margin: 15px auto;
	}
	img {
		width: 96px;
		border: 1ps solid #000;
	}
}

.search-image-news {
	img {
		width: 200px;
	}
}

.news-font {
	font-size: 1.25em;
}

.filter-set {
	overflow: hidden;
	margin: 0;
}

.facet-value-name {
	float: left;
}

.facet-value-count {
	float: right;
	line-height: 2em;
}

.global-store-search {
	&::-webkit-input-placeholder {
		color: #fff;
	}
	&::-moz-placeholder {
		color: #fff;
	}
	&:-ms-input-placeholder {
		color: #fff;
	}
	&:-moz-placeholder {
		color: #fff;
	}
}

.badge-search {
	@extend .badge, .new, .grey; 
	width: 40%;
	min-width: 190px !important;
	margin-left: 0 !important;
	@media #{$medium-and-down} {
		width: 100%;
		min-width: 0;
	}
}

.search-store-listings {
	margin-bottom: 0;
	label {
		.material-icons {
			@extend .grey-text, .text-darken-2;
			position: absolute;
			line-height: 1rem;
			transition: color .28s ease;
		}
	}
	input[type="search"] {
		&:focus {
			& + label {
				.material-icons {
					color: map-get($cyan, "darken-1") !important;
				}
			}
		}
	}
}

.news-search-results {
	.collection {
		.isFeature {
			border-bottom: 4px solid #78bf41;
		}
		.collection-item {
			.hit-wrapper {
				display: flex; 
				flex-direction: row; 
				justify-content: start; 
				flex-wrap: wrap;
				.hit {
					display: flex; 
					flex-direction: row; 
					flex-wrap: wrap;
					max-width: 475px;
					.item {
						padding-left: 10px; 
						padding-top: 5px; 
						display: flex; 
						flex-direction: column; 
						flex-wrap: wrap;
						.featured-item {
							font-size: 1.5em;
    						line-height: 1.25em;
						}
					}
				}
			}
		}
	}
}

.algolia-subject-tags-wrapper {
	display:flex; 
	flex-wrap: wrap;
	.search-filters-button {
		cursor: pointer;
	}
	.refined-tag {
		color: #fff;
    	background-color: #00acc1;
	}
}

.algolia-cpe-feed {
	.flex-item {
		display:flex;
	}
	.feed-text {
		padding-left: 10px; 
		padding-top: 5px; 
		display: flex; 
		flex-direction: column; 
		flex-wrap: wrap;
		.flex-wrap {
			flex-wrap: wrap;
		}
	}
}