// --------------------------------------------
// --------------------------------------------
//
// Print stylesheet
//
// --------------------------------------------
// --------------------------------------------

@media print {

	.site-nav {
		display: none;
	}

	.header-mod, .hdr-img-inner {
		margin: 0;
		padding: 0;
		height: auto;
	}

	.hdr {
		background-image: none !important;
		padding: 0 0 20px !important;
	}
	
	.article-aside-mod {
		display: none;
	}

	.article-related-mod {
		display: none;
	}

	.social-list-items {
		display: none;
	}

	.article-meta-img {
		display: none;
	}

	.article-meta-text {
		line-height: 1.5em;
	}

	.article-body {
		width: 100%;
	}

	.article-bio {
		display: none;
	}

	.ftr {
		display: none;
	}

	.dfad-post {
		display: none !important;
	}

	.tz-article-lg, .article-text, .article-text--firstgraph, .article-text > p, .article-text > blockquote > p, .article-text .pull-quote > p {
		font-size: 12pt;
	}

	.tz-art {
		display: none;
	}

	.cap-image-right, .cap-image-left, .wp-caption-text {
		display: none;
	}

	.article-text > p > a, .article-text p em > a, .article-text p strong > a {
		text-decoration: none;
		color: #2e2e2f;
	}

	.side-menu-trigger {
		display: none;
	}

	.td-row {
		float: left;
		width: 100%;
		padding: 0;
	}


	.table-th {
		display: table-cell;
		float: left;
		&.one-tenth {
			width: 10%;
		}
		&.two-tenths {
			width: 20%;
		}
		&.three-tenths {
			width: 30%;
		}
		&.four-tenths {
			width: 40%;
		}
		&.five-tenths {
			width: 50%;
		}
		&.six-tenths {
			width: 60%;
		}
		&.seven-tenths {
			width: 70%;
		}
		&.eight-tenths {
			width: 80%;
		}
		&.nine-tenths {
			width: 90%;
		}
		&.full-width {
			width: 100%;
		}
	}

	.table-td {
		clear: none;
		display: table-cell;
		line-height: 1.2em;
		padding: 0.75em 1em 0 0;
		margin-bottom: 1em;
		&.one-tenth {
			width: 10%;
		}
		&.two-tenths {
			width: 20%;
		}
		&.three-tenths {
			width: 30%;
		}
		&.four-tenths {
			width: 40%;
		}
		&.five-tenths {
			width: 50%;
		}
		&.six-tenths {
			width: 60%;
		}
		&.seven-tenths {
			width: 70%;
		}
		&.eight-tenths {
			width: 80%;
		}
		&.nine-tenths {
			width: 90%;
		}
		&.full-width {
			width: 100%;
		}
	}

	.mob-label {
		display: none;
	}
	.mob-hide {
		display: block;
	}

	.sched-btn {
		display: none;
	}

	.cbt-schedule {
		.close-trigger {
			display: none;
		}
	}

	.casebook-detail {
		.supporting-list {
			display: none;
		}
		.breadcrumbs {
			display: none;
		}
		.ftc-case-save {
			display: none;
		}
		.page-head-lg, .section-hdr-lg {
			margin-bottom: 12px;
		}
		.casebook-social {
			display: none;
		}
		ul.index {
			display: none;
		}
	}

	.no-print {
		display: none;
	}

}