/* 
 * HTML5 ✰ Boilerplate
 *
 * What follows is the result of much research on cross-browser styling. 
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 *
 * Detailed information about this CSS: h5bp.com/css
 * 
 * ==|== normalize ==========================================================
 */


/* =============================================================================
   HTML5 display definitions
   ========================================================================== */

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section { display: block; }
audio, canvas, video { display: inline-block; *display: inline; *zoom: 1; }
audio:not([controls]) { display: none; }
[hidden] { display: none; }

/* =============================================================================
   Base
   ========================================================================== */

/* Make everything conform to a standard box-model */

* { box-sizing: border-box; }


/*
 * 1. Correct text resizing oddly in IE6/7 when body font-size is set using em units
 * 2. Force vertical scrollbar in non-IE
 * 3. Prevent iOS text size adjust on device orientation change, without disabling user zoom: h5bp.com/g
 */

html { font-size: 100%; overflow-y: scroll; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; }

body { margin: 0; font-size: 13px; line-height: 1.231; }

body, button, input, select, textarea { font-family: sans-serif; color: #222; }

/* 
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection declarations have to be separate
 * Also: hot pink! (or customize the background color to match your design)
 */

/*
::-moz-selection { background: #fe57a1; color: #fff; text-shadow: none; }
::selection { background: #fe57a1; color: #fff; text-shadow: none; }
*/


/* =============================================================================
   Links
   ========================================================================== */

// a { color: #00e; }
// a:visited { color: #551a8b; }
// a:hover { color: #06e; }
// a:focus { outline: thin dotted; }

/* Improve readability when focused and hovered in all browsers: h5bp.com/h */
a:hover, a:active { outline: 0; }


/* =============================================================================
   Typography
   ========================================================================== */

abbr[title] { border-bottom: 1px dotted; }

b, strong { font-weight: bold; }

blockquote { margin: 1em 40px; }

dfn { font-style: italic; }

hr { display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0; }

ins { background: #ff9; color: #000; text-decoration: none; }

mark { background: #ff0; color: #000; font-style: italic; font-weight: bold; }

/* Redeclare monospace font family: h5bp.com/j */
pre, code, kbd, samp { font-family: monospace, monospace; _font-family: 'courier new', monospace; font-size: 1em; }

/* Improve readability of pre-formatted text in all browsers */
pre { white-space: pre; white-space: pre-wrap; word-wrap: break-word; }

q { quotes: none; }
q:before, q:after { content: ""; content: none; }

small { font-size: 85%; }

/* Position subscript and superscript content without affecting line-height: h5bp.com/k */
sub, sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }
sup { top: -0.5em; }
sub { bottom: -0.25em; }


/* =============================================================================
   Lists
   ========================================================================== */

ul { margin: 0; padding: 0; list-style-type: none; }
ol { margin: 0; padding: 0; }

dd { margin: 0; }
nav ul, nav ol { list-style: none; list-style-image: none; margin: 0; padding: 0; }


/* =============================================================================
   Embedded content
   ========================================================================== */

/*
 * 1. Improve image quality when scaled in IE7: h5bp.com/d
 * 2. Remove the gap between images and borders on image containers: h5bp.com/e 
 */

img { border: 0; -ms-interpolation-mode: bicubic; vertical-align: middle; }

/*
 * Correct overflow not hidden in IE9 
 */

svg:not(:root) { overflow: hidden; }


/* =============================================================================
   Figures
   ========================================================================== */

figure { margin: 0; }


/* =============================================================================
   Forms
   ========================================================================== */

form { margin: 0; }
fieldset { border: 0; margin: 0; padding: 0; }

/* Indicate that 'label' will shift focus to the associated form element */
label { cursor: pointer; }

/* 
 * 1. Correct color not inheriting in IE6/7/8/9 
 * 2. Correct alignment displayed oddly in IE6/7 
 */

legend { border: 0; *margin-left: -7px; padding: 0; }

/*
 * 1. Correct font-size not inheriting in all browsers
 * 2. Remove margins in FF3/4 S5 Chrome
 * 3. Define consistent vertical alignment display in all browsers
 */

button, input, select, textarea { font-size: 100%; margin: 0; vertical-align: baseline; *vertical-align: middle; }

/*
 * 1. Define line-height as normal to match FF3/4 (set using !important in the UA stylesheet)
 * 2. Correct inner spacing displayed oddly in IE6/7
 */

button, input { line-height: normal; *overflow: visible; }

/*
 * Reintroduce inner spacing in 'table' to avoid overlap and whitespace issues in IE6/7
 */

table button, table input { *overflow: auto; }

/*
 * 1. Display hand cursor for clickable form elements
 * 2. Allow styling of clickable form elements in iOS
 */

button, input[type="button"], input[type="reset"], input[type="submit"] { cursor: pointer; -webkit-appearance: button; }

/*
 * Consistent box sizing and appearance
 */

input[type="checkbox"], input[type="radio"] { box-sizing: border-box; }
input[type="search"] { -webkit-appearance: textfield; -moz-box-sizing: content-box; -webkit-box-sizing: content-box; box-sizing: content-box; }
input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

/* 
 * Remove inner padding and border in FF3/4: h5bp.com/l 
 */

button::-moz-focus-inner, input::-moz-focus-inner { border: 0; padding: 0; }

/* 
 * 1. Remove default vertical scrollbar in IE6/7/8/9 
 * 2. Allow only vertical resizing
 */

textarea { overflow: auto; vertical-align: top; resize: vertical; }

/* Colors for form validity */
input:valid, textarea:valid {  }
input:invalid, textarea:invalid { background-color: #f0dddd; }


/* =============================================================================
   Tables
   ========================================================================== */

table { border-collapse: collapse; border-spacing: 0; }
td { vertical-align: top; }


/* =============================================================================
   Clearfix
   =========================================================================== */

/* Contain floats: h5bp.com/q */ 
.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.clearfix { zoom: 1; }






/* ==|== print styles =======================================================
   Print styles.
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
 
@media print {
  * { background: transparent !important; color: black !important; text-shadow: none !important; filter:none !important; -ms-filter: none !important; } /* Black prints faster: h5bp.com/s */
  a, a:visited { text-decoration: underline; }
  a[href]:after { content: " (" attr(href) ")"; }
  abbr[title]:after { content: " (" attr(title) ")"; }
  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after { content: ""; }  /* Don't show links for images, or javascript/internal links */
  pre, blockquote { border: 1px solid #999; page-break-inside: avoid; }
  thead { display: table-header-group; } /* h5bp.com/t */
  tr, img { page-break-inside: avoid; }
  img { max-width: 100% !important; }
  @page { margin: 0.5cm; }
  p, h2, h3 { orphans: 3; widows: 3; }
  h2, h3 { page-break-after: avoid; }
}




$default-has-layout-approach: zoom;

@mixin has-layout($approach: $default-has-layout-approach) {
  @if $approach == zoom {
    @include has-layout-zoom;
  }
  @else if $approach == block {
    @include has-layout-block;
  }
  @else {
    @warn "Unknown has-layout approach: #{$approach}";
    @include has-layout-zoom;
  }
}

@mixin has-layout-zoom {
  *zoom: 1;
}

@mixin has-layout-block {
    // This makes ie6 get layout
    display: inline-block;
    // and this puts it back to block
    & {
      display: block;
    }
}

@mixin pie-clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  @include has-layout;
}



// Responsive Images
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
// FOR: Setting the default properties of a responsive image
// WHY: It's repetitive. Saves re-writing of multiple rules.
// USE: @include resp-img;
@mixin resp-img {
	width: 100%;
	max-width: 100%; 
}


//
// Universal 
//

// Kill the margin on the last column
.ui-block:last-child,
.ui-block.last {
  margin-right: 0;
}


// ============================================= //

// Media Grid                    // 

// ============================================= //

@mixin media-grid($num: 2, $pad: em(20), $target:'.media-block') {
  margin-left: -#{$pad};
  @include pie-clearfix;

  > #{$target} {
    float: left;
    padding-left: #{$pad};
    @if $num == 2 or $num == "two" {
      width: 50%;
      &:nth-of-type(2n+3) {
        clear: left;
      }
      &.n3 {
        clear:left;
      }
    }
    @if $num == 3 or $num == "three" {
      width: 33.3333%;
      &:nth-of-type(3n+4) {
        clear: left;
      }
      .ie7 & {
        width: 33%;
      }
      &.n4 {
        clear: left;
      }
    }
    @if $num == 4 or $num == "four" {
      width: 25%;
      &:nth-of-type(4n+5) {
        clear: left;
      }
      &.n5 {
        clear: left;
      }
    }
    @if $num == 5 or $num == "five" {
      &:nth-of-type(5n+6) {
        clear: left;
      }
      width: 20%;
      &.n6 {
        clear: left;
      }
    }
    @if $num == 6 or $num == "six" {
      &:nth-of-type(6n+7) {
        clear: left;
      }
      width: 16.666%;
      .ie7 & {
        width: 16%;
      }
      &.n7 {
        clear: left;
      }
    }
  }
} //media-grid


// ============================================= //

// UI Grids                        // 

// ============================================= //

// Static Column Grid Variation
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
// FOR: Setting one explicit column width while keeping the other liquid
// WHY: It's annoying to remember this pattern and several numbers depend on one another
// REF: http://alistapart.com/article/negativemargins

// HTML:
// <div class="l-static">
//  <section class="ui-block-1">
//    <div class="ui-block-inner">
//
//      <!-- Block 1 Content Goes Here -->
//
//    </div> <!-- /ui-block-inner -->
//  </section> <!-- /ui-block-1 -->
//  <section class="ui-block-2">
//    <div class="ui-block-inner">
//
//      <!-- Block 2 Content Goes Here -->
//
//    </div> <!-- /ui-block-inner -->
//  </section> <!-- /ui-block-2 --> 
// </article> <!-- /l-static -->

// USE: @include grid-static(300px, 40px);

@mixin grid-static($width: 300px, $margin: 40px, $static-col: right) {
  // Variables
  $total: $width + $margin;

  // If the right column has a static width
  @if $static-col == right {
    > .ui-block-1 {
      width: 100%;
      float: left;
      margin-right: -$total;
      > .ui-block-inner {
        margin-right: $total;
      }
    }

    > .ui-block-2 {
      width: $width;
      float: right;
    }
  } // right

  // If the left column has a static width
  @else {
    > .ui-block-1 {
      width: $width;
      float: left;
    }   
    > .ui-block-2 {
      width: 100%;
      float: right;
      margin-left: -$total;
      > .ui-block-inner {
        margin-left: $total;
      }
    }
  } //else

  @include pie-clearfix;
}


// Grid-2 vars

$width-1: 50%; 
$width-2: 50%; 
$margin-r: 5%; 

@mixin ui-grid-2($width-1: 50%, $width-2: 50%, $margin-r:5%) {
  @include pie-clearfix;

  > .ui-block-1,
  > .ui-block.first {
    width: $width-1 - ($margin-r/2);
    @include float-left;
    margin-right: $margin-r;
  }

  > .ui-block-2,  
  > .ui-block.second {
    width: $width-2 - ($margin-r/2);
    @include float-left;
    margin-right: 0;
  }
}

@mixin ui-grid-rl($width-1: 50%, $width-2:50%, $margin-r:5%) {
  @include pie-clearfix;

  > .ui-block-1,  
  > .ui-block.first {
    width: $width-1 - ($margin-r/2);
    @include float-right;
  }
  
  > .ui-block-2,
  > .ui-block.second {
    width: $width-2 - ($margin-r/2);
    @include float-left;
    margin-right: 0;
  }
}

@mixin ui-grid-lr($width-1:50%, $width-2:50%, $margin-r:5%) {
  @include pie-clearfix;

  > .ui-block-1,  
  > .ui-block.first {
    width: $width-1 - ($margin-r/2);
    @include float-left;
  }

  > .ui-block-2,  
  > .ui-block.second {
    width: $width-2 - ($margin-r/2);
    @include float-right;
    margin-right: 0;
  }
}

// Grid-3 vars 

@mixin ui-grid-3($width3-1: 33.333%, $width3-2: 33.333%, $width3-3: 33.333%, $margin3-r: 3.5%)  {
  @include pie-clearfix;

  > .ui-block-1,
  > .ui-block.first {
    width: $width3-1 - ($margin3-r/3);
    @include float-left;
    margin-right: $margin3-r/2;
  }

  > .ui-block-2,  
  > .ui-block.second {
    width: $width3-2 - ($margin3-r/3);
    @include float-left;
    margin-right: $margin3-r/2;
  }

  > .ui-block-3,
  > .ui-block.last {
    width: $width3-3 - ($margin3-r/3);
    @include float-left;
    margin-right: 0;    
  }
}

// Grid-3-rrl vars
  
@mixin ui-grid-rrl($width3-1: 33.333%, $width3-2: 33.333%, $width3-3: 33.333%, $margin3-r: 3.5%)  {
  @include pie-clearfix;

  > .ui-block-1,
  > .ui-block.first {
    width: $width3-1 - ($margin3-r/3);
    @include float-right;
    margin-right: 0;
  }

  > .ui-block-2,
  > .ui-block.second {
    width: $width3-2 - ($margin3-r/3);
    @include float-right;
    margin-right: $margin3-r/2;
  }

  > .ui-block-3,
  > .ui-block.last {
    width: $width3-3 - ($margin3-r/3);
    @include float-left;
    margin-right: $margin3-r/2;   
  }
}


// Grid-4 vars 

@mixin ui-grid-4($width4-1: 25%, $width4-2: 25%, $width4-3: 25%, $width4-4: 25%, $margin5-r: 8%) {

  @include pie-clearfix;

  > .ui-block-1,
  > .ui-block.first {
    width: $width4-1 - ($margin4-r/4);
    @include float-left;
    margin-right: $margin4-r/3;
  }

  > .ui-block-2,  
  > .ui-block.second {
    width: $width4-2 - ($margin4-r/4);
    @include float-left;
    margin-right: $margin4-r/3;
  }

  > .ui-block-3,
  > .ui-block.third {
    width: $width4-3 - ($margin4-r/4);
    @include float-left;
    margin-right: $margin4-r/3;
  }

  > .ui-block-4,
  > .ui-block.last {
    width: $width4-4 - ($margin4-r/4);
    @include float-left;
    margin-right: 0;  
  }
}


// Grid-5 vars

@mixin ui-grid-5($width5-1: 20%, $width5-2: 20%, $width5-3: 20%, $width5-4: 20%, $width5-5: 20%, $margin5-r: 8%) {

  @include pie-clearfix;

  > .ui-block-1,
  > .ui-block.first {
    width: $width5-1 - ($margin5-r/5);
    @include float-left;
    margin-right: $margin5-r/4;
  }

  > .ui-block-2,  
  > .ui-block.second {
    width: $width5-2 - ($margin5-r/5);
    @include float-left;
    margin-right: $margin5-r/4;
  }

  > .ui-block-3,
  > .ui-block.third {
    width: $width5-3 - ($margin5-r/5);
    @include float-left;
    margin-right: $margin5-r/4;
  }

  > .ui-block-4,
  > .ui-block.fourth {
    width: $width5-4 - ($margin5-r/5);
    @include float-left;
    margin-right: $margin5-r/4;
  }

  > .ui-block-5,
  > .ui-block.last {
    width: $width5-5 - ($margin5-r/5);
    @include float-left;
    margin-right: 0;  
  }
}

//Jeff's universal ui-grid layout mixin

@mixin cols($col-num: 2, $width-1: 0, $width-2: 0, $width-3: 0, $width-4: 0, $width-5: 0, $width-last: 0, $margin-r:5% ) {
  
  @include pie-clearfix;
  $var-list: $col-num, $width-1, $width-2, $width-3, $width-4, $width-5, $width-last, $margin-r;
 
  //Discovers margin option
  @for $num from 1 through length($var-list) {
    $value: nth($var-list, $num);
    @if $num == ($col-num + 2){
      @if $num < length($var-list) {
        @if type_of($value) == number {
          @if unit($value) == "%" {
            $margin-r: $value;
          }
        }
      }
    }
  }
 
  //Flip functionality
  $flip: false;
  @each $var in $var-list {
    @if $var == flip {
      $flip: true;
    }
  }
  @if $col-num == flip { $col-num: 2; }
  @if $margin-r == flip { $margin-r: 5%; }
  @if $width-1 == flip { $width-1: 0; }
  @if $width-2 == flip { $width-2: 0; }
  @if $width-3 == flip { $width-3: 0; }
  @if $width-4 == flip { $width-4: 0; }
  @if $width-5 == flip { $width-5: 0; }
  //eo flip functionality
 
  //Default values given no supplied values
  @if $width-1 == 0 { $width-1: 100% / $col-num; }
  @if $width-2 == 0 { $width-2: 100% / $col-num; }
  @if $width-3 == 0 { $width-3: 100% / $col-num; }
  @if $width-4 == 0 { $width-4: 100% / $col-num; }
  @if $width-5 == 0 { $width-5: 100% / $col-num; }
  @if $width-last == 0 { $width-last: 100% / $col-num; }
 
  //width-last needs to be determined no matter what, in case it's not supplied
  @if $col-num == 6 { $width-last: 100% - $width-5 - $width-4 - $width-3 - $width-2 - $width-1; }
  @if $col-num == 5 { $width-last: 100% - $width-4 - $width-3 - $width-2 - $width-1; }
  @if $col-num == 4 { $width-last: 100% - $width-3 - $width-2 - $width-1; }
  @if $col-num == 3 { $width-last: 100% - $width-2 - $width-1; }
  @if $col-num == 2 { $width-last: 100% - $width-1; }
 
  //Needed to reset var-list for some reason
  $var-list: $col-num, $width-1, $width-2, $width-3, $width-4, $width-5, $width-last, $margin-r;
  $name-list: first, second, third, fourth, fifth, sixth;
 
  //First col - second-to-last col
  @for $num from 2 through $col-num {
    $width: nth($var-list, $num);
    $name: nth($name-list, ($num - 1));
    $col: $num - 1;
    > .col-#{$col},
    > .col.#{$name} {
      margin-right: $margin-r/($col-num - 1);
      width: $width - ($margin-r/$col-num);
      @include float-left;
      @if $flip == true {
        @include float-right;
      }
    }
  } 
 
  //Last col
  > .col-#{$col-num},
  > .col.#{nth($name-list, $col-num)},
  > .col.last {
    width: $width-last - ($margin-r/$col-num);
    @include float-left;
    margin-right: 0;    
  }
}



// ============================================= //

// Micro Layouts                   // 

// ============================================= //


// Art & Text Pairs
// ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
// FOR: Harmoniously placing art and text next to each other.
// Good for thumbnails/text and many other art/text situations
// WHY: It's a common pattern. It's easier to write this way.

// HTML:
// <article class="l-pair">
//  <div class="art-bd">
//    <img class="art" src="#" />
//  </div> <!-- /art-bd -->
//  <div class="txt-bd">
//    <h1>Headline</h1>
//    <p>Paragraph paragraph paragraph paragraph</p>
//  </div> <!-- /txt-bd -->
// </article> <!-- /l-pair -->

// USE: @include pair(100px, 30px);

@mixin pair($art-width: 50px, $art-margin: 20px, $v-align: middle, $direction: left) {
  $gutter: $art-width + $art-margin;
  @include pie-clearfix;

  @if $v-align == middle {
    display: table;

    > .art-bd {
      display: table-cell;
      width: $art-width;
      margin: 0;
      > img,
      > .art {
        @include resp-img;
      }
    }
    > .txt-bd {
      display: table-cell;
      vertical-align: middle;
      padding-left: $art-margin;
    }

    // If lower than IE8, which has no
    // support for tables
    .lt-ie8 & {
      > .art-bd,
      > .txt-bd {
        display: block;
      }
      > .art-bd {
        width: $art-width;
        float: left;
      }
      > .txt-bd {
        margin-left: $gutter;
      }
    } // .lt-ie8
  } //middle

  @else {
    > .art-bd,
    > .txt-bd {
      display: block;
    }
    > .art-bd {
      width: $art-width;
      @if $direction == right {
        float: right;
      } @else {
        float: left;
      }
      margin: 0;
      > img,
      > .art {
        @include resp-img;
      }
    }
    > .txt-bd {
      @if $direction == right {
        margin-right: $gutter;
      } @else {
        margin-left: $gutter;
      }
    }
  }

} //pair  

  
// ============================================= //

// Spacers                         // 

// ============================================= //

// Variables

$spacer: 50px;
$spacer-small: round($spacer/5);
$spacer-med: round($spacer/2);
$spacer-large: round($spacer*1.5);


$spacer-top: inherit !default;
$spacer-bottom: inherit !default;

@mixin spacer($spacer-top, $spacer-bottom) {
  margin-top: $spacer-top;
  margin-bottom: $spacer-bottom;
}


// Selectors

%spacer {
  margin-bottom: $spacer;
}

%spacer-small {
  margin-bottom: $spacer-small;
}

%spacer-med {
  margin-bottom: $spacer-med;
}

%spacer-large {
  margin-bottom: $spacer-large;
}