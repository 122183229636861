.brand-logo {
	img {
		width: 90px;
		max-width: 90px;
	}
}

nav {
	.nav-wrapper {
		.nav-trigger i {
			@media #{$medium-only} {
				height: 56px;
				line-height: 56px;
			}
		}
	}
}

.nav-container {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1000;
	height: 0;
	nav {
		.btn {
			i.material-icons {
				font-size: 1.5rem;
			}
		}
		&.top-nav {
			font-family: $sans;
			height: 36px;
			line-height: 36px;
			max-height: 36px;
			box-shadow: none;
			background-color: #fff;
			border-bottom: 1px solid map-get($grey, 'lighten-4');
			z-index: 100;
			/*transition: max-height .15s linear;*/
			/*&.collapsed {
				max-height: 0;
				border: none;
			}*/
			.material-icons {
				font-size: 1.5rem;
				line-height: inherit;
			}
			ul {
				// height: inherit;
				li {
					a {
						@extend .grey-text;
						font-size: 0.75rem;
						letter-spacing: 1px;
						text-transform: uppercase;
						&.dropdown-trigger {
							border-right: 1px solid map-get($grey, 'lighten-4');
							padding-right: 30px;
							.material-icons {
								font-size: 1.25rem;
							}
						}
					}
				}
				&.left {
					li {
						border-right: 1px solid map-get($grey, 'lighten-4');
					}
				}
				&.right {
					li {
						border-left: 1px solid map-get($grey, 'lighten-4');
					}
				}
			}
		}
		&.site-nav {
			position: relative;
			font-family: $serif;
			z-index: 101;
			transition: all .5s ease;
			&.fixed {
				position: fixed;
				top: 0;
			}
			.menu-trigger {
				.material-icons {
					font-size: 33px;
				}
			}
			@media #{$medium-only} {
				height: 56px;
    			line-height: 56px;
			}
			@media #{$large-and-up} {
				.nav-items-list {
					position: absolute;
					right: 0;
					.nav-item {
						a {
							&:after {
								content: "";
								position: absolute;
								bottom: 0;
								left: 0;
								right: 0;
								border-bottom: 4px solid map-get($green, 'base');
								opacity: 0;
								transition: opacity .28s ease;
							}
							&.active {
								&:after {
									opacity: 1;
								}
							}
						}
						&.store-btn-container {
							&.active {
								background-color: #fff !important;
							}
							.store-btn {
								padding: 0 15px;
							}
						}
						&.checkout-btn-container {
							display: none;
							.checkout-btn {
								display: block;
								padding: 0 5px;
								.checkout-btn {
									transition: all .5s ease;
								}
								&:after {
									display: none;
								}
								i {
									float: left;
									font-size: 2.5rem;
								}
								.cart-count {
									position: absolute;
									font-size: 0.7rem;
									width: 100%;
									top: -5px;
									left: 0;
									text-align: center;
								}
							}
							&.cart-active {
								display: block;
							}
						}
					}
				}
			}
			&.mini {
				margin-top: -15px;
				max-height: 50px;
				&.mini-bottom {
					border-bottom: 1px solid map-get($grey, "lighten-2");
					box-shadow: none;
				}
				.nav-items-list {
					max-height: 50px;
					transition: all .5s ease;
					.nav-item {
						max-height: 50px;
						transition: all .5s ease;
						a {
							max-height: 50px;
							transition: all .5s ease;
							&.store-btn {
								border-radius: 0;
								box-shadow: none;
							}
						}
					}
				}
				.checkout-btn {
					transform: scale(.75) translateY(12px);
					transition: all .5s ease;
				}
				.nav-btns {
					.profile-btn {
						transform: scale(.75) translateY(18px);
					}
				}
			}
		}
		ul {
			li {
				a {
					&:hover {
						background-color: map-get($grey, 'lighten-4');
					}
				}
			}
		}
	}
}

.nav-btns {
	position: absolute;
	right: 1rem;
	bottom: 1px;
	padding-top: 0;
	.cart-btn {
		display: inline-block;
		position: relative;
		width: 42px;
		height: 30px;
		transform: translateY(-2px);
		.material-icons {
			font-size: 42px;
		}
		.cart-count {
			position: absolute;
			width: inherit;
			top: 0;
			text-align: center;
			font-size: 0.85rem;
			margin-top: -5px;
		}
	}
	.profile-btn {
		background-color: map-get($grey, 'base');
		width: 36px;
		height: 36px;
		margin-left: 0.5rem;
		transition: all .5s ease;
		.material-icons {
			line-height: 36px;
			height: 36px;
		}
	}
}

.search-trigger {
	max-height: 36px;
}

.user--loggedin {
	.nav-btns {
		.profile-btn {
			background-color: map-get($green, 'lighten-1');
		}
	}
}

.nav-search-form {
	@extend .grey, .lighten-4;
	display: block;
	overflow: hidden;
	height: 64px;
	max-height: 0;
	border-bottom: 1px solid map-get($grey, 'lighten-3');
	transition: max-height .28s ease;
	form {
		@extend .container;
		height: 64px;
		.input-field {
			@extend .grey, .lighten-3;
			margin-top: 0; 
			input[type="search"] {
				margin-top: 0;
				height: 64px;
				line-height: 64px;
				border-bottom: none;
				font-size: 1.15rem;
			}
			label {
				top: 0;
				&.active {
					transform: translateY(0);
					.material-icons {
						color: map-get($green, 'base') !important;
					}
					& + .nav-search-close {
						opacity: 1;
					}
				}
			}
			.material-icons {
				@extend .grey-text, .text-darken-4;
				font-size: 30px;
				line-height: 64px !important;
				transition: all .28s ease;
				&.nav-search-close {
					opacity: 0;
				}
			}
		}
	}
	/*&.active {
		max-height: 64px;
		&.collapsed {
			max-height: 0;
		}
	}*/
}

.nav-items {
	background-color: #fff;
	max-width: none;
	transition: max-width 300ms ease-in-out;
	&.hidden {
		max-width: 200px;
	}
}

.nav-store {
	font-family: $sans;
	@media #{$medium-and-down} {
	 	background-color: map-get($grey, 'lighten-3');
	}
}

.utility-menu {
	right: 10px;
	bottom: 10px;
}

.nav-article-progress {
	@extend .progress, .grey, .lighten-4; 
	position: absolute;
	height: 4px;
	margin: 0;
	bottom: -4px;
	border-radius: 0;
	z-index: 998;
	opacity: 0;
	transition: opacity .25s ease;
	.progress-amt {
		@extend .determinate, .green;
	}
}

.conference-nav-drop-items {
	z-index: 1;
}

// --------------------------------------------
// --------------------------------------------
//
// Alpha Nav
//
// --------------------------------------------
// --------------------------------------------

.alpha-nav-mod {
	display: block;
	background-color: #fff;
	overflow: hidden;
}

.alpha-nav-item {
	float: left;
	margin-right: 10px;
	font-size: 1.15em;
	margin-bottom: 1em;
	&:last-child {
		margin-right: 0;
	}
}

.alpha-nav-link {
	color: $link-color;
	text-decoration: none;
	font-weight: $sans-weight-bold;
	&:hover {
		text-decoration: underline;
	}
}


// --------------------------------------------
// --------------------------------------------
//
// Combo Nav
//
// --------------------------------------------
// --------------------------------------------

.combo-nav-mod {
	margin-bottom: 4em;
}
