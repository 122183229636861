// modals global
.modal {
	max-width: 1000px;
	max-height: none;
}
.modal-inner {
	float: left;
	display: block;
	width: 100%;
	.modal-content {
		display: block;
		width: 100%;
	}
}
.modal-footer {
	a, button {
		.material-icons {
			line-height: 2rem;
		    font-size: 1.2rem;
		    width: auto;
		}
	}
	a + a,
	button + button,
	a + button,
	button + a {
		margin-right: 0.5rem !important;
	}
}

// login modal
#login-modal {
	@media #{$large-and-up} {
		width: 40%;
		max-width: 600px;
	}
}

// mega menu modals
.mega-modal {
	@extend .modal, .container;
	position: fixed;
	font-family: $serif;
	border-radius: 0;
	height: auto;
	padding: 0;
	overflow: hidden;
	max-height: none;
	max-width: 1280px;
	z-index: 99999;
	&.active {
		@media #{$medium-and-up} {
			display: block;
		}
	}

	&#conferences-modal {
		.row {
			margin-right: 0;
		}
	}
	.row {
		margin-bottom: 0;
		.col {
			&:nth-child(1) {
				padding-right: 0;
			}
			&:nth-child(2) {
				padding-left: 0;
			}
			.collection {
				margin: 0;
				border-left: none;
				.collection-item {
					@extend .grey-text, .text-darken-4;
					border-left: 4px solid map-get($grey, 'lighten-2');
					transition: all .28s ease;
					&:hover {
						border-left: 4px solid map-get($green, 'base');
					}
				}
				&.featured-items {
					background-color: #fff;
					border: none;
					border-radius: 0;
					.collection-item {
						width: 50%;
						float: left;
						border: none;
						min-height: 0;
						&:nth-child(odd) {
							clear: both;
						}
						.title {
							@extend .grey-text, .text-darken-3;
							font-family: $sans;
							font-weight: bold;
							font-size: 16px;
						}
						p {
							@extend .grey-text, .text-darken-2;
							font-size: 0.85rem;
							margin-top: 0;
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
	&.store-modal {
		max-width: 600px;
		visibility: hidden;
		&.hidden {
			display: none !important;
		}
		@media #{$medium-and-up} {
			margin-right: 2.5%;
		}
		@media #{$xlarge-and-up} {
			margin-right: 7.5%;
		}
		&.not-empty {
			visibility: visible;
		}
		.row {
			.col {
				.mega-cart-hdr {
					padding: 0 20px;
				}
				.collection.featured-items {
					.collection-item {
						width: 100%;
						border-bottom: 1px solid map-get($grey, "lighten-3");
						&:hover {
							background-color: #fff;
						}
						.mega-cart-title {
							@extend .grey-text, .text-darken-4;
							display: block;
							max-width: 70%;
							float: left;
							.mega-cart-img {
								height: 64px;
								width: 64px;
								float: left;
								margin-right: 1rem;
								img {
									height: 100%;
									max-height: 100%;
									width: auto;
								}
							}
						}
						.m-price {
							span {
								&:nth-child(1) {
									display: inline-block;
								}
								&:nth-child(2) {
									display: none;
								}
							}
						}
						.nm-price {
							span {
								&:nth-child(1) {
									display: none;
								}
								&:nth-child(2) {
									display: inline-block;
								}
							}
						}
						.mega-cart-quantity {
							@extend .grey, .lighten-2;
							float: right;
							border-radius: 2px;
							margin-left: 0.5rem;
							span {
								float: left;
								&.increase-count {
									@extend .grey-text, .text-darken-1;
									font-size: 0.85rem;
									padding: 0.4rem 0.1rem 0;
									cursor: pointer;
									transition: color .28s ease;
									&:hover {
										color: map-get($green, "base") !important;
									}
								}
								&.count {
									@extend .grey, .lighten-4;
									font-size: 1rem;
									padding: 0 0.4rem;
								}
								&.decrease-count {
									@extend .grey-text, .text-darken-1;
									font-size: 0.85rem;
									padding: 0.4rem 0.1rem 0;
									cursor: pointer;
									transition: color .28s ease;
									&:hover {
										color: map-get($orange, "base") !important;
									}
								}
							}
							&:after {
								display: none;
							}
						}
						.mega-cart-remove,
						.mega-cart-edit {
							display: inline-block;
							float: right;
							height: 24px;
							padding: 0 3px;
							margin-left: 0.5rem;
							margin-bottom: 0;
							line-height: inherit;
							cursor: pointer;
							transition: all .28s ease;
						}
						.mega-cart-remove {
							color: map-get($orange, "base");
							&:hover {
								background-color: map-get($orange, "base");
								color: #fff;
							}
							.material-icons {
								font-size: 1.1rem;
								transform: translateY(4px);
							}
						}
						.mega-cart-edit {
							color: map-get($light-blue, "darken-1");
							padding: 0 0.5rem;
							margin-right: -0.25rem;
							&:hover {
								background-color: map-get($light-blue, "darken-1");
								color: #fff;
							}
							.material-icons {
								font-size: 0.85rem;
								margin-right: 0.25rem;
								transform: translateY(5px);
							}
						}
					}
				}
				.mega-cart-update {
					position: relative;
					height: 3.4rem;
					.btn {
						font-family: $serif;
						text-transform: uppercase;
						position: absolute;
						right: 1.75rem;
						top: 0.5rem;
						width: 40%;
						padding: 0 15px;
					}
					.mega-cart-total {
						position: absolute;
						font-weight: bold;
						left: 1.25rem;
						top: 1rem;
					}
					.mega-cart-total-pending {
						@extend .mega-cart-total, .grey-text;
						font-weight: normal;
						font-style: italic;
					}
				}
			}
		}
	}
}

// profile modal
.profile-modal {
    width: 260px;
    margin-right: 0.5rem;
    padding-top: 1rem;
    font-family: $serif;
    .modal-content {
    	display: block;
    	padding: 0;
    	.row {
    		margin-bottom: 0.75rem;
    		.heading {
	    		padding: 0 0.5rem;
	    		h6 {
	    			font-family: $serif;
	    			color: #222;
	    		}
	    		p {
	    			line-height: 0;
	    			color: #222;
	    		}
	    	}
    	}
    	.collection {
			margin: 0;
			.collection-item {
				@extend .grey-text, .text-darken-4;
				border-left: 4px solid map-get($grey, 'lighten-2');
				transition: all .28s ease;
				&:hover {
					border-left: 4px solid map-get($green, 'base');
				}
			}
		}
    }
}

// Filter modal
.filters-modal {
	&.modal-fixed-footer{
		max-height: 100%;
		height: 100%;
		top: 0!important;
		width: 100%;
		.modal-footer {
			text-align: center;
			.close-filters {
				float: none;
			}
		}
	}
}

// Cookie-setting modals
#consent-modal,
#office-closed-modal {
	position: absolute;
	z-index: 100;
	display: block;
	max-width: none;
	width: 100%;
	border-bottom: 4px solid map-get($orange, "base");
	.modal-content {
		display: block;
		position: relative;
		padding: 1em 0;
		overflow: hidden;
		@media #{$medium-and-down} {
			padding: 1.5em 0 0.5em;
		}
		p {
			display: inline-block;
			padding: 0;
			margin: 0;
			@media #{$large-and-up} {
				max-width: 75%;
			}
			a {
				color: map-get($cyan, "darken-1");
			}
			&.right {
				@media #{$large-and-up} {
					position: absolute;
					bottom: 15px;
					right: 0;
					max-width: none;
				}
			}
		}
	}
	.modal-close {
		font-family: $sans;
	}
}
#office-closed-modal {
	.modal-content {
		p {
			&.left,
			&.right {
				@media #{$large-and-up} {
					bottom: 0.3em;
					line-height: 120%;
				}
			}
		}
	}
}
#ps-modal {
	display: block;
	bottom: 0;
	max-width: none;
	border-top: 4px solid map-get($green, "base");
	.modal-content {
		display: block;
		padding: 0.5em;
		overflow: hidden;
		@media #{$medium-and-down} {
			padding: 0 0 0.5em;
		}
		p {
			display: inline-block;
			padding: 0;
			margin-bottom: 0;
			line-height: 1.5em;
			@media #{$large-and-up} {
				margin: 0;
			}
			a {
				color: map-get($cyan, "darken-1");
			}
			&.icon-container {
				height: auto;
				@media #{$medium-and-down} {
					display: none;
				}
				.material-icons {
					transform: translateY(0.2em);
				}
			}
		}
	}
	.modal-close {
		font-family: $sans;
	}
}