// --------------------------------------------
// --------------------------------------------
//
// Default Tab Styles
//
// --------------------------------------------
// --------------------------------------------

.r-tabs {
	position: relative;

	.r-tabs-nav {
		margin: 0;
		padding: 0;
		margin-bottom: 2em;

		&:before {
			content: '\0020';
			position: absolute;
			left: 0;
			right: 0;
			top: 66px;
			height: 4px;
			width: 100%;
			background-color: $bg-med;
			z-index: 1;
		}

		.r-tabs-tab {
			position: relative;
		}

		.r-tabs-anchor {
			display: inline-block;
			width: 100%;
			text-decoration: none;
			color: $black-40;
			font-size: 16px;
			padding: 1em;
		}

		.tab-text-sm {
			font-size: 14px;
		}

		.r-tabs-state-active {
			&:after {
				content: '\0020';
				width: 0;
				height: 0;
				position: absolute;
				top: 70px;
				left: 50%;
				margin-left: -9px;
				z-index: 4;
				border-top: 9px solid $green-main;
				border-right: 9px inset transparent;
				border-bottom: 9px inset transparent;
				border-left: 9px inset transparent;
			}

			&:before {
				content: '\0020';
				position: absolute;
				left: 0;
				right: 0;
				top: 66px;
				height: 4px;
				width: 100%;
				background-color: $green-main;
				z-index: 1;
			}
		}

		.r-tabs-state-disabled {
			opacity: 0.5;
		}
	}

	.r-tabs-nav.icon-tabs {

		&:before {
			content: '\0020';
			position: absolute;
			left: 0;
			right: 0;
			top: 91px;
			height: 4px;
			width: 100%;
			background-color: $bg-med;
			z-index: 1;
		}
	}

	.r-tabs-tab {
	    display: table-cell;
	    list-style: none;
	    vertical-align: middle;
	    text-align: center;
	    border-right: 3px solid #fff;
	    height: 70px;
	    min-width: 92px;
	}

	.r-tabs-panel {
		display: none;
	}

	.r-tabs-accordion-title {
	    display: none;
	    font-size: 18px;
	    text-align: center;
	    padding: 0.55em 0;
	    border-bottom: 1px solid $bg-med;

	    .r-tabs-anchor {
	    	display: block;
	    	text-decoration: none;
	    	color: $black-40;
	    }
	}

	.r-tabs-accordion-title.r-tabs-state-active {
		background-color: $bg-med;
	}

	.r-tabs-panel.r-tabs-state-active {
	    display: block;
	}

	.r-tabs-accordion-title.r-tabs-state-disabled {
		opacity: 0.5;
	}

	.r-tabs-accordion-title.r-tabs-state-active {
		.r-tabs-anchor {
				margin-bottom: 0;
			}
	}
}

.icon-tabs {
	.txt-only {
		min-width: 92px;

		.r-tabs-anchor {
			height: 100%;
			padding: 1.3em 1em;
		}
	}
}

.panel {
	padding-top: 2em;
}

.collapsible {
	min-height: 7em;

	.r-tabs-nav,
	.r-tabs-accordion-title {

		.r-tabs-anchor {
			color: $link-color;
			font-weight: bold;
		}
	}

	.r-tabs-state-active {

		.r-tabs-anchor {
			color: $black-40;
		}
	}

	.r-tabs-nav {

		&:before {
			display: none;
		}		
	}

	.panel {
		&:before {
			content: '\0020';
			position: absolute;
			left: 0;
			right: 0;
			margin-top: -4.3em;
			height: 4px;
			width: 100%;
			background-color: $bg-med;
			z-index: 0;
		}
	}
}

.wc-tabs {
	.r-tabs-nav {
		&:before {
			top: 199px;
		}
	}
}

.no-js {
	min-height: 7em;

	.r-tabs-nav,
	.r-tabs-accordion-title {

		.r-tabs-anchor {
			color: $link-color;
			font-weight: bold;
		}
	}

	.r-tabs-state-active {

		.r-tabs-anchor {
			color: $black-40;
		}
	}

	&.loading {
		
		.panel {
			-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=33)";
			filter: alpha(opacity=33);
			-moz-opacity: 0.33;
			-khtml-opacity: 0.33;
			opacity: 0.33;
			&:after {
				content: url('/wp-content/themes/iapp/static/img/ui-img/icons/gif/712.GIF');
				position: absolute;
				width: 40px;
				height: 40px;
				top: 290px;
				left: 50%;
				margin-left: -20px;
				text-align: center;
			}
		}
	}
}

.breakout-sessions {
	.tabs {
		.sess-tabs {
			display: none;
			@media(min-width: $desktop) {
				display: block;
			}
		}
	}
}

.more-conferences {
	.r-tabs-nav {
		float: right;
		margin-top: -1.5em;
		&:before {
			top: 47px;
		}
	}
	.r-tabs-accordion-title {
		clear: both;
		margin: 2em 0 1em;
		float: left;
		width: 100%;
	}
	.panel {
		clear: both;
		padding-top: 0;
	}
}

.sponsoring, .exhibiting {
	.r-tabs-nav {
		&:before {
			top: 66px;
		}
		.r-tabs-state-active {
			&:after {
				top: auto;
				bottom: -14px;
			}

			&:before {
				top: auto;
				bottom: 0;
			}
		}
	}
}

.college-tabs {
	@media (min-width: $large) {
		.r-tabs-nav {
			.r-tabs-tab {
				width: 15%;
				&:first-child {
					width: 40%;
					&.r-tabs-state-active {
						&:after {
							left: 47%;
						}
					}
				}
			}
		}
	}
}

.college-region {
	margin-top: 3em;
	margin-bottom: 0;
	border-bottom: 4px solid #ccc;
}

// --------------------------------------------
// --------------------------------------------
//
// Accordion Responsive Breakpoint
//
// --------------------------------------------
// --------------------------------------------

@media only screen and (max-width: $small) {
	.no-js {
		.r-tabs-nav {
			.r-tabs-tab {
				min-width: 0;
				.r-tabs-anchor {
					padding: 0.2em;
					font-size: 10px;
				}
			}
		}
	}
}

@media only screen and (max-width: $medium) {
	.no-js {
		.r-tabs-nav {
			.r-tabs-tab {
				min-width: 0;
				width: 18%;
				.r-tabs-anchor {
					padding: 0.3em;
					font-size: 12px;
				}
			}

			.r-tabs-state-active {
				&:after {
					left: 35%;
				}
			}
		}
	}
}

@media only screen and (max-width: $large) {
    .r-tabs {
    	.r-tabs-nav {
    	    display: none;
    	}
    	.list-item {
    		padding-bottom: 0;
    	}
    }

    .r-tabs {
        .r-tabs-accordion-title {
            display: block;
        }
    }

    .panel {
    	&:before {
    		display: none;
    	}
    }

	.no-js {
		.r-tabs-nav {
		    display: block;
		}
		.r-tabs-accordion-title {
		    display: none;
		}
		.panel {
			&:before {
				display: block;
			}
		}
	}
}



// --------------------------------------------
// --------------------------------------------
//
// Westin Surveys
//
// --------------------------------------------
// --------------------------------------------



.westin-surveys {
	.countries {
		.country {
			display: table;
			width: 100%;
			padding-top: 10px;
			border-bottom: 4px solid #e9eaea;
			@media(min-width: $medium) {
				padding-top: 0;
			}
			.rail {
				@media(min-width: $medium) {
					display: table-cell;
					width: 150px;
					padding: 10px;
					background-color: #f2f2f2;
				}
			}
			.answer {
				@media(min-width: $medium) {
					display: table-cell;
					padding: 10px;
				}
			}
		}
	}
}



// --------------------------------------------
// --------------------------------------------
//
// Floating tabs
//
// --------------------------------------------
// --------------------------------------------



.floating-tabs {
	position: relative;
	ul {
		position: relative;
		max-width: 788px;
		background-color: #fff;
	}
}


.list-training-partner {
	max-width: 50px;
	float: right;
	@media(min-width: $small) {
		margin-top: -1em;
	}
	@media(min-width: $large) {
		margin-top: -0.3em;
		margin-bottom: -0.3em;
	}
}