// --------------------------------------------
// --------------------------------------------
//
// Base 16 breakpoints
//
// --------------------------------------------
// --------------------------------------------


// if base 16
$small: 			12.5em; 		// 200px
$medium: 			37.5em;			// 600px
$med-lg:			46.875em;		// 750px
$large: 			56.25em;		// 900px
$desktop: 			64em;			// 1024px
$x-large: 			68.75em; 		// 1100px



// --------------------------------------------
// --------------------------------------------
//
// Element or mod breakpoints
//
// --------------------------------------------
// --------------------------------------------

$el-small: 			30.769230769em; 		// 400px
$el-medium: 		$medium;




// --------------------------------------------
// --------------------------------------------
//
// RWD type breakpoints
//
// --------------------------------------------
// --------------------------------------------

// These breakpoints are used for display type elements,
// generally making them smaller at smaller screen sizes.
// Most applications of this will be in type.scss

$type-bp-med: $medium;

$type-bp-lg: $large;




// --------------------------------------------
// --------------------------------------------
//
// Site Navigation breakpoints
//
// --------------------------------------------
// --------------------------------------------

$nav-bp-sm: $medium;

$nav-bp-med: $large;

$nav-bp-lg: $x-large;

$nav-bp-xlg: 1260px;




// --------------------------------------------
// --------------------------------------------
//
// Materialize media queries
//
// --------------------------------------------
// --------------------------------------------


$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;



