
// --------------------------------------------
// --------------------------------------------
//
// Blacks + Grays
//
// --------------------------------------------
// --------------------------------------------

$black-100: 	#1f1f1f; 				// 31, 31, 31
$black-90: 		#222222;				// 34, 34, 34
$black-80: 		#2e2e2f; 				// 46, 46, 47
$black-70: 		#414141; 				// 65, 65, 65
$black-60: 		#464547;				// 70, 69, 71
$black-50: 		#555555; 				// 85, 85, 85
$black-40: 		#666666; 				// 102, 102, 102
$black-30: 		#808285; 				// 128, 130, 133
$black-20: 		#a7a9ac; 				// 167, 169, 172
$black-10: 		#cccccc; 				// 204, 204, 204




// --------------------------------------------
// --------------------------------------------
//
// Color
//
// --------------------------------------------
// --------------------------------------------

$green-main: 	#70bf4c; 				// 112, 191, 76
$green-med: 	#6db650; 				// 109, 182, 80
$green-lt: 		#8cc073;				// 140, 192, 115

$purple-bg: 	#7a7994;				// 122, 121, 148
$green-bg: 		#246c60; 				// 36, 108, 96
$blue-bg: 		#003f64; 				// 0, 63, 100
$gray-bg: 		#4e565b; 				// 78, 86, 91


$link-color: 	#0c7ec0; 				// 12, 126, 192

$orange: 		#fab150;				// 250, 177, 80
$orange-x: 		#e64b3d; 				// 230, 75, 61

$red-lt:		#ff9494;				// 255, 148, 148


// --------------------------------------------
// --------------------------------------------
//
// Palette 2
//
// --------------------------------------------
// --------------------------------------------

$swatch-1: 	#e64b3d; 					// 230, 75, 61
$swatch-2: 	#51a1aa; 					// 81, 161, 170
$swatch-3: 	#b42d35; 					// 180, 45, 53
$swatch-4: 	#f0c125; 					// 240, 193, 37
$swatch-5: 	#e12231; 					// 225, 34, 49
$swatch-6: 	#732969; 					// 115, 41, 105
$swatch-7: 	#6db44f; 					// 109, 180, 79
$swatch-7a: #558149; 					// 85, 129, 73
$swatch-8: 	#2782b8; 					// 39, 130, 184
$swatch-8a: #336483; 					// 51, 100, 131
$swatch-9: 	#1a3865; 					// 26, 56, 101
$swatch-9a: #1e324e; 					// 30, 50, 78
$swatch-10: #236c5f; 					// 35, 108, 95



$head-color: $black-80;
$head-color-lt: $black-40;

$tz-text-color: $black-60;
$tz-text-color-med: $black-50;
$tz-text-color-lt: $black-30;

$nav-link-color: $black-60;

$article-text: $black-80;





// --------------------------------------------
// --------------------------------------------
//
// Backgrounds
//
// --------------------------------------------
// --------------------------------------------

$bg-dark: 			#d9dadb;			// 217, 218, 219
$bg-med: 			#e9eaea; 			// 233, 234, 234
$bg-lt: 			#f7f7f7;			// 247, 247, 247

$bg-skybox: 		#89a5b4; 			// 137, 165, 180




// --------------------------------------------
// --------------------------------------------
//
// Header Colors
//
// --------------------------------------------
// --------------------------------------------

$hdr-default-color: 	$gray-bg;

// These are the specific headers for specific IAPP
// Publications
$hdr-daily-dashboard: 		#1eb89a; 					// 30, 184, 154
$hdr-privacy-tracker: 		#256D60;
$hdr-privacy-perspective: 	#7A2E36;


$hdr-conference: darken( rgba($purple-bg, 0.55), 17%);




// --------------------------------------------
// --------------------------------------------
//
// Social Icon Colors
//
// --------------------------------------------
// --------------------------------------------

$twitter: 		#55acee;
$facebook: 		#3b5998;
$google: 		#dd4b39;
$linkedin:		#007bb6;
$comment: 		$purple-bg;
$email: 		$swatch-10;
$print: 		$swatch-6;




// --------------------------------------------
// --------------------------------------------
//
// Resource Colors
//
// --------------------------------------------
// --------------------------------------------

$topics-bg:			$orange; 
$tools-bg: 			$link-color;
$research-bg: 		$purple-bg; 					
$articles-bg: 		$green-main;
$job-bg: 			$orange-x; 
$jobs-bg: 			$orange-x;
$dpa-bg: 			$swatch-2;
$gallery-bg: 		$orange;
$discuss-bg: 		$swatch-10;
$glossary-bg: 		$swatch-8a;
$westin-bg:			$black-100;
$books-bg:			$blue-bg;
$presentations-bg: 	$swatch-1;
$infographic-bg: 	$swatch-1;
$webcons-bg:		$swatch-6;	
$videos-bg:			$gray-bg;
$video-bg:			$gray-bg;




// --------------------------------------------
// --------------------------------------------
//
// Hero Background Colors
//
// --------------------------------------------
// --------------------------------------------

$hero-default: 	#1a4d6d;
$hero-red: 		$swatch-3;
$hero-green: 	$green-bg;
$hero-purple: 	$swatch-6;
$hero-orange: 	#974d09;
$hero-pink: 	#9a1f4d;



// --------------------------------------------
// --------------------------------------------
//
// Session Colors
//
// --------------------------------------------
// --------------------------------------------

$traditional-breakout-bg:			#C21C76;
$preconference-workshop-bg:			$orange-x;
$certification-bg:					$green-main;
$training-bg:						$swatch-10;
$active-learning-bg:				$orange;
$conversations-in-privacy-bg:		$purple-bg;
$conversations-at-psr-bg:	  		$purple-bg;
// $from-the-game-changers-bg:			$swatch-2;
// $inspired-speakers:					$swatch-2;
$spotlight-stage:					$swatch-2;
$the-little-big-stage-bg:			$swatch-8a;
$advanced-topics-in-privacy-bg:		$blue-bg;
$networking-bg:						$gray-bg;
$general-session-bg:				$swatch-6;
$presentation-of-research:			#236C5F;
$privacy-tech-vendor-showcase-bg:   #00a0e2;
$panel-discussion:					#2782B8;




// --------------------------------------------
// --------------------------------------------
//
// Learn Colors
//
// --------------------------------------------
// --------------------------------------------

$access-bg: 		$green-main;
$insight-bg: 		$link-color;
$innovation-bg: 	#3f3e66;


// --------------------------------------------
// --------------------------------------------
//
// Colorized teases :: Conference teases
//
// --------------------------------------------
// --------------------------------------------

$colorized-1: $swatch-6;
$colorized-2: $swatch-3;
$colorized-3: $swatch-8;
