// element resets
p {
	line-height: 1.5;
	display: block;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;

	@media only screen and (min-width: 0) {
		font-size: 14px;
	}

	@media only screen and (min-width: 992px) {
		font-size: 14.5px;
	}

	@media only screen and (min-width: 1200px) {
		font-size: 15px;
	}
}

ul {
	display: block;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 40px;
}

// nav resets
.site-nav {
	position: relative;
	// .nav-search-trigger {
	// 	height: auto;
	//     line-height: inherit;
	//     width: auto;
	//     height: auto;
	// }
}

.user--loggedin {
	.profile-btn {
		.btn-floating {
			background-color: #66BB6A !important;
		}
	}
}

//

.subsection-hdr {
	text-transform: none;
	letter-spacing: normal;
}

// modals

.modal {
	background-color: #fff;
	border: none;
	.modal-close {
		right: inherit;
		top: inherit;
		font-weight: inherit;
		width: auto;
	}
}

// Ads

.dfad {
	padding-bottom: 0;
	border-bottom: none;
}