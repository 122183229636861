#env-indicator {
	margin: 0;
    bottom: 0;
    right: 75px;
    font-size: 30px;
    color: #fff;
    position: fixed;
    display: block;
    z-index: 99999999;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 5px 20px;
	@media(max-width: $medium) {
		display: none !important;
	}
	&.production {
        background-color: red;
    }
    &.staging {
        color: #808285;
        background-color: yellow;
    }
    &.dev {
        background-color: green;
    }
}

$toast-height: 48px !default;
$toast-color: #323232 !default;
$toast-text-color: #fff !default;

#toast-container {
  display:block;
  position: fixed;
  z-index: 10000;

  @media #{$small-and-down} {
    min-width: 100%;
    bottom: 0%;
  }
  @media #{$medium-only} {
    right: 5%;
    bottom: 7%;
    max-width: 90%;
  }
  @media #{$large-and-up} {
    bottom: 10%;
    right: 7%;
    max-width: 86%;
  }
}

.toast {
  @extend .z-depth-1;
  font-family: $serif;
  border-radius: 2px;
  top: 0;
  width: auto;
  clear: both;
  margin-top: 10px;
  position: relative;
  max-width:100%;
  height: auto;
  min-height: $toast-height;
  line-height: 1.5em;
  word-break: break-all;
  background-color: $toast-color;
  padding: 10px 25px;
  font-size: 1.1rem;
  font-weight: 300;
  color: $toast-text-color;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .btn, .btn-flat {
    margin: 0;
    margin-left: 3rem;
  }

  &.rounded{
    border-radius: 6px;
    @media #{$medium-and-down} {
    	border-radius: none;
    }
  }

  @media #{$small-and-down} {
    width:100%;
    border-radius: 0;
  }
  @media #{$medium-only} {
    float: left;
  }
  @media #{$large-and-up} {
    float: right;
  }

}


// --------------------------------------------
// --------------------------------------------
//
// Rounded tease images
//
// --------------------------------------------
// --------------------------------------------

.img-rounded {
	border-radius: 50%;
	overflow: hidden;
	height: auto;
	img {
		@include resp-img;
	}
}



// --------------------------------------------
// --------------------------------------------
//
// Regular tease image
//
// --------------------------------------------
// --------------------------------------------

.img-tz, %img-tz {
	overflow: hidden;
	img {
		@include resp-img;
		width: auto;
	}
	figure {
		img {
			margin: auto;
			display: table-cell;
		}
		
	}

}

.tz-art {
	@extend %img-tz;
}




// --------------------------------------------
// --------------------------------------------
//
// HP top image
//
// --------------------------------------------
// --------------------------------------------

.hp-hero-media-block {
	@extend %img-tz;
	margin-bottom: 2em;
	display: block;
	text-align: center;
	img {
		max-height: 18em;
	}
}



// --------------------------------------------
// --------------------------------------------
//
// Page image tease
//
// --------------------------------------------
// --------------------------------------------

.page-img-tz {
	@extend %img-tz;
	margin-bottom: 1.5em;
	position: relative;
}



// --------------------------------------------
// --------------------------------------------
//
// Nav triangle
//
// --------------------------------------------
// --------------------------------------------

%nav-triangle {
	position: relative;
	&:before {
		content: '\0020';
		width: 0;
		height: 0;
		position: absolute;
		top: -19px;
		left: 53%;
		z-index: 2;
		border-top: 9px inset transparent;
		border-right: 9px inset transparent;
		border-bottom: 9px solid $green-main;
		border-left: 9px inset transparent;
	}
}

// --------------------------------------------
// --------------------------------------------
//
// Menu burger
//
// --------------------------------------------
// --------------------------------------------

%burger {
	background-color: red;
	width: 30px;
	height: 1px;
	position: relative;
	&:before, &:after {
		content: '\0020';
		position: absolute;
		height: 1px;
		background-color: blue;
		left: 0;
		right: 0;

	}
	&:before {
		top: 3px;
	}
	&:after {
		bottom: 3px;
	}
}



// --------------------------------------------
// --------------------------------------------
//
// Hex shape
//
// --------------------------------------------
// --------------------------------------------

@mixin hex($width, $color: $green-main) {
	width: ($width * 0.85) * 1px;
	height: (round($width * 1.6)) * 1px;
	background-color: $color;
	position: relative;

	&:before, &:after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		width: 0;
		height: 0;
	}

	$tri-visible: (round($width * 0.5)) * 1px;
	$tri-invisible: (round($width * 0.8)) * 1px;

	&:before {
		left: -$tri-visible;
		border-right: $tri-visible solid $color;
		border-bottom: $tri-invisible solid transparent;
		border-top: $tri-invisible solid transparent;
	}

	&:after {
		right: -$tri-visible;
		border-left: $tri-visible solid $color;
		border-bottom: $tri-invisible solid transparent;
		border-top: $tri-invisible solid transparent;
	}

	@each $session in $sessions {
		&.icon-#{nth($session, 1)}  {
			background-color: nth($session, 2);
			&:before { border-right-color: nth($session, 2); }
			&:after { border-left-color: nth($session, 2); }
		}
	}

	@each $resource in $resources {
		&.icon-#{nth($resource, 1)}  {
			background-color: nth($resource, 2);
			&:before { border-right-color: nth($resource, 2); }
			&:after { border-left-color: nth($resource, 2); }
		}
	}

	.menu-icons & {
		position: absolute;
		top: 7px;
		right: 15px;
		opacity: 0.6;
		.icon { top: 4px; left: 0; }
	}
}


.hex-sm { @include hex(15); }

.hex-med { @include hex(20); }

.hex-lg { @include hex(35); }

.hex-xlg { @include hex(100); }


.hex-gray-sm { @include hex(15, $black-10); }

.hex-gray-lg { @include hex(35, $black-10); }

.hex-gray-xlg { @include hex(100, $black-10); }


.hex-mega { @include hex(28); }


.hex-warning-med { @include hex(20, $orange); margin-left: 20px !important; }

.hex-success-med { @include hex(20); margin-left: 20px !important; }
.hex-error-med { @include hex(20, $red-lt); margin-left: 20px !important; }

// --------------------------------------------
// Learn section icons

.icon-headphones {
	background-color: $access-bg;
}

.icon-star {
	background-color: $insight-bg;
}

.icon-lightbulb {
	background-color: $innovation-bg;
}


.icon-container {
	width: 60px;
	height: 60px;
	position: relative;
	border-radius: 50%;
}



// --------------------------------------------
// saveable

.tz-saveable-block {
	position: absolute;
	top: 0;
	left: -3em;
	@media (min-width: $medium) {
		left: 0;
	}
}

.tz-saveable {
	text-decoration: none;
	color: $bg-dark;
	text-align: center;
	&:hover {
		color: darken($bg-dark, 5%);
	}
	.is-saved & {
		color: $green-main;
	}
}

.saveable-icon {
	font-size: 2em;
}



// --------------------------------------------
// --------------------------------------------
//
// Helper + Modifiers
//
// --------------------------------------------
// --------------------------------------------

.text-centered { text-align: center; }


.img-link { display: block; }





// --------------------------------------------
// --------------------------------------------
//
// Space between media block inside grid-x
//
// --------------------------------------------
// --------------------------------------------
// This controls space between media-block and the .txt-bd that are
// inside grid-x containers.

.media-block {
	[class^="grid-"] & {
		margin-bottom: 2em;
		.txt-bd {
			margin-top: 1em;
		}
	}
	&.vertical-break {
		position: relative;
		&:before {
			@media (min-width: $medium) {
				content: '';
				width: 0;
				height: 100%;
				position: absolute;
				border: 1px solid #dedede;
				top: 0;
				left: 35px;
			}
		}
	}
}






// --------------------------------------------
// --------------------------------------------
//
// Next + Previous
//
// --------------------------------------------
// --------------------------------------------

%next {
	height: 35px;
	width: 35px;
	&:before {
		position: absolute;
		content: '';
		top: 25%;
		right: 36%;
		border-top: 8px inset transparent;
		border-bottom: 8px inset transparent;
		border-left: 8px solid lighten($black-90, 40%);
		border-right: 0px inset transparent;
	}
	&:after {
		position: absolute;
		content: '';
		top: 25%;
		right: 48%;
		border-top: 8px inset transparent;
		border-bottom: 8px inset transparent;
		border-left: 8px solid white;
		border-right: 0px inset transparent;
	}
}


%prev {
	height: 35px;
	width: 35px;
	&:before {
		position: absolute;
		content: '';
		top: 25%;
		left: 36%;
		border-top: 8px inset transparent;
		border-bottom: 8px inset transparent;
		border-right: 8px solid lighten($black-90, 40%);
		border-left: 0px inset transparent;
	}
	&:after {
		position: absolute;
		content: '';
		top: 25%;
		left: 48%;
		border-top: 8px inset transparent;
		border-bottom: 8px inset transparent;
		border-right: 8px solid white;
		border-left: 0px inset transparent;
	}
}




// --------------------------------------------
// --------------------------------------------
//
// Chapter list blocks
//
// --------------------------------------------
// --------------------------------------------

.chapter-block {
	border-bottom: $bdr-thin;
	margin-bottom: 2em;
	padding-bottom: 2em;
	p {
		margin-bottom: 0.55em;
	}
	&:last-child {
		border-bottom: none;
	}
	.grid-3 {
		p.media-block {
			margin-bottom: 1em;
			font-size: 1.076923077em;
			line-height: 1.15em;
			font-weight: 400;
		}
	}
}




// --------------------------------------------
// --------------------------------------------
//
// Interstitial Quote
//
// --------------------------------------------
// --------------------------------------------

$quote-gradient-1: rgba(#336483, 0.9);
$quote-gradient-2: rgba(#6db44f, 0.8);



.inter-img {
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;

	@each $quote in $quotes {
		&.hdr-quote-#{$quote} {
			background-image: url(#{$quote-path}/#{$quote}.jpg);
		}
	}

	&:before {
		content: '';
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		position: absolute;
		background-image: linear-gradient( 70deg, $quote-gradient-1, transparent);
		// ie9
		.rgba.no-cssgradients & { background-color: $quote-gradient-1; }
		 // ie8
		.no-rgba.no-cssgradients & { background-color: lighten(#336483, 10%); }
	}
	&:after {
		content: '';
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		position: absolute;
		background-image: linear-gradient( 70deg, transparent, $quote-gradient-2);
	}
}

.inter-inner {
	padding: 4em 0;
	@include pie-clearfix;
}

.inter-quote {
	position: relative;
}


.inter-tagline {
	@extend %tz-text-reg;
	font-weight: $sans-weight-light;
	color: #fff;
}

.inter-quote-mod {
	color: #fff;
	font-weight: $sans-weight-light;
	font-size: 2.25em;
	line-height: 1.5em;
	position: relative;
	z-index: 2;
	border-left: 0px !important;
	background-color: transparent !important;
}


.inter-quote {
	margin-bottom: 0.85em;
}

.inter-quote-attr {
	font-size: 0.45em;
	text-align: right;
	font-weight: $serif-weight-reg;
}

.quote-line {
	position: relative;
	width: 100%;
	height: 30px;
	background-image: url(/wp-content/themes/iapp/static/img/quote_arrow.svg);
	background-repeat: no-repeat;
	background-position: 70% 0;
	background-size: cover;
}


.inter-block--quote {
	@media(min-width: $nav-bp-lg) {
		float: right;
		width: 55%;
		.inter-sm & {
			margin-right: 2em;
		}
	}
}

.inter-noimg {
	.inter-block--quote {
		width: 90%;
		margin-right: 5%;
	}
}

.conf-section {
	&.full-width-bg-section {
		& + .page-mod--bg {
			margin-top: 0 !important;
		}
		.page-mod--bg + & {
			margin-top: 0 !important;
		}
		.inter-img {
			color: $black-100;
			.section-hdr-lg {
				border-bottom: none;
			}
			&:before, &:after {
				display: none;
			}
		}
	}
}



// --------------------------------------------
// --------------------------------------------
//
// Head Overlay
//
// --------------------------------------------
// --------------------------------------------

.head-overlay, %head-overlay {
	position: absolute;
	display: table;
	z-index: 3;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	text-align: center;
	padding: 0 1em;
	width: 100%;
}

.head-overlay-lg {
	@extend %head-overlay;

	@media(min-width: $medium) {
		font-size: 1.5em;
	}
	@media(min-width: $desktop) {
		font-size: 1.7em;
	}
}




// --------------------------------------------
// --------------------------------------------
//
// Notifications in default layout rail
//
// --------------------------------------------
// --------------------------------------------


.aside-secondary-mod {
	padding: 0 1em;
	margin: 3em 0;

	.hex-med { margin-bottom: 2em; }
}




// --------------------------------------------
// --------------------------------------------
//
// Inner page nav
//
// --------------------------------------------
// --------------------------------------------


.inner-nav-mod {
	border-bottom: $bdr-thin;
	padding: 1em 0;
	margin-bottom: 3em;
	@include pie-clearfix;
	.combo-nav-mod & {
		margin-bottom: 1em;
	}
}

.inner-nav-hdr {
	font-weight: 700;
	float: left;
	font-size: 2em;
}


.inner-nav-items {
	float: right;
	font-size: 1.35em;
	line-height: 2em;
	.combo-nav-mod & {
		font-size: 1.25em;
		float: left;
	}
}

.inner-nav-item {
	display: inline-block;
	margin-right: 1em;
	&:last-child {
		margin-right: 0;
	}
}

.inner-item-link {
	@include link(head-lt);
	font-weight: 700;
	.is-active & {
		@include link(head);
	}
}




// --------------------------------------------
// --------------------------------------------
//
// Conference bits
//
// --------------------------------------------
// --------------------------------------------

.conf-section {
	img {
		max-width: 100%;
	}
}

.featured-conf-img {
	display: block;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	@media(min-width: $small) {
		height: 15em;
	}
	@media(min-width: $medium) {
		height: 19em;
	}
	@media(min-width: $large) {
		height: 20em;
	}
	@media(min-width: $desktop) {
		background-size: contain;
		height: 17em;
	}
	@media(min-width: $x-large) {
		height: 18em;
	}
}

.featured-conf-title {
	@media(min-width: $large) {
		h4 {
			padding: 0 2em;
		}
	}
}

.more-conf {
	.media-block { width: 100% !important; }
	@media(min-width: $medium) {
		margin-left: -40px !important;
	}
	@media(min-width: $large) {
		margin-left: -62.5px !important;
		.media-block { width: 33.333333% !important; }
	}
}

.other-conf-img {
	display: block;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	@media(min-width: $small) {
		height: 15em;
	}
}

.conf-stacked {
	padding: 0 2em 1em;
}

.conf-separator {
	width: 100%;
	height: 1px;
	margin: 2em 0;
	border-bottom: 1px solid $black-10;
}

.tz-conf-hp {
	display: block;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	height: 13em;
}
.conf-mega-img {
	width: 45px;
	height: 45px;
	display: block;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50%;
}
.aag {
	width: 100%;
	margin-bottom: 3em;
	.breakout {
		td {
			margin: 1em 0 3em;
			img {
				padding: 0 0.5em 0.5em 0;
			}
		}
		&:last-child {
			padding-bottom: 3em;
		}
	}
	td {
		height: 2em;
		padding-bottom: 10px;
		&:nth-child(odd) {
			width: 20%;
			max-width: 20%;
			padding-right: 5px;
		}
		a {
			@include link;
			text-decoration: underline;
		}
	}
}

.callout-box, %callout-box {
	background-color: $bg-med;
	padding: 1em 1.5em;
	width: 100%;
	margin-bottom: 2em;
	p:last-child {
		margin-bottom: 0;
	}
	&.callout-right {
		@media(min-width: $large) {
			width: 40%;
			float: right;
			margin-left: 1em;
		}
	}
	&.callout-left {
		@media(min-width: $large) {
			width: 40%;
			float: left;
			margin-right: 1em;
		}
	}
}

.callout-box-fancy {
	@extend %callout-box;
	border-left: 4px solid $green-main;
	&.callout-right {
		@media(min-width: $large) {
			width: 40%;
			float: right;
			margin-left: 1em;
		}
	}
	&.callout-left {
		@media(min-width: $large) {
			width: 40%;
			float: left;
			margin-right: 1em;
		}
	}
	&.warning {
		border-left: 4px solid $orange;
	}
}

.tool-box-resources {
	width: 100%;
	border: 2px solid #efefef;
	margin-bottom: 1em;
	.tz {
		padding-bottom: 0 !important;
		margin-bottom: 0 !important;
	}	
	.txt-bd {
		padding-left: 0 !important;
	}
}

.conference {
	padding-bottom: 62.5px;
	.conf-section, .conf-subpage {
		p, li {
			a {
				color: $link-color;
			}
			a.button-lg--fancy {
				color: #fff;
			}
		}
		.pricing-grid {
			@media(min-width: $med-lg) {

			}
			@media(min-width: $large) {
				margin-left: -5em;
				margin-right: -5em;
			}
			@media(min-width: $desktop) {
				margin-left: -10em;
				margin-right: -10em;
			}
			&.price-section {
				clear: both;
				display: block;
				padding-top: 6em;
			}
			&.pricing-grid-3 { 
				.price-card-spacer {
					width: 5%;
					display: inline-block;
					float: left;
				}
				.conf-callout {
					width: 100%;
					box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
    				margin-bottom: 1em;
    				padding: 25px;
    				.banner {
						background-color: #70bf4c;
						height: 4em;
    					h3 {
    						padding-top: .5em;
    						color: #fff;
    					}
					}
				}
				.price-card {
					width: 100%;
					display: inline-block;
					float: left;
					background-color: #fff;
					border: 1px solid rgba(192,192,192,0.3);
					box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
					@media(min-width: $large) {
						width: 30%;
					}
					h3 {
						padding-top: 20px;	
					}
					.tz-text-sm {
						color: #555;
					}
					.banner-special {
    						margin-top: 3em;
					} 
					.active-learning-banner {
    						background-color: #70bf4c;
	    					h3 {
	    						padding-top: .5em;
	    						color: #fff;
	    					}
					}
					&.special {
						border: 2px solid $green-main;
						border-bottom: 7px solid $green-main;
						@media(min-width: $large) {
							transition: all .2s ease-in-out;   
							&:hover {
								transform: scale(1.1);
							}
						}
						.banner {
    						background-color: #70bf4c;
    						height: 4em;
	    					h3 {
	    						padding-top: .5em;
	    						color: #fff;
	    					}
						}
					}
					.pricing-text {
						color: $green-med;
					}
					.card-content {
						.content-section {
							padding: 10px;
							.button-lg {
								color: #fff;
							}
							&:nth-child(even) {
								background-color: rgba(192,192,192,0.3);
							}
						}
					}
				}
			}
		}
	}
}

.page-mod.quote-section {
	margin-top: 0;
	+ .page-mod--bg {
		margin-top: 0;
	}
}



.session-key {
	margin-top: 3em;
	.tz {
		margin-bottom: 0;
		padding-bottom: 0;
		display: table;
		overflow: hidden;
		.art-bd {
			width: 10%;
			min-width: 60px;
			display: table-cell;
			vertical-align: middle;
		}
		.txt-bd {
			display: table-cell;
			vertical-align: middle;
			font-size: 1.384615385em;
			line-height: 1.555555556em;
			font-weight: $sans-weight-light;
		}
	}
}

.timestamp-shortcode {
	padding: 2em 0;
}

.countdown {
	text-align: center;
	overflow: hidden;
	.kkcountdown-box {
		display: block;
		width: 100%;
		margin: 0 auto;
		.kkc-dni, .kkc-godz, .kkc-min, .kkc-sec {
			display: block;
			float: left;
			width: 21.1%;
			max-width: 21.1%;
			margin-right: 5%;
			padding: 1em 0;
			font-size: 1em;
			font-weight: bold;
			background-color: #fff;
			border: 4px solid #efefef;
			@media (min-width: $medium) {
				font-size: 1.3em;
			}
			&:before {
				display: block;
				position: absolute;
				margin-top: -3em;
			}
		}
		.kkc-dni {
			&:before {
				content: "Days";
				@media (min-width: $large) {
					padding-left: 3.3em;
				}
			}
		}
		.kkc-godz {
			&:before {
				content: "Hours";
				@media (min-width: $large) {
					padding-left: 3.1em;
				}
			}
		}
		.kkc-min {
			&:before {
				content: "Minutes";
				@media (min-width: $large) {
					padding-left: 2.5em;
				}
			}
		}
		.kkc-sec {
			margin-right: 0;
			&:before {
				content: "Seconds";
				@media (min-width: $large) {
					padding-left: 2.5em;
				}
			}
		}
		.kkc-dni-text, .kkc-godz-text, .kkc-min-text, .kkc-sec-text {
			display: none;
		}
	}
}



// --------------------------------------------
// --------------------------------------------
//
// DPA Map
//
// --------------------------------------------
// --------------------------------------------


.map-tabs {
	.r-tabs-accordion-title {
		display: none !important;
	}
	.map-area.r-tabs-state-active {
		display: none !important;
		@media (min-width: $large) {
			display: block !important;
		}
	}
	.map-area:last-child {
	    overflow: hidden;
	    padding: 15px;
	    column-count: 5;
	    column-gap: 10px;
	    -moz-column-count: 5;
	    -moz-column-gap: 10px;
	    -webkit-column-count: 5;
	    -webkit-column-gap: 10px;
		ul {
	    	margin: 0;
	    	padding: 0;
	    	li {
	    	    list-style-type: none;
	    	    margin: 0;
	    	    width: 100%;
	    	    padding: 0.2% 1%;
	    	    text-align: left;
	    	    border-bottom: none;
	    	    a {
	    	    	color: $link-color;
	    	    }
	    	}
		}
		select {
			display: none;
		}
		@media (max-width: $large) {
			display: block !important;
			text-align: center;
			column-count: 1;
			column-gap: 0;
			-moz-column-count: 1;
			-moz-column-gap: 0;
			-webkit-column-count: 1;
			-webkit-column-gap: 0;
			ul {
				display: none;
			}
			select {
				display: block;
				width: 50%;
				margin: 0 auto;
			}
		}
		@media (max-width: $medium) {
			select {
				width: 80%;
			}
		}
	}
}


// --------------------------------------------
// --------------------------------------------
//
// Embedded videos
//
// --------------------------------------------
// --------------------------------------------

iframe {
	max-width: 100%;
}

.responsive-video {
	width: 100%;
	height: 300px;
	@media (min-width: $medium) {
		height: 400px;
	}
	@media (min-width: $large) {
		height: 500px;
	}
}

.img-overlay-btm {
	@media(min-width: $x-large) {
		margin-top: -3em;
		padding: 0 0 3em 2em;
	}
	a {
		@extend %page-text-med;
		font-weight: bold;
		margin-right: 2.8em;
		text-decoration: none;
		display: inline-block;
		@media(max-width: $medium) {
			display: block;
			width: 100%;
		}
		&:last-child {
			margin-right: 0;
		}
		i {
			margin-left: 0.5em;
		}
	}
}



// --------------------------------------------
// --------------------------------------------
//
// Tooltips
//
// --------------------------------------------
// --------------------------------------------



$tooltip-bg: rgba(0,0,0,0.75);


.info-tooltip {
	color: $green-main;
	font-size: 0.75em;
	display: none !important;
	&:hover {
		color: darken($green-main, 5%);
	}
	@media(min-width: $medium) {
		margin-left: 1em;
		display: inline !important;
	}
}

#tooltip {
    text-align: center;
    color: #fff;
    background: $tooltip-bg;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    max-width: 300px;
    position: absolute;
    z-index: 10000000;
    padding: 15px;
    line-height: 1.4em;
    &:after {
    	width: 0;
    	height: 0;
    	border-left: 10px solid transparent;
    	border-right: 10px solid transparent;
    	border-top: 10px solid $tooltip-bg;
    	content: '';
    	position: absolute;
    	left: 50%;
    	bottom: -10px;
    	margin-left: -10px;
    }
    .top {
    	&:after {
    		border-top-color: transparent;
    		border-bottom: 10px solid $tooltip-bg;
    		top: -20px;
    		bottom: auto;
    	}
    }
    .left {
    	&:after {
    		left: 10px;
    		margin: 0;
    	}
    }
    .right {
    	&:after {
    		right: 10px;
    		left: auto;
    		margin: 0;
    	}
    }
}

// temporary fix for material tooltips

.material-tooltip {
  padding: 15px;
  font-size: 1rem;
  z-index: 2000;
  background-color: transparent;
  border-radius: 2px;
  color: #fff;
  min-height: 36px;
  line-height: 120%;
  opacity: 0;
  display: none;
  position: absolute;
  text-align: center;
  max-width: 300px;
  overflow: hidden;
  left: 0;
  top: 0;
  pointer-events: none;
}

.backdrop {
  position: absolute;
  opacity: 0;
  display: none;
  height: 7px;
  width: 14px;
  border-radius: 0 0 50% 50%;
  background-color: #323232;
  z-index: -1;
  transform-origin: 50% 0%;
  transform: translate3d(0,0,0);
}


//Expandable Details

.expandable-details {
	margin-top: 3em;
	display: block;
	.page-text-sm p {
		margin-bottom: 1.6em;
	}
	.button-block--centered {
		margin-top: 1em;
		margin-bottom: 1.6em;
	}
}

//Expandable Content used for Industry Events

.expandable-content {
	padding-bottom: 0!important;
	margin-bottom: 1em!important;
	p.page-head-med {
		margin-bottom: 0;
	}
	.page-text-sm a {
		@extend %alternate-link--underline;
		}
	:nth-child(1).expandable-col {
		float: left;
		@media(min-width: $small) {
			width: 70%;
		}
		@media(min-width: $medium) {
			width: 75%;
		}
	}
	:nth-child(2).expandable-col {
		float: left;
		text-align: right;
		@media(min-width: $small) {
			width: 30%;
		}
		@media(min-width: $medium) {
			width: 25%;
			margin-top: 1em;
			margin-bottom: 1.5em;
		}
	}
	.ghost-button {
		margin-top: 0;
		padding: 1em;
		@media(min-width: $medium) {
			padding: 1em 2em;
		}
	}
	.show {
		&:after {
			content: "Details";
		}
	}
	.hide {
    	border: 2px solid $orange;
    	color: $orange;
    	&:hover {
			color: #fff;
			background-color: $orange!important;
		}
		&:after {
			content: "Hide";
		}
	}
}

// Expandable sections 

.expandable {
	position: relative;
	height: 200px;
	overflow: hidden;
	padding-bottom: 1em;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 100;
		/* IE9 SVG, needs conditional override of 'filter' to 'none' */
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjY2JSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
		background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,1))); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%); /* IE10+ */
		background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-8 */
	}
	&:after {
		font-family: "FontAwesome";
		font-size: 2em;
		content: "\f055";
		color: $black-30;
		position: absolute;
		left: 50%;
		margin-left: -0.5em;
		top: 170px;
		z-index: 100;
		display: block;
		cursor: pointer;
	}
	&.expanded {
		height: auto;
		overflow: visible;
		&:before {
			display: none;
		}
		&:after {
			content: "\f056";
			top: auto;
			bottom: 0;
			margin-bottom: -0.5em;
		}
	}
}

.long-description {
	position: relative;
	height: 200px;
	overflow: hidden;
	padding-bottom: 1em;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 9;
		/* IE9 SVG, needs conditional override of 'filter' to 'none' */
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjY2JSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
		background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,1))); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%); /* IE10+ */
		background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-8 */
	}
	&.expanded {
		height: auto;
		overflow: visible;
		&:before {
			display: none;
		}
	}
}
.ld-button {
	z-index: 10;
	font-size: 2em;
	cursor: pointer;
}

.expandable-outer {
	.expandable-box {
		position: relative;
		height: 200px;
		overflow: hidden;
		padding-bottom: 1em;
		&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 10000;
		/* IE9 SVG, needs conditional override of 'filter' to 'none' */
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjY2JSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
		background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,1))); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%); /* IE10+ */
		background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-8 */
		}
	}
	.expanded {
		height: auto;
		overflow: visible;
		position: relative;
		padding-bottom: 1em;
		&:before {
		display: none;
		}
	}
	i:last-child {
		color: #808285;
		font-size: 2em;
		cursor: pointer;
	}
}
// .expand-btn {
// 	font-family: "FontAwesome";
//     font-size: 2em;
//     content: "\f055";
//     color: #808285;
// }

// Basic expandable sections
.expand-section {
	display: none;
}



// Opaque white bg

.white-fade-bg {
	background-color: rgba(255, 255, 255, 0.52); 
	padding: 1.5em;
}

// Accordion Boxes

.accordion-box {
	#accordion {
	    list-style: none;
	    padding: 0 0 0 0;
	    width: 100%;
	}
	#accordion div {
	    display: block;	    
	    cursor: pointer;
	    padding:0.5em;
	}
	#accordion ul {
	    list-style: none;
	    display: none;
	    padding: 0 0 0 0;
	    	.grid-3 {
	    		margin-left:0px;
	    		padding:0px;
	    		.media-block {
	    			margin-bottom:0px;
	    		}
	    		ul {
	    			display: list-item;
	    			li {
	    				list-style: circle;
	    				p {
	    					padding-left: 0.5em;
	    				}
	    			}
	    		}
	    	}
	    .gallery {
	    	margin-left: 0;
	    }
	    li.form {
	    	padding: 0.75em;
	    }
	}
	#accordion ul li  {
	    font-weight: normal;
	    cursor: auto;
	    padding: 0.5em 0 0 0;
	    p.triangle-bullet:before {
	    	content: "\f0da";
			font-family: FontAwesome;
			color: #4e81bd;
			padding-right: 0.3em;
	    }
	}
	#accordion a {
	    text-decoration: none;
	}
	#accordion a:hover {
	    text-decoration: underline;
	}
	.media-block-behind {
		position: relative;
		z-index: 1;
		overflow: hidden;
		@media(min-width: $small) {
			min-height: 200px;
		}
		@media(min-width: $medium) {
			min-height: 600px;
		}
		@media(min-width: $med-lg) {
			min-height: 420px;
		}
		@media(min-width: $large) {
			min-height: 600px;
		}
		@media(min-width: $x-large) {
			min-height: 420px;
		}
		&:nth-child(2) {
			border-top: 1px solid $black-10;
		}
		.animated-bg { 
			position: absolute; 
			z-index: -1; 
			top: 0; 
			left: 0; 
			right: 0;
			bottom: 0;
			text-align: center;
			margin-top: 4em;
			// background-size: 80px 160px;
			// background: no-repeat;
			// background-position: 50% 50%;
			img {
				max-width: 20%;
				opacity: 0.2;
				@media(min-width: $medium) {
					max-width: 50%;
				}
			}
		} 
	}
}

.svg-containter-ie {
     display: block!important;
     min-width: 100%;
     position: relative;
     padding-bottom: 100%;
     .gdpr-svg {
	     position: absolute;
	     display: block!important;
	     width: 100%; 
	}

}

.gdpr-rect {
	cursor: pointer;
	&:hover {
	  	opacity: .6;
	}
}

.gdpr-modal-hide {
	display: none;
}

.modal {
	width: 75%;
	z-index: 99999;
	position: absolute;
	background: #f1f1f1;
	border: 5px solid #8cc63f;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 8px;
    @media(min-width: $medium) {
		width: 50%;
	}
	.modal-content {
	    padding: 20px 20px 0px 20px;
	    display: table-cell;
	    
	}
}

.modal-close {
	background: #414042;
	color: #FFFFFF;
	line-height: 25px;
	position: absolute;
	right: -12px;
	text-align: center;
	top: -10px;
	width: 24px;
	text-decoration: none;
	font-weight: bold;
	-webkit-border-radius: 12px;
    -moz-border-radius: 12px;
	border-radius: 12px;
	cursor: pointer;
}
