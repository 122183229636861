//Marketo Shortcode Form Styles
.iapp-marketo-form {
	.mktoFormRow {
		.mktoFormCol {
			.mktoFieldWrap {
				select {
					display: inline-block;
				}
			}
		}
	}
}