// --------------------------------------------
// --------------------------------------------
//
// Variables
//
// --------------------------------------------
// --------------------------------------------

$form-bp: $large;




// --------------------------------------------
// --------------------------------------------
//
// Event register
//
// --------------------------------------------
// --------------------------------------------

form {
	max-width: 100%;
	overflow: hidden;
}

fieldset {
	-webkit-margin-start: 0;
	-webkit-margin-end: 0;
	-webkit-padding-before: 0;
	-webkit-padding-start: 0;
	-webkit-padding-end: 0;
	-webkit-padding-after: 0;
	border: none;
	border-image-source: none;
	border-image-slice: none;
	border-image-width: none;
	border-image-outset: none;
	border-image-repeat: none;
	min-width: 0;
}

input[type="text"] {
	max-width: 100%;
}

input[type="checkbox"] {
  vertical-align: middle;
  width: 25px;
  height: 25px;
}

input[type="radio"], select {
	margin-left: 0.3em;
}

textarea {
	max-width: 100%;
}

button,
input[type="submit"] {
	&.disabled {
		background-color: $button-secondary;
		opacity: 0.33;
		cursor: default;
		&:hover {
			background-color: $button-secondary;
		}
	}
}


%input {
	border: 0;
	padding: 0;
	margin: 0;
	&:focus {
		border: 0;
		outline: 0;
	}
}


.form-primary { }


.form-block {
	border-bottom: $bdr-thin-lt;
	margin: 1em 0;
	padding: 1em 0;
	overflow: hidden;
	&:last-child {
		border-bottom: none;
	}
}


.label-text {
	@extend %page-text-med;
	color: $black-20;
	margin-right: 1em;
	display: block;
	max-width: 100%;
	@include sans(light);
	@media(min-width: $form-bp) {
		display: inline-block;
		padding-right: 5em;
	}
}



.input-text, %input-text {
	@extend %page-text-med;
	@extend %input;
	color: $black-90;
	display: inline-block;
	background-color: #f2f2f2;
	width: 100%;
	max-width: 100%;
	padding-left: 0.3em;
}

.input-text-sm {
	@extend %page-text-sm;
	@extend %input;
	border: $bdr-thin-lt;
	padding: 0.35em 0.5em;
	font-size: 12px;
	color: #000;
	display: inline-block;
	background-color: transparent;
	max-width: 100%;
	border-radius: 5px;
	&:focus {
		outline: 0;
		border: $bdr-thin;
	}
}

.input-textarea {
	@extend %input-text;
	border: none;
	background-color: #f2f2f2;
	color: $black-90;
	padding: 0.35em 0.5em;
	width: 100%;
	height: 43px;
	max-width: 100%;
	border-radius: 5px;
	&:focus {
		outline: 0;
		height: 150px;
	}
}

.form-button-block {
	margin-left: auto;
	margin-right: auto;
}

.form-button-block--center {
	text-align: center;
}

.button-input {
	position: absolute;
	left: -9999em;
}

.button-right {
	float: right;
}


.form-hdr-bold {
	@extend %subsection-hdr-bold;
	color: $black-30;
	margin-bottom: 0;
	float: left;
}

input[type="text"],
input[type="email"],
input[type="phone"],
input[type="tel"] {
	min-height: 28px;
}

input[type="checkbox"].input-text,
input[type="radio"].input-text {
	background-color: transparent !important;
}

.ie-label {
	display: none;
}



// --------------------------------------------
// --------------------------------------------
//
// Nav login
//
// --------------------------------------------
// --------------------------------------------


%nav-input {
	text-align: left;
	border: 0;
	padding: 0.15em 0.25em;
	margin: 0;
	border: $section-bdr-thin;
	width: 100%;
	&:focus {
		outline: 0;
		box-shadow: 0;
	}

}


.nav-form-block {
	margin: 0 0 1em 0;
}

.nav-label-text {
	display: block;
	line-height: 1em;
	text-align: left;
	margin-bottom: 0.45em;
}


.nav-input-email {
	@extend %nav-input;
	height: 3em;
}

.nav-input-password {
	@extend %nav-input;
	height: 3em;
}



// --------------------------------------------
// --------------------------------------------
//
// Membership Directory lookup
//
// --------------------------------------------
// --------------------------------------------


.member-search-input {
	@extend %input-text;
	&:focus {
		border: 1px solid #d9d9d9;
	}
}

.member-search-results {
	.details {
		.page-text-sm {
			margin-bottom: 0;
			&.results-title,
			&.results-phone,
			&.results-line3 {
				margin-bottom: 0.5em;
			}
		}
	}
}

.results-area {
	margin-top: 5em;
	overflow: hidden;
	position: relative;
	height: auto;
	min-height: 100px;
	&.loading {
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=33)";
		filter: alpha(opacity=33);
		-moz-opacity: 0.33;
		-khtml-opacity: 0.33;
		opacity: 0.33;
		&:before {
			content: url('/wp-content/themes/iapp/static/img/ui-img/icons/gif/712.GIF');
			position: absolute;
			width: 40px;
			height: 40px;
			top: 0px;
			left: 50%;
			margin-left: -20px;
			text-align: center;
		}
	}
}





// --------------------------------------------
// --------------------------------------------
//
// Required
//
// --------------------------------------------
// --------------------------------------------

.required-field {
	color: #ff0000;
	margin-left: 3px;
}




// --------------------------------------------
// --------------------------------------------
//
// Additional Layouts
//
// --------------------------------------------
// --------------------------------------------

.form-block {
	&.form-compound-block--one,
	&.form-compound-block--two {
		overflow: hidden;
		padding-top: 0;
		@media (max-width: $large) {
			margin-top: 0;
			margin-bottom: 0;
			padding-bottom: 0;
		}
		.form-block {
			margin: 1em 0 0 !important;
			@media (min-width: $medium) {
				width: 50%;
				float: left;
				border-bottom: none;
				margin: 1em 0 !important;
				padding: 0 1em 0 0 !important;
				overflow: visible;
				&:nth-child(even) {
					padding-right: 0 !important;
				}
			}
			@media (min-width: $large) {
				margin: 1em 0 0 !important;
			}
		}
	}
	&.form-compound-block--one {
		.form-block {
			width: 100%;
			float: none;
			padding-right: 0 !important;
		}
	}
	&.form-compound-block--four {
		overflow: hidden;
		margin-bottom: 0;
		padding-bottom: 1em;
		@media (max-width: $large) {
			margin-top: 0;
			padding-top: 0;
		}
		.form-block {
			@media (min-width: $medium) {
				border-bottom: none;
				margin: 0 0 1em 0;
				padding: 0 1em 0 0 !important;
				overflow: visible;
				&:nth-child(even) {
					padding-right: 0 !important;
				}
			}
			@media (min-width: $large) {
				&:nth-child(even) {
					padding-right: 1em !important;
				}
				&:last-child {
					padding-right: 0 !important;
				}
			}
		}
		.form-block-city,
		.form-block-state {
			@media (min-width: $medium) {
				border-bottom: $bdr-thin-lt;
				margin: 1em 0;
				padding: 0 1em 1em 0 !important;
				overflow: hidden;
			}
			@media (min-width: $large) {
				padding: 0 1em 0 0 !important;
			}
		}
		.form-block-city,
		.form-block-state,
		.form-block-postal-code,
		.form-block-country {
			@media (min-width: $medium) {
				width: 50%;
				float: left;
			}
			@media (min-width: $large) {
				width: 25%;
				float: left;
				border-bottom: none;
				margin: 0;
				overflow: visible;
			}
		}
	}
	&.checkboxes,
	&.radios {
		padding-bottom: 1em;
		.form-block {
			border-bottom: none;
		}
	}
	&.radios {
		input[type="radio"] {
			float: left;
			display: inline;
			width: 30px;
			margin-left: 0;
		}
		.radio-input-text {
			@extend %page-text-sm;
			max-width: 200px;
			margin-top: -0.3em;
			margin-bottom: 0;
		}
		&.credit-radios {
			input[type="radio"] {
				margin-top: 0.1em;
			}
			.input-label {
				display: block;
				padding-left: 2.5em;
			}
		}
	}
}

.form-hero {
	max-width: 100%;
	margin-bottom: 2em;
	@media(min-width: $medium) {
		max-width: 300px;
		float: right;
		padding-left: 2em;
	}
	@media(min-width: $large) {
		max-width: 500px;
	}
}

// Forgot password

.forgot-password {
	margin-top: 1em;
	@media (min-width: $medium) {
		float: right;
		margin-top: -1.7em;
	}
}

// Innovation Awards form 

.innovation-form {
	.bulleted li {
		font-weight: inherit;
		font-size: 1em;
		line-height: 1em;
	}
	.input-textarea {
		height: 8.35em;
	}
	select {
		margin-left: 0;
	}
	#save {
		min-width: 114px;
		&.ghost-button {
			padding: 0.76em;
			margin-top: -0.05em;
		}
	}
	.judging-label {
		display: block;
		&.text-green {
			color: $green-main;
			font-weight: bold;
		}
	}
	.fa-check-square {
		color: $green-main;
	}
}

// Marketo forms on IAPP pages

.marketo-form {
	.mktoForm, .mktoForm *, span {
		@include sans(regular);
		font-family: $sans !important; 
		// font-size: 1.03em !important;
	}
	.mktoForm {
		.mktoField {
			clear: both;
		}
		.mktoFormCol {
			min-height: 0 !important;
		}
		input[type="email"],
		input[type="text"],
		input[type="phone"],
		input[type="tel"],
		textarea {
			@extend %input-text;
		}
		input[type="submit"],
		input[type="button"],
		button[type="submit"],
		button[type="button"] {
			@extend %button-lg;
			border: none !important;
			font-size: 1.3em !important;
		}
		.mktoButtonRow {
			width: 100%;
			.mktoButtonWrap {
				margin-left: 0 !important;
				margin-right: 0 !important;
				display: block;
				text-align: center;
			}
		}
		.mktoRadioList > label, 
		.mktoCheckboxList > label {
			margin-top: 0;
			padding-top: 0.2em;
		}
	}
	.mktoOffset,
	.mktoGutter,
	label[style="width: 10px;"] {
		display: none;
	}
	// subscription center
	&.subscription-center {
		margin-bottom: 2em;
		.mktoForm, .mktoForm *, span, p {
			font-size: 1.03em !important;
		}
		.mktoForm {
			.mktoFormCol {
				width: 100%;
				min-height: 0 !important;
				.mktoFieldWrap {
					width: 100%;
					input[style="width: 450px;"],
					div[style="width: 450px;"] {
						width: 100% !important;
					}
				}
			}
			.mktoFormRow {
				select {
					margin: 1em 0;
					@media(min-width: $medium) {
						float: right;
						margin: -20px 0 0 0;
					}
				}
				.mktoLabel {
					position: relative;
					margin-left: 1.5em;
					&:before, &:after {
						font-family: "FontAwesome";
						position: absolute;
						left: 0;
						margin-left: -1.5em;
						transition: all 300ms linear;
					}
					&:before {
						content: "\f0fe";
						color: $green-main;
					}
					&:after {
						content: "\f056";
						color: $orange;
						opacity: 0;
					}
					&.active {
						&:before {
							opacity: 0;
						}
						&:after {
							opacity: 1;
						}
					}
				}
				input[type="email"] {
					margin-bottom: 2em;
				}
				.mktoLabel[style="width: 0px;"],
				.mktoLabel[style="width: 0;"],
				.mktoLabel[width="0px"],
				.mktoLabel[width="0"],
				label[for="Email"] {
					display: none;
					&:before, &:after {
						display: none;
					}
				}
				.email-description {
					background-color: $bg-lt;
					padding: 0.75em 1em;
					color: $black-30;
					line-height: 1.6em;
					font-size: 0.9em !important;
					overflow: hidden;
					img {
						max-width: 190px;
						float: left;
						margin-right: 1em;
						@media(max-width: $medium) {
							max-width: 100%;
						}
					}
				}
				&:nth-child(4) {
					background-color: $bg-lt;
					padding: 1em 1em 0 1em !important;
					margin-bottom: 2em;
					input[type="checkbox"] {
						float: right;
						margin-top: -37px;
						@media(min-width: $medium) {
							margin-left: 14px;
						}
					}
					label {
						display: none;
					}
					.mktoFieldDescriptor {
						height: 0;
						margin-bottom: 0.3em !important;
					}
				}
				// Hide Privacy Advisor to anonymous and nonmembers
				&:nth-child(13),
				&:nth-child(14),
				&:nth-child(15) {
					display: none;
				}
			}
			.mktoCheckboxList {
				float: right;
			}
			.mktoCheckboxList[style="width: 150px;"] {
				margin-top: -20px;
				@media(min-width: $medium) {
					padding-left: 14px;
				}
			}
		}
		&.member {
			.mktoForm {
				.mktoFormRow {
					// Show Privacy Advisor to members only
					&:nth-child(13),
					&:nth-child(14),
					&:nth-child(15) {
						display: block;
					}
				}
			}
		}
	}
}

//Marketo Shortcode Form Styles
.iapp-marketo-form {
	.mktoForm {
		width: 100% !important;
	}
	.mktoFormRow {
		width: 100%;
			@media (min-width:$medium) {
				margin: 1em 0 !important;
				border-bottom: 1px solid #e9eaea;
			}
		.mktoFormCol {
			width: 100%;
			.mktoCheckboxList {
				input[type="checkbox"] {
					font-size: 2em !important;
					width: initial !important;
					height: initial !important;
				}
			}
			.mktoCheckboxList > label {
				padding-top: 0 !important;
				padding-bottom: 1em !important;
			}
			.mktoFieldWrap {
				width: 100% !important;
				margin-bottom: 10px;
				@media (min-width: $medium) {
					margin-bottom: 0;
				}
				.mktoHasWidth {
					width: 100% !important;
				}
				label {
					@extend %page-text-med;
					margin-bottom: 0;
				}
				textarea {
					margin-top: 0.5em;
					min-height: 5em;
				}
				input[type="date"] {
					margin-top: 0.5em;
				}
				ul {
					font-size: 1em;
					margin-top: 1em;
					li {
						font-weight: 300;
						// margin-bottom: 0.25em;
					}
				}
			}
			.mktoFieldWrap.mktoRequiredField {
			margin-top: 0em !important;
			}
		}
			@for $i from 0 through 3 {
	  			.mktoFormCol:first-child:nth-last-child(#{$i}),
	  			.mktoFormCol:first-child:nth-last-child(#{$i}) ~ .mktoFormCol {
	  				@media (min-width:$small) {
	    				border-bottom: 1px solid #e9eaea;
	    			}
	  				@media (min-width:$medium) {
	    				width: 100% / ($i - 1);
	    				border-bottom: none;
	    			}
	    		}
    		} 
		.mktoFormCol + .mktoFormCol {
			@media (min-width:$medium) {
				padding-left: 0.5em;
				padding-right: 0;
			}
		}
	}
	.mktoButtonRow {
		margin-top: 1em;
	}
}
.privacylist {
	padding: 2em;
}

.tracker {
	padding: 6px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
    font-weight: 300;
    font-size: 0.8rem;
    color: #fff;
    background-color: #e57373;
    border-radius: 2px;
    display: inline-block;
}
.tracker:after {
	content: ' characters remaining';
}