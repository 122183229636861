// --------------------------------------------
// --------------------------------------------
//
// Article variables
//
// --------------------------------------------
// --------------------------------------------

$article-spacing--large: 4em;
$article-spacing--med: 3em;
$article-spacing: 2em;



.article-meta {
	margin-bottom: $article-spacing--large;
}


%article-block {
	border-top: $section-bdr;
	padding-top: $article-spacing--med;
	margin-top: $article-spacing--med;
}


.article-bio-mod { 
	@extend %article-block;
	& + & {
		border-top: none;
		margin-top: 0;
		padding-top: 2em;
		.txt-bd {
			.subsection-hdr {
				display: none;
			}
		}
	}
}

.article-comment-mod { @extend %article-block; }

.article-related-mod { @extend %article-block; }


// --------------------------------------------
//
// Article byline

.article-meta-block {
	margin-bottom: $article-spacing;
	margin-top: $article-spacing;
}

// extends %tz-meta-img from objects.scss
.article-meta-img {
	@extend %tz-meta-img;
	width: 4em;
}

// extends various silent classes from tease.scss
.article-meta-text {
	@extend %tz-meta-text;
	line-height: 4em;
}

.article-meta-author { @extend %tz-meta-author; }

.article-author-link--bold { @extend %tz-author-link--bold; }

.article-meta-date { @extend %tz-meta-date; }


.article-aside-mod {
	// max-width: $article-aside;
}



// --------------------------------------------
//
// Article featured image


.article-content {
	.art-bd {
		margin-top: -2em;
		margin-bottom: 2em;
	}
}


// --------------------------------------------
//
// Inner article Styles

.article-text {
	// background-color: blue;
	font-size: 1.538461538em;
		&.lg-quote {
			padding: 2em;
			background-color: #efefef;
			font-style: normal;
			i {
				font-size: 3em;
				color: #a7a9ac;
			}
	img, p img, p a img {
		max-width: 100%;
		height: auto;
	}


	// ---------------------------
	// body size
	> p {
		@extend %article-text;
		margin-bottom: 1em;
	}


	// ---------------------------
	// Headline Styles
	> h1 {
		@extend %h2;
		margin-bottom: 1em;
		@include sans(bold);
	}

	> h2 {
		@extend %h2;
		margin-bottom: 1em;
		@include sans(bold);
	}

	> h3 {
		@extend %h3;
		margin-bottom: 1em;
		@include sans(bold);
	}

	> h4 {
		@extend %h4;
		margin-bottom: 1em;
		@include sans(bold);
	}

	> h5 {
		@extend %h5;
		margin-bottom: 1em;
		@include sans(bold);
	}

	> h6 {
		@extend %h6;
		margin-bottom: 1em;
		@include sans(bold);
	}

	// ---------------------------
	// Blockquote
	> blockquote, .pull-quote {
		// margin-bottom: 1.5em;
		margin: 2.5em 0;
		padding-left: 2em;
		border-left: 3px solid lighten($green-main, 10%);
		font-style: italic;
			@media (min-width: $large) {
				padding: 1em 1.5em 0.5em;
				margin-left: 0;
				margin-right: 0;
			}
			a {
				@include link(articlelink);
			}
			.fa-border {
				padding: 0.2em 0.25em 0.15em;
				border: solid 0.08em #e7e7e7;
				border-radius: 0.1em;
				margin-right: 0.5em;
				background-color: #f7f7f7;
				color: $black-20;
			}
		}
		> p {
			@extend %article-text;
		}
	}

	.pull-quote a {
		@include link(articlelink);
	}


	// ---------------------------
	// Lists
	>ul, ol {
		margin-left: 1.35em;
	}

	> ul {
		@extend %list-items;
		> li {
			@extend %list-item;
			position: relative;
			// margin-left: 1.35em;
			margin-bottom: 1em;
			list-style: disc;
			font-weight: $serif-weight-reg;
			&:last-child { margin-bottom: 1em; }

			&:before {
				content: '';
				width: 0px;
				height: 0px;
				//border: 5px solid $black-10;
				// background-color: $show-main;
				position: absolute;
				top: 6px;
				left: -1.35em;
			}
			> ul {
				> li {
					@extend %list-item;
					position: relative;
					margin-left: 2.7em;
					list-style: square;
					font-weight: $serif-weight-reg;
					&:before {
						content: '';
						width: 0px;
						height: 0px;
						position: absolute;
						top: 6px;
						left: -1.35em;
					}
				}
			}
			> ol {
				> li {
					@extend %list-item;
					list-style: decimal;
					margin-left: 2.7em;
				}
			}
		}
	}

	> ol {
		@extend %list-items;
		> li {
			@extend %list-item;
			list-style: decimal;
			margin-bottom: 1em;
			&:last-child { margin-bottom: 1em; }
			> ul {
				> li {
					@extend %list-item;
					position: relative;
					margin-left: 2.7em;
					list-style: square;
					font-weight: $serif-weight-reg;
					&:before {
						content: '';
						width: 0px;
						height: 0px;
						position: absolute;
						top: 6px;
						left: -1.35em;
					}
				}
			}
			> ol {
				> li {
					@extend %list-item;
					list-style: decimal;
					margin-left: 2.7em;
				}
			}
		}
	}


	// ---------------------------
	// Links
	> p, p em, p strong, ul li, ol li, ul li p, ul p, ol p, ol li p, ul li ul li p, ol li ul p, div, div p {
		> a {
			@include link(articlelink);
		}
	}


	// ---------------------------
	// Image stuff
	> p {
		> .size-full { width: auto; max-width: 100%; height: auto; }
		> .alignright { 
			margin-bottom: 1em; 
			@media (min-width: $large) {
				float: right; 
				margin-left: 1em; 
			}
		}
		> .alignleft { 
			margin-bottom: 1em; 
			@media (min-width: $large) {
				float: left; 
				margin-right: 1em; 
			}
		}
		> img { margin-bottom: 1em; }
	}

	.wp-caption {
		display: inline-block;
		overflow: hidden;
		&.size-full { width: 100%; max-width: 100%; height: auto; }
		&.alignright { width: 100%; float: right; margin-left: 1em; margin-bottom: 1em; }
		&.alignleft { width: 100%; float: left; margin-right: 1em; margin-bottom: 1em; }
	}

	.wp-caption-text {
		@include sans;
		font-size: 0.75em;
		line-height: 1.35em;
		padding: 0.85em 0.5em;
		background-color: #f2f2f2;
		margin-right: 10px;
	}


	.cap-image-right,
	.cap-image-left {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 1em;
			@media(min-width: $el-small) {
				float: left;
				max-width: 100%;
				height: auto;
				margin-right: 2em;
				margin-left: 0;
			}
		}


	.cap-caption {
		@include sans;
		font-size: 0.85em;
		line-height: 1.35em;
		margin-top: 0.85em;
	}

	// ---------------------------
	// This hides legacy author bio mugs. An img tag within the first paragraph of the story.

	> p:first-of-type {
		img { display: none; }
	}


} // article-text

.sb-callout {
	display: none;
	@media(min-width: $article-split) {
		display: block;
	}
}
.bottom-callout {
	display: block;
	@media(min-width: $article-split) {
		display: none;
	}
}
.article-callout {
	margin-bottom: 3em;
	.article-callout-head {
		@extend %h3;
		background-color: $green-med;
		padding: 1em;
		color: #fff;
		font-weight: 500;
	}
	.article-callout-body {
		background-color: $bg-lt;
		padding: 1em;
		p, li {
			line-height: 1.6em;
			margin-bottom: 1em;
		}
		a {
			@include link(articlelink);
		}
		img {
			max-width: 100%;
			margin-bottom: 1em;
		}
		ul {
			list-style: disc;
			li {
				@extend %tease-text;
				margin-left: 2em;
				font-weight: $sans-weight-reg;
			}
		}
		ol {
			margin-left: 1.55em;
			li {
				margin-bottom: 1em;
			}
		}
	}
}

.tumblr-section {
	background: #d9dadb;
	margin-top: -2em;
	margin-bottom: 4em;
	.article-tumblr {
		padding: 2em;
		width: 100%;
		margin-bottom: 2em;
		.media-block {
			margin-bottom: 0px !important;
		}
		.tumblr-card {
			background: #fff;
			padding: 30px;
			.tumblr-tz-head {
				padding: 0px 15px;
			}
			.tumblr-p-body {
				padding: 0px 15px;
			}
		}

	}
}
