.p-2 {
	padding: 2em !important;
}

.m-0 {
	margin: 0 !important;
}

.m-05 {
	margin: 0.5em !important;
}

.ml-1 {
	margin-left: 1em !important;
}

.ml-2 {
	margin-left: 2em !important;
}

.mr-025 {
	margin-right: 0.25em !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-1 {
	margin-bottom: 1em !important;
}

.rel {
	position: relative;
}

.abs {
	position: absolute;
}