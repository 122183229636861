// --------------------------------------------
// --------------------------------------------
//
// Carousel Hero background color and texture
//
// --------------------------------------------
// --------------------------------------------
.carousel {
	@each $hero-color in $hero-colors {
		.carousel-item {
			&.hero-bg-#{nth($hero-color, 1)} {
				background: nth($hero-color, 2);
				background-image: radial-gradient(50% 50%, circle cover, nth($hero-color, 2), darken(nth($hero-color, 2), 5%) 100%);
			}
		}
	}

	@each $hero-texture in $hero-textures {
		.carousel-item {
			&.hero-pattern-#{nth($hero-texture, 1)} {
				&:before {
					background-image: url(/wp-content/themes/iapp/static/img/#{nth($hero-texture, 2)}.png);
					opacity: #{nth($hero-texture, 3)};
				}
			}
		}
	}
}


// --------------------------------------------
// --------------------------------------------
//
// Carousel Hero styles
//
// --------------------------------------------
// --------------------------------------------
.carousel {
	.slide-prev {
		display: table; 
		height: 100%; 
		position: absolute;
		cursor: pointer; 
		z-index: 5;
		left: 0;
		.slide {
			font-size: 4em; 
			display: table-cell;
			vertical-align: middle;
			text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
			transition: transform .28s ease;
		}
		&:hover {
			.slide {
				transform: scale(1.1);
			}
		}
	}
	.slide-next {
		@extend .slide-prev;
		left: initial;
		right: 0;
	}
	&.featured-content {
		.slide {
			font-size: 3em;
		}
	}
}

.carousel-item {
	background-color: map-get($grey, "darken-2");
	.carousel-hero-content {
		@media #{$small-and-down} {
			h2 {
				font-size: 1.3em!important;
				margin: 0;
				line-height: 1em!important;
			}
			h5 {
				font-size: 0.9em;
				font-weight: 500;
				margin: 0.75em 0;
			}
		}
		width: 90%;
		margin: 0 auto;
		position: relative;
		z-index: 3;
		@media #{$medium-only} {
			width: 75%;
		}
		@media #{$large-and-up} {
			width: 50%;
		}
		.carousel-hero-cta {
			padding-top: 1em;
			@media #{$small-and-down} {
				padding-top: 0;
				.btn-large {
					height: 43px;
					line-height: 43px;
				}
			}
		}
		&.grid-2 {
			@media #{$small-and-down} {
				width: 100%;
				.row {
					margin-bottom: 0;
					height: 150px;
					.header-block {
						flex: 1;
					}
					.valign {
						width: 100%;
						flex: 2;
						display: flex;
						flex-direction: column;
					}
				}
			}

			@media #{$medium-only} {
				width: 90%;
				h5 {
					font-size: 1.4em;
				}
			}
			width: 75%;
			.header-block {
				.featured-image {
					@media #{$small-and-down} {
						height: 100px;
					}
					height: 300px; 
					width: 100%; 
					background-size: cover;
				}
			}
		}
	}
}

.hero-bg {
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 50% 0;
	position: absolute;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-perspective: 1000;
	-moz-perspective: 1000;
	-ms-perspective: 1000;
	perspective: 1000;
	width: 100%;
	&:before {
		content: "";
		position: absolute;
		left: 0;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
	&.carousel-item {
		min-height: 60em;
		@media(min-width: $desktop) {
			background-size: cover!important;
		}
	}
}