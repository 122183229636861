// --------------------------------------------
// --------------------------------------------
//
// Thick borders
//
// --------------------------------------------
// --------------------------------------------

$section-bdr: 4px solid darken($bg-lt, 3%);

$nav-bdr: 4px solid $bg-med;




// --------------------------------------------
// --------------------------------------------
//
// Thin borders
//
// --------------------------------------------
// --------------------------------------------

$section-bdr-thin: 1px solid $bg-dark;

$bdr-thin: 1px solid $bg-dark;

$bdr-thin-lt: 1px solid $bg-med;

