// --------------------------------------------
// --------------------------------------------
//
// Skybox
//
// --------------------------------------------
// --------------------------------------------

$skybox-sm: 	31.25em; 		// 500px
$skybox-med: 	$medium;
$skybox-lg: 	50em; 			// 800px


%skybox-block {
	@include pie-clearfix;
}

.pre-skybox-mod {
	background-color: #f2f2f2;
	padding: 2em 2em 0.5em $space-grid;
	position: relative;
	top: -$space-grid;
	@include pie-clearfix;

	@media(min-width: $default-bp-sm) {
		margin-left: -$space-grid;
	}
	.fa-ul {
		margin-left: 1em;
		.fa-li {
			color: $green-main;
		}
	}
	.close-trigger {
		color: $black-40;
		font-size: 1.25em;
		position: absolute;
		right: 0.65em;
		top: 0.5em;
		&:hover {
			color: darken($black-40, 10%);
		}
	}
}

.skybox-mod {
	@extend %page-text-med;
	color: #fff;
	background-color: $bg-skybox;
	padding: 1em 1em 0.5em;
	// margin-bottom: $grid-1;
	position: relative;
	top: -$space-grid;
	overflow: hidden;
	@include pie-clearfix;

	@media(min-width: $default-bp-sm) {
		&:before {
			content: '';
			position: absolute;
			background-color: $bg-skybox;
			width: 9999em;
			left: -9999em;
			top: 0;
			bottom: 0;
			// z-index: 1;
		}
	}
}

.skybox-content {
	display: none;
	margin-top: 3em;
	padding-top: 1em;
	border-top: 1px solid lighten($bg-skybox, 5%);
	.skybox-mod.is-active & {
		display: block;
	}
}

.skybox-top,
.skybox-bottom {
	@extend %skybox-block;
}

.l-skybox {
	margin: 0;
	@media(min-width: $skybox-sm) and (max-width: $skybox-med) {
		@include media-grid(2, $space-grid);
	}

	@media(min-width: $skybox-lg) and (max-width: $x-large - 1) {
		@include media-grid(2, $space-grid);
	}

	@media(min-width: $x-large) {
		@include media-grid(3, $space-grid);
	}
}

.skybox-item {
	font-size: 0.6em;
}

.skybox-message {
	float: left;
	font-size: 1em;
	@media(min-width: $skybox-lg) {
		padding-right: 3em;
	}
}

.skybox-link {
	display: inline-block;
	margin-top: 1em;
	margin-bottom: 0.5em;
	font-size: 0.85em;
	color: $black-60;
	background-color: #fff;
	text-decoration: none;
	padding: 0 0.5em;
	@media(min-width: $skybox-lg) {
		float: right;
		margin-top: -1.5em;
	}
}

.skybox-is-closed {
	display: block;
	.skybox-mod.is-active & {
		display: none;
	}
}

.skybox-is-open {
	display: none;
	.skybox-mod.is-active & {
		display: block;
	}
}

.skybox-head-sm {
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: $sans-weight-light;
	font-size: 1em;
	margin-bottom: 0.5em;
}



// --------------------------------------------
// --------------------------------------------
//
// Profile xxx
//
// --------------------------------------------
// --------------------------------------------

.profile-hdr-bold {
	@extend %subsection-hdr-bold;
	color: $black-90;
	margin-bottom: 0.55em;
	padding-bottom: 0.55em;
	text-transform: uppercase;
}

.profile-badge {	
	text-align: center;
	span {
		color: #ff0000;
	}
	div:first-child {
		border: 1px dashed #000000;
		padding:3px;
		height: 300px;
		width: 400px;		
		vertical-align: middle;
		display: table-cell;
		margin-left: auto;
		margin-right: auto; 
	}
	div:last-child {		
		margin-top: 25px;
		width: 400px;
	}
	p:nth-child(1) label {
		font-size: 1.8em;
		height: 2em;
		font-weight: bold;
	}
	p:nth-child(2) input {
		font-size: 2em;
		height: 2em;
	}
	p:nth-child(3) input {
		font-size: 1.4em;
		height: 2em;
		color: #999999;
	}	
	p:nth-child(4) input {
		font-size: 1.6em;
		height: 2em;
	}	
	p:nth-child(5) input {		
		height: 2em;
	}		
	input {
	    background-color: transparent;
	    border: 0px solid;
	    height: 20px;	    
	    text-align: center;
		margin-left: auto;
		margin-right: auto;	    
	}		
}

// --------------------------------------------
// --------------------------------------------
//
// Shopping Cart
//
// --------------------------------------------
// --------------------------------------------



.cart-item {
	vertical-align: center;
}

.discount-label {
	@media(min-width: $large) {
		float: left;
		margin-right: 1em;
		padding-top: 0.25em;
	}
}

.discount-input {
	@media(min-width: $large) {
		float: left;
		max-width: 6em !important;
	}
}


.cart-qty {
	width: 40px;
	background-color: $bg-med;
	border: 0;
}

.cart-remove {
	border-bottom: 1px solid #ccc;
	padding-bottom: 2em;
	width: 100%;
	@media(min-width: $x-large) {
		position: absolute;
		left: 980px;
		border-bottom: none;
		padding-bottom: 0;
		width: 10%;
	}
	a {
		font-size: 0.85em;
		color: $black-20;
		// background-color: $bg-lt;
		border-radius: 25%;
		text-decoration: none;
		&:hover {
			color: red;
		}
	}
}

.bndl-comp {
	font-style: italic;
	color: $black-30;
	padding-left: 2em;
}


// Profile styles to match Community Hub

.profile-hdr {
	@media(min-width: $large) {
		margin-top: -4em;
		margin-bottom: -1em;
	}
}

.profile-secondary {
	@media(min-width: $med-lg) {
		width: 25%;
		max-width: none;
		padding-right: 15px;
	}
	.page-rail {
		@media(min-width: $large) {
			padding-top: 5.5em;
		}
		.side-menu-item {
			a {
				border-bottom: 1px solid $bg-med;
				padding: 12.5px 5px;
				letter-spacing: -0.1px;
				transition: all 200ms ease;
				&:hover {
					background-color: #f7f7f7;
				}
			}
			&.is-active {
				a {
					background: $bg-med;
					border-color: $bg-med;
					color: $black-30;
					&:hover {
						background-color: $bg-med;
					}
				}
			}
		}
	}
	&:after {
		display: none;
	}
}
.profile-primary {
	@media(min-width: $large) {
		width: 96%;
		float: left;
		margin-left: -18em;
	}
}

.login-prompt {
	margin: 2em 0;
	overflow: hidden;
	.login, .create {
		width: 100%;
		text-align: center;
		padding: 2em;
		border: 4px solid $bg-med;
		border-radius: 12px;
		margin-bottom: 2em;
		@media(min-width: $med-lg) {
			width: 47.5%;
			float: left;
			margin-right: 5%;
			margin-bottom: 0;
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.ebook-purchases-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	@media(max-width: $medium) {
		flex-direction: column;
		margin-bottom: 1em;
	}
}

.ebook-purchases-header h1.page-head-lg {
	width: 100%;
}

.ebook-purchases-header div.md {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.ebook-purchases-header button {
	cursor: pointer;
}