.preloader-wrapper {
	&.tiny {
		width: 1rem;
    	height: 1rem;
	}
	&.on-btn {
		.spinner-layer {
			border-color: #fff;
		}
		&.tiny {
			margin-top: 10px;
		}
	}
}

.loading-spinner-holder {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	opacity: 1;
	background-color: #fff;
	z-index: 999;
	transition: opacity 0.5s linear 0.5s;
	.preloader-wrapper {
		position: fixed;
	    top: 50%;
	    left: 50%;
	    margin-top: -32px;
	    margin-left: -32px;
	    color: $black-10;
	    z-index: 13;
	}
	&.fade-out {
		opacity: 0;
	}
	&.hidden {
		display: none;
	}
}

.product-loading {
	position: absolute;
	top: 50%;
	margin-top: -10px;
	padding-left: 3px;
	background-color: #fff;
	opacity: 0;
	transition: opacity .25s ease 1.25s;
	.preloader-wrapper {
		width: 20px;
		height: 20px;
		opacity: 0;
		transition: opacity .25s ease 1.25s;
	}
	&.active {
		opacity: 1;
		transition: opacity .25s ease;
		.preloader-wrapper {
			opacity: 1;
			transition: opacity .25s ease;
		}
	}
}