.dfad {
	width: 100%;
	text-align: center;
	margin: 0 0 1rem;
}

.manual-ad-placement {
	display: none;
}

// Sidebar add

.page-article {
	@media #{$small-and-down} {
		// This makes it so the add in the sidebar is above the article content on mobile
		.row {
			display: flex;
			flex-direction: column-reverse;
		}
	}
	.article-sidebar {
		@media #{$medium-and-down} {
			background: none!important;
		}
		.article-sidebar-ad {
			padding: 1em 0;
			img {
				width: 100%;
				height: auto;
			}
		}
	}
}