// --------------------------------------------
// --------------------------------------------
//
// xxxx
//
// --------------------------------------------
// --------------------------------------------


.bulleted, %bulleted {
	list-style: disc;
	li {
		@extend %tease-text;
		margin-left: 2em;
		font-weight: $sans-weight-light;
	}
}

.numbered, %numbered {
	margin-left: 1.55em;
	li {
		margin-bottom: 1em;
		list-style-type: decimal !important;
	}
}

ul.two-columns {
	list-style: none;
	margin-left: 0;
	padding-left: 0;
	li {
		margin-left: 0;
		padding-left: 0;
		margin-bottom: 1em;
	}
	@media(min-width: $medium) {
		-moz-column-count: 2;
	    -moz-column-gap: 20px;
	    -webkit-column-count: 2;
	    -webkit-column-gap: 20px;
	    column-count: 2;
	    column-gap: 20px;
	}
}

ul.three-columns {
	list-style: none;
	margin-left: 0;
	padding-left: 0;
	li {
		margin-left: 0;
		padding-left: 0;
		margin-bottom: 1em;
	}
	@media(min-width: $medium) {
		-moz-column-count: 2;
	    -moz-column-gap: 20px;
	    -webkit-column-count: 2;
	    -webkit-column-gap: 20px;
	    column-count: 2;
	    column-gap: 20px;
	}
	@media(min-width: $large) {
		-moz-column-count: 3;
	    -moz-column-gap: 10px;
	    -webkit-column-count: 3;
	    -webkit-column-gap: 10px;
	    column-count: 3;
	    column-gap: 10px;
	}
}

ul.four-columns {
	list-style: none;
	margin-left: 0;
	padding-left: 0;
	li {
		margin-left: 0;
		padding-left: 0;
		margin-bottom: 1em;
	}
	@media(min-width: $medium) {
		-moz-column-count: 2;
	    -moz-column-gap: 20px;
	    -webkit-column-count: 2;
	    -webkit-column-gap: 20px;
	    column-count: 2;
	    column-gap: 20px;
	}
	@media(min-width: $large) {
		-moz-column-count: 4;
	    -moz-column-gap: 10px;
	    -webkit-column-count: 4;
	    -webkit-column-gap: 10px;
	    column-count: 4;
	    column-gap: 10px;
	}
}

ul.five-columns {
	list-style: none;
	margin-left: 0;
	padding-left: 0;
	li {
		margin-left: 0;
		padding-left: 0;
		margin-bottom: 1em;
	}
	@media(min-width: $medium) {
		-moz-column-count: 3;
	    -moz-column-gap: 10px;
	    -webkit-column-count: 3;
	    -webkit-column-gap: 10px;
	    column-count: 3;
	    column-gap: 10px;
	}
	@media(min-width: $large) {
		-moz-column-count: 5;
	    -moz-column-gap: 10px;
	    -webkit-column-count: 5;
	    -webkit-column-gap: 10px;
	    column-count: 5;
	    column-gap: 10px;
	}
}

.list-items, %list-items {
	@include pie-clearfix;
	list-style: none;
}

.list-item, %list-item {
	position: relative;
	list-style: none;
	margin-left: 0;
	@include pie-clearfix;

	// If a list item has the parent class of .list-items--lined each list item
	// will have a bottom border. Except for the last one.
	.list-items--lined > &  {
		border-bottom: $bdr-thin;
		padding-bottom: 2.25em;
		margin-bottom: 2.25em;
		&:last-child {
			border-bottom: none;
		}
	}

	.list-items--lined-sm > & {
		border-bottom: $bdr-thin;
		padding-bottom: 0.85em;
		margin-bottom: 2em;
	}

	&:last-child { margin-bottom: 0; border-bottom: none; }
}

.list-item-link, %list-item-link {
	@include link;
}

.static-page {
	.fa-ul {
		li {
			list-style:none !important;
		}
	}
}


// --------------------------------------------
// --------------------------------------------
//
// Sponsoring/exhibiting lists
//
// --------------------------------------------
// --------------------------------------------


.sponsoring, .exhibiting {
	.list-items {
		border: 4px solid #efefef;
		margin-bottom: 2em;
		.list-item {
			border-bottom: 1px solid #efefef;
			margin-bottom: 0;
			padding: 1em;
			.page-head-split {
				padding: 0;
				margin-bottom: 0;
				.page-bold-link {
					max-width: none;
					display: table-cell;
					vertical-align: middle;
					padding: 0;
					&.sold-out {
						display: block;
						&:after {
							content: "sold out";
							float: right;
							color: $black-80;
							text-transform: uppercase;
						}
					}
				}
			}
			.iapp-product-details {
				padding-top: 1em;
			}
		}
	}
}




// --------------------------------------------
// --------------------------------------------
//
// Aside items
//
// --------------------------------------------
// --------------------------------------------

.aside-items {
	@extend %list-items;
}

.aside-item {
	@extend %list-item;
	border-bottom: $bdr-thin-lt;
	padding-bottom: 1.25em;
	margin-bottom: 1.25em;
	&:last-child {
		border-bottom: none;
	}
}

.aside-item-head {
	@extend %tz-head-sm;
	@extend %head-regular-weight;
}



// --------------------------------------------
// --------------------------------------------
//
// Inline items
//
// --------------------------------------------
// --------------------------------------------

.list-items--inline, %list-items--inline {
	@extend %list-items;
}

.list-item--inline, %list-item--inline {
	@extend %list-item;
	display: inline-block;
}



// --------------------------------------------
// --------------------------------------------
//
// Tag items Inline
//
// --------------------------------------------
// --------------------------------------------

.tag-items--inline {
	@extend %list-items--inline;
}

.tag-item--inline {
	@extend %list-item--inline;
	margin-right: 15px;
	font-size: 12px;
	position: relative;
	& + & {
		.tag-item-link:after {
			content: ",";
			position: absolute;
			top: 0;
			left: -7px;
			font-weight: $sans-weight-bold;
			color: $black-30;
		}
	}
}

.tag-item-link {
	@include link(tag);
	font-weight: $sans-weight-bold;
}



// --------------------------------------------
// --------------------------------------------
//
// Filter menu items
//
// --------------------------------------------
// --------------------------------------------

.filter-menu-items {
	@extend %list-items;
}

.filter-menu-item {
	@extend %list-item;
	font-size: 1.07692307692308em;
	&:last-child {
		margin-bottom: 0;
	}
	&.is-active {
		background-color: $bg-dark;
	}
}

.filter-item-link {
	@include link(pagenav);
	padding: 0.55em 7px;
	display: block;
	&:hover {
		background-color: $bg-med;
	}
}

.filter-item-number {
	float: right;
	color: $black-20;
}




// --------------------------------------------
// --------------------------------------------
//
// Social
//
// --------------------------------------------
// --------------------------------------------


.social-list-items {
	@extend %list-items--inline;
	list-style: none;
}


.social-list-item {
	@extend %list-item--inline;
	text-indent: -9999em;
	border-radius: 50%;
	overflow: hidden;
	margin-left: 7px;
	margin-bottom: 0;
}


.social-list-link {
	position: relative;
	background-color: darken($hdr-default-color, 15%);
	opacity: 0.9;
	display: block;
	&:hover {
		opacity: 1;
	}

	.hdr-daily-dashboard & { background-color: darken($hdr-daily-dashboard, 15%); }
	.hdr-privacy-tracker & { background-color: darken($hdr-privacy-tracker, 15%); }
	.hdr-privacy-perspective & { background-color: darken($hdr-privacy-perspective, 15%); }

	.icons-sm & {
		width: 25px;
		height: 25px;
	}

	.icons-med & {
		width: 30px;
		height: 30px;
	}

	.icons-med-social & {
		width: 30px;
		height: 30px;
	}

	.icons-lg & {
		width: 50px;
		height: 50px;
	}

	.icons-lg-social & {
		width: 50px;
		height: 50px;
	}
}




// --------------------------------------------
// --------------------------------------------
//
// Social Items with brand colors
//
// --------------------------------------------
// --------------------------------------------


.social-list-link {
	.brand-color .brand-facebook & { background-color: $facebook; }
	.brand-color .brand-twitter & { background-color: $twitter; }
	.brand-color .brand-google & { background-color: $google; }
	.brand-color .brand-linkedin & { background-color: $linkedin; }
	.brand-color .brand-comment & { background-color: $comment; }
	.brand-color .brand-email & { background-color: $email; }
	.brand-color .brand-print & { background-color: $print; }
}





// --------------------------------------------
// --------------------------------------------
//
// Page items
//
// --------------------------------------------
// --------------------------------------------


.page-list-items {
	@extend %list-items;

}

.page-list-item {
	@extend %list-item;
	@extend %page-text-med;
	list-style: disc inside none;
}

.page-list-link {
	@include link;
}


.iapp-product-details {
	ul {
		@extend %bulleted;
		margin-bottom: 1em;
		li {
			margin-bottom: 0;
		}
	}
}



// --------------------------------------------
// --------------------------------------------
//
// My IAPP nav items
//
// --------------------------------------------
// --------------------------------------------

.nav-myiapp-items {
	@extend %side-menu-items;
}

.nav-myiapp-item {
	@extend %side-menu-item;
}

.nav-myiapp-link {
	@extend %side-menu-link;
}




// --------------------------------------------
// --------------------------------------------
//
// Pagination
//
// --------------------------------------------
// --------------------------------------------

.pagination-mod {
	margin: 0 auto 6em auto;
	position: relative;
	display: inline-block;
	text-align: center;
	width: 100%;
	@media(min-width: $medium) {
		max-width: 100%;
	}
}

.pagination-inner {
	display: inline-block;
}

.pagination-items {
	@extend %list-items;
	font-size: 12px;
	display: block;
	float: left;
	line-height: 35px;
}


.pagination-item {
	@extend %list-item;
	display: inline-block;
	margin-right: 5px;
	&:last-child {
		margin-right: 0;
	}
}


.pagination-item-link {
	background-color: $bg-lt;
	padding: 0.35em 0.85em;
	line-height: 2em;
	font-weight: $sans-weight-bold;
	text-decoration: none;
	color: $black-10;
	&:hover {
		text-decoration: underline;
	}
	.is-active & {
		color: $bg-med;
		background-color: darken($bg-med, 45%);
	}
}


.pagination-next,
.pagination-prev {
	border: 1px solid $bg-med;
	position: relative;
	display: block;
	&:hover {
		border-color: darken($bg-med, 5%);
	}
	&:active {
		border-color: darken($bg-med, 15%);
	}
	&.disabled {
		border-color: $black-10;
		opacity: 0.15;
	}
}


.pagination-next {
	@extend %next;
	margin-left: 12px;
	float: right;
}

.pagination-prev {
	@extend %prev;
	margin-right: 12px;
	float: left;
}



// --------------------------------------------
// --------------------------------------------
//
// Session anchor links
//
// --------------------------------------------
// --------------------------------------------

.sess-tracks {
	display: none !important;
	width: 100%;
	padding-left: 0 !important;
	padding-bottom: 2em;
	ul {
		width: 100%;
		-webkit-column-count: 3; /* Chrome, Safari, Opera */
		-moz-column-count: 3; /* Firefox */
		column-count: 3;
		-webkit-column-gap: 1em; /* Chrome, Safari, Opera */
		-moz-column-gap: 1em; /* Firefox */
		column-gap: 1em;
		li {
			width: 100%;
			margin-bottom: 1em;
		}
	}
	@media(min-width: $desktop) {
		display: block !important;
	}
}



// --------------------------------------------
// --------------------------------------------
//
// Session selectors
//
// --------------------------------------------
// --------------------------------------------



.session-selectors {
	display: block;
	position: relative;
	margin-bottom: 1em;
	width: 100%;
	float: left;
	.session-selector {
		display: block;
		float: left;
		width: 100%;
		border: 4px solid #efefef;
		height: auto;
		margin-bottom: 2em;
		-webkit-transition: height 0.3s ease;
	    -moz-transition: height 0.3s ease;
	    -o-transition: height 0.3s ease;
	    transition: height 0.3s ease;
		@media(min-width: $large) {
			width: 30%;
			margin-right: 5%;
			&:last-child {
				margin-right: 0;
			}
		}
		&.session-types {
			li {
				a {
					.hex-sm {
						float: left;
						margin: -0.3em 1em 0 0.4em;
					}
					i {
						display: none;
						@media (min-width: $large) {
							display: inline-block;
							float: right;
						}
					}
				}
			}
			.selected.arrow {
				a {
					i {
						display: none;
					}
				}
			}
		}
		&.session-tracks {
			&.open {
				@media(min-width: $medium) {
					position: absolute;
					width: 100%;
					background-color: #fff;
					z-index: 1000;
					margin-top: 8.7em;
					li {
						width: 50%;
						float: left;
						&.selected {
							position: absolute;
							width: 100%;
							background-color: #fff;
							margin-top: -3em;
						}
					}
				}
				@media(min-width: $large) {
					margin-top: 5em;
					li {
						width: 33.33333%;
						&.selected {
							border: 4px solid #efefef;
							width: 30.11111%;
							left: 50%;
							margin-left: -15.11111%;
							margin-top: -5.3em;
						}
					}
				}
			}
		}
		&.session-days {
			&.tagsopen {
				@media(min-width: $large) {
					position: absolute;
					right: 0;
				}
			}
		}
		li {
			border-bottom: 1px solid #efefef;
			&.noborder {
				border-bottom: none;
			}
			a {
				padding: 0.8em 0.5em;
				display: block;
				text-decoration: none;
				color: $black-30;
				font-size: 1.1em;
				border-left: 4px solid #efefef;
				margin-left: -4px;
				-webkit-transition: all 0.3s ease;
			    -moz-transition: all 0.3s ease;
			    -o-transition: all 0.3s ease;
			    transition: all 0.3s ease;
				&:hover {
					color: $black-100;
					border-left: 4px solid $green-main;
					margin-left: -4px;
				}
			}
			&.selected {
				a {
					color: $black-100;
					border-left: 4px solid lighten($green-main, 20%);
					&:hover {
						border-left: 4px solid $green-main;
					}
				}
			}
			&.arrow {
				a {
					border-left: 4px solid #efefef;
					&:hover {
						border-left: 4px solid #efefef;
					}
					&:after {
						font-family: FontAwesome;
						content: "\f078";
						float: right;
					}	
				}
			}
			&:last-child {
				border-bottom: none;
			}
		}
	}
	.noicon {
		a {
			.hex-sm {
				display: none;
			}
			i {
				display: none;
			}
		}	
	}
}

.session-icon-key-trigger {
	display: none;
	@media(min-width: $large) {
		display: block;
		margin-left: 10em;
	}
	@media(min-width: $x-large) {
		margin-left: 1em;
	}
}

.static-session-list {
	clear: both;
	margin-top: 4em !important;
	margin-left: -2em;
	margin-right: -2em;
	width: auto;
	@media(min-width: $large) {
		margin-top: 1em !important;
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}
	.session-desc-exp {
		clear: both;
		padding: 2em 0;
		@media(min-width: $large) {
			padding: 2em 0 2em 1em;
		}
		@media(min-width: $x-large) {
			padding: 2em 1em 2em 13.7em;
		}
		p {
			font-size: 1em;
			line-height: 1.4em;
			margin-bottom: 1em;
		}
	}
	.l-pair-tz-session {
		.txt-bd {
			float: none !important;
		}
	}
	a.page-bold-link {
		display: table-cell;
	}	
}



// --------------------------------------------
// --------------------------------------------
//
// APIA
//
// --------------------------------------------
// --------------------------------------------



.contributions {
	li {
		border-bottom: 1px solid $black-10;
		margin-bottom: 3em;
		padding-bottom: 1em;
		&:last-child {
			border-bottom: none;
			margin-bottom: 0;
			padding-bottom: 0;
		}
		.btn {
			text-align: center;
			margin-top: 2em;
		}
	}
}




// --------------------------------------------
// --------------------------------------------
//
// Colleges
//
// --------------------------------------------
// --------------------------------------------



.college-list {
	.iapp-product-header {
		@media (min-width: $large) {
			display: block;
			width: 38%;
			overflow: hidden;
			float: left;
		}
	}
	.checkmark {
		display: none;
		@media (min-width: $large) {
			display: block;
			float: left;
			width: 15.25%;
			height: 16px;
			text-align: center;
			i {
				margin-left: -0.5em;
			}
		}
	}
	.iapp-product-details {
		width: 100%;
		float: left;
		a {
			@include link;
			text-decoration: underline;
		}
	}
}


// --------------------------------------------
// --------------------------------------------
//
// Topic Posts
//
// --------------------------------------------
// --------------------------------------------


.topic-posts {
	margin-top: 3em;
}



// --------------------------------------------
// --------------------------------------------
//
// Recent Comments sidebar list
//
// --------------------------------------------
// --------------------------------------------



.recent-comments {
	background-color: #f2f2f2;
	padding: 1em;
	margin-top: 1em;
	li {
		margin-bottom: 1em;
	}
	@media(min-width: $large) {
		margin-top: 3em;
		margin-left: -1em;
	}
}



// --------------------------------------------
// --------------------------------------------
//
// Paragraph List
//
// --------------------------------------------
// --------------------------------------------


.p-list {
	margin-bottom: 0.75em;
}

