$collection-active-bg-color: map-get($grey, "darken-3");
$collection-active-color: #fff;

$spinner-default-color: #fff !important;

@mixin sold-out-badge {
	display: inline-block;
	background-color: map-get($red, "accent-1");
	color: #fff;
	font-size: 0.7rem;
	font-family: $sans;
	letter-spacing: 0.025em;
	border-radius: 3px;
	margin-left: 2px;
	padding: 0 5px;
}

@mixin live-online-badge {
	display: inline-block;
	background-color: map-get($blue, "accent-1");
	color: #fff;
	font-size: 0.7rem;
	font-family: $sans;
	letter-spacing: 0.025em;
	border-radius: 3px;
	margin-left: 2px;
	padding: 0 5px;
}