// --------------------------------------------
// --------------------------------------------
//
// Hero variables
//
// --------------------------------------------
// --------------------------------------------

$hero-bg: 		#000;

// --------------------------------------------
// --------------------------------------------
//
// Hero styles
//
// --------------------------------------------
// --------------------------------------------

.hp-hero-mod {
	padding: 0;
	background-color: $hero-bg;
	color: #fff;
	@media(min-width: $x-large) {
		min-height: 18em;
	}
	&.has-img {
		@media(min-width: $x-large) {
			min-height: 25em;
		}
	}
}


.hp-hero-main-mod {
	margin: 0 auto 3em;
	padding-top: 7em;
}


.hp-hero-secondary-mod {
	@extend %grid-hero-3;
	margin-bottom: 6em;
	margin-top: 4em;
	@media(min-width: $medium) {
		text-align: left;
	}
}

.hero-bg {
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 50% 0;
	position: absolute;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	perspective: 1000;
	width: 100%;
	&:before {
		content: "";
		position: absolute;
		left: 0;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
	&.hero-bg-img {
		min-height: 60em;
		@media(min-width: $desktop) {
			background-size: cover;
		}
		// This sets the darkened gradient over the hero
		&:after {
			content: '\0020';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;

			filter: none;
			background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPHJhZGlhbEdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9IjUwJSIgcj0iNzUlIj4KICAgIDxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMCIvPgogICAgPHN0b3Agb2Zmc2V0PSI5MCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L3JhZGlhbEdyYWRpZW50PgogIDxyZWN0IHg9Ii01MCIgeT0iLTUwIiB3aWR0aD0iMTAxIiBoZWlnaHQ9IjEwMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);

			background-image: linear-gradient(transparent, #000 70%, #000);
			@media(min-width: $max-width) and (max-width: 98.4375em) {
				background-image: linear-gradient(to top, transparent, #000 95%, #000),
					                      linear-gradient(to left, #000, transparent 20%),
										  linear-gradient(to right, #000, transparent 20%);
			}
			@media(min-width: 98.4375em) {
				background-image: linear-gradient(to top, transparent, #000 90%, #000),
					                      linear-gradient(to left, #000, transparent 25%),
										  linear-gradient(to right, #000, transparent 25%);
			}
		}
		&.hero-conf-banner {
			min-height: 35em;
			background-repeat: no-repeat !important;
			background-color: #000;
			&:after {
				top: 10em;
				background-image: linear-gradient(transparent, #000 75%, #000);
				@media(min-width: $medium) {
					top: 20em;
					bottom: -5em;
					background-image: linear-gradient(transparent, #000 100%, #000);
				}
			}
		}
	}
}

.hero-conf-banner + .l-site-width {
	.hp-hero-main-mod {
		position: relative;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			bottom: -10em;
			left: 0;
			right: 0;
			filter: none;
			background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjI1JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjQiLz4KICAgIDxzdG9wIG9mZnNldD0iNzUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuNCIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
			background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.33) 25%,rgba(0,0,0,0.33) 50%,rgba(0,0,0,0.33) 75%,rgba(0,0,0,0) 100%);
			z-index: 0;
		}
		.hp-hero-head-sm,
		.hp-hero-head-med,
		.hp-hero-subhead,
		.hp-hero-text,
		.hp-hero-links {
			position: relative;
			z-index: 1;
			text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
		}
		.hp-hero-text {
			font-size: 1.4em;
		}
		.hp-hero-links .button-lg {
			font-size: 1.3em;
		}
	}
}


// 1575 / 16    98.4375em

.hero-head-link {
	@include link(white);
	&:hover {
		text-decoration: none;
	}
}



.hp-hero-text {
	ul {
		display: table;
		max-width: 70%;
		margin: 0 auto;
		li {
			@extend %tease-text;
			color: #fff;
			text-align: left;
			&:before {
				font-family: FontAwesome;
				content: "\f138";
				padding-right: 1em;
				float: left;
				height: 2em;
			}
		}
		@media(max-width: $large) {
			max-width: 100%;
		}
	}
	a {
		color: #fff;
	}
}




// --------------------------------------------
// --------------------------------------------
//
// HP Message
//
// --------------------------------------------
// --------------------------------------------

.message-mod {
	background-color: $black-90;
	padding: 2em $site-margin;
	color: $bg-lt;
	text-align: center;
	margin: 0 auto;
	&.message-overlay {
		background-color: #000;
		position: relative;
		border-bottom: none;
		color: $black-20;
		@media(min-width: $medium) {
			max-width: $max-width;
		}
		@media(min-width: $large) {
			border-top: none;
		}
	}
}


.message-inner {
	display: inline-block;
}

%message-text {
	@include sans(light);
	line-height: 1.428571429em;
	margin-bottom: 0.25em;
	.message-overlay & {
		display: block;
		margin-bottom: 0.25em;
		margin-right: 0;
	}
	@media(min-width: $desktop) {
		display: inline-block;
		margin-bottom: 0;
	}
}

.message-text-lg {
	@extend %message-text;
	color: $bg-med;
	font-size: $base-med;
	text-decoration:none;
	@media(min-width: $type-bp-med) {
		font-size: 2.153846154em; 			// 28
	}
}

.message-text-med {
	@extend %message-text;
	font-size: $base-med;
	@media(min-width: $type-bp-med) {
		font-size: 1.69230769230769em; 		// 22
	}
}


.message-text-sm {
	@extend %message-text;
	font-size: 1.230769231em; 		// 16
	margin-left: 1.5em;
	margin-right: 1.5em;
	margin-bottom: 1em;
	@media(min-width: $desktop) {
		margin-bottom: 0;
	}
}

.message-link {
	@include link(white);
	font-size: 0.85em;
}


// --------------------------------------------
// --------------------------------------------
//
// Non hero styles :: other sections of hp
//
// --------------------------------------------
// --------------------------------------------



// --------------------------------------------
//
// HP Bottom

.hp-bottom-mod {
	background-color: $bg-med;
	position: relative
}

.hp-sections-mod {
	padding: 4em 0 6em;
	margin: 0 auto;
}

.hp-sections-outer {
	max-width: $grid-15;
	margin: 0 auto;
}

.l-hp-sections {

	@media(min-width: $medium) and (max-width: $large - 1) {
		@include media-grid(2, $space-grid);
	}
	@media(min-width: $large) {
		@include media-grid(3, $space-grid);
	}
}

.hp-section-item {
	margin-bottom: 3em;
	&:last-child {
		margin-bottom: 0;
	}
	@media(min-width: $medium) {
		&:nth-last-child(2) {
			margin-bottom: 0;
		}
	}
	@media(min-width: $large) {
		&:nth-last-child(3) {
			margin-bottom: 0;
		}
	}
}

.hp-sections-head {
	@extend %tz-head-med;
	@include sans;
}

.hp-hed-link {
	@include link(head-lt);
	color: $black-60;
}

.hp-sections-link {
	@include link(green);
	margin-left: 4px;
}

// --------------------------------------------
//
// Hp Stream

.hp-stream-mod {
	padding: 3em 0 4em;
	position: relative;
	background-color: #fff;
	z-index: 10000;
	&:before, &:after {
		content: '';
		width: 0;
		height: 0;
		left: 50%;
		margin-left: -16px;
		position: absolute;
		border-right: 12px inset transparent;
		border-left: 12px inset transparent;

	}
	&:before {
		border-top: 12px inset transparent;
		border-bottom: 12px solid #fff;
		top: -24px;
	}
	&:after {
		border-top: 12px solid #fff;
		border-bottom: 12px inset transparent;
		bottom: -24px;
	}
	.subsection-hdr-gray {
		clear: both;
		a {
			text-decoration: none;
			color: $black-40;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.about-block {
		margin-bottom: 2.5em;
	}
	.see-all {
		clear: both;
		text-align: center;
		padding: 1em 0 3em;
		a {
			position: relative;
			text-decoration: none;
			margin-left: 0;
			padding: 1em 2em;
			@media(min-width: $medium) {
				margin-left: 1em;
				&:first-child,
				&:nth-of-type(1) {
					margin-left: 0;
				}
			}
			// &:before {
			// 	content: "";
			// 	position: absolute;
			// 	top: 0;
			// 	left: 0;
			// 	right: 0;
			// 	bottom: 0;
			// 	background-color: #efefef;
			// 	opacity: 0;
			// 	transition: opacity 200ms ease;
			// }
			// &:hover {
			// 	&:before {
			// 		opacity: 0.25;
			// 	}
			// }
		}
	}
	.tz, .subsection-hdr-gray, .see-all {
		@media(min-width: $large) {
			visibility: hidden;
			&.visible {
				visibility: visible;
			}
		}
	}
	.tz {
		display: block;
		float: left;
		padding: 1em;
		border: 2px solid #efefef;
		background-color: #fff;
		position: relative;
		z-index: 100000;
		.tz-head-date {
			display: block;
			text-align: left;
			margin-bottom: 0.75em;
			.user--loggedin & {
				text-align: right;
			}
		}
		.tz-head-med {
			font-size: 1.2em;
			@media(min-width: $large) {
				font-size: 1.53846153846154em;
			}
		}
		.art-bd {
			width: 100%;
			max-width: 100%;
			max-height: 24em;
			overflow: hidden;
			display: block;
			margin-bottom: 0.5em;
			margin-left: 0;
			@media(min-width: $med-lg) {
				max-height: 17em;
			}
			img {
				max-width: 100% !important;
			}
		}
		.txt-bd {
			width: 100%;
			max-width: 100%;
			margin-left: 0;
			padding-left: 0;
			display: block;
		}
		.tz-saveable-block {
			left: 1.25em;
			top: 1.1em;
		}
		&.tz-iapp_conferences {
			border: none;
			text-align: center;
			position: relative;
			padding: 0;
			.txt-bd {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				padding-left: 2em;
				padding-right: 2em;
				padding-top: 3.7em;
				color: #fff;
				@media(min-width: $medium) {
					padding-top: 4em;
				}
				.tz-head-link {
					color: #fff;
				}
				.tz-bd-text {
					color: #fff;
					span {
						text-transform: uppercase;
					}
				}
			}
		}
		&.resource-tz {
			.art-bd {
				float: left !important;
				max-width: 90px !important;
				left: 0 !important;
				.hex-lg {
					margin-left: 30px;
				}
			}
			.img-tz-bg {
				width: 56px;
				height: 56px;
				float: left !important;
    			margin-bottom: 1em;
    			margin-left: 20px;
    			margin-right: 15px;
			}
			.tz-text-block {
				clear: both;
			}
			.tz-saveable-block {
				left: 0.25em;
				top: 0.1em;
			}
		}
		&.tz-job {
			min-height: 0;
			display: table;
			padding-top: 0.5em;
			padding-bottom: 0.5em;
			.txt-bd, .art-bd {
				display: table-cell;
				vertical-align: middle;
			}
			.art-bd {
				padding-top: 1em;
			}
		}
		&.tz-promo {
			border-top: 1.8em solid #efefef;
			// &:before {
			// 	content: "";
			// 	position: absolute;
			// 	top: -2px;
			// 	bottom: 0;
			// 	left: -2px;
			// 	right: 0;
			// 	border-top: 4.25em solid $link-color;
			//     border-right: 4.25em solid transparent;
			//     width: 0;
			// 	opacity: 0.75;
			// }
			&:after {
				position: absolute;
				color: $black-20;

				// font-family: "FontAwesome";
				// content: "\f0a1";
				// font-size: 1.3em;
				// top: 0.25em;
				// left: 0.35em;

				// content: url("/wp-content/themes/iapp/static/img/iapp-icon-white.png");
				// top: 0.55em;
				// left: 0.7em;

				content: "IAPP News";
				font-size: 0.85em;
				top: -1.5em;
				left: 1.35em;
			}
		}
		&.tz-dfads {
			border: 0;
			@media(max-width: $x-large) {
				padding: 0;
			}
			.hp-ad {
				text-align: center;
				@media(min-width: $x-large) {
					padding: 4px 0;
				}
				img {
					max-width: 100%;
					height: auto;
				}
			}
		}
	}
	.full-blocks {
		position: relative;
		.tz-iapp_conferences {
			@media(min-width: $large) {
				margin-top: 1em;
			}
			.art-bd {
				.tz-conf-hp {
					@media(min-width: $med-lg) {
						min-height: 17em;
						padding: 1.5em;
					}
				}
			}
			.txt-bd {
				@media(min-width: $med-lg) {
					padding: 5em 1.5em;
				}
			}
			.tz-saveable-block {
				left: 0.35em;
				top: 0.25em;
			}
		}
	}
	.left-blocks,
	.right-blocks {
		position: relative;
		.tz-promo {
			.art-bd {
				@media(min-width: $med-lg) {
					width: 48.5%;
					max-width: 48.5%;
					margin-right: 4%;
					float: left;
				}
			}
		}
	}
	&.hp-stream-default {
		.full-blocks {
			clear: both;
			width: 100%;
			.tz {
				@media(min-width: $med-lg) {
					width: 31.11111%;
					margin-right: 3.33333%;
					&:nth-child(3n+3),
					&:nth-of-type(3) {
						margin-right: 0;
					}
				}
				.txt-bd {
					.tz-head-med {
						@media(min-width: $small) {
							font-size: 1.6em;
						}
						@media(min-width: $medium) {
							font-size: 1.8em;
						}
						@media(min-width: $med-lg) {
							font-size: 1.2em;
						}
						@media(min-width: $large) {
							font-size: 1.53846153846154em;
						}
					}
				}
			}
		}
		.left-blocks {
			@media(min-width: $med-lg) {
				clear: both;
				float: left;
				width: 48.5%;
				max-width: 48.5%;
			}
		}
		.right-blocks {
			@media(min-width: $med-lg) {
				float: right;
				width: 48.5%;
				max-width: 48.5%;
			}
		}
		.news-block {
			position: relative;
			width: 100%;
			overflow: hidden;
			.tz {
				@media(min-width: $medium) {
					width: 48.5%;
					max-width: 48.5%;
					margin-right: 3%;
					&:nth-child(even),
					&:nth-of-type(even) {
						margin-right: 0;
					}
				}
				@media(min-width: $med-lg) {
					width: 46.33333%;
					max-width: 46.33333%;
					margin-right: 6.66666%;
				}
				.tz-saveable-block {
					left: 0.4em;
					top: 0.25em;
				}
			}
		}
		.promo-block {
			position: relative;
			.tz {
				@media(min-width: $med-lg) {
					max-width: 99.25%;
				}
				.tz-saveable-block {
					left: 0.5em;
					top: 0.3em;
				}
			}
		}
		.video-block {
			position: relative;
			.tz {
				width: 100%;
				max-width: 100%;
				.tz-saveable-block {
					padding: 0.1em 0.3em;
					left: -2px;
					top: -2px;
					.tz-saveable-trigger {
						position: relative;
					}
					&:before {
						content: "";
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						border-top: 4.5em solid #f2f2f2;
					    border-right: 4.5em solid transparent;
					    width: 0;
					}
				}

			}
		}
	}
	&.hp-stream-article-one {
		@extend .hp-stream-default;
		.full-blocks {
			.tz {
				@media(min-width: $med-lg) {
					&:nth-child(1),
					&:nth-of-type(1) {
						width: 55%;
						.art-bd {
							max-height: 34em;
						}
						.tz-head-med {
							@media(min-width: $large) {
								font-size: 2.75em;
							}
						}
					}
					&:nth-child(2),
					&:nth-child(3),
					&:nth-of-type(2),
					&:nth-of-type(3) {
						margin-right: 0;
						width: 41.66666%;
						.art-bd {
							max-height: 15em;
							text-align: center;
						}
					}
				}
				&.tz-iapp_conferences {
					&:nth-child(2),
					&:nth-child(3),
					&:nth-of-type(2),
					&:nth-of-type(3) {
						.art-bd {
							max-height: 15em;
						}
					}
				}
			}
		}
	}
	&.hp-stream-video {
		@extend .hp-stream-default;
	}
	&.hp-stream-resource {
		@extend .hp-stream-default;
		.left-blocks {
			.spotlight-block {
				.tz {
					&:nth-child(2),
					&:nth-child(3),
					&:nth-of-type(2),
					&:nth-of-type(3) {
						display: none;
					}
				}
			}
		}
		.right-blocks {
			.spotlight-block {
				.tz {
					&:nth-child(1),
					&:nth-of-type(1) {
						display: none;
					}
				}
			}
		}
	}
}
