// --------------------------------------------
// --------------------------------------------
//
// Buttons
//
// --------------------------------------------
// --------------------------------------------

$button-main:  $green-main;
$button-secondary: $black-20;


.button, %button {
	background-color: $button-main;
	font: inherit;
	line-height: 1em;
	padding: 0.45em 0.65em;
	text-decoration: none;
	text-align: center;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	color: #fff;
	outline: 0;
	border: 0;
	margin-bottom: 1em;

	&:hover {
		background-color: darken($button-main, 5%);
	}
	&:active {
		background-color: darken($button-main, 8%);
		text-shadow: 0 -1px darken($button-main, 15%);
	}
	& + & {
		margin-left: 1em;
	}
}


.fancy-button, %fancy-button {
	@extend %button;
	padding-top: 0.65em;
	box-shadow: inset 0 2px 0 lighten($button-main, 15%);
	border-radius: 0.45em;
	border: 1px solid darken($button-main, 7%);
	border-top-color: darken($button-main, 2%);
	&:hover {
		box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.1);
	}
}


.button-secondary, %button-secondary {
	@extend %button;
	background-color: $button-secondary;
	&:hover {
		background-color: darken($button-secondary, 5%);
	}
	&:active {
		background-color: darken($button-secondary, 8%);
		text-shadow: 0 -1px darken($button-secondary, 15%);
	}
}


.button-sm, %button-sm {
	@extend %button;
	font-size: 0.84615384615385em; 		// 11px
}


.button-med, %button-med {
	@extend %button;
	font-size: 1em;
	padding: 0.65em 1.75em;
}


.button-lg, %button-lg {
	@extend %button;
	font-size: 1.15384615384615em;		//15px
	padding: 0.85em 2.25em;
}

.button-sm--fancy {
	@extend %button-sm;
	@extend %fancy-button;
}

.button-med--fancy {
	@extend %button-med;
	@extend %fancy-button;
}

.button-lg--fancy, %button-lg--fancy {
	@extend %button-lg;
	@extend %fancy-button;
}


.button-secondary-sm, %button-secondary-sm {
	@extend %button-sm;
	@extend %button-secondary;
}

.button-secondary-med {
	@extend %button-med;
	@extend %button-secondary;
}

.button-secondary-lg {
	@extend %button-lg;
	@extend %button-secondary;
}


.button-pill {
	@extend %button;
	padding-left: 1em;
	padding-right: 1em;
	width: 55px;
	line-height: 35px;
	height: 35px;
	border-radius: 1.25em;
}



// Privacy Industry Index

.pii-meta {
    font-weight: bold;
    padding: 0.3em 0.6em;
    margin: 0 0.5em 0.5em 0;
    display: inline-block;
    background-color: #fff;
    span {
        color: $green-main;
    }
}

.active-tag {
	background-color: $green-main;
	fill: $green-main;
	&:hover {
		background-color: $green-main;
		fill: $green-main;
	}
}


// FIP Directory Button
.btn-next {
	border: 1px solid #e9eaea;
    position: relative;
    color: #e9eaea;
    background-color: #747a7a;
    padding: 0.35em 0.85em;
    line-height: 2em;
    font-weight: 700;
    text-decoration: none;
    display: inline-block;
    width: 100px;
    + .is-active {
    	background-color: #f7f7f7;
    	color: #cccccc;
    }
}
.btn-next[disabled] {
	background-color: #f7f7f7;
    color: #cccccc;
}



// --------------------------------------------
// --------------------------------------------
//
// Button blocks
//
// --------------------------------------------
// --------------------------------------------

.button-block--centered {
	text-align: center;
	margin-top: 4em;
	.ghost-fx {
		margin: 1em; 
		padding: 1em;
		display: inline-block;

	}
}



// --------------------------------------------
// --------------------------------------------
//
// Pubs subscription buttons
//
// --------------------------------------------
// --------------------------------------------


.sidebar-sub-callout {
	width: 95%;
	background-color: #fff;
	padding: 0.75em;
	margin-top: 2em;
	text-align: left;
	img {
		max-width: 100%;
	}
	.subscribe-btn {
		width: 100%;
		max-width: none;
		i {
			padding-right: 10px;
		}
	}
	@media(max-width: $large) {
		width: 100%;
		padding: 2em 0 0;
		margin-top: 0;
		text-align: center;
		.subscribe-btn {
			width: 100%;
			max-width: 300px;
			margin: 0 auto;
		}
	}
}

// Remind me button

.remind-me {
	
	@media (min-width: $medium) {
		float: right; 
		margin-top: -2.4em;
	}
}

// Create an account

.create-account {
	line-height: 2.5em;
	max-width: 175px;
	margin: -7em auto 2em;
	text-align: center;
	@media (min-width: $medium) {
		float: right;
		text-align: right;
		border: 1px solid #efefef;
		max-width: 183px;
		margin: 0;
		padding: 0 0.75em 0 0;
	}
	.fa {
		margin: 0 0.5em 0 -0.5em;
	}
}

// Ghost button

.ghost-button {
	text-align: center;
	position: relative;
	margin-left: 0;
	background-color: transparent !important;
	border: 2px solid #fff;
	text-decoration: none;
	color: #fff;
	padding: 1em 2em;
	font-size: 1.1em;
	@media(min-width: $medium) {
		margin-left: 1em;
		&:first-child {
			margin-left: 0;
		}
	}
	.article-text & {
		padding: 0.75em;
		font-size: 1em;
		@media(min-width: $medium) {
			margin-left: 0;
		}
	}
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #fff;
		opacity: 0;
		transition: opacity 200ms ease;
	}
	&:hover {
		&:before {
			opacity: 0.25;
		}
	}
	&.ghost-green {
		color: darken($green-main, 5%);
		border: 2px solid $green-main;
		transition: all 200ms ease;
		&:before {
			display: none;
		}
		&:hover {
			color: #fff;
			background-color: $green-main !important;
		}
		@media(max-width: $medium) {
			display: block;
			margin-top: 3em;
		}
		&.ghost--xl {
			min-width: 100px;
			display: block;
		}
	}
	&.ghost-red {
		color: darken($swatch-1, 5%);
		border: 2px solid $swatch-1;
		transition: all 200ms ease;
		&:before {
			display: none;
		}
		&:hover {
			color: #fff;
			background-color: $swatch-1 !important;
		}
		@media(max-width: $medium) {
			display: block;
			margin-top: 3em;
		}
	}
	&.ghost-blue {
		color: darken($swatch-8a, 5%);
		border: 2px solid $swatch-8a;
		transition: all 200ms ease;
		&:before {
			display: none;
		}
		&:hover {
			color: #fff;
			background-color: $swatch-8a !important;
		}
		@media(max-width: $medium) {
			display: block;
			margin-top: 3em;
		}
	}
	&.ghost-purple {
		color: darken($swatch-6, 5%);
		border: 2px solid $swatch-6;
		transition: all 200ms ease;
		&:before {
			display: none;
		}
		&:hover {
			color: #fff;
			background-color: $swatch-6 !important;
		}
		@media(max-width: $medium) {
			display: block;
			margin-top: 3em;
		}
	}
}

.see-all {
	a {
		transition: all 200ms ease;
	}
}


.see-all-news {
	a {
		color: darken($green-med, 5%);
		border: 2px solid $green-med;
		&:hover {
			color: #fff;
			background-color: $green-med;
		} 
	}
}

.see-all-jobs {
	a {
		color: darken($swatch-1, 5%);
		border: 2px solid $swatch-1;
		&:hover {
			color: #fff;
			background-color: $swatch-1;
		} 
	}
}

.see-all-videos {
	a {
		color: darken($swatch-6, 5%);
		border: 2px solid $swatch-6;
		&:hover {
			color: #fff;
			background-color: $swatch-6;
		} 
	}
}

.see-all-resources {
	a {
		color: darken($swatch-8a, 5%);
		border: 2px solid $swatch-8a;
		&:hover {
			color: #fff;
			background-color: $swatch-8a;
		} 
	}
}

// Switcher

.switcher-label {
	float: left;
	margin-right: 0.5em;
}
.switcher {
	display: block;
	position: relative;
    width: 40px;
    height: 20px;
    background-color: $black-10;
    margin: -2px auto 0;
    border-radius: 20px;
    float: left;
    transition: background-color 200ms ease-out;
    &:after {
		content: "\f111";
	    font-family: $fa;
	    color: #fff;
	    position: absolute;
	    left: 2px;
	    top: -1px;
	    font-size: 19px;
	    width: 19px;
	    transition: all 200ms ease-in-out;
    }
    &.active {
    	background-color: $green-main;
    	transition: background-color 200ms ease-in-out 100ms;
	    &:after {
		    transform: translateX(20px);
	    }
    }
}

.btn-loader {
	width: 20px !important;
    height: 20px !important;
    color: white !important;
    left: 10px !important;
    top: 8px !important;
    position: absolute !important;
    .spinner-layer {
    	border-color: #f2f2f2!important;
    }
}

