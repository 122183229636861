// --------------------------------------------
// --------------------------------------------
//
// Material container width override
//
// --------------------------------------------
// --------------------------------------------


@media #{$medium-and-up} {
  .container {
    width: 90%;
  }
}
@media #{$large-and-up} {
  .container {
    width: 90%;
  }
}

.outer-wrapper {
	overflow: hidden;
}

.valign-wrapper {
	height: inherit;
}

.no-bottom-pad {
	margin-bottom: 0;
	padding-bottom: 0;
}

.no-pad {
	padding: 0 !important;
}
.no-pad-bot {
	padding-bottom: 0;
}
.no-pad-top {
	padding-top: 0;
}
.no-marg {
	margin: 0 !important;
}
.no-marg-bot {
	margin-bottom: 0;
}
.no-marg-top {
	margin-top: 0;
}
.no-min-height {
	min-height: initial;
}

.section {
	position: relative;
	&.raised-section {
		z-index: 1;
	}
	&.extra-pad {
		padding: 3rem 0 1rem;
	}
	&.lined {
		border-bottom: 2px solid map-get($grey, "lighten-3");
		&:last-child {
			border-bottom: none;
		}
	}
}
.margin-after {
	margin-bottom: 3rem;
}
.article-meta {
	margin: 3rem 0;
}

#content {
	overflow: hidden;
}

.flex-row {
	display: flex;
}