// --------------------------------------------
// --------------------------------------------
//
// Footer Variables
//
// --------------------------------------------
// --------------------------------------------

$ftr-text-color: $black-30;

$ftr-bp-sm: $medium;
$ftr-bp-med: $large;

$ftr-bp-top: 50em;




// --------------------------------------------
// --------------------------------------------
//
// Footer Styles
//
// --------------------------------------------
// --------------------------------------------

.l-ftr {
	@media(min-width: $ftr-bp-sm) and (max-width: $ftr-bp-med) {
		@include media-grid(2, $space-grid);
	}

	@media(min-width: $ftr-bp-med) {
		@include media-grid(2, $space-grid--double);
	}
}


.ftr {
	background-color: $black-90;
	color: $ftr-text-color;
	padding: $space-grid 0;
}

.ftr-main {
	border-top: 1px solid $black-60;
	border-bottom: 1px solid $black-60;
	padding: 3em 0;
	margin: 1.5em 0;
}

.ftr-main-item {
	margin-bottom: 3em;
	@media($ftr-bp-sm) {
		margin-bottom: 0;
	}
}

.ftr-tz-head {
	color: $bg-lt;
	margin-bottom: 0.5em;
	font-size: 2em;
	letter-spacing: 1px;
	@include sans;
	text-decoration:none;
	display: block;
}

.ftr-tz-text {
	@extend %tease-text;
	font-size: 1.25em;
	line-height: 1.55em;
	margin-bottom: 1.5em;
	color: $black-30;
}

.ftr-list-item {
	font-size: 1.15em;
	margin-bottom: 0.5em;
	margin-left: 0;
	padding-left: 0;
	list-style: none;
	@media(min-width: $large) {
		float: left;
		width: 40%;
		margin-right: 2em;
	}
 }

.ftr-top {
	@include pie-clearfix;
}

.ftr-logo {
	@media(min-width: $ftr-bp-top) {
		float: left;
	}
}

.ftr-logo-link {
	width: 85px;
	height: 36px;
	display: block;
}


.ftr-tagline {
	line-height: 36px;
	color: $black-30;
	font-size: 1.35em;
	display:block;
	text-decoration:none;
	@media(min-width: $ftr-bp-top) {
		float: right;
	}
}

.ftr-bottom {
	@include pie-clearfix;
	text-align: center;
	@media(min-width: $ftr-bp-med) {
		text-align: left;
	}
}

.ftr-bottom-block {
	@media(min-width: $ftr-bp-med) {
		float: left;
		width: 45%;
		&:first-child {
			float: right;
			width: 50%;
		}
	}
}

.ftr-bottom-text {
	margin: 0.5em 0;
	font-size: 1.15em;
}

.ftr-utility-items {
	@extend %list-items--inline;
	text-align: center;
	@extend %tz-text-sm;
	@include sans(bold);
	font-size: 1.15em;
	@media(min-width: $ftr-bp-med) {
		text-align: right;
	}
}

.ftr-utility-item {
	@extend %list-item--inline;
	margin-right: 1em;
	&:last-child {
		margin-right: 0;
	}

}

.utility-link {
	@include link(tag);
	color: $ftr-text-color;
	font-weight: 700;
}
