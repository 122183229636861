// Remove Focus Boxes
select:focus {
  outline: $select-focus;
}

button:focus {
  outline: none;
  background-color: $button-background-focus;
}

label {
  font-size: $label-font-size;
  color: $input-border-color;
}

// g-recaptcha
.g-recaptcha {
	margin-bottom: 1em;
}


@import 'input-fields';
@import 'radio-buttons';
@import 'checkboxes';
@import 'switches';
@import 'select';
@import 'file-input';
@import 'range';
