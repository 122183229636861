// --------------------------------------------
// --------------------------------------------
//
// Default
//
// --------------------------------------------
// --------------------------------------------

.iapp-bundle {
	@media(min-width: $large) {
		margin-bottom: 6em;
	}
	input[type="radio"] {
		vertical-align: text-top;
		margin-top: 0.1em; 
		margin-right: 0.55em;
	}
	input[type="checkbox"] {
		vertical-align: top;
		margin-top: 0.1em; 
	}
	.list-items--lined {
		.list-item {
			padding-bottom: none !important;
		}
		.iapp-product-header {
			width: 80%;
		}
	}
	.notify--sm	{
		padding-left: 1em !important;
	}
}

.free-resources {
	@media(min-width: $large) {
		margin-bottom: 6em;
	}
}

.bundle-name {
	margin-bottom: 3em;
}

.mob-checkout {
	display: block;
	@media(min-width: $desktop) {
		display: none;
	}
}

.checkout-price {
	margin-bottom: 1em;
	font-size: 1.384615385em;
	line-height: 1.555555556em;
	font-weight: bold;
	@media(min-width: $desktop) {
		position: absolute;
		right: 1em;
		margin-top: -1.5em;
		margin-bottom: 0;
	}
}

.fixed-checkout {
	display: none;
	position: fixed;
	bottom: 3em;
	border-top: $nav-bdr;
	@media(min-width: $desktop) {
		display: block;
		width: 70%;
	}
	@media(min-width: $x-large) {
		display: block;
		width: 62em;
	}
	.notify--sm {
		width: 100%;
	}
	&.li-checkout {
		background-color: $bg-lt;
		padding: 1em 0;
		.checkout-price {
			position: relative;
			float: right;
			margin-top: 0.4em;
		}
		.button-lg--fancy {
			float: right;
			margin-right: 1em;
			margin-bottom: 0;
		}
	}
}

.statme {
	position: absolute;
	bottom: 0;
	margin-bottom: -7em;
	@media(min-width: $desktop) {
		width: 70%;
	}
	@media(min-width: $x-large) {
		width: 62em;
	}
}

.fixme {
	position: fixed;
	bottom: 0;
	margin-bottom: 0;
	z-index: 10;
	@media(min-width: $desktop) {
		width: 66%;
	}
	@media(min-width: $x-large) {
		width: 62em;
	}
}



// --------------------------------------------
// --------------------------------------------
//
// Certification Bundles
//
// --------------------------------------------
// --------------------------------------------


.cert-bundles {
	.bundle-name {
		display: none;
	}
}
