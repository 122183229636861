.styleguide {
	.sg-section {
		overflow: hidden;
	}
	.sg-block {
		width: 100%;
		border: 3px solid #e6e6e6;
		padding: 2em;
		margin-bottom: 2em;
		xmp {
			width: 100%;
			display: block;
			height: auto;
			overflow: hidden;
			font-family: Monaco;
			}
		/* Pretty printing styles. Used with prettify.js. */
		/* Vim sunburst theme by David Leibovic */

		xmp .str { color: #65B042; } /* string  - green */
		xmp .kwd { color: #E28964; } /* keyword - dark pink */
		xmp .com { color: #AEAEAE; font-style: italic; } /* comment - gray */
		xmp .typ { color: #89bdff; } /* type - light blue */
		xmp .lit { color: #3387CC; } /* literal - blue */
		xmp .pun { color: #fff; } /* punctuation - white */
		xmp .pln { color: #fff; } /* plaintext - white */
		xmp .tag { color: #89bdff; } /* html/xml tag    - light blue */
		xmp .atn { color: #bdb76b; } /* html/xml attribute name  - khaki */
		xmp .atv { color: #65B042; } /* html/xml attribute value - green */
		xmp .dec { color: #3387CC; } /* decimal - blue */
		xmp.prettyprint {
	        background-color: #222;
	        width: 100%;
	        margin: 1em auto;
	        padding: 0 1.3em 1.3em;
	        white-space: pre-wrap;
		}

		/* Specify class=linenums on a pre to get line numbering */
		ol.linenums { margin-top: 0; margin-bottom: 0; color: #AEAEAE; } /* IE indents via margin-left */
		li.L0,li.L1,li.L2,li.L3,li.L5,li.L6,li.L7,li.L8 { list-style-type: none }
		/* Alternate shading for lines */
		li.L1,li.L3,li.L5,li.L7,li.L9 { }

		@media print {
		  xmp .str { color: #060; }
		  xmp .kwd { color: #006; font-weight: bold; }
		  xmp .com { color: #600; font-style: italic; }
		  xmp .typ { color: #404; font-weight: bold; }
		  xmp .lit { color: #044; }
		  xmp .pun { color: #440; }
		  xmp .pln { color: #000; }
		  xmp .tag { color: #006; font-weight: bold; }
		  xmp .atn { color: #404; }
		  xmp .atv { color: #060; }
		}
		.display {
			width: 100%;
			display: block;
			height: auto;
			margin: 1em 0;
			padding: 1.3em;
			border: 1px solid #f2f2f2;
			.display-head {
				display: inline-block;
				padding: 0.5em 0.5em 0.3em;
				margin: -0.5em 0 0.5em -0.5em;
				background-color: #f2f2f2;
			}
		}
		.notes {
			width: 100%;
			display: block;
			height: auto;
			background-color: #f2f2f2;
			margin: 1em 0;
			padding: 1.3em;
			h4 {
				margin-bottom: 0.5em;
			}
		}
		.mark-head {
			width: 100%;
			display: block;
			height: auto;
			background-color: #222;
			margin: 1em auto -1.3em;
			padding: 1.3em 1.3em 0;
		}
		.swatch {
			padding: 0.5em 1em 0.7em; 
			color: #fff; 
			margin-bottom: 0.5em; 
			span {
				float: right;
				display: inline-block;
				width: 100px;
				text-align: left;
			}	
		}
		.inline-code {
			font-family: Monaco;
			font-size: 11px;
			padding: 0.1em 0.3em;
			margin: 0 0.1em;
			color: #fff;
			background-color: #777;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			border-radius: 2px;
		}
		.hero-bg.hero-bg-img {
			position: absolute; 
			max-width: 72.7%;
			min-height: 300px; 
			background-image: url(https://iapp.org/media/uploads/2014/08/online-training-bg1.png); 
			background-size: contain;
			@media(min-width: $small){
				max-width: 76.5%;
				min-height: 280px; 
			}
			@media(min-width: $medium){
				max-width: 83%;
				height: 500px;
			}
			@media(min-width: $large){
				max-width: 63.33333%; 
			}
		}
		.fa-icon {
			width: 25%;
			float: left;
			margin-bottom: 5px;
			i {
				width: 20px;
			}
		}
	}
	.sg-selector {
		width: 100%;
		float: left;
		text-align: center;
		border: 4px solid #efefef;
		border-bottom: none;
		position: relative;
		@media(min-width: $large) {
			width: 33.33333%;
			border: 4px solid #efefef;
			border-right: none;
		}
		&:last-child {
			border-bottom: 4px solid #efefef;
			@media(min-width: $large) {
				border-right: 4px solid #efefef;
			}
		}
		&:before {
			content: "";
			display: block;
			position: relative;
			margin-top: -4px;
			border-top: 4px solid #efefef;
			-webkit-transition: all 300ms ease;
		    -moz-transition: all 300ms ease;
		    -o-transition: all 300ms ease;
		    transition: all 300ms ease;
		}
		&:hover {
			&:nth-child(1) {
				&:before {
					border-top: 4px solid $green-main;
				}
				.page-head-lg {
					color: $black-80;
					span {
						color: $green-main;
					}
				}
				i {
					border: 12px outset $green-main;
				}
			}
			&:nth-child(2) {
				&:before {
					border-top: 4px solid $swatch-6;
				}
				.page-head-lg {
					color: $black-80;
					span {
						color: $swatch-6;
					}
				}
				i {
					border: 12px outset $swatch-6;
				}
			}
			&:nth-child(3) {
				&:before {
					border-top: 4px solid $link-color;
				}
				.page-head-lg {
					color: $black-80;
					span {
						color: $link-color;
					}
				}
				i {
					border: 12px outset $link-color;
				}
			}
		}
		.sg-option {
			display: block;
			text-decoration: none;
			width: 100%;
			height: 100%;
			color: $black-80;
			padding: 4em 0;
			background-color: #fff;
			-webkit-transition: all 300ms ease;
		    -moz-transition: all 300ms ease;
		    -o-transition: all 300ms ease;
		    transition: all 300ms ease;
		    .page-head-lg {
		    	color: $black-40;
	    		-webkit-transition: all 300ms ease;
	    	    -moz-transition: all 300ms ease;
	    	    -o-transition: all 300ms ease;
	    	    transition: all 300ms ease;
	    	    span {
    	    		-webkit-transition: all 300ms ease;
    	    	    -moz-transition: all 300ms ease;
    	    	    -o-transition: all 300ms ease;
    	    	    transition: all 300ms ease;
    	    		color: $black-40;
	    	    }
		    }
			i {
				display: block;
				margin: 0 auto 0.5em;
				font-size: 7em;
				height: 180px;
				width: 180px;
				max-height: 180px;
				max-width: 180px;
				padding-top: 0.4em;
				color: $black-40;
				border: 8px outset $black-10;
				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				border-radius: 50%;
				-webkit-transition: all 300ms ease;
			    -moz-transition: all 300ms ease;
			    -o-transition: all 300ms ease;
			    transition: all 300ms ease;
				&.fa-edit {
					color: $green-main;
					padding-top: 0.45em;
					padding-left: 0.15em;
				}
				&.fa-image {
					color: $swatch-6;
				}
				&.fa-laptop {
					color: $link-color;
				}
			}
			&:hover {
				background-color: #efefef;
				.page-head-lg {
					color: $black-80;
				}
				i {
					background-color: #fff;
					color: $black-80;
				}
			}
		}
	}
	.highlighter {
		background-color: #ffff00;
	}
}

.styleguide-nav {
	margin-top: 80px;
	a.is-active {
		background-color: $bg-dark;
		width: 187px;
		font-weight: bold;
		color: $black-80;
	}
}

