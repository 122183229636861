// --------------------------------------------
// --------------------------------------------
//
// Font Path Variable
//
// --------------------------------------------
// --------------------------------------------

$font-path: "/wp-content/themes/iapp/static/fonts";




// --------------------------------------------
// --------------------------------------------
//
// Tabac Dev Links
//
// --------------------------------------------
// --------------------------------------------

@font-face {
  font-family: 'Tabac Sans';
  src: url('#{$font-path}/Tabac_Sans_Regular.eot');
  src: local('☺'),
       url('#{$font-path}/Tabac_Sans_Regular.eot?iefix') format('eot'),
       url('#{$font-path}/Tabac_Sans_Regular.woff') format('woff'),
       url('#{$font-path}/Tabac_Sans_Regular.svg#webfont') format('svg'),
       local('☺');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: 'Tabac Sans';
  src: url('#{$font-path}/Tabac_Sans_Regular_Italic.eot');
  src: local('☺'),
       url('#{$font-path}/Tabac_Sans_Regular_Italic.eot?iefix') format('eot'),
       url('#{$font-path}/Tabac_Sans_Regular_Italic.woff') format('woff'),
       url('#{$font-path}/Tabac_Sans_Regular_Italic.svg#webfont') format('svg'),
       local('☺');
  font-weight: 400;
  font-style: italic;
}


@font-face {
  font-family: 'Tabac Sans';
  src: url('#{$font-path}/Tabac_Sans_Light.eot');
  src: local('☺'),
       url('#{$font-path}/Tabac_Sans_Light.eot?iefix') format('eot'),
       url('#{$font-path}/Tabac_Sans_Light.woff') format('woff'),
       url('#{$font-path}/Tabac_Sans_Light.svg#webfont') format('svg'),
       local('☺');
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'Tabac Sans';
  src: url('#{$font-path}/Tabac_Sans_Light_Italic.eot');
  src: local('☺'),
       url('#{$font-path}/Tabac_Sans_Light_Italic.eot?iefix') format('eot'),
       url('#{$font-path}/Tabac_Sans_Light_Italic.woff') format('woff'),
       url('#{$font-path}/Tabac_Sans_Light_Italic.svg#webfont') format('svg'),
       local('☺');
  font-weight: 300;
  font-style: italic;
}


@font-face {
  font-family: 'Tabac Sans';
  src: url('#{$font-path}/Tabac_Sans_Bold.eot');
  src: local('☺'),
       url('#{$font-path}/Tabac_Sans_Bold.eot?iefix') format('eot'),
       url('#{$font-path}/Tabac_Sans_Bold.woff') format('woff'),
       url('#{$font-path}/Tabac_Sans_Bold.svg#webfont') format('svg'),
       local('☺');
  font-weight: 700;
  font-style: normal;
}


@font-face {
  font-family: 'Tabac Sans';
  src: url('#{$font-path}/Tabac_Sans_Bold_Italic.eot');
  src: local('☺'),
       url('#{$font-path}/Tabac_Sans_Bold_Italic.eot?iefix') format('eot'),
       url('#{$font-path}/Tabac_Sans_Bold_Italic.woff') format('woff'),
       url('#{$font-path}/Tabac_Sans_Bold_Italic.svg#webfont') format('svg'),
       local('☺');
  font-weight: 700;
  font-style: italic;
}


@font-face {
  font-family: 'Tabac G3';
  src: url('#{$font-path}/Tabac_G3_Regular.eot');
  src: local('☺'),
       url('#{$font-path}/Tabac_G3_Regular.eot?iefix') format('eot'),
       url('#{$font-path}/Tabac_G3_Regular.woff') format('woff'),
       url('#{$font-path}/Tabac_G3_Regular.svg#webfont') format('svg'),
       local('☺');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Tabac G3';
  src: url('#{$font-path}/Tabac_G3_Italic.eot');
  src: local('☺'),
       url('#{$font-path}/Tabac_G3_Italic.eot?iefix') format('eot'),
       url('#{$font-path}/Tabac_G3_Italic.woff') format('woff'),
       url('#{$font-path}/Tabac_G3_Italic.svg#webfont') format('svg'),
       local('☺');
  font-weight: 400;
  font-style: italic;
}


@font-face {
  font-family: 'Tabac G3';
  src: url('#{$font-path}/Tabac_G3_Bold.eot');
  src: local('☺'),
       url('#{$font-path}/Tabac_G3_Bold.eot?iefix') format('eot'),
       url('#{$font-path}/Tabac_G3_Bold.woff') format('woff'),
       url('#{$font-path}/Tabac_G3_Bold.svg#webfont') format('svg'),
       local('☺');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Tabac G3';
  src: url('#{$font-path}/Tabac_G3_Bold_Italic.eot');
  src: local('☺'),
       url('#{$font-path}/Tabac_G3_Bold_Italic.eot?iefix') format('eot'),
       url('#{$font-path}/Tabac_G3_Bold_Italic.woff') format('woff'),
       url('#{$font-path}/Tabac_G3_Bold_Italic.svg#webfont') format('svg'),
       local('☺');
  font-weight: 700;
  font-style: italic;
}


// --------------------------------------------
// --------------------------------------------
//
// Variables
//
// --------------------------------------------
// --------------------------------------------

$default-sans: Helvetica, Arial, sans-serif;
$default-serif: Georgia, Times, serif;


$sans: "Tabac Sans", $default-sans;

$serif: "Tabac G3", $default-serif;

$fa: "FontAwesome";




// --------------------------------------------
// --------------------------------------------
//
// Variables for font-weight
//
// --------------------------------------------
// --------------------------------------------
// Using these variables will save time in terms of updating font weight.
// For instance, if you change to a lighter weight of a sans-serif font
// It would need to be updated here, rather than
// every place where font-weight: 300, or what have you exists

$sans-weight-light:  		300;
$sans-weight-reg:  			400;
$sans-weight-bold: 			700;

$serif-weight-reg: 			400;
$serif-weight-bold: 		700;




// --------------------------------------------
// --------------------------------------------
//
// Variables for RWD type
//
// --------------------------------------------
// --------------------------------------------
// These variables are used for various pieces of type on the site
// (such as headlines, and larger text type) to set font-size at smaller resolutions
// The vast majority of declarations shoule be in the type.scss file.

$base-reg: 1em; 					// 13

$base-med: 1.23076923076923em; 		// 16

$base-lg: 2.2em; 		
