// --------------------------------------------
// --------------------------------------------
//
// Header Variables
//
// --------------------------------------------
// --------------------------------------------

$hdr-pad-sm: $nav-height-sm;
$hdr-pad-med: $nav-height-sm * 2 + 1em;
$hdr-pad-lg: $nav-height * 2 + 2em;

$privacy-tech-bg: rgba(#000, 0.33);
$ftc-bg: rgba(#000, 0.50);


// --------------------------------------------
// --------------------------------------------
//
// Header Styles
//
// --------------------------------------------
// --------------------------------------------

.hdr {
	background-color: $hdr-default-color;
	width: 100%;
	padding: $hdr-pad-sm 0;

	@media(min-width: $nav-bp-sm) {
		padding: $hdr-pad-med 0 2em 0;
	}

	@media(min-width: $nav-bp-lg) {
		padding: $hdr-pad-lg 0 2em 0;
	}


	// These classes are used to change the background-color of the header
	// They are applied when they're included at the template level.
	// For instance on news_landing.twig.
	.hdr-news & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/news-banner3.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		.social-div--light {
			float: right;
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222;
					background: #ffffff;
					padding: 6px;
					text-align: center;
					margin: 5px 3px;
					border-radius: 50%;
					height: 25px;
					width: 25px;
				}
			}
		}
	}
	.hdr-daily-dashboard & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/dd-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		.social-div--light {
			float: right;
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222;
					background: #ffffff;
					padding: 6px;
					text-align: center;
					margin: 5px 3px;
					border-radius: 50%;
					height: 25px;
					width: 25px;
				}
			}
		}
	}
	.hdr-privacy-perspectives & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/perspectives-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		.hdr-block--title {
			color: $black-80;
			.hdr-h1-link {
				color: $black-80;
			}
			.hdr-tagline {
				color: $black-80;
			}
		}
		.social-div--light {
			float: right;
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222;
					background: #ffffff;
					padding: 6px;
					text-align: center;
					margin: 5px 3px;
					border-radius: 50%;
					height: 25px;
					width: 25px;
				}
			}
		}
	}
	.hdr-privacy-advisor & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/privacy-advisor-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50%;
		.social-div--light {
			float: right;
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222;
					background: #ffffff;
					padding: 6px;
					text-align: center;
					margin: 5px 3px;
					border-radius: 50%;
					height: 25px;
					width: 25px;
				}
			}
		}
	}

	.hdr-img-iapp-calendar-tablet & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/iapp-calendar-tablet.jpg');
		background-repeat: no-repeat;
		background-size: cover;
	}

	.hdr-img-dpa-banner & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/dpa-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover;
	}

	.hdr-img-cpe-banner & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/IAPP_cert_programs.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 20%;
	}

	.hdr-img-dpa-sanctions-banner & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/dpa-sanctions-hdr.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
	}

	.hdr-img-gdpr-banner & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/gdpr-banner.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
	}

	.hdr-img-enterprise-banner & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/enterprise-01.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
	}

	.hdr-img-persona1-banner & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/enterprise_persona-1.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
	}

	.hdr-img-persona2-banner & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/enterprise_persona-2.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
	}

	.hdr-img-persona3-banner & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/enterprise_persona-3.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
	}

	.hdr-img-persona4-banner & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/enterprise_persona-4.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
	}

	.hdr-img-dpi-overview-banner & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/DPI20_banner.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
	}

	.hdr-img-summit-sessions-banner & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/summit-sessions.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
	}

	.hdr-img-summit-sessions-banner & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/summit-sessions.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
	}

	.hdr-img-ccpa-banner & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/CCPA_header_1440x426-01.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
	}
	.hdr-img-ccpa-banner-2 & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/CCPA_header.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
	}
	.hdr-img-demo-center-landing & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/demo-center.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
	}
	.hdr-privacy-tracker & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/tracker-banner1.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50%;
		.hdr-block--title {
			color: $black-80;
			.hdr-h1-link {
				color: $black-80;
			}
			.hdr-tagline {
				color: $black-80;
			}
		}
		.social-list-link {
			background-color: $black-80;
		}
	}
	.hdr-dpo-confessional & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/dpo-confessional-banner.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50%;
		.hdr-block--title {
			color: $black-80;
			.hdr-h1-link {
				color: $black-80;
			}
			.hdr-tagline {
				color: $black-80;
			}
		}
		.social-list-link {
			background-color: $black-80;
		}
	}
	.hdr-privacy-tech & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/privacy-tech-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50%;
		.social-div--light {
			float: right;
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222;
					background: #ffffff;
					padding: 6px;
					text-align: center;
					margin: 5px 3px;
					border-radius: 50%;
					height: 25px;
					width: 25px;
				}
			}
		}
		&:before {
			content: "";
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			position: absolute;
			background-image: linear-gradient( 20deg, $privacy-tech-bg 0%, transparent 60% );
		}
	}
	.hdr-canada-dashboard-digest & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/cdd-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		.social-div--light {
			float: right;
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222;
					background: #ffffff;
					padding: 6px;
					text-align: center;
					margin: 5px 3px;
					border-radius: 50%;
					height: 25px;
					width: 25px;
				}
			}
		}
	}
	.hdr-europe-data-protection-digest & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/edpd-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		.social-div--light {
			float: right;
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222;
					background: #ffffff;
					padding: 6px;
					text-align: center;
					margin: 5px 3px;
					border-radius: 50%;
					height: 25px;
					width: 25px;
				}
			}
		}
	}
	.hdr-united-states-dashboard-digest & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/usdd-banner.png');
		background-repeat: no-repeat;
		background-size: cover;
		.social-div--light {
			float: right;
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222;
					background: #ffffff;
					padding: 6px;
					text-align: center;
					margin: 5px 3px;
					border-radius: 50%;
					height: 25px;
					width: 25px;
				}
			}
		}
	}
	.hdr-latin-america-dashboard-digest & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/latin-america-dashboard-digest.png');
		background-repeat: no-repeat;
		background-size: cover;
		.social-div--light {
			float: right;
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222;
					background: #ffffff;
					padding: 6px;
					text-align: center;
					margin: 5px 3px;
					border-radius: 50%;
					height: 25px;
					width: 25px;
				}
			}
		}
	}
	.hdr-asia-pacific-dashboard-digest & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/asia-pacific-banner-2.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50%;
		.social-div--light {
			float: right;
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222;
					background: #ffffff;
					padding: 6px;
					text-align: center;
					margin: 5px 3px;
					border-radius: 50%;
					height: 25px;
					width: 25px;
				}
			}
		}
	}

	.hdr-videos & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/videos-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50%;
		.social-div--light {
			float: right;
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222;
					background: #ffffff;
					padding: 6px;
					text-align: center;
					margin: 5px 3px;
					border-radius: 50%;
					height: 25px;
					width: 25px;
				}
			}
		}
	}

	.hdr-westin-research-center & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/westin-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		@media(min-width: $large) {
			background-size: contain;
			background-position: 50%;
			background-color: #000;
		}
		.social-div--light {
			float: right;
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222;
					background: #ffffff;
					padding: 6px;
					text-align: center;
					margin: 5px 3px;
					border-radius: 50%;
					height: 25px;
					width: 25px;
				}
			}
		}
	}


	.hdr-styleguide & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/styleguide-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover;
	}

	.hdr-sg-landing & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/sg-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover;
	}

	.hdr-img &, .hdr-conference & {
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
	}

	// For image headers or conference headers add a name to a respective list
	// in the list-var.scss file. For instance, for a conference header that's set in Paris,
	// add 'conference-paris' to the conferences list. Then, add an image in the
	// static > img > backgrounds > conferences folder name 'conference-paris.jpg'
	// Use the CSS class .hdr-img-conference-paris in your html. Boom. You have a header
	@each $header in $headers {
		.hdr-img-#{$header} & {
			background-image: url(#{$header-path}/#{$header}.jpg);
			position: relative;
		}
	}

	@each $conference in $conferences {
		.hdr-img-#{$conference} & {
			background-image: url(#{$conference-path}/#{$conference}.jpg);
		}
	}

	.hdr-hp & { height: 45em; }
}

.hdr-img {
	&.hdr-img-iapp-calendar-banner {
		&:after {
			content: "";
			position: absolute;
			top: 100px;
			bottom: 0;
			left: 0;
			right: 0;
			/*background-image: linear-gradient( 0deg, #000, transparent);*/
			z-index: 1;
			opacity: 0;
			/*opacity: 0.7;*/
		}
		.hdr-img-inner {
			&:before,
			&:after {
				display: none;
			}
		}
	}
	&.hdr-img-dpa-banner {
		&:after {
			content: "";
			position: absolute;
			top: 100px;
			bottom: 0;
			left: 0;
			right: 0;
			background-image: linear-gradient( 0deg, #000, transparent);
			z-index: 1;
			opacity: 0.7;
		}
		.hdr-img-inner {
			&:before,
			&:after {
				display: none;
			}
		}
	}
	&.hdr-img-dpa-sanctions-banner {
		&:after {
			content: "";
			position: absolute;
			top: 100px;
			bottom: 0;
			left: 0;
			right: 0;
			// background-image: linear-gradient( 0deg, #000, transparent);
			z-index: 1;
			// opacity: 0.7;
		}
		.hdr-img-inner {
			&:before,
			&:after {
				display: none;
			}
		}
	}
	&.hdr-img-gdpr-banner {
		&:after {
			content: "";
			position: absolute;
			top: 100px;
			bottom: 0;
			left: 0;
			right: 0;
			// background-image: linear-gradient( 0deg, #000, transparent);
			z-index: 1;
			// opacity: 0.7;
		}
		.hdr-img-inner {
			&:before,
			&:after {
				display: none;
			}
		}
	}
}

.hdr-content-mod {
	@include pie-clearfix;
	position: relative;
	z-index: 10;
	padding-top: 3em;

	@media(min-width: $nav-bp-sm) {
		padding: 0 $site-margin--half;
	}
	.social-div--dark {
		float: right;
		padding-top: 10px;
		.social-link--dark {
			text-decoration: none;
			.social-icon--dark {
				color: #D1D3D4;
				background: #2e2e2f;
				padding: 6px;
				text-align: center;
				margin: 5px 3px;
				border-radius: 50%;
				height: 25px;
				width: 25px;
			}
		}
	}
}




// --------------------------------------------
// Header blocks

.hdr-block--title {
	@media(min-width: $nav-bp-sm) {
		float: left;
		position: relative;
		z-index: 10;
	}

	.hdr-img & {
		.hdr-h1, .hdr-h1-link, .hdr-tagline {

		}
	}
}

/*.hdr-block--social {
	@media(min-width: $nav-bp-sm) {
		float: right;
		position: relative;
		z-index: 10;
		margin-top: 10px;
	}
}*/

.hdr-block--quote {
	@media(min-width: $nav-bp-lg) {
		float: right;
		width: 55%;
	}
}

.hdr-block--dark {
	color: $black-80;
	.hdr-h1 {
		color: $black-80;
		.hdr-h1-link {
			color: $black-80;
			.hdr-tagline {
				color: $black-80;
			}
		}
	}
	.hdr-tagline {
		color: $black-80;
	}
}


// --------------------------------------------
//
// Header type
//
.hdr-h1 {
	@extend %h1;
	color: #fff;
	margin-bottom: 0.15em;
	letter-spacing: 1px;
}

.hdr-h1-link {
	@include link(white);
	&:hover {
		text-decoration: none;
	}
}

.hdr-h1-reg {
	@include sans(light);
}

.hdr-tagline {
	@extend %tz-text-reg;
	color: #fff;
	@include sans;
}

.hdr-quote-mod {
	color: #fff;
	@include sans(light);
	font-size: 2.25em;
	line-height: 1.5em;
	position: relative;
	z-index: 2;
}


.hdr-quote {
	margin-bottom: 0.85em;
}

.hdr-quote-attr {
	font-size: 0.45em;
	text-align: right;
	@include serif;
}

.quote-line {
	position: relative;
	width: 100%;
	height: 30px;
	background-image: url(/wp-content/themes/iapp/static/img/quote_arrow.svg);
	background-repeat: no-repeat;
	background-position: 70% 0;
	background-size: cover;
}


// --------------------------------------------
// Header image

$gradient-hdr-1: rgba(#6db44f, 0.7);
$gradient-hdr-2: rgba(#336483, 0.9);

$gradient-hdr-1--lt: rgba($gradient-hdr-1, 0.2);
$gradient-hdr-2--lt: rgba($gradient-hdr-2, 0.2);

.hdr-img {
	position: relative;
	&:before,
	&:after {
		top: 0 !important;
	}
	.hdr {
		background-color: #fff;
	}
	&.hdr-img-relative {
		.hdr {
			background-position: 50% 50%;
		}
	}
	&.hdr-img-10 {
		.hdr {
			background-position: 50% 15%;
		}
	}
	&.hdr-img-20 {
		.hdr {
			background-position: 50% 20%;
		}
	}
	&.hdr-img-25 {
		.hdr {
			background-position: 50% 25%;
		}
	}
	&.hdr-img-33 {
		.hdr {
			background-position: 50% 33%;
		}
	}
	&.hdr-img-bottom {
		.hdr {
			background-position: 50% 100%;
		}
	}
	&:before {
		content: '';
		top: 100px;
		bottom: 0;
		left: 0;
		right: 0;
		position: absolute;
		background-image: linear-gradient( 70deg, $gradient-hdr-1--lt, transparent);
		z-index: 1;
		// for browsers that do no support css gradients. ie9.
		.rgba.no-cssgradients & { background-color: $gradient-hdr-1--lt; }
		// .no-rgba.no-cssgradients & { background-color: #6db44f; }
	}
	&:after {
		content: '';
		top: 100px;
		bottom: 0;
		left: 0;
		right: 0;
		position: absolute;
		background-image: linear-gradient( 70deg, transparent, $gradient-hdr-2--lt);
	}
	&.no-overlay {
		&:before, &:after {
			display: none;
		}
	}
}


.hdr-img-inner {
	position: relative;

	// standardt headers. these only appear when the .hdr-img class is also present
	.hdr-img & {
		&:before {
			content: '';
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			position: absolute;
			background-image: linear-gradient(70deg, $gradient-hdr-1 3%, $gradient-hdr-1--lt 20%, transparent);
			z-index: 3;
		}
		&:after {
			content: '';
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			position: absolute;
			background-image: linear-gradient( 70deg, transparent, $gradient-hdr-2--lt 80%, $gradient-hdr-2 97%);
			z-index: 2;
		}
		.social-div--light {
			float: right;
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222;
					background: #ffffff;
					padding: 6px;
					text-align: center;
					margin: 5px 3px;
					border-radius: 50%;
					height: 25px;
					width: 25px;
				}
			}
		}
	}

	.no-overlay & {
		&:before, &:after {
			display: none;
		}
	}

	.hdr-conference & {
		&:after {
			content: '';
			top: 100px;
			bottom: 0;
			left: 0;
			right: 0;
			position: absolute;
			background-color: inherit;
			z-index: 1;
		}
	}

}


// --------------------------------------------
// Homepage header

.hdr-hp-content-mod {
	text-align: center;
}



// --------------------------------------------
// --------------------------------------------
//
// Conference Header
//
// --------------------------------------------
// --------------------------------------------

.hdr-conference {
	// text-align: center;
	color: #fff;
	background-size: cover;
	background-position: 50% 50%;

	.hdr-img-inner {
		text-align: center;
	}

	// overwrites some style for header elements inside the
	// .hdr-conference class
	.hdr-block--title {
		float: none;
		position: relative;
		z-index: 2;
	}
	.social-div--light {
			float: none;
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222;
					background: #ffffff;
					padding: 6px;
					text-align: center;
					margin: 5px 3px;
					border-radius: 50%;
					height: 25px;
					width: 25px;
				}
			}
	}
	.hdr {
		padding-bottom: 4em;
		background-position: 50% 50%;
	}
}



.hdr-conference-overline {
	text-transform: uppercase;
	font-weight: 700;
	font-size: 1.15em;
	margin-bottom: $site-margin--half;
	letter-spacing: 1px;
}

.hdr-conference-h1 {
	font-size: 4.25em;
	font-weight: 300;
	margin-bottom: 0.35em;
}

.hdr-conference-subtitle {
	font-weight: 300;
	font-size: 1.75em;
	margin-bottom: 1em;
}

.hdr-conference-tagline {
	font-weight: 700;
	font-size: 1.5em;
	letter-spacing: 1px;
	& + & {
		margin-bottom: $site-margin;
	}
	span {
		text-transform: uppercase;
	}
}



// --------------------------------------------
// --------------------------------------------
//
// APIA Header
//
// --------------------------------------------
// --------------------------------------------

.apia-icon {
	display: inline-block;
	text-align: center;
	float: left;
	width: 150px;
	height: 150px;
	margin-right: 3em;
	margin-top: 1em;
	border: 8px solid $green-med;
	border-radius: 50%;
	@media(max-width: $medium) {
		display: block;
		float: none;
		margin: 0 auto 2em;
	}
	img {
		padding-top: 30px;
		padding-left: 10px;
	}
}

.callout-box {
	.apia-icon {
		display: block;
		margin-left: 1em;
		width: 100px;
		height: 100px;
		img {
			max-width: 50px;
			padding-top: 17px;
			padding-left: 3px;
		}
	}
}



// --------------------------------------------
// --------------------------------------------
//
// Subscription banners
//
// --------------------------------------------
// --------------------------------------------



.subscribe-banner {
	margin-bottom: 3em;
	max-width: 100%;
}


