// --------------------------------------------
// --------------------------------------------
//
// Font Path Variable
//
// --------------------------------------------
// --------------------------------------------

$font-path: "/wp-content/themes/iapp/static/fonts";




// --------------------------------------------
// --------------------------------------------
//
// Tabac Dev Links
//
// --------------------------------------------
// --------------------------------------------

@font-face {
  font-family: 'Tabac Sans';
  src: url('#{$font-path}/Tabac_Sans_Regular.eot');
  src: local('☺'),
       url('#{$font-path}/Tabac_Sans_Regular.eot?iefix') format('eot'),
       url('#{$font-path}/Tabac_Sans_Regular.woff') format('woff'),
       url('#{$font-path}/Tabac_Sans_Regular.svg#webfont') format('svg'),
       local('☺');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: 'Tabac Sans';
  src: url('#{$font-path}/Tabac_Sans_Regular_Italic.eot');
  src: local('☺'),
       url('#{$font-path}/Tabac_Sans_Regular_Italic.eot?iefix') format('eot'),
       url('#{$font-path}/Tabac_Sans_Regular_Italic.woff') format('woff'),
       url('#{$font-path}/Tabac_Sans_Regular_Italic.svg#webfont') format('svg'),
       local('☺');
  font-weight: 400;
  font-style: italic;
}


@font-face {
  font-family: 'Tabac Sans';
  src: url('#{$font-path}/Tabac_Sans_Light.eot');
  src: local('☺'),
       url('#{$font-path}/Tabac_Sans_Light.eot?iefix') format('eot'),
       url('#{$font-path}/Tabac_Sans_Light.woff') format('woff'),
       url('#{$font-path}/Tabac_Sans_Light.svg#webfont') format('svg'),
       local('☺');
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'Tabac Sans';
  src: url('#{$font-path}/Tabac_Sans_Light_Italic.eot');
  src: local('☺'),
       url('#{$font-path}/Tabac_Sans_Light_Italic.eot?iefix') format('eot'),
       url('#{$font-path}/Tabac_Sans_Light_Italic.woff') format('woff'),
       url('#{$font-path}/Tabac_Sans_Light_Italic.svg#webfont') format('svg'),
       local('☺');
  font-weight: 300;
  font-style: italic;
}


@font-face {
  font-family: 'Tabac Sans';
  src: url('#{$font-path}/Tabac_Sans_Bold.eot');
  src: local('☺'),
       url('#{$font-path}/Tabac_Sans_Bold.eot?iefix') format('eot'),
       url('#{$font-path}/Tabac_Sans_Bold.woff') format('woff'),
       url('#{$font-path}/Tabac_Sans_Bold.svg#webfont') format('svg'),
       local('☺');
  font-weight: 700;
  font-style: normal;
}


@font-face {
  font-family: 'Tabac Sans';
  src: url('#{$font-path}/Tabac_Sans_Bold_Italic.eot');
  src: local('☺'),
       url('#{$font-path}/Tabac_Sans_Bold_Italic.eot?iefix') format('eot'),
       url('#{$font-path}/Tabac_Sans_Bold_Italic.woff') format('woff'),
       url('#{$font-path}/Tabac_Sans_Bold_Italic.svg#webfont') format('svg'),
       local('☺');
  font-weight: 700;
  font-style: italic;
}


@font-face {
  font-family: 'Tabac G3';
  src: url('#{$font-path}/Tabac_G3_Regular.eot');
  src: local('☺'),
       url('#{$font-path}/Tabac_G3_Regular.eot?iefix') format('eot'),
       url('#{$font-path}/Tabac_G3_Regular.woff') format('woff'),
       url('#{$font-path}/Tabac_G3_Regular.svg#webfont') format('svg'),
       local('☺');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Tabac G3';
  src: url('#{$font-path}/Tabac_G3_Italic.eot');
  src: local('☺'),
       url('#{$font-path}/Tabac_G3_Italic.eot?iefix') format('eot'),
       url('#{$font-path}/Tabac_G3_Italic.woff') format('woff'),
       url('#{$font-path}/Tabac_G3_Italic.svg#webfont') format('svg'),
       local('☺');
  font-weight: 400;
  font-style: italic;
}


@font-face {
  font-family: 'Tabac G3';
  src: url('#{$font-path}/Tabac_G3_Bold.eot');
  src: local('☺'),
       url('#{$font-path}/Tabac_G3_Bold.eot?iefix') format('eot'),
       url('#{$font-path}/Tabac_G3_Bold.woff') format('woff'),
       url('#{$font-path}/Tabac_G3_Bold.svg#webfont') format('svg'),
       local('☺');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Tabac G3';
  src: url('#{$font-path}/Tabac_G3_Bold_Italic.eot');
  src: local('☺'),
       url('#{$font-path}/Tabac_G3_Bold_Italic.eot?iefix') format('eot'),
       url('#{$font-path}/Tabac_G3_Bold_Italic.woff') format('woff'),
       url('#{$font-path}/Tabac_G3_Bold_Italic.svg#webfont') format('svg'),
       local('☺');
  font-weight: 700;
  font-style: italic;
}


// --------------------------------------------
// --------------------------------------------
//
// Variables
//
// --------------------------------------------
// --------------------------------------------

$default-sans: Helvetica, Arial, sans-serif;
$default-serif: Georgia, Times, serif;


$sans: "Tabac Sans", $default-sans;

$serif: "Tabac G3", $default-serif;

$fa: "FontAwesome";

html{
  font-family: $sans;
  font-weight: normal;
  color: $off-black;
}

.flow-text{
  // Set max font size
  @media #{$medium-and-up} {
    font-size: 1.4rem;
  }
  @media #{$large-and-up} {
    font-size: 1.5rem;
  }
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

// --------------------------------------------
// --------------------------------------------
//
// Font mixins
//
// --------------------------------------------
// --------------------------------------------

@mixin sans($font-weight: regular) {
  font-family: $sans;
  @if $font-weight == regular {
    font-weight: $sans-weight-reg;
  }

  @if $font-weight == bold {
    font-weight: $sans-weight-bold;
  }

  @if $font-weight == light {
    font-weight: $sans-weight-light;
  }
}


@mixin serif($font-weight: regular) {
  font-family: $serif;
  @if $font-weight == regular {
    font-weight: $serif-weight-reg;
  }

  @if $font-weight == bold {
    font-weight: $serif-weight-bold;
  }
}



// --------------------------------------------
// --------------------------------------------
//
// Override Roboto in Materialcss
//
// --------------------------------------------
// --------------------------------------------

html {
  @include sans(regular); 
}




// --------------------------------------------
// --------------------------------------------
//
// Defaults
//
// --------------------------------------------
// --------------------------------------------



body { 
  @include sans(regular); 
  -webkit-font-smoothing: auto;
}

h1, h2, h3, h4, h5, h6 {
  @include sans(bold); 
  -webkit-font-smoothing: auto;
}

p {
  @include serif(regular);
  -webkit-font-smoothing: auto;
}

strong {
  font-weight: bold;
}

.default-link {
  @extend .cyan-text, .darken-1; 
}

.subsection-hdr {
  @include serif(regular);
}

// --------------------------------------------
// --------------------------------------------
//
// Headings
//
// --------------------------------------------
// --------------------------------------------

.page-heading {
  display: block;
  text-align: center;
  padding-bottom: 0.5rem;
  margin-top: 0;
  border-bottom: 4px solid map-get($grey, "lighten-4");
  @media #{$small-and-down} {
    font-size: 2rem;
  }
  @media #{$medium-and-up} {
    font-size: 2.6rem;
  }
  @media #{$large-and-up} {
    font-size: 3rem;
  }
  @media #{$xlarge-and-up} {
    font-size: 3.2rem;
  }
}
.section-heading {
  @extend .grey-text, .text-darken-3;
  display: block;
  text-align: left;
  padding-bottom: 0.5rem;
  @media #{$small-and-down} {
    font-size: 1.5rem;
  }
  @media #{$medium-and-up} {
    font-size: 1.75rem;
  }
  @media #{$large-and-up} {
    font-size: 2rem;
  }
  @media #{$xlarge-and-up} {
    font-size: 2.25rem;
  }
}


// --------------------------------------------
// --------------------------------------------
//
// Text sizes
//
// --------------------------------------------
// --------------------------------------------

.page-tagline {
  line-height: 125%;
  @media #{$medium-and-down} {
    font-size: 1.35rem;
  }
}

.page-text-lg {
  font-size: 1.75rem;
}

.page-text-med {
  font-size: 1.25rem;
}

.page-text-sm {
  font-size: 0.9rem;
}

.section-text-wrapper {
  font-size: 14px;
  @media #{$medium-and-up} {
    font-size: 14.5px;
  }
  @media #{$large-and-up} {
    font-size: 15px;
  }
  ul, ol {
    font-size: inherit;
    margin-left: 20px;
    li {
      font-size: inherit;
      font-family: $serif;
      margin-left: 20px;
    }
  }
  ul {
    li {
      list-style: disc;
    }
  }
  a {
    @extend .default-link;
  }
}

// --------------------------------------------
// --------------------------------------------
//
// Search default styles
//
// --------------------------------------------
// --------------------------------------------

h5 {
  &.search-title {
    font-size: 1.2em;
    label {
      @media #{$large-and-up} {
        margin-left: 2rem;
        margin-top: 1rem;
        font-size: 1em;
      }
      @media #{$medium-and-down} {
        font-size: 1em;
      }
    }
  }
}

.highlighted-text {
  @extend .blue-grey;
  @extend .lighten-4;
}

// --------------------------------------------
// --------------------------------------------
//
// Store default styles
//
// --------------------------------------------
// --------------------------------------------

h5 {
  &.featured-head {
    margin-bottom: 2em;
  }
}


// --------------------------------------------
// --------------------------------------------
//
// Article default styles
//
// --------------------------------------------
// --------------------------------------------


.article-body {
  @extend .grey-text, .text-darken-4;
  p, blockquote {
    @extend .flow-text;
    font-family: $serif;
    margin-bottom: 1rem !important;
  }
  a {
    @extend .cyan-text, .text-darken-1;
    &:hover {
      text-decoration: underline;
    }
  }
  ul {
    margin-left: 2em;
    li {
      margin-bottom: 1em;
      list-style: disc;
      font-family: $serif;
      font-weight: 400;
      font-size: 1.5rem;
    }
  }
  ol {
    margin-left: 4em;
    li {
      margin-bottom: 1em;
      font-family: $serif;
      font-weight: 400;
      font-size: 1.5rem;
    }
  }
}

.related-posts-mod,
.article-recent-comments-mod {
  ul{
    li {
      a {
        @extend .grey-text, .text-darken-2;
        transition: .25s all ease;
        &:hover {
          @extend .cyan-text;
        }
      }
    }
  }
}




// --------------------------------------------
// --------------------------------------------
//
// Homepage Hero
//
// --------------------------------------------
// --------------------------------------------



.hp-hero {
  h5, h6 {
    @include sans(regular); 
  }
  h4 {
    @include sans(bold); 
  }
  p {
    font-size: 1.2rem;
  }
}

// --------------------------------------------
// --------------------------------------------
//
// Store Carousel Hero
//
// --------------------------------------------
// --------------------------------------------



.header-block {
  h2 {
    margin-top: 0;
  }
}



// --------------------------------------------
// --------------------------------------------
//
// Cards
//
// --------------------------------------------
// --------------------------------------------



.card {
  &.card-image,
  &.card-horizontal {
    h1, h2, h3, h4, h5, h6 {
      @include serif(regular);
    }
    .card-head {
      @media #{$medium-and-up} {
        font-size: 1.3rem;
        line-height: 1.7rem;
      }
    }
    .card-action {
      a {
        &.more-link {
          @extend .green-text;
        }
        &.save-this {
          @extend .cyan-text, .text-darken-1;
        }
        &.saved {
          @extend .light-blue-text, .text-accent-4;
        }
      }
    }
  }
  .card-content {
    .card-title {
      @include serif(regular);
    }
  }
}

.featured-card {
  .card {
    .card-head {
      @media #{$medium-and-up} {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
  }
}

// --------------------------------------------
// --------------------------------------------
//
// Carousel
//
// --------------------------------------------
// --------------------------------------------

.carousel-head {
  // margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 0;
}

.carousel {
  .carousel-item {
    h5 {
      line-height: 1.45em;
    }
  }
}

// --------------------------------------------
// --------------------------------------------
//
// Blockquotes
//
// --------------------------------------------
// --------------------------------------------


.article-body {
  blockquote {
    @extend .blue-grey, .lighten-5, .blue-grey-text, .text-darken-2;
    border-left: 5px solid map-get($cyan, "darken-1") !important;
    padding: 0.1rem 2rem;
    margin: 0;
  }
}


// --------------------------------------------
// --------------------------------------------
//
// Text effects and special modifications
//
// --------------------------------------------
// --------------------------------------------


.weight-regular {
  font-weight: 400 !important;
}

.bold > a {
  font-weight: bold;
}

.text-shadow {
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5), 0 2px 5px rgba(0, 0, 0, 1);
}

.sans {
  @include sans(regular);
}

.clickable {
  cursor: pointer;
}

.boldest {
  font-weight: 900;
}

.ss-item-title {
  font-size: 1.2em;
}


// --------------------------------------------
// --------------------------------------------
//
// Comments
//
// --------------------------------------------
// --------------------------------------------

.comment-items {
  font-family: $serif;
  .card {
    .card-content {
      .card-title {
        font-size: 18px;
        line-height: 18px;
        .material-icons {
          transform: translateY(6px);
        }
      }
      pre {
        white-space: inherit;
        font-family: inherit;
        margin-bottom: 0;
      }
    }
  }
}


// --------------------------------------------
// --------------------------------------------
//
// Store Notifications
//
// --------------------------------------------
// --------------------------------------------
  .iapp-store {
    .product-page {
      #iapp-store-notifications {
          padding-left: 0px;
        .collection-item {
          list-style-type: none!important;
        }
      }
    }
  }

// --------------------------------------------
// --------------------------------------------
//
// Colors
//
// --------------------------------------------
// --------------------------------------------
.iapp-green-txt {
  color: $success-color;
}
