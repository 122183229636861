// --------------------------------------------
// --------------------------------------------
//
// Related teases :: Article pages
//
// --------------------------------------------
// --------------------------------------------

.tz-related {
	// margin-bottom: 4em;
	margin-bottom: 2em;
	&:last-child {
		margin-bottom: 0;
	}
}




// --------------------------------------------
// --------------------------------------------
//
// Index page
//
// --------------------------------------------
// --------------------------------------------

.tz-index {
	border: $bdr-thin-lt;
	border-radius: 3px;
	padding: 1px;
	position: relative;

	.img-tz {
		position: relative;
		&:before, &:after {
			content: '';
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			position: absolute;
		}
		&:before { background-color: rgba($swatch-6, 0.6); z-index: 2;  }
		&:after { background-color: rgba(black, 0.6);  z-index: 1; }

	}

}

.tz-index-bd {
	padding: 1em;
}




// --------------------------------------------
// --------------------------------------------
//
// Colorized tease image
//
// --------------------------------------------
// --------------------------------------------

.img-tz-colorized {
	@extend %img-tz;
	position: relative;
	border-bottom: 6px solid black;
	&:before, &:after {
		content: '';
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background-color:inherit;
		position: absolute;
	}
	&:before {
		z-index:2;
	}

	// Ideally, b&w images are uploaded for these stylized teases.
	// However, for capable browsers, these lines will make sure the image
	// is b&w
	img {
		-webkit-filter: grayscale(100%);
		   -moz-filter: grayscale(100%);
		        filter: grayscale(100%);
	}

	&.type-1 {
		border-bottom-color: darken($colorized-1, 30%);
		&:before { background-color: rgba($colorized-1, 0.35); z-index: 2;  }
	}

	&.type-2 {
		border-bottom-color: darken($colorized-2, 30%);
		&:before { background-color: rgba($colorized-2, 0.35); z-index: 2;  }
	}

	&.type-3 {
		border-bottom-color: darken($colorized-3, 30%);
		&:before { background-color: rgba($colorized-3, 0.35); z-index: 2;  }
	}

	&:after { background-color: rgba(black, .5);  z-index: 1; }
}





// --------------------------------------------
// --------------------------------------------
//
// Colorized image
//
// --------------------------------------------
// --------------------------------------------

.tz-colorized {
	position: relative;
}

.tz-colorized-bd {
	position: absolute;
	z-index: 3;
	top: 0;
	bottom: 20%;
	left: 0;
	right: 0;
	margin: auto;
	height: 0;
	text-align: center;
}

.colorized-head { @include link(white); }




// --------------------------------------------
// --------------------------------------------
//
// Tease Universal
//
// --------------------------------------------
// --------------------------------------------

.tz-meta-block {
	margin-bottom: 0.55em;
}

.tz-meta-text, %tz-meta-text {
	color: $black-30;
	.tz-excerpt & {
		line-height: 2em;
	}
}


.tz-author-link, %tz-author-link {
	@include link(head-lt);
	margin-right: 2px;
}

.tz-author-link--bold, %tz-author-link--bold {
	@extend %tz-author-link;
	@include sans(bold);
}

.tz-pub-link {
	@include link;
	@include sans(bold);
	margin-left: 2px;
}

.tz-meta-date, %tz-meta-date {
	@include sans(bold);
}


// This sets space and a rule between the author and the date
// When both are present directly following one another
.tz-meta-author, %tz-meta-author {
	+ .tz-meta-date,
	+ .article-meta-date {
		margin-left: 0.55em;
		padding-left: 0.85em;
		border-left: $bdr-thin;
	}
}


// --------------------------------------------
// --------------------------------------------
//
// Tease simple
//
// --------------------------------------------
// --------------------------------------------

.tz {
	margin-bottom: 2em;
	padding-bottom: 2em;
	border-bottom: $bdr-thin;
	text-align: left;
	position: relative;
	width: 100%;
	&:last-child {
		border-bottom: 0;
	}
}


.tz-source-link { @include link(head-lt); }

.tz-meta-source {
	margin-left: 15px;
	font-size: 0.85em;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}


.tz-bd { }


.tz-bd-text { 
	@extend %tz-text-reg; 
	p {
		padding-left: 15px;
		padding-right: 15px;
	}

}

.tz-bd-link {
	@include link(accent);
	margin-left: 3px;
}




// --------------------------------------------
// --------------------------------------------
//
// Tease Excerpt
//
// --------------------------------------------
// --------------------------------------------

.tz-meta-img, %tz-meta-img {
	width: 2em;
	margin-right: 8px;
	float: left;
}

.tz-excerpt {
	position: relative;
}

.tz-tags-block {
	padding-top: 1em;
	border-top: $bdr-thin-lt;
	@include pie-clearfix;
}


.tz-social-block {
	display: none;
	position: absolute;
	width: $grid--quarter;
	left: -$space-grid;
	.tz-excerpt:hover & {
		display: inline-block;
	}
}


.tz-excerpt-link {
	@include link;
	@include sans;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 0.08em;
}

.tz-excerpt-cat {
	@include link(linkbold);
	@include sans(bold);
	font-size: 12px;
	line-height: 0;
	display: inline-block;
	margin-right: 7px;
	text-transform: uppercase;
	letter-spacing: 0.05em;
}




// --------------------------------------------
// --------------------------------------------
//
// Resource page
//
// --------------------------------------------
// --------------------------------------------

.resource-tease {
	position: relative;
}




// --------------------------------------------
// --------------------------------------------
//
// Contextual Styles for various teases
//
// --------------------------------------------
// --------------------------------------------

.resource-tz {
	// This allows for the saveable star to not overlap with the resource icon
	min-height: 10em;
	max-width: 85%;
	margin-left: auto;
	margin-right: auto;
	@media(min-width: $medium) {
		max-width: 100%;
		padding-left: 4em;
	}

	.art-bd {
		position: relative;
		left: 1em;
		padding-right: 4em;
		padding-bottom: 1em;
	}
	.img-tz-bg-saved {
		width: 56px;
		height: 56px;
		float: left !important;
    	margin-bottom: 1em;
    	margin-right: 25px;
    	@media(min-width: $large) {
    		margin-right: 20px !important;
		}   
	}
}

.tz--has-img {
	@media(min-width: $large) {
		padding-left: 62.5px;
	}
}

.icon-tz { }


.resource-tz,
.icon-tz {
	.art-bd { float: left; }
	.txt-bd {
		@media(min-width: $medium) {
			margin-left: 7em;
		}
	}
}

.resource-single {
	.art-bd {
		margin-bottom: 2em;
		@media(min-width: $medium) {
			max-width: 50%; 
			float: left; 
			padding: 0 4em 2em 0;
			margin-bottom: 0;
		}
	}
}

.hp-stream-mod {
	.l-pair-tz, .resource-tz {
		max-width: 100%;
	}
}

.tz-glossary {
	.txt-bd {
		width: 100%;
	}
}

// DPA teases

.hex-lg.icon-dpa {
	display: none;
	@media(min-width: $medium) {
		display: block;
		position: absolute;
	}
}

.tz-iapp_dpa {
	.txt-bd {

		a {
			color: #0c7ec0;
		}
	}
}

// Issue subsections

.issue-subsection {
	padding-top: 20px;
	margin-top: -30px;
	border-top: 10px solid #fff;
	position: relative;
}


// Used for fix where conference head overlays were as deep as .art-bd
.conference-tz-media {
	position: relative;
}

.breakout-sessions {
	padding: 0 2em;
	@media(min-width: $large) {
		padding: 0;
	}
	.tz {
		border-bottom: 0;
	}
}

// Used for top news stories

.top-story {
	position: relative;
	.tz-head-xlg {
		margin-left: 0.6em;
		font-size: 2.1em;
		margin-bottom: 0.2em;
		@media(min-width: $small) {
			margin-left: 8%;
		}
		@media(min-width: $medium) {
			margin-left: -0.25em;
			font-size: 3.23076923076923em;
		}
		@media(min-width: $large) {
			margin-left: 0;
		}
	}
	.art-bd {
		margin-top: 1em;
	}
	.tz-saveable-block {
	margin-top: 3px;
	left: -1.2em;
		@media(min-width: $medium) {
			margin-top: 8px;
			left: 0.2em;
		}
	}
	.tz-meta-author {
		margin-left: 2em;
		@media(min-width: $small) {
			margin-left: 8%;
		}
		@media(min-width: $medium) {
			margin-left: -0.8em;
		}
		@media(min-width: $large) {
			margin-left: 0;
		}
	}
	@media(min-width: $medium) {
		padding-left: 62.5px;
	}
}

// Volunteer spotlights

.vol-spot {
	background-color: $bg-lt;
	padding: 3em 3em 0;
	.page-head-lg {
		text-align: center;
	}
}

// Show Daily

.show-daily-wrapper {
	margin-top: -4em;
	padding-bottom: 4em;
	.l-pair-tz {
		max-width: none;
		.art-bd, .txt-bd {
			margin-left: 0;
		}
	}
}

// New sessions/schedule template for conferences

.session-list {
	margin-top: -4em;
	@media (min-width: $large) {
		width: 85%;
		float: right;
		border: 4px solid #efefef;
		margin-top: 0;
	}
	@media (min-width: $x-large) {
		width: 90%;
	}
	@media (min-width: 1200px) {
		width: 100%;
	}
	.l-pair-tz-session {
		@extend %l-pair-tz-sm;
		width: 100%;
		border-bottom: 1px solid #efefef;
		padding: 1em 0;
		overflow: hidden;
		@media (min-width: $large) {
			padding: 1em;
		}
		.art-bd {
			width: 25%;
			display: block;
			@media (min-width: $large) {
				float: left;
				display: table-cell;
			}
			.hex-sm {
				float: left;
				display: none;
				@media (min-width: $large) {
					display: block;
					margin-left: 1em;
				}
				@media (min-width: $x-large) {
					margin-left: 0;
				}
			}
			.session-label {
				float: left;
				font-weight: bold;
				color: $black-50;
				width: 80%;
			}
			.session-time {
				padding-top: 0.4em;
				width: 100%;
				@media (min-width: $medium) {
					max-width: 100px;
				}
				@media (min-width: $large) {
					width: 80%;
					max-width: none;
				}
				.fa {
					display: none;
					width: 20px;
					margin-bottom: 0.5em;
					float: left;
					@media (min-width: $large) {
						display: block;
					}
				}
				.session-time-break {
					@media (min-width: $large) {
						display: none;
					}
				}
				.session-end-time {
					color: $black-30;
				}
			}
		}
		.art-bd-time {
			width: 15%;
			display: block;
			float: left;
			overflow: hidden;
			@media (min-width: $medium) {
			width: 20%;
			}
			@media (min-width: $large) {
				float: left;
				display: table-cell;
			}
			.time-bg {
				text-align: center;
			    position: absolute;
			    // width: 15%;
			    top: 13px;
			    left: 10px;
			    font-size: 2.5em;
			    color: #EFEFEF;
			    z-index: -1;
			    @media (min-width: $medium) {
				display:none;
				}
			}
			.hex-sm {
				float: left;
				display: none;
				@media (min-width: $large) {
					display: block;
					margin-left: 0em;
				}
				@media (min-width: $x-large) {
					margin-left: 0;
				}
			}
			.session-label {
				float: left;
				font-weight: bold;
				color: $black-50;
				width: 80%;
			}
			.session-time {
				padding-top: 0.4em;
				width: 100%;
				@media (min-width: $medium) {
					max-width: 100px;
				}
				@media (min-width: $large) {
					width: 80%;
					max-width: none;
				}
				.fa {
					display: none;
					width: 20px;
					margin-bottom: 0.5em;
					float: left;
					@media (min-width: $medium) {
						display: block;
					}
				}
				.session-time-break {
					@media (min-width: $large) {
						display: none;
					}
				}
				.session-end-time {
					color: $black-30;
				}
			}
		}
		.txt-bd {
			padding: 0;
			text-align: left;
			position: relative;
			display: block;
			float: left;
			width: 80%;
			&.green-border {
				width: 50%;
				border-right: 2px solid #70bf4c;
				min-height: 5.5em;
	    		padding-right: 0.4em;
	    		@media (min-width: $medium) {
				border: none;
				width: 60%;
				min-height:100%;
				}
	    	}
			@media (min-width: $medium) {
				float: left;
				width: 80%;
				border: none;
				min-height:100%;
			}
			@media (min-width: $large) {
				padding-left: 1em;
			}
			@media (min-width: $x-large) {
				padding-left: 0;
			}
			.tz-head-sm {
				font-size: 1em;
				margin-top: 0.25em;
				@media (min-width: $medium) {
					padding-left: 0;
					font-size:1.1em;
				}
				@media (min-width: $large) {
					font-size: 1.3em;
					margin-top: 0;
				}
			}
			a.tz-head-sm {
				text-decoration: none;
				display: block;
				@media (min-width: $large) {
					padding-right: 5%;
				}
				i {
					display: none;
					font-size: 0.75em;
					color: $black-50;
					position: absolute;
					right: 0;
					top: 0.45em;
				}
				&:hover {
					i {
						display: none;
						@media (min-width: $large) {
							display: block;
						}
					}
				}
			}
			span.tz-head-sm {
				font-weight: normal;
				display: block;
			}
		}

		.sess-loc {
			float: left;
    		width: 35%;
    		padding-left: 0.5em;
    		@media (min-width: $medium) {
				width: 20%;
			}
    		.session-label {
    			padding-top:0.4em;
    		}
    		.fa {
    			display:none;
    			@media (min-width: $medium) {
				display: block;
    			float: left;
   				padding-right: 0.4em;
    			min-height: 30px;
				}
    		}
		}
		.sess-desc {
			clear: both;
			padding-top: 1em;
			.sess-rail {
				float: left;
				display: block;
				width: 100%;
				background-color: #efefef;
				margin-bottom: 1em;
				padding: 1em 1em 0;
				min-height: 10px;
				@media (min-width: $large) {
					display: block;
					width: 25%;
					padding: 0 1em 0 0;
					background-color: #fff;
				}
				.session-label {
					float: left;
					width: 100%;
					margin-bottom: 1em;
					@media (min-width: $large) {
						width: 75%;
					}
					.fa {
						display: block;
						width: 20px;
						float: left;
						@media (min-width: $large) {
							width: 15%;
						}
					}
					span {
						@media (min-width: $large) {
							display: block;
							width: 85%;
							float: right;
						}
					}
					.session-tag-list-items {
						@extend %list-items--inline;
						list-style: none;
						margin-left: 20px;
						float: left;
						@media (min-width: $large) {
							margin-left: 15%;
						}
						li {
							display: inline-block;
						}
					}
				}
				.session-cpe {
					width: 75%;
					display: block;
					margin-bottom: 1em;
					margin-left: 20px;
					font-size: 0.8em;
				}
			}
			.sess-content {
				width: 100%;
				color: $black-50;
				@media (min-width: $large) {
					float: right;
					width: 75%;
				}
				p {
					margin-bottom: 1em;
				}
				a {
					color: $link-color;
				}
				ul li {
					list-style: disc;
				}
				li {
					margin-left: 1.5em;
				}
				ul, ol {
					margin-bottom: 1em;
				}
			}
		}
	}
	.session-date {
		display: block;
		position: static;
		width: 100%;
		font-weight: bold;
		background: #fff;
		color: $link-color;
		text-align: center;
		padding: 1em 0;
		z-index: 100000;
		font-size: 1.2em;
		border-bottom: 4px solid #efefef;
		margin-top: 4em;
		margin-bottom: -53px;
		&.static-date-fix {
			margin-bottom: 0;
		}
		@media (min-width: $large) {
			margin-top: 0em;
		}	
		@media (min-width: $large) {
			width: 20%;
			margin-left: -21%;
			margin-top: 0;
			padding: 17px 1.5em 17px 0;
			text-align: right;
			font-size: 1em;
			color: $black-100;
			border-bottom: none;
			z-index: 1;
			&.static-date-fix {
			margin-bottom: -75px;
			}
		}
		@media (min-width: $x-large) {
			font-size: 1.2em;
			width: 300px;
			margin-left: -305px;
		}
		&.static-date {
			position: static;
			z-index: 10;
		}
		&.fixed-date {
			position: fixed;
			z-index: -10;
			top: 35px;
		}
		&.absolute-date {
			position: absolute;
		}

	}
	.session-date-buffer {
		display: block;
		height: 4em;
		@media (min-width: $large) {
			display: none;
		}
	}
	.session-label {
		font-weight: bold;
		color: $black-50;
	}
	.fa-cog, .fa-exclamation-circle {
		margin-right: 1em;
	}
}

.session-tooltip {
	position: absolute;
	width: 65%;
	max-width: 65%;
	top: 6em;
	right: 0;
	padding: 2em 2em 1em 2em;
	background-color: #fff;
	border: 4px solid #efefef;
	z-index: 1;
	.h4 {
		margin-bottom: 0.55em;
	}
	.session-tooltip-hex {
		position: absolute;
		margin: -3.4em 0 0 -2.75em;
		padding: 0 1.25em 0.5em 0;
		background-color: #fff;
	}
}

.ftc-news {
	margin-top: 5em;
	.tz {
		&:nth-child(4) {
			border-bottom: none;
			margin-bottom: -2em;
		}
	}
}



// --------------------------------------------
// --------------------------------------------
//
// Privacy Industry Index
//
// --------------------------------------------
// --------------------------------------------



.pii-results-hdr {
	margin-bottom: 0;
	font-size: 2em;
	color: $black-40;
}

.tz-pii {
	margin-bottom: 0;
	padding: 1.5em 1.5em 0.5em;
	&.iapp-diamond, 
	&.iapp-platinum,
	&.iapp-gold,
	&.iapp-silver,
	&.iapp-bronze,
	&.iapp-group-member {
		background-color: #f7f7f7;
		.member-level {
			display: none;
			width: 30px;
			height: 30px;
			float: right;
			margin-top: 0.4em;
			background-position: 50% 50%;
			background-size: cover;
			background-repeat: no-repeat;
			@media(max-width: $medium) {
				margin-top: -0.2em;
			}
			@media(min-width: $medium) {
				display: inline-block;
			}
			&:before {
				font-weight: normal;
				width: 200px;
				text-align: right;
				float: right;
				margin-top: -2.2em;
				font-size: 0.6em;
				color: $black-20;
				@media(max-width: $medium) {
					display: none;
				}
			}
		}
	}
	&.iapp-diamond {
		.member-level {
			background-image: url(/wp-content/themes/iapp/static/img/iapp-diamond.png); 
			&:before {
				content: "IAPP Diamond Member";
			}
		}
	}
	&.iapp-platinum {
		.member-level {
			background-image: url(/wp-content/themes/iapp/static/img/platinum.png); 
			&:before {
				content: "IAPP Platinum Member";
			}
		}	
	}
	&.iapp-gold {
		.member-level {
			background-image: url(/wp-content/themes/iapp/static/img/gold.png); 
			&:before {
				content: "IAPP Gold Member";
			}
		}
	}
	&.iapp-silver {
		.member-level {
			background-image: url(/wp-content/themes/iapp/static/img/silver.png); 
			&:before {
				content: "IAPP Silver Member";
			}
		}
	}
	&.iapp-bronze {
		.member-level {
			background-image: url(/wp-content/themes/iapp/static/img/iapp-bronze.png);
			&:before {
				content: "IAPP Bronze Member";
			}
		} 	
	}
	&.iapp-group-member {
		.member-level {
			background-image: url(/wp-content/themes/iapp/static/img/group.png);
			&:before {
				content: "IAPP Group Member";
			}
		} 
	}
}

// Twitter tweets

blockquote.twitter-tweet {
  display: inline-block;
  font-family: "Helvetica Neue", Roboto, "Segoe UI", Calibri, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  border-color: #eee #ddd #bbb;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  margin: 10px 5px;
  padding: 16px;
  max-width: 468px;
}
 
blockquote.twitter-tweet p {
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
}
 
blockquote.twitter-tweet a {
  color: inherit;
  font-weight: normal;
  text-decoration: none;
  outline: 0 none;
}
 
blockquote.twitter-tweet a:hover,
blockquote.twitter-tweet a:focus {
  text-decoration: underline;
}


// Video teases

.tz-video {
	p {
		margin-bottom: 1em;
	}
}

// External linking tease

#news {
	.tz-iapp_page {
		margin-bottom: 0;
		padding-bottom: 0;
		.external-tease {
			padding: 2em;
			&.featured {
				background-color: #efefef;
			}
			.page-text-sm {
				margin-bottom: 0;
			}
			:first-child & {
				&.featured {
					padding-top: 2em;
				}
			}
		}
	}
}
