// --------------------------------------------
// --------------------------------------------
//
// Nav Variables
//
// --------------------------------------------
// --------------------------------------------

// Nav breakpoint variables reside in breakpoint-var.scss file.

$nav-height-sm: 	2.692307692em;
$nav-height-sm-mod: 1.3em;
$nav-height: 		5em; 

$nav-height-px: 63px;


$nav-pad: $site-margin;

$nav-logo-width: 5.769230769em;

$nav-block-width: $nav-logo-width;

$nav-block-pad: $grid--quarter;

$max-nav-width: $nav-bp-xlg;



.site-nav {
	position: relative;
	z-index: 100000;
	@media(min-width: $nav-bp-med) {
		position: fixed;
	}
}

.nav-inner {
	background-color: #fff;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
	@include pie-clearfix;
	@media(min-width: $nav-bp-med) {
		position: fixed; // changed from fixed for headroom.js
		left: 50%;
		max-width: $nav-bp-lg;
		margin-left: $nav-bp-lg / 2 * -1;
		padding: 0 $nav-block-pad;
		border-bottom: $nav-bdr;
		box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.1);
	}
	@media(min-width: $nav-bp-lg) {
		max-width: 100%;
		margin-left: 0;
		left: 0;
	}
	@media(min-width: $nav-bp-xlg) {
		left: 50%;
		max-width: $max-nav-width;
		margin-left: $max-nav-width / 2 * -1;
	}
}


// --------------------------------------------
//
// Main nav layout

.nav-mod, %nav-mod {
	display: block;
	float: left;
}

.nav-main-mod {
	@extend %nav-mod;
	// width: 100%;
	margin-right: -$nav-block-width;
}

.nav-main-inner {
	width: 100%;
	@media(min-width: $nav-bp-xlg) {
		margin-right: $nav-block-width;
	}
	@include pie-clearfix;
}


.nav-secondary-mod {
	@extend %nav-mod;
	line-height: $nav-height-sm-mod;
	float: right;
	text-align: right;
	padding-right: 0.5em;
	padding-top: 0.1em;
	@media(min-width: $nav-bp-lg) {
		line-height: $nav-height;
		padding: 0;
		border-left: $bdr-thin-lt;
	}
	@media(min-width: $nav-bp-xlg) {
		padding: 0 0.7em;
	}
}


// --------------------------------------------
//
// xxx

.nav-logo-mod {
	@extend %nav-mod;
	text-align: center;
	width: $nav-logo-width;
	line-height: $nav-height-sm-mod;
	height: $nav-height-sm;
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -($nav-logo-width/2);
	@media(min-width: $nav-bp-lg) {
		position: static;
		float: left;
		margin-left: 0;
		width: $nav-block-width + $nav-block-pad;
		border-right: $bdr-thin-lt;
		padding-right: 1em;
		line-height: $nav-height;
		height: $nav-height;
	}
}


.nav-menu-mod {
	@extend %nav-mod;
	float: left;
	color: $black-50;
}


// --------------------------------------------
//
// Nav elements

.nav-logo {
	display: block;
	width: 75%;
	margin: 0 auto;
	&.is-active {
		position: relative;
		@media(min-width: $nav-bp-lg) {
			&:after {
				content: '\0020';
				width: 0;
				height: 0;
				position: absolute;
				bottom: -3px;
				left: 35%;
				z-index: 4;
				border-top: 9px inset transparent;
				border-right: 9px inset transparent;
				border-bottom: 9px solid $green-main;
				border-left: 9px inset transparent;
			}

			&:before {
				content: '\0020';
				position: absolute;
				left: 0;
				right: 0;
				bottom: -4px;
				height: 4px;
				width: 8.2em;
				margin-left: -1.9em;
				background-color: $green-main;
				z-index: 1;
			}
			.l-canvas & {
				&:before,
				&:after {
					display: none;
				}
			}
		}
	}
}


.nav-logo-link {
	display: block;
	height: 3em;
	width: 8.2em;
	margin-left: -1.9em;
	vertical-align: middle;
	@media(max-width: $nav-bp-lg) {
		margin-top: 0.75em;
	}
	@media(min-width: $nav-bp-lg) {
		height: $nav-height;
	}
	img {
		width: 100%; // for ie
		max-width: 4.2em;
		// height: 100%;
		height: auto;
		// display: table-cell;
		vertical-align: middle;
	}
}


.nav-items-mod {
	display: none;
	position: absolute;
	top: $nav-height-sm-mod + 2em;
	left: 0;
	right: 0;
	background-color: $bg-lt;
	border-top: 1px solid $bg-med;
	padding: 0 $nav-pad $nav-pad $nav-pad;
	z-index: 1;
	@media(min-width: $nav-bp-lg) {
		position: static;
		display: block;
		float: left;
		padding: 0;
		background-color: transparent;
		top: $nav-height;
		border-top: none;
	}
	&.is-active {
		display: block;

	}
}



.nav-trigger {
	line-height: $nav-height-sm;
	width: 30px;
	display: inline-block;
	text-align: center;
	cursor: pointer;
	font-size: 1.25em;
	&:hover {
		background-color: $bg-lt;
	}
	@media(min-width: $nav-bp-lg) {
		display: none;
	}
}


// --------------------------------------------
// --------------------------------------------
//
// Nav items

.nav-items {
	@extend %list-items;
	@include pie-clearfix;
	@media(max-width: $nav-bp-lg) {
		width: 100%;
		border-top: 1px solid $bg-med;
	}
	@media(min-width: $nav-bp-med) and (max-width: $nav-bp-lg) {
		margin-top: 5em;
	}
	@media(min-width: $nav-bp-lg) and (max-width: $nav-bp-xlg) {
		padding: 0.4em 0.3em 0.1em;
	}
	& + & {
		margin-top: 0;
		.nav-link {
			&.is-active {
				&:before, &:after {
					display: none;
				}
			}
		}
		@media(min-width: $nav-bp-lg) {
			border-left: 1px solid $bg-med;
		}
	}
}

.nav-item {
	@extend %list-item;
	font-size: 1.25em;
	@media(min-width: $nav-bp-lg) {
		display: table-cell;
		font-size: 1.15em;
	}
	@media(min-width: $nav-bp-xlg) {
		font-size: 1.25em;
	}
}

.nav-link {
	display: block;
	padding: 1em 0;
	color: $nav-link-color;
	text-decoration: none;
	@include serif;
	&:hover {
		color: $black-90;
		text-decoration: none;
	}
	@media(min-width: $nav-bp-lg) {
		line-height: 4em;
		padding: 0 1.25em;
	}
	@media(min-width: $nav-bp-xlg) {
		padding: 0 1.575em;
	}
	&.is-active {
		position: relative;
		@media(min-width: $nav-bp-lg) {
			&:after {
				content: '\0020';
				width: 0;
				height: 0;
				position: absolute;
				bottom: -3px;
				left: 50%;
				margin-left: -9px;
				z-index: 4;
				border-top: 9px inset transparent;
				border-right: 9px inset transparent;
				border-bottom: 9px solid $green-main;
				border-left: 9px inset transparent;
			}

			&:before {
				content: '\0020';
				position: absolute;
				left: 0;
				right: 0;
				bottom: -4px;
				height: 4px;
				width: 100%;
				background-color: $green-main;
				z-index: 1;
				@media(min-width: $nav-bp-lg) and (max-width: $nav-bp-xlg) {
					margin-bottom: -1px;
				}
			}
			.l-canvas & {
				&:before,
				&:after {
					display: none;
				}
			}
		}

	}

}




// --------------------------------------------
// --------------------------------------------
//
// Search
//
// --------------------------------------------
// --------------------------------------------

// Clicking the .nav-search-trigger will expose the search mod
// Blah blah blah words

.js-search-nav { }


.instant-search-mod {
	margin: 0 auto;
	display: none;
	height: $nav-height-px;

	.js-search-nav & {
		display: block;
		@media(min-width: $nav-bp-xlg) {
			width: 119%;
		}
	}
}

.nav-trigger--instant-search {
	display: none;
	float: left;
	font-size: 2em;
	line-height: 63px;
	text-align: center;
	cursor: pointer;
	color: $black-50;
	&:hover {
		color: $black-90;
	}

	.js-search-nav & {
		padding: 0 1em;
		@media(min-width: $nav-bp-lg) {
			display: block;
		}
	}
}

.nav-items {
	float: left;
	@media(max-width: $nav-bp-med) {
		margin-top: 5em;
	}
	.js-search-nav & {
		@media(min-width: $nav-bp-lg) {
			display: none;
		}
	}
}

// --------------------------------------------
//
// Search link

.nav-search-block {
	padding: 0;
	line-height: $nav-height-sm;
	@media(min-width: $nav-bp-lg) {
		line-height: $nav-height;
		border-left: $bdr-thin-lt;
		padding: 0 0.9em;
	}
	@media(min-width: $nav-bp-lg) and (max-width: $nav-bp-xlg) {
		position: absolute;
		right: 9.5em;
	}
	@media(min-width: $nav-bp-xlg) {
		float: right;
		padding: 0 1.5em;
	}
	.js-search-nav & {
		@media(min-width: $nav-bp-lg) and (max-width: $nav-bp-xlg) {
			right: auto;
		}
	}
	.search-form {
		@media(max-width: $nav-bp-lg) {
			margin-top: 1em;
		}
	}
}


.search-link {
	display: block;
}

.search-trigger-text {
	display: none;
}

.nav-search-trigger {
	width: 30px;
	height: $nav-height-sm;
	line-height: $nav-height-sm;
	position: relative;
	@media(min-width: $nav-bp-lg) {
		height: $nav-height;
		line-height: $nav-height;
	}

	.js-search-nav & {
		display: none;
	}

	.icon-lg {
		top: 18px;
		left: 2px;
	}
}


// --------------------------------------------
//
// My IAPP link

// The default state for the MyIapp link has a gray dot beside it.
// If the user is logged in,
// the class of .user--loggedin is applied to the body
// and the dot changes color to IAPP greeen

.myiapp-link {
	@extend .nav-link;
	position: relative;
	display: inline-block;

	// this makes the dot appear beside myIAPP
	&:before {
		content: '\0020';
		width: 8px;
		height: 8px;
		position: absolute;
		top: 1.3em;
		left: 0px;
		left: -1em;
		background-color: $bg-dark;
		border-radius: 50%;
		@media(min-width: $nav-bp-lg) {
			top: 1.65em;
			left: 1.3em;
		}
		@media(min-width: $nav-bp-xlg) {
			left: 0.7em;
		}
	}

	@media(min-width: $nav-bp-lg) {
		width: 7em;
		padding-right: 0.5em;
		font-size: 1.1em;
	}

	@media(min-width: $nav-bp-xlg) {
		width: $nav-logo-width;
		font-size: 1.125em;
		padding-right: 0;
	}

	.user--loggedin & {
		&:before {
			background-color: $green-main;
		}
	}
}


// --------------------------------------------
//
// Nav Login mod

// The Login mod is hidden by default
// If the class is-active is added to the mod
// Mod will be displayed

.nav-myiapp-mod {
	@extend %nav-triangle;
	display: none;
	background-color: $bg-lt;
	position: absolute;
	top: $nav-height-sm-mod + 2em;
	right: 0;
	border-top: 4px solid $green-main;
	z-index: 1;
	width: auto;
	@media(min-width: $nav-bp-lg) {
		top: $nav-height;
		// right: 0;
	}

	&.is-active {
		display: block;
	}
}

.nav-myiapp-mobile {
	@extend %nav-triangle;
	display: none;
	background-color: $bg-lt;
	position: absolute;
	top: $nav-height-sm-mod;
	right: 10px;
	border-top: 4px solid $green-main;
	z-index: 1;
	width: 15.615384615em;
	@media(min-width: $nav-bp-lg) {
		top: $nav-height;
		// right: 0;
	}

	&.is-active {
		display: block;
	}
}


.nav-myiapp-inner {
	padding: 2em 1.25em 1em 1.25em;
	text-align: left;
}

.nav-myiapp-bottom {
	background-color: $black-10;
	padding: 0 0.25em;
	text-align: center;
}

.login-link {
	@include link;
	display: block;
	line-height: 3em;
}



// --------------------------------------------
//
// Logged in vs. not logged in

// By default, the prompt to login will show
// If the the class of .user--loggedin is present on the body
// The login state will not display and the menu allowing users
// To navigate to section os their MyIAPP profile will display

.nav-myiapp-notloggedin {
	display: block;
	.user--loggedin & {
		display: block;
	}
}

.nav-myiapp-loggedin {
	display: block;
	.user--loggedin & {
		display: block;
	}
}




// --------------------------------------------
// --------------------------------------------
//
// Mega Menu
//
// --------------------------------------------
// --------------------------------------------


.mega-menu-mod {
	background-color: transparent;
	position: fixed;
	left: 0;
	right: 0;
	top: $nav-height;
	display: none;
	z-index: 10000000;
	@media(min-width: $nav-bp-lg) {
		&.mega-is-active  {
			display: block;
		}
	}
	@media(min-width: $nav-bp-xlg) {
		left: 50%;
		max-width: $max-nav-width;
		margin-left: $max-nav-width / 2 * -1;
	}
}



.mega-menu-inner {
	font-size: 1em;
	border-bottom: 4px solid $black-20;
	background-color: #fff;
	// background-image: url(/wp-content/themes/iapp/static/img/ui-img/bg/mega_side.gif);
	// background-repeat: repeat-y;
	// background-position: 0 0;
	@include pie-clearfix;
}





// --------------------------------------------
//
// Mega Menu Layout

.l-mega-primary {
	width: 100%;
	@include pie-clearfix;
	min-height: 100%;
	padding: 2em 0;
	@media(min-width: $medium) {
		width: 100%;
		float: right;
		margin-left: -$grid-3;

		> .inner {
			margin-left: $grid-3;
			padding-left: $grid-1;
			padding-right: $grid-1;
			background-color: #fff;
			@include pie-clearfix;
		}
	}
	.nav-message-isactive & {
		padding-top: 4em;
	}
}


.l-mega-secondary {
	width: $grid-3;
	height: 100%;
	padding: 2em 1.5em 2em 1em;
	@media(min-width: $medium) {
		float: left;
	}
	&:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: $grid-3;
		background-color: $bg-med;
		z-index: 0;
	}
	&:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 4px;
		background-color: $black-20;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

.mega-main-mod { }



// --------------------------------------------
//
// Mega Menu Side

.mega-side-mod { }

.mega-side-hdr {
	@extend %tz-overline-sm;
	position: relative;
	font-weight: 700;
	text-transform: uppercase;
	color: #000;
	letter-spacing: 1px;
	margin-bottom: 1em;
}

.mega-side-items {
	@extend %side-menu-items;
	line-height: 2em;
}

.mega-side-item {
	@extend %side-menu-item;
	margin-left: 0;
	line-height: 1.3em;
	margin-bottom: 0.5em;
}

.mega-side-link {
	@extend %side-menu-link;
	padding: 0.25em 0 !important;
	&:hover {
		color: $black-90;
	}
}


.mega-section-link {
	display: inline-block;
	float: right;
	margin-top: 1em;
	@include sans(bold);
	font-size: 1.15em;
	color: $green-main;
	text-decoration: none;
	&:hover {
		color: darken($green-main, 5%);
	}
}

.link-arrow {
	line-height: 0;
	font-size: 1.35em;
	margin-left: 4px;
	letter-spacing: 5px;
	font-weight: 400;
}


// --------------------------------------------
//
// Mega Menu Main Content area layout modes


.l-mega-1 { }

.l-mega-2 {
	@include media-grid(2, $space-grid);

	// This removes the bottom border for the last two teases that are inside the .l-mega-2 container.
	.media-block {
		&:nth-last-of-type(-1n + 2) {
			.mega-tz-item {
				border-bottom: 0;
			}
		}
	}
}


.l-mega-3 {
	@include media-grid(3, $space-grid);

	// This removes the bottom border for the last three teases that are inside the .l-mega-3 container.
	.media-block {
		&:nth-last-of-type(-1n + 3) {
			.mega-tz-item {
				border-bottom: 0;
			}
		}
	}
}



// --------------------------------------------
//
// Space for media-block items in Mega Menu

.mega-tz-item {
	// border-bottom: $bdr-thin-lt;
	margin-bottom: 1em;
	width: 100%;
}


// --------------------------------------------
//
// Mega images and icons

.mega-tz-img, %mega-tz-img {
	overflow: hidden;
	display: block;
	position: relative;
	border-radius: 50%;
	img {
		width: 100%; // for ie
		max-width: 100%;
	}
	&.hex-mega {
		border-radius: 0;
		overflow: visible;
	}
}


.hex-mega {
	margin-left: 12px;
}


// Note: Question
// use data or something to change colors?
.mega-icon-link {
	@extend %mega-tz-img;
	width: 45px;
	height: 45px;
	display: block;
	.hex-mega & {
		background-color: transparent;
	}
}



// --------------------------------------------
// --------------------------------------------
//
// Conference Nav
//
// --------------------------------------------
// --------------------------------------------

.hdr-conference {
	.nav-items-mod {
		.nav-item {
			text-align: left;
		}
	}
}

.conference-nav {
	background-color: $black-90;
	color: $bg-dark;
	text-align: center;
	@media(max-width: $nav-bp-med) {
		width: 100%;
	}
}

.conference-nav-items {
	@include pie-clearfix;
	display: inline-block;
	vertical-align: top;
	@media(max-width: $nav-bp-med) {
		width: 100%;
	}
}

.conference-nav-item {
	float: left;
	@media(max-width: $nav-bp-med) {
		width: 50%;
	}
	@media(max-width: $nav-bp-sm) {
		width: 100%;
	}
	&.conference-nav-item--withdrop {
		@media(max-width: $nav-bp-med) {
			width: 100%;
			display: block;
			clear: both;
		}
	}
	&.cta {
		@media(max-width: $nav-bp-med) {
			width: 100%;
		}
	}
 }

.conference-item-link {
	display: block;
	padding: 1em 2em;
	border-right: 1px solid #000;
	color: $bg-dark;
	text-decoration: none;
	cursor: pointer;
	&:hover {
		background-color: #000;
		border-right: 1px solid $black-90;
	}
	.conference-nav-drop-items & {
		border-right: none;
	}
	@media(max-width: $nav-bp-med) {
		border-right: none;
	}
}

.conference-nav-dropdown {
	border: 0;
	outline: 0;
	-webkit-appearance: none;
	background-color: transparent;
	border-right: 1px solid #000;
	@media(max-width: $nav-bp-med) {
		border-right: none;
	}
}

.conference-nav-submit {
	@extend %button;
	text-transform: uppercase;
	padding: 1em 2em;
	margin: 0;
	display: block;
	float: left;
	line-height: inherit;
	@media(max-width: $nav-bp-med) {
		width: 100%;
	}
}

.conference-nav-drop-items {
	padding: 1em 2em;
	@include pie-clearfix;
	background-color: $black-80;
	position: absolute;
	left: 0;
	right: 0;
	display: none;
	z-index: 1;
	.conference-nav-item--withdrop.is-active & {
		display: block;
	}
	@media(max-width: $nav-bp-med) {
		position: relative;
	}
}

.conference-nav-anchor {
	display:block;
	position:relative;
	top:-5em;
	visibility: hidden;
}

// :target:before {
// 	content: "";
// 	display: block;
// 	height: 75px;
// 	margin: -75px 0 0;
// }

.drop-show-more {
	display: block;
	cursor: pointer;
	.conference-nav-item--withdrop.is-active & {
		display: none;
	}
}

.drop-show-less {
	display: none;
	cursor: pointer;
	.conference-nav-item--withdrop.is-active & {
		display: block;
	}
}

.md {
	.mega-modal {
		.row {
				margin-right: 0;
		}
	}
}


// --------------------------------------------
// --------------------------------------------
//
// Side nav :: layout-default
//
// --------------------------------------------
// --------------------------------------------

.side-menu-items, %side-menu-items {
	@extend %list-items;
	font-size: 1.07692307692308em;		// 14
	line-height: 2.357142857em;
	list-style: none;
	margin-left: 0;
	padding-left: 0;
}


.side-nav-lg {
	font-size: 1.38461538461538em; 		// 18
	line-height: 2.111111111em; 		// 38
}


.side-nav-med {
	font-size: 1.23076923076923em; 		// 16
	line-height: 2.375em;				// 38
}


.side-nav-reg {
	font-size: 1.07692307692308em;		// 14
	line-height: 2.357142857em;
}


.side-menu-item, %side-menu-item {
	@extend %list-item;
	font-weight: 400;
	line-height: 1.6em;
	&:hover {
		& + .is-sublink {
			&:after {
				border-top: 9px solid $bg-med;
			}
		}
	}

	&.is-active {
		// font-weight: $sans-weight-bold;
		background-color: $bg-dark !important;
		.side-menu-link {
			color: $black-100;
			&:hover {
				background-color: $bg-dark;
			}
		}
		& + .is-sublink {
			&:after {
				border-top: 9px solid $bg-dark;
			}
		}
	}
	&.is-sublink {
		background-color: $bg-med;
		// padding-left: 0.85em;
		&:after {
			content: '\0020';
			width: 0;
			height: 0;
			position: absolute;
			top: -3px;
			left: 44%;
			z-index: 4;
			border-top: 9px solid $bg-lt;
			border-right: 9px inset transparent;
			border-bottom: 9px inset transparent;
			border-left: 9px inset transparent;
		}
		& + & {
			&:after {
				display: none;
			}
		}
		.side-menu-link {
			padding-left: 0.85em;
			padding-right: 0;
			&:before {
				font-family: 'FontAwesome';
				content: '\f0da';
				margin-right: 0.5em;
				// position: absolute;
				// left: 7px;
				// top: 10px;
			}
		}
	}
	.side-menu-no-link {
		color: #808285;
		padding: 0.75em 7px;
		display: block;
	}
}


.side-menu-link, %side-menu-link {
	@include link(pagenav);
	// padding: 0.25em 7px;
	padding: 0.75em 7px;
	display: block;
}

.results-count {
	margin-top: 0;
	margin-bottom: 2em;
}

.ftc-case-save {
	position: relative;
	left: 0;
	margin-top: -2.5em;
}

.casebook {
	.index {
		display: table;
		margin: 1em auto;
		li {
			@extend %button-secondary;
			list-style-type: none;
			float: left;
			margin-left: 0;
			a {
				color: #fff;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}

.supporting-list {
	.docs-header {
		@extend %h4;
		display: none;
		text-align: center;
		padding-bottom: 0.5em;
		border-bottom: 3px solid $black-10;
		@media (min-width: $large) {
			display: block;
		}
	}
	.button-lg {
		width: 100%;
		text-transform: uppercase;
		i {
			margin-right: 0.5em;
		}
	}
}

.breadcrumbs {
	margin-bottom: 2em;
	margin-top: -2em;
	@media (max-width: $medium) {
		display: none;
	}
	.bread {
		color: #222;
		font-size: 1em;
		margin-right: 0.5em;
		text-decoration: none;
		&:after {
			font-family: FontAwesome;
			content: "\f105";
			margin-left: 0.7em;
		}
		&:last-child {
			&:after {
				display: none;
			}
		}
	}
	a .crumb {
		color: $green-main;
		&:hover {
			text-decoration: underline;
		}
	}
}

.tools-sidebar {
	margin-top: 2em;
}

// Disable hover state on cart sidebar

.cart-sidebar {
	.side-menu-item {
		.side-menu-link {
			&:hover {
				background-color: transparent;
			}
		}
	}
}

.sidebar-sub-callout {
	.page-text-sm {
		margin-top: 0;
	}
}

/* 
	added for headroom.js in addtion to changes to 
	/scss/base/_nav.css on the element .inner-nav
	which changed postion: fixed to position: absolute
*/
.headroom {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: all .5s ease-in-out;
}
.headroom--unpinned {top: -105px;}
.headroom--pinned {top: 0px;}

