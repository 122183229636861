// Table in collapsible body, used on DPA Map page
.collapsible {
	.collapsible-body {
		padding: 0 1rem;
		table {
			p {
				padding: 0;
			}
		}
	}
}