// --------------------------------------------
// --------------------------------------------
//
// Links
//
// --------------------------------------------
// --------------------------------------------

@mixin link($style: normal) {
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}

	@if $style == normal { color: $link-color; }

	@if $style == linkbold { color: lighten($link-color, 3%); }

	@if $style == head-lt { color: $head-color-lt; }

	@if $style == tag { color: $black-30; }

	@if $style == green { color: $green-main; }

	@if $style == accent { color: $green-main; }

	@if $style == white { color: #fff; }


	@if $style == head {
		color: $head-color;
		&:hover {
			color: #000;
		}
	}

	@if $style == pagenav {
		color: $black-30;
		// text-decoration: underline;
		&:hover {
			color: $black-40;
			background-color: $bg-med;
			text-decoration: none;
		}
	}

	@if $style == articlelink {
		// color: $article-text;
		color: $green-main;
		// text-decoration: underline;
		&:hover {
			// color: #000;
			text-decoration: underline;
		}
		em {
			// text-decoration: underline;
		}
	}

}

.infographSm {
	display: none;
}

@media screen and (max-width: 768px) {
	.infographLg {
		display: none;
	}	

	.infographSm {
		visibility: visible;
		display: block;
	}

	.infographImg {
		float: none !important; 
		width: auto !important;
	}
}