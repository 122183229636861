.input-field {
	label.active {
		transform: translateY(-100%);
	}
}

.input-field {
	label {
		color: $off-black;
	}
	input,
	.materialize-textarea {
		&:focus {
			border-bottom: 1px solid map-get($cyan, "darken-1") !important;
			box-shadow: 0 1px 0 0 map-get($cyan, "darken-1") !important;
			& + label {
				color: map-get($cyan, "darken-1") !important;
			}
		}
		&.valid {
			border-bottom: 1px solid map-get($green, "base") !important;
			box-shadow: 0 1px 0 0 map-get($green, "base") !important;
		}
		&.invalid {
			border-bottom: 1px solid map-get($red, "base") !important;
			box-shadow: 0 1px 0 0 map-get($red, "base") !important;
		}
	}
	.prefix.active {
		color: map-get($cyan, "darken-1") !important;
	}
}

.reset-table-field {
	margin-top: 0.5rem;
	button {
		width: 100%;
		background-color: map-get($grey, "lighten-3");
		.material-icons {
			transform: translateY(-1px);
		}
	}
}

.switch {
	label {
		input[type="checkbox"] {
			&:checked {
				& + .lever {
					@extend .green;
					&:after {
						background-color: #fff;
					}
				}
			}
		}
	}
}

.prefix {
  &.not-icon {
    font-size: 1rem;
    bottom: 46%;
    @media #{$small-and-down} {
      bottom: 38%;
    }
  }
}

.marketo {
	select {
		display: inline-block;
	}

}

// edits to member directory filter form
#directory-filters {
	background-color: #F5F5F5; 
	border: 1px solid #E4E4E4;
	padding: 0 1em; 
	margin-top: 1em;
	.heading {
		display:flex; 
		justify-content: space-between;
		align-items:center;
		cursor: pointer;
		#filterButtons {
			display:flex; 
			justify-content: space-evenly; 
			align-items:center;
		}
	}
	.filter-body {
		.label-or {
			// this is a label override
			position: relative; 
			top: initial; 
			left: initial; 
			font-size: .8rem; 
			color: #9e9e9e;
		}
	}
	.count {
		background-color: #34a69a;
		color: #fff;
		border-radius: 50%;
		width: 16px;
		height: 16px;
		font-size: 10px;
		line-height: 16px;
		display: inline-block;
		margin-left: 0px;
		top: -5px;
		position: relative;
		text-align: center;
	}
}
.tab-bottom-border {
	cursor: pointer;
	padding: 9px;
	z-index: 10;
	position: relative;
	font-size: 18px;
}

@import 'input-fields';
@import 'radio-buttons';
@import 'checkboxes';
@import 'switches';
@import 'select';
@import 'file-input';
@import 'range';