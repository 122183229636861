.chip {
	a {
		display: inline-block;
	}
	&.bio-chip {
		@extend .grey, .lighten-3;
		height: 64px;
		line-height: 1.3rem;
		padding: 0 24px 0 12px;
		border-radius: 32px;
		font-size: 1.3rem;
		overflow: hidden;
		img {
			height: 64px;
			width: 64px;
		}
		a {
			color: inherit;
			display: block;
			transition: .25s all ease;
			@media #{$small-and-down} {
				font-size: 0.8rem;
			}
			&:hover {
				@extend .cyan-text, .text-darken-1;
			}
			span {
				display: inline-block;
				padding: 0.85rem 0;
				.contributor-type {
					@extend .grey-text, .darken-1;
					display: block;
					padding: 0;
					font-size: 0.8rem;
				}
			}
		}
		@media #{$small-and-down} {
			height: 48px;
			font-size: 1rem;
			img {
				height: 48px;
				width: 48px;
			}
			a {
				span {
					padding: 0.3rem 0;
				}
			}
		}
	}
	&.opaque-chip {
		opacity: 0.7;
	}
	i {
		&.left {
			margin-right: 7px;
		}
	}
}

.article-tags-mod {
	.chip {
		transition: .25s all ease;
		a {
			color: map-get($grey, "darken-1");
			display: block;
			transition: .25s all ease;
			&:hover {
				color: #fff;
			}
		}
		&:hover {
			@extend .cyan, .darken-1;
			a {
				color: #fff;
			}
		}
	}
}