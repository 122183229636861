@charset "UTF-8";

// --------------------------------------------
// --------------------------------------------
//
// Font Awesome
//
// --------------------------------------------
// --------------------------------------------

$fa-font-path: "/wp-content/themes/iapp/node_modules/font-awesome/fonts";

@import "../../../node_modules/font-awesome/scss/font-awesome.scss";

// --------------------------------------------
// --------------------------------------------
//
// Variables
//
// --------------------------------------------
// --------------------------------------------

@import "variables/mixins";
@import "variables/layout-var";
@import "variables/color-var";
@import "variables/border-var";
@import "variables/breakpoint-var";
@import "variables/type-var";
@import "variables/img-var";
@import "variables/list-var";

// --------------------------------------------
// --------------------------------------------
//
// Base
//
// --------------------------------------------
// --------------------------------------------

@import "base/links";
@import "base/type";
@import "base/lists";
@import "base/buttons";
@import "base/objects";
@import "base/layout";
@import "base/icons";
@import "base/search";
@import "base/nav";
@import "base/header";
@import "base/footer";
@import "base/teases";
@import "base/hp-hero";
@import "base/events";
@import "base/forms";
@import "base/person";
@import "base/article";
@import "base/calendar";
@import "base/profile";
@import "base/comments";
@import "base/notify";
@import "base/tabs";
@import "base/bundles";
@import "base/ads";
@import "base/member_search";
@import "base/gallery";
@import "base/tables";
@import "base/static_page";
@import "base/leadgen";
@import "base/maps";
@import "base/styleguide";
@import "base/print";
@import "base/animate";
@import "base/youtube";
@import "base/store";

// Import Material Design
///////////////////////////////////////////

body {
	@media #{$large-and-up} {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		height: 100%;
	}
}

#content {
	flex: 1 0 auto;
	position: relative;
	overflow: hidden;
}

.md { // WRAPPER CLASS FOR MATERIAL DESIGN
	width: 100%;
	line-height: 1.5;

	  @media only screen and (min-width: 0) {
	    font-size: 14px;
	  }

	  @media only screen and (min-width: 992px) {
	    font-size: 14.5px;
	  }

	  @media only screen and (min-width: 1200px) {
	    font-size: 15px;
	  }

	@import "material/materialize";
}

