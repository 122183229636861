

.card-panel {
  transition: box-shadow .25s;
  padding: $card-padding;
  margin: $element-top-margin 0 $element-bottom-margin 0;
  border-radius: 2px;
  @extend .z-depth-1;
  background-color: $card-bg-color;
}

.card {
  position: relative;
  margin: $element-top-margin 0 $element-bottom-margin 0;
  background-color: $card-bg-color;
  transition: box-shadow .25s;
  border-radius: 2px;
  @extend .z-depth-1;

  .card-title {
    font-size: 1.1rem;
    line-height: inherit;
    @media #{$small-and-down} {
      display: block;
      line-height: 1.1rem;
      font-size: 1rem;
      padding: 0 0 15px 15px;
    }
    &.activator {
      cursor: pointer;
    }
  }

  // Card Sizes
  &.small, &.medium, &.large {
    position: relative;

    .card-image {
      max-height: 60%;
      overflow: hidden;
    }
    .card-image + .card-content {
      max-height: 40%;
    }
    .card-content {
      max-height: 100%;
      overflow: hidden;
    }
    .card-action {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &.small {
    height: 300px;
  }

  &.medium {
    height: 400px;
  }

  &.large {
    height: 500px;
  }

  // Horizontal Cards
  &.horizontal {
    &.small, &.medium, &.large {
      .card-image {
        height: 100%;
        max-height: none;
        overflow: visible;

        img {
          height: 100%;
        }
      }
    }

    display: flex;

    .card-image {
      max-width: 50%;
      &.card-language {
        max-width: 100%;
        .card-title {
          font-size: 2rem;
          text-shadow: 0px 1px 2px #000000;
        }
      }
      img {
        border-radius: 2px 0 0 2px;
        max-width: 100%;
        width: auto;
      }
    }

    .card-stacked {
      display: flex;
      flex-direction: column;
      flex: 1;
      position: relative;

      .card-content {
        flex-grow: 1;
      }
    }
  }

  // Sticky Action Section
  &.sticky-action {
    .card-action {
      z-index: 2;
    }

    .card-reveal {
      z-index: 1;
      padding-bottom: 64px;
    }
  }




  .card-image {
    position: relative;

    // Image background for content
    img {
      display: block;
      border-radius: 2px 2px 0 0;
      position: relative;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
    }

    .card-title {
      color: $card-bg-color;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: $card-padding;
    }

  }

  .card-content {
    padding: $card-padding;
    border-radius: 0 0 2px 2px;
    &.card-language {        
        p {
          margin: 10px;
        }
      }
    &.card-language-special {
      p {
        font-size: 1.05rem;
        margin: 10px;
      }
    }
    p {
      margin: 0;
      color: inherit;
    }
    .card-title {
      line-height: 48px;
    }
  }

  .card-action {
    position: relative;
    background-color: inherit;
    border-top: 1px solid rgba(160,160,160,.2);
    padding: $card-padding;

    a:not(.btn):not(.btn-large):not(.btn-floating) {
      color: $card-link-color;
      margin-right: $card-padding;
      transition: color .3s ease;
      text-transform: uppercase;

      &:hover { color: $card-link-color-light; }
    }
  }

  .card-reveal {
    padding: $card-padding;
    position: absolute;
    background-color: $card-bg-color;
    width: 100%;
    overflow-y: auto;
    left: 0;
    top: 100%;
    height: 100%;
    z-index: 3;
    display: none;

    .card-title {
      cursor: pointer;
      display: block;
    }
  }
}

.card-alt {
  position: relative;
  height: 475px;
  margin: $element-top-margin 0 $element-bottom-margin 0;
  background-color: #3a3a3c;
  transition: box-shadow .25s;
  border-radius: 2px;
  @extend .z-depth-1;

  .card-image {
    position: relative;

    // Image background for content
    img {
      display: block;
      border-radius: 2px 2px 0 0;
      position: relative;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
    }

    .card-title {
      color: $card-bg-color;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: $card-padding;
    }

  }

  .card-content {
    padding: $card-padding;
    border-radius: 0 0 2px 2px;
    background-color: #3a3a3c;
    height: 45% !important;
    &.card-language {        
        p {
          margin: 10px;
        }
      }
    &.card-language-special {
      p {
        font-size: 1.05rem;
        margin: 10px;
      }
    }

    p {
      margin: 0;
      color: #ffffff; 
      min-height: 60%;
    }
    .card-title {
      line-height: 48px;
    }
  }

  .card-action {
    position: relative;
    background-color: inherit;
    border-top: 1px solid rgba(160,160,160,.2);
    height: 40%;
    padding-top: 30px;
    
    a:not(.btn):not(.btn-large):not(.btn-floating) {
      color: $card-link-color;
      margin-right: $card-padding;
      transition: color .3s ease;
      text-transform: uppercase;

      &:hover { color: $card-link-color-light; }
    }
  }

  // Handle below 360px screen
  @media only screen and (max-width: 425px) {
    height: auto;
  }

}