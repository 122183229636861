// --------------------------------------------
// --------------------------------------------
//
// Font mixins
//
// --------------------------------------------
// --------------------------------------------

@mixin sans($font-weight: regular) {
	font-family: $sans;
	@if $font-weight == regular {
		font-weight: $sans-weight-reg;
	}

	@if $font-weight == bold {
		font-weight: $sans-weight-bold;
	}

	@if $font-weight == light {
		font-weight: $sans-weight-light;
	}
}


@mixin serif($font-weight: regular) {
	font-family: $serif;
	@if $font-weight == regular {
		font-weight: $serif-weight-reg;
	}

	@if $font-weight == bold {
		font-weight: $serif-weight-bold;
	}
}




// --------------------------------------------
// --------------------------------------------
//
// Body
//
// --------------------------------------------
// --------------------------------------------
//
body { 
	@include sans(regular); 
	-webkit-font-smoothing: auto;
}




// --------------------------------------------
// --------------------------------------------
//
// Headlines
//
// --------------------------------------------
// --------------------------------------------

%headline {
	@include sans(bold);
	color: $head-color;
	line-height: 1.35em;
	.head-link {
		@include link(head);
	}
}


.h1, %h1 {
	@extend %headline;
	font-size: $base-lg;
	margin-bottom: 1em;
	@media(min-width: $type-bp-med) {
		font-size: 2.46153846153846em; 		// 32
		letter-spacing: -0.025em;
	}
}


.h2, %h2 {
	@extend %headline;
	font-size: 1.92307692307692em; 		// 25
}


.h3, %h3 {
	@extend %headline;
	font-size: 1.53846153846154em; 		// 20
}


.h4, %h4 {
	@extend %headline;
	font-size: 1.30769230769231em; 		// 17
}


.h5, %h5 {
	@extend %headline;
	font-size: 1.07692307692308em; 		// 14
}


.h6, %h6 {
	@extend %headline;
	font-size: 0.92307692307692em; 		// 12
}


// --------------------------------------------
// Article headlines

.article-h1, %article-h1 {
	@extend %h1;
	@media(min-width: $medium) {
		font-size: 4.46153846153846em;
		letter-spacing: -0.025em;
	}
}


// --------------------------------------------
// Tease headlines

.tz-head-xlg, %tz-head-xlg {
	@extend %headline;
	font-size: 3.23076923076923em;  	// 42
	line-height: 1.19047619047619em;
	margin-top: 0;
}


.tz-head-lg, %tz-head-lg {
	@extend %headline;
	font-size: 2.23076923076923em; 		// 29
	line-height: 1.3448275862069em;
	margin-bottom: 0;
	margin-top: 0;
}


.tz-head-med, %tz-head-med {
	@extend %headline;
	font-size: 1.53846153846154em; 		// 20
	line-height: 1.3448275862069em;
	margin-bottom: 0;
	margin-top: 0;
}


.tz-head-sm, %tz-head-sm {
	@extend %headline;
	font-size: 1.30769230769231em; 		// 17
	line-height: 1.3448275862069em;
	// margin-bottom: 0.45em;
	margin-bottom: 0;
	margin-top: 0;
}


.tz-head-thin {
	@include sans(light);
	letter-spacing: -0.025em;
	color: $black-30;
	margin-right: 1px;
}


.head-regular-weight, %head-regular-weight { @include sans; }
.head-light-weight, %head-light-weight { @include sans(light); }


// --------------------------------------------
// --------------------------------------------
//
// Section Headers
//
// --------------------------------------------
// --------------------------------------------

.section-hdr-lg, %section-hdr-lg {
	font-size: 2.23076923076923em; 		// 29
	margin-bottom: 1.5em;
	margin-top: 0;
	color: $black-90;
	border-bottom: $section-bdr;
	text-align: center;
	padding-bottom: 0.5em;
	@include sans(bold);
}

.section-hdr-med {
	font-size: 1.76923076923077em; 		// 23
	border-bottom: $section-bdr-thin;
	margin-bottom: 1.5em;
	margin-top: 0;
	padding-bottom: 0.5em;
	color: $black-40;
	@include sans(light);
}


.section-hdr-sm {
	font-size: 1.23076923076923em; 		// 16
	border-bottom: $section-bdr-thin;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 1.5em;
	margin-top: 0;
	padding-bottom: 0.5em;
	letter-spacing: 0.15em;
	color: $black-30;
	@include sans(bold);
}

.section-hdr-accordion {
	font-size: 1.23076923076923em; 		// 16
	text-transform: uppercase;
	text-align: center;
	padding: 0.5em;
	letter-spacing: 0.15em;
	color: #ffffff;
	background-color: #4e81bd;
	@include sans(bold);
}

.sub-section-hdr-accordion {
	font-size: 0.85em; 		// 16
	text-transform: uppercase;
	text-align: center;
	padding: 0.25em;
	letter-spacing: 0.15em;
	color: $black-30;
	background-color: #dae5f0;
	@include sans(bold);
}

.section-hdr-lg--gray {
	@extend %section-hdr-lg;
	border-bottom: none;
	color: $black-30;
}




%sub-hdr {
	font-size: 1.07692307692308em; 		// 14
	color: $link-color;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	margin-bottom: 1.5em;
	@include sans(bold);
}

.subsection-hdr, %subsection-hdr {
	@extend %sub-hdr;
	@include sans(light);

}

.subsection-hdr-bold, %subsection-hdr-bold {
	@extend %sub-hdr;

}

.subsection-hdr-underline {
	@extend %sub-hdr;
	border-bottom: $section-bdr-thin;
	padding-bottom: 1em;
}

.subsection-hdr-centered, %subsection-hdr-centered {
	@extend %sub-hdr;
	text-align: center;
	margin: 4em 0;
}

.subsection-hdr-gray, %subsection-hdr-gray {
	@extend %subsection-hdr;
	color: $black-30;
}

.subsection-hdr-centered-gray {
	@extend %subsection-hdr-centered;
	color: $black-30;
}

.hdr-strong, %hdr-strong {
	color: $black-40;
	@include sans(bold);
}




// --------------------------------------------
// --------------------------------------------
//
// Tease Text
//
// --------------------------------------------
// --------------------------------------------

%tease-text {
	@include sans(regular);
	color: $tz-text-color;
	line-height: 1.35em;
	margin-bottom: 1em;
	a {
		color: #00acc1;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}


.tz-text-lg, %tz-text-lg {
	@extend %tease-text;
	font-size: $base-med;
	@media(min-width: $type-bp-med) {
		font-size: 1.69230769230769em;  	// 22
	}
}


.tz-text-med, %tz-text-med {
	@extend %tease-text;
	font-size: 1.30769230769231em;
}

.tz-text-reg, %tz-text-reg {
	@extend %tease-text;
	font-size: 1.07692307692308em; 		// 14
	line-height: 1.5625em;
}


.tz-text-sm, %tz-text-sm {
	@extend %tease-text;
	font-size: 0.92307692307692em; 		// 12
	color: $tz-text-color-lt;
	line-height: 2em;
}


.tz-text-promo, %tz-text-promo {
	@extend %tease-text;
	font-size: 1.07692307692308em; 		// 14
	line-height: 1.78571428571429em;
}


.tz-text-promo-med, %tz-text-promo-med {
	@extend %tease-text;
	font-size: 1.23076923076923em;
	line-height: 1.5625em;
}

.tz-text-promo-lg, %tz-text-promo-lg {
	@extend %tz-text-lg;
	// 22/37
	line-height: 1.681818182em;
	@include sans(light);
}

.tz-text-aside, %tz-text-aside {
	@extend %tease-text;
	// 13/20
	font-size: 1em;
	line-height: 1.538461538em; 	// 20px
	margin-bottom: 0;
	color: $tz-text-color-lt;
}




// --------------------------------------------
// Page Type

.page-head-lg, %page-head-lg {
	@extend %h1;
	color: $black-80;
	margin-bottom: 0.55em;
	margin-top: 0;
}

.page-head-med, %page-head-med {
	@extend %h3;
	margin-bottom: 0.55em;
	margin-top: 0;
}

.page-head-med-fancy, %page-head-med-fancy {
	@extend %page-head-med;
	color: $link-color;
	margin-top: 0;
}

.page-head-sm {
	@extend %h4;
	margin-bottom: 0.55em;
	margin-top: 0;
}

.casebook-detail {
	.page-head-lg {
		margin-bottom: 2em;
	}
}


.page-text-lg, %page-text-lg {
	@extend %tz-text-promo-lg;
	font-size: 1.69230769230769em;
	font-weight: $sans-weight-light;
	> p { margin-bottom: 1em; }
}

.page-text-med, %page-text-med {
	@extend %tease-text;
	// 18/28
	font-size: 1.384615385em;
	line-height: 1.555555556em;
	font-weight: $sans-weight-light;
}

.page-text-med-fancy {
	@extend %page-text-med;
	color: $link-color;
}

.page-text-sm, %page-text-sm {
	@extend %tease-text;
	// 14/22
	font-size: 1.076923077em; 		// 14
	line-height: 1.571428571em;
}


.page-text-tiny {
	@extend %page-text-sm;
	line-height: 1.15em;
}




.page-text-thin, %page-text-thin { font-weight: $sans-weight-light; }

.page-text-thin-fancy {
	@extend %page-text-thin;
	color: $link-color;
}

.page-item-right {
	position: absolute;
	right: 0;
	top: 0;
	color: $link-color;
}

.page-head-split {
	@extend %page-head-med;
	position: relative;
	padding-right: 2em;
	margin-bottom: 1em;
	&.no-margin {
		margin-bottom: 0;
	}
}

.mug-head {
	@extend %h5;
	margin-bottom: 0.15em;
}

.mug-text, %mug-text {
	@extend %tease-text;
	font-size: 1em;
	line-height: 1.25em;
}

.mug-list {
	text-align: left;
	li {
		margin-bottom: 0.55em;
	}
}

.mug-link {
	text-align: left;
}

.mug-subhd-lft {
	@extend %mug-text;
	text-align: left;
	margin-top: 3em;
	font-weight: bold;
}

.head-item-split {
	padding-right: 65px;
	position: relative;
}


// --------------------------------------------
// --------------------------------------------
//
// Article Text
//
// --------------------------------------------
// --------------------------------------------


%article-text {
	@include serif;
	line-height: 1.7em;
	margin-bottom: 1em;
	color: $article-text;
}


.tz-article-lg {
	@extend %article-text;
	font-size: 1.53846153846154em;
	color: $black-50;
}


.article-text {
	@extend %article-text;
	font-size: 1.38461538461538em;
	line-height: 1.88888888888889em;
}


// this will probably change to a first child:firstletter type of selector
.article-text--firstgraph {
	@extend %article-text;
	font-size: 1.76923076923077em;
	line-height: 1.65217391304348em;
	&:first-letter {
		font-weight: 700;
		font-size: (1.76923076923077 * 2) * 1em;
		color: $black-100;
		float: left;
		padding-top: 0.25em;
		margin-right: 0.15em;
	}
}




// --------------------------------------------
// --------------------------------------------
//
// Overlines
//
// --------------------------------------------
// --------------------------------------------

%tz-overline {
	color: $link-color;
	display: block;
	margin-bottom: 0.25em;
	@include sans(regular);
}

.tz-overline-sm, %tz-overline-sm {
	@extend %tz-overline;
	font-size: 0.769230769em;  // 10px
	font-size: 0.846153846em;  // 11px
}




// --------------------------------------------
// --------------------------------------------
//
// Link includes
//
// --------------------------------------------
// --------------------------------------------

.tz-head-link { @include link(head); }

.tz-overline-sm {
	text-transform: uppercase;
	@include link;
}

.tz-related-head-link { @include link(head-lt); }

.tz-related-link { @include link(green); }

.default-link { @include link(green); }

.default-link--underline {
	@include link(green);
	text-decoration: underline;
}

.alternate-link { @include link; }

.alternate-link--underline, %alternate-link--underline {
	@include link;
	text-decoration: underline;
}

.link--bold {
	font-weight: 700;
}


.page-bold-link {
	font-weight: 700;
	display: inline-block;
	max-width: 80%;
	@include link;
	@media(min-width: $medium){
		max-width: 90%;
	}
}

.show-full {
	@extend %hdr-strong;
	text-align: center;
	margin: 4em 0;
	text-transform: uppercase;
	cursor: pointer;
	position: relative;
	&:after {
		content: '\0020';
		width: 0;
		height: 0;
		position: absolute;
		left: 50%;
		margin-left: -9px;
		margin-top: 20px;
		z-index: 4;
		border-top: 9px solid $black-40;
		border-right: 9px inset transparent;
		border-bottom: 9px inset transparent;
		border-left: 9px inset transparent;
	}
	&.active {
		&:before {
			content: '\0020';
			width: 0;
			height: 0;
			position: absolute;
			left: 50%;
			margin-left: -9px;
			margin-top: -25px;
			z-index: 4;
			border-top: 9px inset transparent;
			border-right: 9px inset transparent;
			border-bottom: 9px solid $black-40;
			border-left: 9px inset transparent;
		}
		&:after {
			border: 0;
		}
	}
	a {
		color: $black-40;
		text-decoration: none;
	}
}

.see-full-page {
	@extend %hdr-strong;
	text-align: center;
	margin: 4em 0;
	text-transform: uppercase;
	cursor: pointer;
	&:after {
		content: '\0020';
		width: 0;
		height: 0;
		position: absolute;
		z-index: 4;
		margin-left: 5px;
		margin-top: 1px;
		border-top: 6px inset transparent;
		border-right: 6px inset transparent;
		border-bottom: 6px inset transparent;
		border-left: 6px solid $black-40;
	}
	a {
		color: $black-40;
		text-decoration: none;
	}
}


// --------------------------------------------
// --------------------------------------------
//
// Hp tops
//
// --------------------------------------------
// --------------------------------------------

%hp-hero-type {
	color: #fff;
	text-align: center;
}


.hp-hero-head-sm {
	@extend %tz-head-sm;
	@extend %hp-hero-type;
	margin-bottom: 1em;
	font-size: 1.8em;
	font-weight: 400;
}

.hp-hero-head-med {
	@extend %tz-head-med;
	@extend %hp-hero-type;
	line-height: 1.181818182em; 	// 26/22
	font-size: 1.8em;
	letter-spacing: 0;
	margin-bottom: 0.5em;
	@media(min-width: $type-bp-med) {
		font-size: 2.5em; 
	}
}

.hp-hero-head-lg {
	@extend %article-h1;
	@extend %hp-hero-type;
	margin-bottom: 0.55em;
	letter-spacing: 0;
	text-align: center;
}

.hp-hero-text, %hp-hero-text {
	@extend %tz-text-med;
	@extend %hp-hero-type;
	@include sans(light);
	line-height: 1.5em;
	font-size: 1.2em;
	@media(min-width: $type-bp-med) {
		font-size: 1.36em;
	}
	// > p { display: inline-block; }
}

.hp-hero-subhead {
	@extend %hp-hero-text;
	font-weight: 400;
	font-size: 2em;
}

.hp-hero-tease-text {
	@extend %tz-text-med;
	@extend %hp-hero-type;
	@include sans(light);
	line-height: 1.5em;
}

.hp-hero-link {
	@include link(white);
	font-size: 0.85em;
}

.hp-hero-links {
	text-align: center;
	margin-top: 3em;
	a.button-lg,
	a.default {
		position: relative;
		margin-left: 0;
		background-color: transparent !important;
		border: 2px solid #fff;
		@media(min-width: $medium) {
			margin-left: 1em;
			&:first-child {
				margin-left: 0;
			}
		}
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #fff;
			opacity: 0;
			transition: opacity 200ms ease;
		}
		&:hover {
			&:before {
				opacity: 0.25;
			}
		}
	}
	a.green {
		@extend %button-lg;
		background-color: $green-main !important;
		border: none !important;
		text-shadow: none !important;
		transition: background-color 200ms ease;
		&:before {
			display: none;
		}
		&:hover {
			background-color: darken($green-main, 5%) !important;
		}
	}
}

.tz-promo {
	.txt-bd {
		.tz-text-block {
			p {
				@extend %page-text-sm;
			}
			a,
			ul li a,
			ol li a {
				@extend %alternate-link--underline;
			}
			ul {
				@extend %page-text-sm;
				@extend %bulleted;
				ul {
					@extend %page-text-sm;
					@extend %bulleted;
				}
				ol {
					@extend %page-text-sm;
					@extend %numbered;
				}
			}
			ol {
				@extend %page-text-sm;
				@extend %numbered;
				ul {
					@extend %page-text-sm;
					@extend %bulleted;
				}
				ol {
					@extend %page-text-sm;
					@extend %numbered;
				}
			}
		}
	}
}



// --------------------------------------------
// --------------------------------------------
//
// Hp tops
//
// --------------------------------------------
// --------------------------------------------

.text-bold {
	margin-right: 5px;
	@include sans(bold);
}




// --------------------------------------------
// --------------------------------------------
//
// Pricing
//
// --------------------------------------------
// --------------------------------------------

.with-pricing {
	.page-item-right {
		font-size: 20px;
		position: relative;
		@media(min-width: $medium) {
			position: absolute;
		}
	}
	.page-head-split {
		.page-bold-link {
			max-width: 100%;
			@media(min-width: $medium) {
				max-width: 80%;
			}
		}
	}
}
.pricing {
	@extend %page-text-sm;
	position: absolute;
	width: 100%;
	text-align: center;
	.session-pricing & {
		display: none;
	}
}
.split-pricing {
	display: inline-block;
	font-size: 1em;
	position: relative;
	top: 0;
	right: 0;
	min-width: 160px;
	margin-bottom: 2em;
	.session-pricing & {
		font-size: 0.66em;
	}
	@media(min-width: $medium) {
		position: absolute;
		text-align: right;
		margin-bottom: 0;
	}
}
.m-pricing, .nm-pricing {
	display: inline-block;
	color: $link-color;
	.first-price & {
		font-size: 0.66em;
	}
}
.nm-pricing {
	border-right: 1px solid #ccc;
	padding-right: 0.5em;
	margin-right: 0.5em;
	text-align: right;
}
.m-pricing {
	text-align: left;
}
.price {
	font-size: 1.3em;
}
.nonmember {
	color: $black-30;
}
.nonmember-price {
	text-decoration: line-through;
	color: $black-30;
}
.member-price {
	color: $black-10;
}
.price-login {
	color: $black-30;
}
.session-pricing {
	padding-top: 0;
	.iapp-product-short-desc {
		.iapp-product-header {
			font-size: 0.8em;
			font-weight: normal;
		}
	}
}

// Override any pasted text styles from MS Word in bios

.person-content-block .txt-bd span, 
.person-content-block .txt-bd .MsoNormal span, 
font {
	font-size: 17px !important;
	line-height: 1.35em !important;
	font-family: inherit !important;
	color: inherit !important;
}
.MsoNormal {
	margin-bottom: 17px;
}
