// --------------------------------------------
// --------------------------------------------
//
// Navigation Tile Placeholders
//
// --------------------------------------------
// --------------------------------------------

.myiapp-wrapper {
	display: flex;
	justify-content: center;
}


.myiapp-tile-container {
  padding-left: 40px;
  padding-right: 40px;
  width:768px;
  height:268px;
  background-color:#fff;
  display:grid;
  grid-template-columns: repeat(2, minmax(240px, 320px));
  grid-auto-rows: 126px;
  grid-column-gap: 50px;
  grid-row-gap: 16px;

  @media (max-width: 768px) {
	padding-left: unset;
	padding-right: unset;
	width: 320px;
	height: 576px; // allow for 126 px height with 24px gap between vertical tiles
	grid-template-columns: minmax(240px, 320px);
	grid-row-gap: 24px;
  }

  .myiapp-tile{
    border-radius:2px;
    color:#fff;
    font-family:sans-serif;
	text-align: center;
	max-height: 128px;
	max-width: 320px;
	text-decoration: none;
	box-shadow: 2px 2px 4px 0px rgba(30, 30, 30, 0.25);

	&:hover {
		box-shadow: none;
	}

	.myiapp-tile-img-frame {
		display: flex;
		margin: 0 auto;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		max-height: 80px;
		width: 100%;
		height: 80px;
	}

	.myiapp-tile-img-frame img {
		flex-shrink: 1;
    	min-width: 100%;
    	min-height: 100%;
		object-fit: cover;
		// border-left: 2px solid #000000;
    	// border-right: 2px solid #000000;
	}

	.myiapp-tile-label { 
		display: flex;
		justify-content: center;
		align-items: center;
		height: 46px;
		width: 100%;
		background-color: #000000;
		border-bottom-left-radius: 2px;
		border-bottom-right-radius: 2px;
		// border-left: 2px solid #000000;
    	// border-right: 2px solid #000000;

		.myiapp-tile-icon {
			margin-right: 8px;
			font-size: 30px;
		}

		.myiapp-tile-title {
			font-size: 20px;
			font-family: Lato;
			font-weight: 700;
		}
	}
  }
}

// --------------------------------------------
// --------------------------------------------
//
// Profile xxx
//
// --------------------------------------------
// --------------------------------------------

.profile-hdr-bold {
	@extend %subsection-hdr-bold;
	color: $black-90;
	margin-bottom: 0.55em;
	padding-bottom: 0.55em;
	text-transform: uppercase;
}

.profile-badge {	
	text-align: center;
	span {
		color: #ff0000;
	}
	div:first-child {
		border: 1px dashed #000000;
		padding:3px;
		height: 300px;
		width: 400px;		
		vertical-align: middle;
		display: table-cell;
		margin-left: auto;
		margin-right: auto; 
	}
	div:last-child {		
		margin-top: 25px;
		width: 400px;
	}
	p:nth-child(1) label {
		font-size: 1.8em;
		height: 2em;
		font-weight: bold;
	}
	p:nth-child(2) input {
		font-size: 2em;
		height: 2em;
	}
	p:nth-child(3) input {
		font-size: 1.4em;
		height: 2em;
		color: #999999;
	}	
	p:nth-child(4) input {
		font-size: 1.6em;
		height: 2em;
	}	
	p:nth-child(5) input {		
		height: 2em;
	}		
	input {
	    background-color: transparent;
	    border: 0px solid;
	    height: 20px;	    
	    text-align: center;
		margin-left: auto;
		margin-right: auto;	    
	}		
}

// --------------------------------------------
// --------------------------------------------
//
// Shopping Cart
//
// --------------------------------------------
// --------------------------------------------



.cart-item {
	vertical-align: center;
}

.discount-label {
	@media(min-width: $large) {
		float: left;
		margin-right: 1em;
		padding-top: 0.25em;
	}
}

.discount-input {
	@media(min-width: $large) {
		float: left;
		max-width: 6em !important;
	}
}


.cart-qty {
	width: 40px;
	background-color: $bg-med;
	border: 0;
}

.cart-remove {
	border-bottom: 1px solid #ccc;
	padding-bottom: 2em;
	width: 100%;
	@media(min-width: $x-large) {
		position: absolute;
		left: 980px;
		border-bottom: none;
		padding-bottom: 0;
		width: 10%;
	}
	a {
		font-size: 0.85em;
		color: $black-20;
		// background-color: $bg-lt;
		border-radius: 25%;
		text-decoration: none;
		&:hover {
			color: red;
		}
	}
}

.bndl-comp {
	font-style: italic;
	color: $black-30;
	padding-left: 2em;
}


// Profile styles to match Community Hub

.profile-hdr {
	@media(min-width: $large) {
		margin-top: -4em;
		margin-bottom: -1em;
	}
}

.profile-secondary {
	@media(min-width: $med-lg) {
		width: 25%;
		max-width: none;
		padding-right: 15px;
	}
	.page-rail {
		@media(min-width: $large) {
			padding-top: 5.5em;
		}
		.side-menu-item {
			a {
				border-bottom: 1px solid $bg-med;
				padding: 12.5px 5px;
				letter-spacing: -0.1px;
				transition: all 200ms ease;
				&:hover {
					background-color: #f7f7f7;
				}
			}
			&.is-active {
				a {
					background: $bg-med;
					border-color: $bg-med;
					color: $black-30;
					&:hover {
						background-color: $bg-med;
					}
				}
			}
		}
	}
	&:after {
		display: none;
	}
}
.profile-primary {
	@media(min-width: $large) {
		width: 96%;
		float: left;
		margin-left: -18em;
	}
}

.login-prompt {
	margin: 2em 0;
	overflow: hidden;
	.login, .create {
		width: 100%;
		text-align: center;
		padding: 2em;
		border: 4px solid $bg-med;
		border-radius: 12px;
		margin-bottom: 2em;
		@media(min-width: $med-lg) {
			width: 47.5%;
			float: left;
			margin-right: 5%;
			margin-bottom: 0;
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.ebook-purchases-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	@media(max-width: $medium) {
		flex-direction: column;
		margin-bottom: 1em;
	}
}

.ebook-purchases-header h1.page-head-lg {
	width: 100%;
}

.ebook-purchases-header div.md {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.ebook-purchases-header button {
	cursor: pointer;
}