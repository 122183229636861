.card {
	overflow: hidden;
	&.hoverable {
		&:hover {
			box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
		}
	}
	&.best-value {
	border: #fab150 solid 2px;
	overflow: initial;
		&:before {
			width: 45px;
			height: 45px;
			position: absolute;
			top: 31px;
			right: -25px;
			content: " ";
			background: url('https://iapp.org/media/images/certification/bestValueOrange-01-01.png') no-repeat 0 0;
			background-size: 100%;
		}
	}

	// Card Sizes
	&.xxlarge {
		position: relative;
		cursor: default;

		.card-image {
			max-height: 60%;
			overflow: hidden;
		}
		.card-image + .card-content {
			max-height: 40%;
		}
		.card-content {
			max-height: 100%;
			overflow: hidden;
		}
		.card-action {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}

	&.xxlarge {
		height: 800px;
		cursor: default;
	}

	&.card-iapp_conferences {
		.card-image {
			height: 15rem;
			@media #{$medium-only} {
				height: 18rem;
			}
			img {
				height: 100%;
				width: auto;
			}
			.card-title {
				width: 100%;
				height: 100%;
				.valign {
					width: 100%;
					.card-conf-hdr {
						display: block;
						font-weight: bold;
					}
					.card-conf-secondary {
						display: block;
						font-size: 1.2rem;
					}
				}
			}
		}
	}
	&.card-video {
		.video-container {
			.youtube-player {
				div {
					img {
						margin-top: -9.3%;
					}
				}
			}
		}
	}
	&.card-horizontal {
		.card-image {
			@extend .grey, .lighten-4;
			overflow: hidden;
			@media #{$medium-and-down} {
				max-width: 100%;
			}
			a {
				display: block;
				height: 100%;
				overflow: hidden;
				img {
					min-width: 100%;
					max-width: none;
					min-height: 0;
					height: auto;
				}
			}
		}
		@media #{$medium-and-down} {
			display: block;
			.card-image {
				a {
					img {
						min-width: 100%;
						max-width: 100%;
						min-height: 0;
						height: auto;
						max-height: none;
					}
				}
			}
		}
		@media #{$large-and-up} {
			.card-image {
				a {
					img {
						max-height: 21.5rem;
					}
				}
			}
		}
	}
	&.share-this-card {
		margin: 1rem 0 0;
		.card-content {
			padding: 10px 20px 8px;
			.card-title {
				display: block;
				line-height: 2.8rem;
				text-align: right;
				i {
					text-align: left;
					font-size: 2rem;
					padding: 0;
					border-radius: 50%;
					width: 2.4rem;
					height: 2.4rem;
					line-height: 2.4rem;
				}
			}
		}
		.card-action {
			padding: 20px 20px 0;
			.social-sharing-btns {
				text-align: center;
			}
		}
	}
	&.card-note {
		@extend .blue-grey, .lighten-1, .grey-text, .text-lighten-5;
		p {
			font-size: 1.2rem;
		}
		a {
			color: #fff !important;
			text-decoration: underline;
			transition: color .28s ease;
			&:hover {
				color: map-get($blue-grey, "darken-3") !important;
			}
		}
		.card-title {
			display: block;
			line-height: 2rem;
			border-bottom: 1px solid map-get($grey, "lighten-5");
			padding-bottom: 0.75rem;
			margin-bottom: 0.75rem;
		}
		.card-content li {
			list-style-type: disc;
			margin-left: 15px;
		}
	}
	&.card-tumblr {
		@extend .grey, .lighten-3;
		.card-image {
			figure {
				margin: 0;
			}
		}
		.card-title,
		.card-content,
		p {
			a {
				@extend .grey-text, .text-darken-4;
				transition: color .28s ease;
				&:hover {
					color: map-get($cyan, "darken-1") !important;
				}
			}
		}
		.row,
		.card {
			margin-bottom: 0;
		}
		p {
			margin-bottom: 1rem;
		}
		.card-action {
			a {
				color: map-get($cyan, "darken-1") !important;
				i {
					@extend .cyan, .darken-1;
					position: absolute;
					right: 20px;
					top: 50%;
					margin-top: -0.75rem;
					color: #fff !important;
					width: 1.5rem;
					height: 1.5rem;
					line-height: 1.5rem;
					text-align: center;
					border-radius: 50%;
				}
				span {
					@media #{$small-and-down} {
						display: none;
					}
				}
			}
		}
	}
	&.intro-card {
	    border: 3px solid map-get($blue-grey, "darken-1");
	    background-color: map-get($blue-grey, "lighten-5");
	    .card-body {
	    	h3 {
	    		color: map-get($blue-grey, "darken-1");
	    		margin-top: 0;
	    		@media #{$medium-and-down} {
	    			font-size: 2rem;
	    		}
	    	}
	    	p {
	    		font-size: 1.05rem;
	    	}
	    }
	}
	&.intro-card-anz {
	    border: 3px solid map-get($light-green, "darken-4");
	    background-color: #e3e8e2;
	    .card-body {
	    	h3 {
	    		color: map-get($light-green, "darken-4");
	    		margin-top: 0;
	    		@media #{$medium-and-down} {
	    			font-size: 2rem;
	    		}
	    	}
	    	p {
	    		font-size: 1.05rem;
	    	}
	    }
	}
	.card-comments {
		height: 3em;
	}
	.card-content {
		&.has-comments {
			padding-bottom: 3rem;
		}
		.card-title {
			z-index: 2;
			&.bolded {
				font-weight: bold;
			}
		}
		p {
			margin-bottom: 1em;
			&:last-child {
				margin-bottom: 0;
			}
			a {
				color: map-get($cyan, "darken-1");
				&:hover {
					text-decoration: underline;
				}
			}
		}
		ol, ul {
			margin-left: 2em;
			&.bolded {
				font-weight: bold;
			}
			&.numbered {
				list-style: decimal;
			}
			&.lettered {
				list-style: upper-alpha;
			}
			&.bulleted {
				list-style: disc;
			}
			&.squares {
				list-style: square;
			}
			li {
				list-style-type: inherit !important;
				margin-left: 0;
			}
		}
	}
	.card-language {
		position: relative;
		&:before {
			content: "";
			display: block;
			position: absolute;
			background-color: map-get($light-blue, "darken-4");
			opacity: 0.25;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
		}
		&:after {
			content: "";
			display: block;
			position: absolute;
			background-color: map-get($blue-grey, "darken-4");
			opacity: 0.1;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
		}
		.card-title {
			z-index: 2;
		}
	}
	.card-language-anz {
		&:before {
			background-color: map-get($light-green, "darken-4");
		}
	}
	.card-full-content {
		display: none;
		.card-title {
			cursor: default;
		}
	}
	.card-action {
		.saveable-trigger {
			margin-right: 0 !important;
			margin-top: -3px; // may need to remove this when legacy Scss removed
			position: absolute;
			right: 20px;
		}
		.details-link,
		.back-to-top {
			color: map-get($cyan, "darken-1") !important;
		}
		.expand-link {
			color: map-get($green, "base") !important;
			.material-icons {
				font-size: 1.75rem;
			}
			&.left {
				margin-left: 2em;
			}
		}
		&.action-links {
			overflow: hidden;
			.more-link,
			.save-this,
			.back-to-top,
			.expand-link {
				text-transform: uppercase;
			}
		}
		.back-to-top,
		.expand-link {
			.material-icons {
				position: absolute;
				margin-left: -1.25em;
				margin-top: -0.45em;
			}
		}
	}
	&.reveal-active {
		.card-action {
			position: absolute;
			bottom: 0;
			width: 100%;
		}
	}
	.card-author + .card-body {
		margin-top: 0.5rem;
	}
	.featured-title {
		@media #{$medium-and-up} {
			font-size: 2.28rem;
			line-height: 110%;
		}
	}
}

.featured-resource {
	.col {
		display: none;
		&:first-child {
			display: block;
			padding: 0;
			.card {
				&:before {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 0 50px 50px;
					border-color: transparent transparent transparent map-get($green, 'base');
					z-index: 1;
				}
				&:after {
					font-family: $fa;
					content: "\f005";
					color: #fff;
					position: absolute;
					left: 0.5rem;
					top: 0.3rem;
					z-index: 2;
				}
				.card-head {
					font-size: 1.8rem;
					line-height: 2.3rem;
				}
			}
		}
	}
}

.other-resources {
	.col {
		&:first-child {
			display: none;
		}
	}
}

.store-card-head {
	padding: 10px 20px 0 !important;
	.card-title {
		@extend .cyan-text, .text-darken-1;
		font-weight: bold;
	}
}

.top-story-title {
	font-size: 2.3rem;
	line-height: 3rem;
}

.carousel-cards {
	.row {
		width: 100%; 
		margin:0 auto;
		@media #{$large-and-up} {
			width: 89%;
		}
		.col {
			&.valign {
				padding: 0;
			}
		}
	}
	.carousel-card {
		justify-content: center;
		padding-top: 0;
		flex-direction: column;
		display: flex; 
		position: relative;
		.c-card--image{
			height: 150px; 
			width: 100%;
			background-size: cover;
			.halfway-fab {
				top: 110px;
				.material-icons {
					transform: initial;
				}
			}
		}
		.c-card--txt {
			padding: 0.75em;
			margin-bottom: 0;
			width: 100%;
		}
	}
}

.featured-carousel {
	&.card {
		.card-content {
			padding: 0;
			.carousel-slider {
				margin: 0;
			}
		}
	}
}

.carousel-cards-wrapper {
	@media #{$medium-and-down} {
		display: flex!important;
		overflow-x: auto;
	}
	.carousel-card {
		@media #{$medium-and-down} {
			max-width: 250px;
			margin: 0 auto;
			display: table;
		}
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
			.c-card--image {
				.halfway-fab {
					right: 12px;
				}
			}
		}
	}
}

.hp-stream {
	.row {
		.l6 {
			&:nth-child(odd) {
				clear: both !important;
			}
		}
	}
	.card.card-horizontal {
		@media #{$large-and-up} {
			.card-image {
				a {
					img {
						max-height: 17rem;
					}
				}
			}
		}
	}
	.card-comments {
		height: auto;
	}
}

.clear-odd {
	.col {
		&:nth-child(odd) {
			clear: both;
		}
	}
}