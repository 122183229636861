// used to indicate items not yet built. within layout-article, for instance
.placehold {
	background-color: $bg-lt;
	padding: 2em;
	margin-bottom: 2em;
}

// just a spacer to add margin between demostration items in style guide
.demo-spacer {
	width: 100%;
	height: 1px;
	margin: 4em 0;
}

.clear {
	clear: both;
}

body {
	padding-top: 56px;
	@media(min-width: 993px) {
		padding-top: 100px;
	}
}

#content {
	// overflow: hidden;
}

// --------------------------------------------
// --------------------------------------------
//
// Layout variables
//
// --------------------------------------------
// --------------------------------------------

$space-grid: 62.5px; 					// 4.807692308em;
$space-grid--half: 32.25px; 			// 2.403846154em;
$space-grid--quarter: 16.125px;			// 1.240384615em;

$space-grid--double: 165px;


$grid--quarter: 	1.240384615em;

$one-px: 0.0625em;  // 1px

$grid-1: 			4.807692308em;				// 62.5px
$grid-2: 			12.692307692em; 			// 165px
$grid-3:  			14.423076924em;
$grid-5: 			24.03846154em;
$grid-7: 			33.653846156em;
$grid-10:			48.076923em;
$grid-12: 			57.692307696em;				// 750px
$grid-13: 			62.500000004em;
$grid-14: 			67.307692312em;
$grid-15: 			72.11538462em;

// 84.615384615em; 		// 1100px

#content { @include pie-clearfix; }


// --------------------------------------------
// --------------------------------------------
//
// Site width
//
// --------------------------------------------
// --------------------------------------------

.l-site-width, %l-site-width {
	margin: 0 $site-margin;
	position: relative;
	@include pie-clearfix;
	@media(min-width: $desktop + 1em) {
		max-width: $max-width;
	}
	@media(min-width: $desktop + ($site-margin*4) ) {
		margin: 0 auto;
	}
}

.l-nav-width {
	margin: 0 $site-margin--half;
	position: static;
	@media(min-width: $desktop + 1em) {
		position: relative;
		max-width: $max-width;
		margin: 0 auto;
	}
}

.l-ftr-width { @extend %l-site-width; }

.l-content-width {
	margin: 0 $site-margin;
	position: relative;
	@include pie-clearfix;
	@media(min-width: $desktop + 1em) {
		max-width: $x-large;
		margin: 0 auto;
	}
}




// --------------------------------------------
// --------------------------------------------
//
// Padding
//
// --------------------------------------------
// --------------------------------------------

.grid-pad, %grid-pad { padding: 0 $space-grid; }

%content-pad {
	padding-left: $site-margin;
	padding-right: $site-margin;
	@media(min-width: $desktop) {
		padding-left: 0;
		padding-right: 0;
	}
}




// --------------------------------------------
// --------------------------------------------
//
// Micro Layout :: Layout pairs
//
// --------------------------------------------
// --------------------------------------------

// These are used in a variety of places.
// On teases for instance where an image and tease text sits next to one another
// In a two-column layout.

.l-pair-1 { @include pair(100px, $space-grid--half); }


.l-pair-2, %l-pair-2 {
	.art-bd {
		max-width: 300px;
		margin-bottom: 1.25em;
		position: relative;
	}
	@media(min-width: $medium) { margin-bottom: 0; }

	@media(min-width: $medium) and (max-width: $desktop - $one-px) {
		@include pair(250px, $space-grid--half);
	}

	@media(min-width: $desktop) {
		@include pair(300px, $space-grid--half);
	}

	& + & { margin-top: 4em; }
}


.l-pair-tz-sm, %l-pair-tz-sm {
	@media(min-width: $medium) {
		@include pair(150px, $space-grid--half);
	}
	.art-bd {
		.img-rounded {
			max-width: 150px;
			margin: 0 auto 2em;
		}
	}
}


.l-pair-3 { @include pair (85px, $space-grid--half); }


.l-pair-aside { @include pair (70px, $space-grid--quarter); }


.l-pair-tz {
	max-width: 85%;
	margin-left: auto;
	margin-right: auto;
	@media(min-width: $medium) {
		max-width: 100%;
	}
	> .tz-art {
		display: block !important;
		margin-bottom: 2em;
		max-width: 800px;
		@media(min-width: $medium) {
			margin-left: 4em;
		}
		@media(min-width: $large) {
			margin-bottom: 0;
		}
	}
	> .txt-bd {
		@media(min-width: $medium) {
			margin-left: 4em;
		}
	}

	@media(min-width: $large) {
		@include pair(250px, $space-grid);
		> .tz-art, .txt-bd {
			margin-left: 0;
		}
	}
}


.l-pair-related {
	> .img-tz {
		margin-bottom: 2em;
	}
	@media(min-width: 28.125em) {
		@include pair (185px, $space-grid--half, top, right);
		> .img-tz { margin-bottom: 0; }
	}
}


.l-pair-mega { 
	@include pair(45px, $space-grid--quarter);
}




// --------------------------------------------
// --------------------------------------------
//
// Media Grids
//
// --------------------------------------------
// --------------------------------------------

// Media Grids are used for the layout of various site components.
// For instance, if you see a row of Conference Speaker mugshots in
// a row of three. Good chance it's using one of these grid classes.


$grid-bp-sm: $medium;


.grid-2, %grid-2 {

	@media(min-width: $grid-bp-sm) {
		@include media-grid(2, $space-grid);
	}
}

.grid-2-sm {
	margin-left: 0;
	.art-bd {
		max-width: 175px;
		margin: 0 auto;
	}

	.txt-bd {
		max-width: 225px;
		margin: 0 auto;
	}

	@media(min-width: $grid-bp-sm) {
		@include media-grid(2, $space-grid);
	}
}

.grid-2-sm-centered {
	@media(min-width: $grid-bp-sm) {
		max-width: 540px;
		margin: 0 auto;
		.media-block {
			padding-left: 0;
		}
	}
}

.grid-2-resources {
	margin-left: -15px !important;
	overflow: hidden;
	.media-block {
		padding-left: 15px !important;
		margin: 0 !important;
	}
	@media(min-width: $grid-bp-sm) {
		@include media-grid(2, $space-grid);
	}

}

.conf-section {
	.grid-2, %grid-2 {
		.art-bd {
			max-width: 250px;
			margin: 0 auto;
		}

		.txt-bd {
			max-width: 250px;
			margin: 0 auto;
		}

		@media(min-width: $grid-bp-sm) {
			@include media-grid(2, $space-grid);
		}
	}
}

.grid-3, %grid-3 {
	.art-bd {
		max-width: 175px;
		margin: 0 auto;
	}

	.txt-bd {
		max-width: 225px;
		margin: 0 auto;
	}

	@media(min-width: $grid-bp-sm) {
		@include media-grid(3, $space-grid);

		.art-bd { max-width: auto; }
		.txt-bd { max-width: auto; }
	}
}

.grid-2-outer {
	max-width:550px;
	margin-left: 17.5%;
	margin-right: 17.5%;
	.grid-2-med, %grid-2-med {
		.art-bd {
			max-width: 175px;
			margin: 0 auto;
		}

		.txt-bd {
			max-width: 225px;
			margin: 1em auto;
		}

		@media(min-width: $grid-bp-sm) {
			@include media-grid(2, $space-grid);
			.art-bd { max-width: auto; }
			.txt-bd { max-width: auto; }
		}
	}
}


.grid-4, %grid-4 {
	.media-block {
		width: 100% !important;
		@media(min-width: $medium) {
			width: 50% !important;
		}
		@media(min-width: $large) {
			width: 25% !important;
		}
	}
	.art-bd { max-width: 125px; margin: 0 auto; }
	@media(min-width: 400px) and (max-width: $grid-bp-sm) {
		@include media-grid(2, $space-grid);
	}
	@media(min-width: $grid-bp-sm) {
		@include media-grid(4, $space-grid);
	}
}


.grid-3--pad {
	@extend %grid-pad;
	@include media-grid(3, $space-grid);
}


.grid-4--pad {
	@extend %grid-pad;
	@include media-grid(4, $space-grid);
}

.grid-5, %grid-5 {
	.media-block {
		float: left;
		width: 50% !important;
		@media(min-width: $large) {
			width: 20% !important;
		}
	}
}


// --------------------------------------------
//
// Narrow left rail layout (responsive)



.narrow-left-rail {
	width: 100%;
	.col-1 {
		width: 100%;
		float: left;
		@media(min-width: $medium) {
			width: 15%;
		}
		@media(min-width: $desktop) {
			width: 20%;
		}
	}
	.col-2 {
		width: 100%;
		float: left;
		@media(min-width: $medium) {
			width: 85%;
		}
		@media(min-width: $desktop) {
			width: 80%;
		}
	}
}




// --------------------------------------------
//
// Issues grid


.issues-grid {
	width: 101%;
	margin-left: 0;
	overflow: hidden;
	.media-block {
		padding-left: 0;
		margin-bottom: 0;
		padding-right: 1%;
		padding-bottom: 1%;
		width: 50% !important;
		float: left;
		&:nth-of-type(4n+5) {
		    clear: none;
		}
		@media(min-width: $medium) {
			width: 33.33333% !important;
		}
		@media(min-width: $large) {
			width: 25% !important;
		}
		.topic-tease {
			display: block;
			position: relative;
			padding: 1em;
			height: 8em;
			width: 100%;
			text-decoration: none;
			.topic-bg {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				background-size: cover;
				opacity: 0.1;
				z-index: 0;
				-webkit-filter: grayscale(100%);
				-moz-filter: grayscale(100%);
				-ms-filter: grayscale(100%);
				-o-filter: grayscale(100%);
				filter: grayscale(100%);
				filter: url(/wp-content/themes/iapp/static/img/grayscale.svg#greyscale); /* Firefox 4+ */
				filter: gray; /* IE 6-9 */
				transition: all 0.3s ease-in-out;
			}
			.topic-bg-train {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				background-size: cover;
				opacity: 0.1;
				z-index: 0;
				filter: none;
				transition: all 0.3s ease-in-out;
			}
			&:hover,
			&:focus,
			&:active {
				.topic-bg {
					opacity: 0.75;
				}
				.topic-bg-train {
					-webkit-filter: grayscale(100%);
					-moz-filter: grayscale(100%);
					-ms-filter: grayscale(100%);
					-o-filter: grayscale(100%);
					filter: grayscale(100%);
					filter: url(/wp-content/themes/iapp/static/img/grayscale.svg#greyscale); /* Firefox 4+ */
					filter: gray; /* IE 6-9 */
				}
				.txt-bd {
					.tz-head-sm {
						color: #fff;
						text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
					}
				}
				&:before {
					opacity: 0.4;
				}
				&:after {
					opacity: 0;
				}
			}
			&:before {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				opacity: 0;
				z-index: 1;
				transition: all 0.3s ease-in-out;
			}
			&:after {
				content: "";
				position: absolute;
				right: 0;
				top: 0;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 50px 50px 0;
				opacity: 0.5;
				z-index: 1;
				transition: all 0.3s ease-in-out;
			}
			.txt-bd {
				display: table-cell;
				vertical-align: middle;
				height: 6em;
				width: 230px;
				text-align: center;
				position: relative;
				z-index: 2;
				@media(min-width: $large) {
					width: 170px;
				}
				.tz-head-sm {
					@media(max-width: $x-large) {
						font-size: 1em;
					}
					text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.75);
					transition: all 0.2s ease-in-out;
					// color: #fff;
				}
			}
			.overlay-icon {
				width: 10px;
				height: 10px;
				top: -0.5em;
				right: 1em;
				z-index: 3;
				.icon-lg {
					width: 20px;
					height: 20px;
				}
			}
		}
		&:nth-child(6n+1),
		&:nth-of-type(6n+1) {
			.topic-tease {
				&:before {
					background-color: $green-main;
				}
				&:after {
					border-color: transparent $green-main transparent transparent;
				}
			}
		}
		&:nth-child(6n+2),
		&:nth-of-type(6n+2) {
			.topic-tease {
				&:before {
					background-color: $swatch-8a;
				}
				&:after {
					border-color: transparent $swatch-8a transparent transparent;
				}
			}
		}
		&:nth-child(6n+3),
		&:nth-of-type(6n+3) {
			.topic-tease {
				&:before {
					background-color: $swatch-6;
				}
				&:after {
					border-color: transparent $swatch-6 transparent transparent;
				}
			}
		}
		&:nth-child(6n+4),
		&:nth-of-type(6n+4) {
			.topic-tease {
				&:before {
					background-color: $green-bg;
				}
				&:after {
					border-color: transparent $green-bg transparent transparent;
				}
			}
		}
		&:nth-child(6n+5),
		&:nth-of-type(6n+5) {
			.topic-tease {
				&:before {
					background-color: $swatch-5;
				}
				&:after {
					border-color: transparent $swatch-5 transparent transparent;
				}
			}
		}
		&:nth-child(6n+6),
		&:nth-of-type(6n+6) {
			.topic-tease {
				&:before {
					background-color: $swatch-4;
				}
				&:after {
					border-color: transparent $swatch-4 transparent transparent;
				}
			}
		}
	}
}


// --------------------------------------------
//
// Corporate Seals

.corp-seals{
	max-width: 150px;
	margin-bottom: 2em;
}

// --------------------------------------------
//
// Sponsor logo grids

.logo-grid-1 {
	.media-block { margin-bottom: $space-grid--quarter; }
	.art-bd {
		margin: 0 auto;
	}
	@media(min-width: 350px) and (max-width: $grid-bp-sm) {
		.art-bd { 
			display: table;
			max-width: 100%;
			min-height: 6em;
			.img-link {
				display: table-cell;
				vertical-align: middle;
				color: $link-color;
				img {
					// max-width: 100%;
					max-width: 150px;
					max-height: 150px;
				}
			}
		}
	}
	@media(min-width: $grid-bp-sm) {
		.art-bd { 
			display: table;
			max-width: 100%;
			min-height: 10em;
			.img-link {
				display: table-cell;
				vertical-align: middle;
				color: $link-color;
				img {
					// max-width: 100%;
					max-width: 150px;
					max-height: 150px;
				}
			}
		}
	}
}

.logo-grid-2 {
	.media-block { margin-bottom: $space-grid--quarter; }
	.art-bd {
		max-width: 300px;
		margin: 0 auto;
	}
	@media(min-width: 350px) and (max-width: $grid-bp-sm) {
			@include media-grid(2, $space-grid--half);
			.art-bd { 
				display: table;
				max-width: 100%;
				min-height: 6em;
				.img-link {
					display: table-cell;
					vertical-align: middle;
					color: $link-color;
					img {
						// max-width: 100%;
						max-width: 150px;
						max-height: 150px;
					}
				}
			}
		}
	@media(min-width: $grid-bp-sm) {
		@include media-grid(2, $space-grid--half);
		.art-bd { 
			display: table;
			max-width: 100%;
			min-height: 10em;
			.img-link {
				display: table-cell;
				vertical-align: middle;
				color: $link-color;
				img {
					// max-width: 100%;
					max-width: 150px;
					max-height: 150px;
				}
			}
		}
	}
}


.logo-grid-3 {
	@media(min-width: $medium) {
			.media-block:nth-of-type(3n+4) {
				clear: none !important;
			}
		}
	.media-block {
		margin-bottom: $space-grid--quarter;
		width: 50% !important;
		@media(min-width: $large) {
			width: 33% !important;
		}
		.member-logos {
		display: inline-block;
		.art-bd {
			display: table-cell; 
			height: 150px; 
			vertical-align: middle;
		}
	}
	}
	.art-bd { 
		max-width: 175px; 
		margin: 0 auto;
	} 
			img {
					// max-width: 100%;
					max-width: 150px;
					max-height: 150px;
				}
	

	@media(min-width: 350px) and (max-width: $grid-bp-sm) {
		@include media-grid(2, $space-grid--half);
		.art-bd { 
			display: table;
			max-width: 100%;
			min-height: 6em;
			.img-link {
				display: table-cell;
				vertical-align: middle;
				color: $link-color;
				
			}
		}
	}
	@media(min-width: $grid-bp-sm) {
		@include media-grid(3, $space-grid--half);
		.art-bd { 
			display: table;
			max-width: 100%;
			min-height: 10em;
			.img-link {
				display: table-cell;
				vertical-align: middle;
				color: $link-color;
				img {
					// max-width: 100%;
					max-width: 150px;
					max-height: 150px;
				}
			}
		}
	}
}

.logo-grid-4, %logo-grid-4 {
	.media-block {
		margin-bottom: $space-grid--quarter;
		width: 50% !important;
		@media(min-width: $large) {
			width: 25% !important;
		}
	}
	.art-bd { max-width: 80px; margin: 0 auto; }

	@media(min-width: 350px) and (max-width: $grid-bp-sm) {
		@include media-grid(2, $space-grid--half);
		.art-bd { 
			display: table;
			max-width: 100%;
			min-height: 6em;
			.img-link {
				display: table-cell;
				vertical-align: middle;
				color: $link-color;
				img {
					// max-width: 100%;
					max-width: 150px;
					max-height: 150px;
				}
			}
		}
	}
	@media(min-width: $grid-bp-sm) {
		@include media-grid(4, $space-grid--half);
		.art-bd { 
			display: table;
			max-width: 100%;
			min-height: 10em;
			.img-link {
				display: table-cell;
				vertical-align: middle;
				color: $link-color;
				img {
					// max-width: 100%;
					max-width: 150px;
					max-height: 150px;
				}
			}
		}
	}
}


// used for secondary tease area in HP Heros
%grid-hero-3 {
	@media(min-width: $medium) {
		@include media-grid(3, $space-grid);
		margin-left: -$space-grid;
	}
}




// --------------------------------------------
// --------------------------------------------
//
// Mods
//
// --------------------------------------------
// --------------------------------------------

// .mod-aside { max-width: 300px; }

.mod-related { max-width: 624px; }



// --------------------------------------------
// --------------------------------------------
//
// Page layout types
//
// --------------------------------------------
// --------------------------------------------




// --------------------------------------------
//
// Default page layout

// This section now contains some stuff not related to layout
// But related to the specific elements that reside on a default page
// So @gcstaublin will decide what to do about this
// One option might to break individual layouts out into their own files
// So Default page layout would have it's own default-layout.scss file
// And so on and so forth.

$default-bp-sm: $large;

.page-base { }


.l-primary {
	width: 100%;
	@include pie-clearfix;
	@media(min-width: $default-bp-sm) {
		width: 100%;
		float: right;
		margin-left: -$grid-3;

		> .inner {
			margin-left: $grid-3;
			padding-left: $grid-1;
			padding-right: $grid-1;
			padding-top: $grid-1;
			background-color: #fff;
			overflow: hidden;
			@include pie-clearfix;
		}
	}
}


.l-secondary {
	width: 100%;
	margin-bottom: $space-grid;
	@media(min-width: $default-bp-sm) {
		float: left;
		max-width: $grid-3;
		margin-bottom: 0;
	}
	&:after {
		content: '';
		position: absolute;
		width: 9999em;
		left: -9999em;
		top: -9999em;
		bottom: 0;
		margin-left: $grid-3;
		z-index: -1;
		@media(min-width: $default-bp-sm) {
			background-color: $bg-lt;
		}
	}
}


.side-menu-container {
	background-color: $bg-lt;
	@include pie-clearfix;
	@media(min-width: $default-bp-sm) {
		padding-bottom: 0;
		margin-bottom: 3em;
		background-color: transparent;
	}
}


.side-menu-items {
	.side-menu-container & {
		display: none;
		@media(min-width: $default-bp-sm) {
			display: block;
		}
	}
	.side-menu-container.is-active & {
		display: block;
		padding-bottom: 1em;
	}
	.sub-side-menu-items {
		display: none;
		@media(min-width: $default-bp-sm) {
			display: block;
		}
	}

}



.side-menu-trigger {
	background-color: $bg-med;
	padding: 1em 0;
	text-align: center;
	display: block;
	border-bottom: 1px solid #fff;
	text-transform: uppercase;
	@include link(pagenav);
	&:hover {
		background-color: darken($bg-med, 5%);
	}
	.is-active & { margin-bottom: 1em; }
	@media(min-width: $default-bp-sm) {
		display: none;
	}
}


.side-menu-message--closed {
	display: inline;
	.is-active & {
		display: none;
	}
}

.side-menu-message--open {
	display: none;
	.is-active & {
		display: inline;
	}
}


// .page-bg {
// 	@media(min-width: $default-bp-sm) {
// 		background-image: url(#{$bg-path}/bg_lt.png);
// 		background-repeat: repeat-y;
// 		background-position: 30% 0;
// 	}
// }

.page-rail {
	@media(min-width: $default-bp-sm) {
		position: relative;
		height: 100%;
		padding-top: $grid-1;
	}
}


.page-main-wrap {
	@include pie-clearfix;
	margin-bottom: $grid-1;
}

.mobileAds {
	@media(min-width: 1024px) {
		display: none;
	}	
}

.mobileSideAds {
	@media(max-width: 768px) {
		display: none;
	}	
}

.page-main-wrap-ads {
	@media(max-width: 768px) {
		@include pie-clearfix;
		margin-bottom: 0;
	}
}




// --------------------------------------------
//
// Article page layout

$article-split: $large;
$article-aside: $grid-5;
$article-main: $article-aside + $grid-1;


.article-content {
	padding: $space-grid--half 0;
	@include pie-clearfix;
}

.l-article-main {
	width: 100%;
	margin-bottom: 4em;
	@include pie-clearfix;
	@media(min-width: $article-split) {
		width: 100%;
		float: left;
		margin-right: -$article-main;
		> .inner {
			margin-right: $article-main;
		}
	}
}

.l-article-callout {
	width: 100%;
	position: relative;
	margin: 0 auto;
	@media(min-width: $article-split) {
		float: right;
		max-width: $article-aside;
	}
}

.l-article-aside {
	width: 100%;
	max-width: $article-aside;
	position: relative;
	margin: 0 auto;
	display: none;
	@media(min-width: $article-split) {
		float: right;
		display: block;
	}
}



// These .l-xx--center classes are used on open page-base

// --------------------------------------------
//
// 15 Cols Centered

.l-15--center {
	max-width: $grid-15;
	margin: 0 auto;
}


// --------------------------------------------
//
// 13 Cols Centered

.l-13--center {
	max-width: $grid-13;
	margin: 0 auto;
}


// --------------------------------------------
//
// 12 Cols Centered

.l-12--center {
	max-width: $grid-12;
	margin: 0 auto;
	padding: 0 $site-margin;
	@media(min-width: $grid-12) {
		padding: 0;
	}
}


// --------------------------------------------
//
// 10 Cols Centered

.l-10--center {
	max-width: $grid-10;
	margin: 0 auto;
	padding: 0 $site-margin;
	@media(min-width: $grid-10) {
		padding: 0;
	}
}


// --------------------------------------------
//
// 7 Cols Centered

.l-7--center {
	max-width: $grid-7;
	margin: 0 auto;
}






// --------------------------------------------
// --------------------------------------------
//
// Page-mod
//
// --------------------------------------------
// --------------------------------------------


.page-mod {
	// &:first-of-type { // margin-top: $space-grid*2; }
	& + & {
		margin-top: $space-grid;
	}
}




// --------------------------------------------
// --------------------------------------------
//
// Compound page mods
//
// --------------------------------------------
// --------------------------------------------

.page-mod--compound {
	margin: $space-grid*2 0;
}

.compound-block--top {
	background-color: $bg-lt;
	padding: $space-grid--half 0;
	@media(min-width: $large) {
		padding: $space-grid 0 $space-grid*2 0;
	}
}

.compound-block--top-sales {
	background-color: $bg-lt;
	padding: $space-grid--half 0;
	@media(min-width: $large) {
		padding: $space-grid 0 $space-grid 0;
	}
}

.compound-block--bottom {
	> .inner {
		background-color: #fff;
		padding-top: $space-grid--half;
		@media(min-width: $large) {
			margin-top: -$space-grid;
			padding-top: $space-grid;
		}
	}
}

.compound-block--bottom-sales {
	> .inner {
		background-color: #fff;
		padding-top: $space-grid--half;
		@media(min-width: $large) {
			padding: $space-grid 0 $space-grid 0;
		}
	}
}



// --------------------------------------------
// --------------------------------------------
//
// Page mod with background
//
// --------------------------------------------
// --------------------------------------------

.page-mod--bg {
	background-color: $bg-lt;
	padding: $space-grid--half 0;
	@media(min-width: $large) {
		padding: $space-grid 0;
	}
}

.sponsors-sect {
	background-color: #fff;
	padding-top: 0;
}

.page-mod.page-mod + .sponsors-sect {
	background-color: $bg-lt;
	padding-top: $space-grid;
}


// --------------------------------------------
// --------------------------------------------
//
// Open Page Wrap
//
// --------------------------------------------
// --------------------------------------------

.open-page-wrap {
	@include pie-clearfix;
	padding: $space-grid 0;
}




// --------------------------------------------
// --------------------------------------------
//
// Open Page Wrap
//
// --------------------------------------------
// --------------------------------------------

.mod--center { margin: 0 auto; }



// --------------------------------------------
// --------------------------------------------
//
// University landing page styles
//
// --------------------------------------------
// --------------------------------------------

.univ-callout {
	@media (min-width: $medium) {
		overflow: hidden; 
		padding: 2em;
	}
	.univ-logo { 
		text-align: center;
		margin: 1em auto 2em;
		@media (min-width: $medium) {
			float: left;
			margin: 0;
			padding: 0.5em 3em 1em 0.5em;
		}
	}
}

.survey {
	padding: 3em 1em;
}


// Topic Pages

.topic-ed {
	margin-bottom: 3em;
}

// hides the grecaptcha-badge that would normally show up on bottom right
.grecaptcha-badge {
	display:none!important;
}
