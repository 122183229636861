.hp-hero {
	height: auto;
	padding: 2rem 0 3rem;
	width: 100%;
	h1, h2, h3, h4, h5, h6, p {
		margin-bottom: 2rem;
	}
	.parallax {
		background-attachment: fixed; 
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 0%;
	}
	.hero-inner {
		width: 100%;
	}
}

// --------------------------------------------
// --------------------------------------------
//
// Hero background color and texture
//
// --------------------------------------------
// --------------------------------------------

@each $hero-color in $hero-colors {
	.hero-bg {
		&.hero-bg-#{nth($hero-color, 1)} {
			background: nth($hero-color, 2);
			background-image: radial-gradient(50% 50%, circle cover, nth($hero-color, 2), darken(nth($hero-color, 2), 5%) 100%);
		}
	}
}

@each $hero-texture in $hero-textures {
	.hero-bg {
		&.hero-pattern-#{nth($hero-texture, 1)} {
			&:before {
				background-image: url(/wp-content/themes/iapp/static/img/#{nth($hero-texture, 2)}.png);
				opacity: #{nth($hero-texture, 3)};
			}
		}
	}
}

// --------------------------------------------
// --------------------------------------------
//
// Page banners
//
// --------------------------------------------
// --------------------------------------------

.parallax {
	background-position: 100% 0%;
	background-attachment: fixed; 
	background-repeat: no-repeat;
	@media #{$small-and-down} {
		background-size: 250%;
		background-position: 80% 0%;
	}
	@media #{$medium-only} {
		background-size: 150%;
		background-position: 100% 0%;
	}
	@media #{$large-and-up} {
		background-size: 125%;
		background-position: 75% 10%;
	}
	@media #{$xlarge-and-up} {
		background-size: 100%;
		background-position: 50% 10%;
	}
}

.pub-hdr {
	position: absolute;
    width: 100%;
    overflow: hidden;
    z-index: 99;
    top: 55px;
    transition: margin-top .5s ease;
    @media #{$large-and-up} {
    	margin-top: -35px;
    	top: 136px;
    }
	header {
		position: relative;
		.backsplash {
			@extend .grey, .darken-4;
			opacity: 0.75;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 0;
		}
		.row {
			width: 100%;
			margin-bottom: 0;
			position: relative;
			z-index: 1;
			.pub-title {
				@extend .white-text;
				font-size: 2rem; 
				padding-left: 1rem; 
				margin: 1rem 0;
				opacity: 0.95;
				@media #{$medium-and-down} {
					font-size: 1.5rem;
				}
				.pub-link,
				.divider {
					display: inline-block;
					max-width: none;
					float: left;
					background-color: transparent;
					height: 100%;
					overflow: hidden;
					transition: max-width .28s ease;
				}
				span {
					position: absolute;
					font-family: $serif;
					font-weight: normal;
					opacity: 0;
				}
			}
			.rss-link {
				position: absolute;
				right: 0;
				opacity: 0.95;
				line-height: 67px;
				@media #{$medium-and-down} {
					line-height: 58px;
				}
				i {
					font-size: 2rem;
					@media #{$medium-and-down} {
						font-size: 1.5rem;
					}
				}
			}
		}
	}
	&.collapsed {
		@extend .z-depth-2;
		position: fixed;
		top: 36px;
		header {
			.backsplash {
				background-color: #fff !important;
				opacity: 1;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				height: 35px;
				z-index: 0;
			}
			.row {
				.pub-title {
					color: map-get($grey, "darken-3") !important;
					font-size: 1rem;
					margin: 0.5rem 0;
					max-height: 15px;
					a {
						font-family: $serif;
						font-weight: normal;
						color: map-get($cyan, "darken-1") !important;
						margin-left: -0.25rem;
					}
					span {
						display: inline-block;
						padding-left: 1rem;
						opacity: 1;
						&.current-title,
						&.next-title {
							transition: all .28s ease;
						}
						&.current-title {
							opacity: 1;
						}
						&.next-title {
							opacity: 0;
						}
						& + span {
							padding-left: 2rem;
						}
					}
				}
				.rss-link {
					line-height: 34px;
					i {
						color: map-get($grey, "darken-3") !important;
						font-size: 1rem;
					}
				}
			}
		}
	}
	&.mini {
		margin-top: 0;
		&.mini-bottom {
			header {
				.row {
					.pub-title {
						.pub-link,
						.divider {
							max-width: 0;
						}
						.current-title {
							opacity: 0;
						}
						.next-title {
							opacity: 1;
							margin-left: -0.25rem;
							padding-left: 0;
							width: 100%;
							a {
								margin-left: 1rem;
							}
						}
					}
				}
			}
		}
	}
}

.banner {
	height: auto;
	padding: 1rem;
	@media #{$large-and-up} {
		padding: 2.5rem 1rem;
	}
	@media #{$xlarge-and-up} {
		padding: 5rem 1rem;
	}
	&.colored-overlay {
		&:before {
			content: '';
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			position: absolute;
			background-image: linear-gradient(
				70deg, 
				map-get($green, "base") 3%, 
				map-get($green, "lighten-2") 20%, 
				transparent
			);
			opacity: 0.5;
		}
		&:after {
			content: '';
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			position: absolute;
			background-image: linear-gradient(
				70deg, 
				transparent, 
				map-get($blue, "lighten-2") 80%, 
				map-get($blue, "base") 97%
			);
			opacity: 0.5;
		}
		.valign-wrapper {
			position: relative;
			z-index: 1;
		}
	}
	&.no-overlay {
		background-color: map-get($grey, "darken-3");
	}
	.chip {
		float: left;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
		font-weight: bold;
		&.chip-icon-before {
			.material-icons {
				float: left;
				transform: translateX(-6px);
			}
		}
		&.hoverable {
			transition: box-shadow .25s;
			&:hover {
				transition: box-shadow .25s;
				box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			}
		}
		&.action-links {
			width: 105px;
			max-width: 105px;
			.save-this {
				position: absolute;
				i {
					font-size: 21px;
				}
			}
			.saved {
				position: absolute;
				text-transform: uppercase;
				letter-spacing: 1px;
			}
		}
	}
	&.light-scheme,
	&.default-scheme {
		h1, .header-social > ul > li > a {
			@extend .text-shadow;
			color: #fff;
		}
		.chip,
		.chip a {
			@extend .grey, .darken-4;
			color: #fff;
			&.save-this,
			&.saved {
				background-color: map-get($grey, "darken-4");
				color: #fff !important;
			}
		}
	}
	&.dark-scheme {
		h1, .header-social > ul > li > a {
			@extend .grey-text, .text-darken-4;
		}
		.chip,
		.chip a {
			@extend .grey-text, .text-darken-4;
			background-color: #fff;
			&.save-this,
			&.saved {
				background-color: #fff;
				color: map-get($grey, "darken-4") !important;
			}
		}
	}
	&.custom-scheme {
		h1, .header-social > ul > li > a {
			@extend .text-shadow;
		}
		.chip,
		.chip a {
			background-color: transparent;
			position: relative;
			z-index: 10;
			&.save-this,
			&.saved {
				background-color: transparent;
			}
		}
		.chip {
			&:before {
				content: "";
				display: block !important;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: #fff;
				border-radius: 16px;
				opacity: 0.9;
				z-index: -1;
				@media #{$small-and-down} {
					opacity: 0.75;
				}
			}
		}
	}
	.container {
		.row {
			margin-bottom: 0;
			@media #{$large-and-up} {
				margin: 0;
			}
		}
	}
}

.hdr-article {
	.banner {
		@media #{$medium-and-down} {
			.hdr-title {
				margin-top: 2.5em;
			}
		}
		@media #{$large-and-up} {
			padding: 6rem 1rem;
		}
		@media #{$xlarge-and-up} {
			padding: 9rem 1rem;
			.hdr-title {
				font-size: 4.2rem;
			}
		}
		.parallax {
			background-attachment: fixed; 
			background-repeat: no-repeat;
			&:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: #000;
				opacity: 0.1;
			}
			@media #{$small-and-down} {
				background-size: 150% auto;
				background-position: 50% 0%;
				&:after {
					opacity: 0.25;
				}
			}
			@media #{$medium-and-up} {
				background-size: cover;
				background-position: 50% 0%;
			}
		}
	}
}

.hdr-publication {
	.parallax-container {
		.parallax {
			background-position: 75% 0%;
			@media #{$medium-and-down} {
				background-size: 300%;
			}
			@media #{$large-and-up} {
				background-size: 150%;
			}
			@media #{$xlarge-and-up} {
				background-size: contain;
			}
		}
	}
	&.hdr-article {
		.parallax-container {
			.parallax {
				@media #{$medium-and-down} {
					background-size: 350%;
				}
				@media #{$xlarge-and-up} {
					background-size: cover;
				}
			}
		}
	}
	.pub-title {
		font-size: 3rem !important;
		padding-left: 0;
		@media #{$small-and-down} {
			font-size: 1.8rem !important;
		}
	}
}

.pub-title {
	font-size: 3rem;
	float: left;
	padding-left: 1rem;
	& + .header-social {
		left: 8%;
		bottom: 5%;
		@media #{$large-and-up} {
			top: 50%;
			margin-top: -1rem;
			left: initial;
			bottom: initial;
		}
	}
}

.hdr-title {
	color: #fff;
	font-size: 1.75rem;
	line-height: 140%;
	text-shadow: 0 1px 1px #000 !important;
	a {
		color: #fff;
	}
	@media #{$medium-and-up} {
		font-size: 2.5rem;
	}
	@media #{$large-and-up} {
		font-size: 3rem;
	}
}

.hdr-tagline {
	color: #fff;
	font-size: 1rem;
	text-shadow: 0 1px 1px #000;
	letter-spacing: 0.025em;
	a {
		color: #fff;
	}
}

.section-seal {
	position: absolute;
	display: inline-block;
    overflow: hidden;
    width: 36px;
    height: 36px;
	top: 0.9rem;
	background-color: #fff;
	border-radius: 50%;
	padding-top: 3px;
	text-align: center;
	@media #{$medium-and-down} {
		display: none;
	}
	&:nth-child(2) {
		right: 3rem;
	}
	&:nth-child(3) {
		right: 6.5rem;
	}
	&:nth-child(4) {
		right: 10rem;
	}
	&:nth-child(5) {
		right: 13.5rem;
	}
	img {
		max-width: 90%;
	}
}

.mini {
	.section-seal {
		width: 24px;
		height: 24px;
		top: 0.2rem;
	}
}

.chip + .header-social {
	position: absolute;
	bottom: 2%;
	@media #{$medium-and-up} {
		bottom: initial;
	}
}

.header-social {
	display: block;
	position: relative;
	clear: both;
	@media #{$medium-and-up} {
		position: absolute;
		right: 5%;
	}
	@media #{$xlarge-and-up} {
		right: 15%;
		padding-right: 1rem;
	}
	ul {
		margin-top: 3px;
		li {
			float: left;
			margin-right: 2rem;
			&:last-child {
				margin-right: 0;
			}
			a {
				text-decoration: none;
				i {
					font-size: 2rem;
					text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
				}
			}
		}
	}
}

// --------------------------------------------
// --------------------------------------------
//
// Header Styles
//
// --------------------------------------------
// --------------------------------------------

.hdr {
	background-color: $hdr-default-color;
	width: 100%;
	padding: $hdr-pad-sm 0;

	@media(min-width: $nav-bp-sm) {
		padding: $hdr-pad-med 0 2em 0;
	}

	@media(min-width: $nav-bp-lg) {
		padding: $hdr-pad-lg 0 2em 0;
	}


	// These classes are used to change the background-color of the header
	// They are applied when they're included at the template level.
	// For instance on news_landing.twig.
	.hdr-news & { 
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/news-banner3.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		.social-div--light {
			float: right; 
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222; 
					background: #ffffff; 
					padding: 6px; 
					text-align: center; 
					margin: 5px 3px; 
					border-radius: 50%; 
					height: 25px; 
					width: 25px;
				}
			}
		}
	}
	.hdr-daily-dashboard & { 
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/dd-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover; 
		.social-div--light {
			float: right; 
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222; 
					background: #ffffff; 
					padding: 6px; 
					text-align: center; 
					margin: 5px 3px; 
					border-radius: 50%; 
					height: 25px; 
					width: 25px;
				}
			}
		}
	}
	.hdr-privacy-perspectives & { 
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/perspectives-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover; 
		.hdr-block--title {
			color: $black-80;
			.hdr-h1-link {
				color: $black-80;
			}
			.hdr-tagline {
				color: $black-80;
			}
		}
		.social-div--light {
			float: right; 
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222; 
					background: #ffffff; 
					padding: 6px; 
					text-align: center; 
					margin: 5px 3px; 
					border-radius: 50%; 
					height: 25px; 
					width: 25px;
				}
			}
		}
	}
	.hdr-privacy-advisor & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/privacy-advisor-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover; 
		background-position: 50%;
		.social-div--light {
			float: right; 
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222; 
					background: #ffffff; 
					padding: 6px; 
					text-align: center; 
					margin: 5px 3px; 
					border-radius: 50%; 
					height: 25px; 
					width: 25px;
				}
			}
		}
	}

	.hdr-img-dpa-banner & { 
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/dpa-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover; 
	}

	.hdr-img-dpa-sanctions-banner & { 
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/dpa-sanctions-hdr.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%; 
	}

	.hdr-img-gdpr-banner & { 
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/gdpr-banner.png');
		background-repeat: no-repeat;
		background-size: cover; 
		background-position: 50% 50%;
	}

	.hdr-privacy-tracker & { 
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/tracker-banner1.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50%; 
		.hdr-block--title {
			color: $black-80;
			.hdr-h1-link {
				color: $black-80;
			}
			.hdr-tagline {
				color: $black-80;
			}
		}
		.social-list-link {
			background-color: $black-80;
		}
	}
	.hdr-dpo-confessional & { 
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/dpo-confessional-banner.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50%; 
		.hdr-block--title {
			color: $black-80;
			.hdr-h1-link {
				color: $black-80;
			}
			.hdr-tagline {
				color: $black-80;
			}
		}
		.social-list-link {
			background-color: $black-80;
		}
	}
	.hdr-privacy-tech & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/privacy-tech-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover; 
		background-position: 50%;
		.social-div--light {
			float: right; 
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222; 
					background: #ffffff; 
					padding: 6px; 
					text-align: center; 
					margin: 5px 3px; 
					border-radius: 50%; 
					height: 25px; 
					width: 25px;
				}
			}
		}
		&:before {
			content: "";
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			position: absolute;
			background-image: linear-gradient( 20deg, $privacy-tech-bg 0%, transparent 60% );
		}
	}
	.hdr-privacy-advisor-podcast & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/podcast-banner.png');
		background-repeat: no-repeat;
		background-size: cover; 
		.social-div--light {
			float: right; 
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222; 
					background: #ffffff; 
					padding: 6px; 
					text-align: center; 
					margin: 5px 3px; 
					border-radius: 50%; 
					height: 25px; 
					width: 25px;
				}
			}
		}
	}
	.hdr-canada-dashboard-digest & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/cdd-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover; 
		.social-div--light {
			float: right; 
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222; 
					background: #ffffff; 
					padding: 6px; 
					text-align: center; 
					margin: 5px 3px; 
					border-radius: 50%; 
					height: 25px; 
					width: 25px;
				}
			}
		}
	}
	.hdr-europe-data-protection-digest & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/edpd-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover; 
		.social-div--light {
			float: right; 
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222; 
					background: #ffffff; 
					padding: 6px; 
					text-align: center; 
					margin: 5px 3px; 
					border-radius: 50%; 
					height: 25px; 
					width: 25px;
				}
			}
		}
	}
	.hdr-united-states-dashboard-digest & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/usdd-banner.png');
		background-repeat: no-repeat;
		background-size: cover; 
		.social-div--light {
			float: right; 
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222; 
					background: #ffffff; 
					padding: 6px; 
					text-align: center; 
					margin: 5px 3px; 
					border-radius: 50%; 
					height: 25px; 
					width: 25px;
				}
			}
		}
	}
	.hdr-latin-america-dashboard-digest & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/latin-america-dashboard-digest.png');
		background-repeat: no-repeat;
		background-size: cover; 
		.social-div--light {
			float: right; 
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222; 
					background: #ffffff; 
					padding: 6px; 
					text-align: center; 
					margin: 5px 3px; 
					border-radius: 50%; 
					height: 25px; 
					width: 25px;
				}
			}
		}
	}
	.hdr-asia-pacific-dashboard-digest & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/asia-pacific-banner-2.jpg');
		background-repeat: no-repeat;
		background-size: cover; 
		background-position: 50%; 
		.social-div--light {
			float: right; 
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222; 
					background: #ffffff; 
					padding: 6px; 
					text-align: center; 
					margin: 5px 3px; 
					border-radius: 50%; 
					height: 25px; 
					width: 25px;
				}
			}
		}
	}

	.hdr-videos & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/videos-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover; 
		background-position: 50%; 
		.social-div--light {
			float: right; 
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222; 
					background: #ffffff; 
					padding: 6px; 
					text-align: center; 
					margin: 5px 3px; 
					border-radius: 50%; 
					height: 25px; 
					width: 25px;
				}
			}
		}
	}

	.hdr-westin-research-center & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/westin-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover; 
		@media(min-width: $large) {
			background-size: contain; 
			background-position: 50%;
			background-color: #000;
		}
		.social-div--light {
			float: right; 
			padding-top: 10px;
			.social-link--light {
				text-decoration: none;
				.social-icon--light {
					color: #222222; 
					background: #ffffff; 
					padding: 6px; 
					text-align: center; 
					margin: 5px 3px; 
					border-radius: 50%; 
					height: 25px; 
					width: 25px;
				}
			}
		}
	}

	.hdr-styleguide & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/styleguide-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover; 
	}

	.hdr-sg-landing & {
		background-image: url('/wp-content/themes/iapp/static/img/backgrounds/standard/sg-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover; 
	}

	.hdr-img &, .hdr-conference & {
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
	}
}