.dc_card {
    height: 775px;

    .dc_card_headline {
    	height: 15%; 
    	padding-bottom: 5px; 
    	overflow: auto;
    }

    .dc_card_description {
    	height: 28%; 
    	padding-top: 5px; 
    	padding-bottom: 5px; 
    	overflow: auto;
    }

    .dc_card_action {
    	height: 10%;
    }

    .dc_vendor_link {
    	color: #ffffff; 
    	background: #70bf4c;
    }

    .dc_card_images {
    	justify-content: space-around;
        margin-left: 25px;
        margin-right: 25px;
        margin-top: 48px;
    }

    .dc_card_image_single {
        justify-content: space-around; 
        margin: 25px; 
        height: 12%;
    }

    .dc_vendor_logo {
    	width: 100px!important;
        float: left;
        top: 50% !important;
        transform: translateY(-50%);
    }

    .dc_vendor_logo_single {
        height: 100%;
        object-fit: scale-down;
        transform: translateY(-25%);
        margin-left: auto;
        margin-right: auto;
    }

    .dc_vendor_level {
    	width: 70px !important; 
    	float: right; 
    	top: 50% !important; 
    	transform: translateY(-50%);
    }

    .dc_nonmember {
    	justify-content: space-around; 
    	margin: 25px; 
    	height: 5%;
    }

    .img-tz-dc {
        overflow: visible;
    }

    .dc_nonmember_logo {
    	max-width: 150px; 
    	margin: 25px; 
    	max-height: 150px; 
    	top: 50%; 
    	transform: translateY(-50%);
    }
}