// --------------------------------------------
// --------------------------------------------
//
// Notifications
//
// --------------------------------------------
// --------------------------------------------


.notify, %notify {
	background-color: $bg-lt;
	padding: 1em 0;
	width: 100%;
	display: table;
	@include pie-clearfix;
	[class^="hex-"] {
		float: left;
	}
}

.notify--sm {
	@extend %notify;
	position: relative;
	padding: 1em 0;
	margin-left: 2em;
	margin-bottom: 2em;
	width: 90%;
	@media(min-width: $large) {
		width: 100%;
		margin-left: 0;
	}
}

.notify-text, %notify-text {
	padding-left: 4.5em;
	padding-right: 1em;
	margin: 0.5em 0;
}

.notify-text-lft {
	@extend %notify;
	float: left;
	width: 60%;
	padding-left: 1em;
	overflow: hidden;
}

.notify-text-lg {
	@extend %notify;
	font-size: 1.1em;
	line-height: 0.5em;
	padding-top: 1.3em;
	font-weight: $sans-weight-light;
	@media(min-width: $x-large) {
		font-size: 1.384615385em;
		line-height: 0.5em;
		padding-top: 0.9em;
	}
}

.notify-emph {
	font-weight: 700;
}

.notify--header {
	@extend %notify;
	padding: 0.5em 0;
	background-color: rgba(black, 0.3);
	width: 100%;
	color: #fff;
	.notify-text {
		font-size: 1.15em;
		float: left;
	}

	@media(min-width: $large) {
		float: right;
		max-width: 450px;
	}
}

.iapp-product-details .notify--sm {
	@extend %notify;
	width: 100%;
	margin-left: 0;
}




// --------------------------------------------
// --------------------------------------------
//
// Cart Nav
//
// --------------------------------------------
// --------------------------------------------

$nav-cart-height: 35px;

.nav-cart-mod {
	position: absolute;
	// top: $nav-height;
	top: $nav-height-sm-mod;
	// margin-top: 4px;
	right: 10px;
	z-index: 99999;
	display: none;
	.nav-cart-isactive & {
		display: block;
	}
	@media(min-width: $nav-bp-med) {
		margin-top: 4px;
	}
	@media(min-width: $nav-bp-lg) {
		top: $nav-height;
	}
}


.nav-cart-link {
	background-color: $green-main;
	display: block;
	width: 65px;
	height: $nav-cart-height;
	position: relative;
	padding: 0 0.55em;
	&:hover {
		background-color: darken($green-main, 5%);
	}
}

.nav-cart-icon {
	opacity: 0.8;
	.icon.icon-med { left: 0.55em; top: 7px; }
}

.nav-cart-count {
	float: right;
	line-height: $nav-cart-height;
	color: #fff;
	font-weight: 700;
	letter-spacing: 1px;
}




// --------------------------------------------
// --------------------------------------------
//
// Message Nav
//
// --------------------------------------------
// --------------------------------------------

$nav-message-height: $nav-cart-height;

.nav-message-mod {
	position: absolute;
	top: $nav-height;
	margin-top: 4px;
	z-index: 99999;
	left: 30%;
	display: none;
	.nav-message-isactive & {
		display: block;
	}
}

.notify-nav {
	background-color: $green-main;
	color: #fff;
	height: $nav-message-height;
	line-height: $nav-message-height;
	padding: 0 0.75em 0 1em;
}

.nav-message-link {
	margin-right: 1em;
	text-decoration: none;
	color: #fff;
}

.notify-head-nav {
	@extend %h5;
	display: inline-block;
	margin-right: 1em;
	color: #fff;
}

.notify-text-nav {
	display: inline-block;
}

.nav-message-close {
	background-color: lighten($green-main, 5%);
	border: 1px solid darken($green-main, 5%);
	font-weight: 700;
	color: darken($green-main, 10%);
	position: relative;
	top: 4px;
	border-radius: 50%;
	&:hover {
		color: #fff;
	}

}

#iapp-notifications {
	margin: 0 auto;
	width: 100%;
	max-width: $large;
}

.default-notify {
	display: block;
	@media(min-width: $medium) {
		margin-left: 10em;
	}
}
