.dfad {
	text-align: center;
	padding-bottom: 2.5em;
	margin-bottom: 2.5em;
	border-bottom: 1px solid #d9dadb;
	img {
		max-width: 100%;
		height: auto;
	}
}

.dfad-post {
	text-align: center;
	padding: 1em;
	margin: 1em;
	// padding-bottom: 1em;
	// margin-bottom: 1em;	
	img {
		max-width: 100%;
		height: auto;
	}
}

.iapp-ad-hidden {
	display: none;
}

.iapp-ad-certification {
	.dfad {
		border-bottom: none;
	}
}

#jobs-intro {
	.dfad {
		border-bottom: none;
		margin-top: 4em;
		margin-bottom: 0;
		padding-bottom: 0;
	}
}

.show-daily-wrapper {
	.iapp-ad-app {
		position: fixed;
		width: 100%;
		bottom: 0;
		padding: 10px;
		margin-bottom: 0;
		height: 70px;
		left: 0;
		background-color: #f2f2f2;
	}
}

.cert-sidebar-ad {
	padding: 0;
	max-width: 300px;
	margin: 3em auto 0;
	@media(min-width: $large) {
		margin: 2em 0.1em 0 0;
	}
	a {
		text-decoration: none;
		.tz-head-sm {
			color: #fff;
			text-transform: uppercase;
			margin-bottom: -3em;
			position: relative;
			text-align: center;
			padding: 0 1em;
			text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
			@media(min-width: $large) {
				font-size: 1.2em;
			}
		}
		.page-text-sm {
			color: #fff;
			background-color: $green-main;
			padding: 0.5em 1.5em;
			text-align: center;
			@media(min-width: $large) {
				font-size: 1em;
			}
			span {
				text-transform: uppercase;
				font-weight: bold;
			}
		}
	}
}