// --------------------------------------------
// --------------------------------------------
//
// http://www.labnol.org/internet/light-youtube-embeds/27941/
//
// --------------------------------------------
// --------------------------------------------
.margin-wrapper {
    margin: 5px;
}
.youtube-player {
        position: relative;
        padding-bottom: 56.23%;
        /* Use 75% for 4:3 videos */
        height: 0;
        overflow: hidden;
        max-width: 100%;
        //background: #000;
        //margin: 5px;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 100;
            background: transparent;
        }
        img {
            bottom: 0;
            display: block;
            left: 0;
            margin: auto;
            max-width: 105%;
            width: 105%;
            position: absolute;
            right: 0;
            top: 0;
            border: none;
            height: auto;
            cursor: pointer;
            -webkit-transition: .4s all;
            -moz-transition: .4s all;
            transition: .4s all;
            &:hover {
                -webkit-filter: brightness(75%);
            }

        }
        .play {
            height: 72px;
            width: 72px;
            left: 50%;
            top: 50%;
            margin-left: -36px;
            margin-top: -36px;
            position: absolute;
            background: url("/media/images/publications/TxzC70f.png") no-repeat;
            cursor: pointer;
            }

    
}

    
