.see-all {
	width: 100%;
	max-width: 15rem;
}

.subsection-hdr {
	& + .btn {
		float: right;
		margin-top: -2.75rem;
	}
}

// Social sharing

.social-sharing-btns {
	@media #{$medium-and-down} {
		a {
			width: 30px;
			height: 30px;
			line-height: 30px;
			i {
				font-size: 1.2rem;
				line-height: 30px;
			}
		}
		ul {
			display: inline-block;
			margin: 0;
	    	li {
	    		float: left;
	    		margin: 0 0.5rem 1rem;
	    	}
	    }
	}
	@media #{$large-and-up} {
		a {
			width: 30px;
			height: 30px;
			line-height: 30px;
		}
		ul {
			position: absolute;
			left: 2.5%;
			margin-left: -15px;
			margin-top: 160px;
			&.fixed {
				position: fixed;
				bottom: 60px;
			}
			li {
				margin-bottom: 1rem;
				a {
					width: 30px;
					height: 30px;
					line-height: 30px;
					i {
						font-size: 1.2rem;
						line-height: 30px;
					}
				}
			}
		}
	}
	@media #{$xlarge-and-up} {
		a {
			width: 40px;
			height: 40px;
			line-height: 40px;
		}
		ul {
			margin-left: -20px;
			li {
				a {
					width: 40px;
					height: 40px;
					line-height: 40px;
					i {
						font-size: 1.5rem;
						line-height: 40px;
					}
				}
			}
		}
	}
}

// RSS

.article-rss {
	a {
		i {
			transform: translateY(0) !important;
		}
	}
}

// Special store buttons

.prod-cart-adjust,
.mega-cart-update {
	.btn {
		@extend .fade-on-change;
	}
}

.iapp-store .store-table table td .checkout-btn {
	&.wide {
		width: 100px;
		max-width: none;
		.material-icons {
			margin: 0 auto;
			text-align: center;
			transform: translateX(0.6rem);
		}
		@media #{$medium-and-down} {
			width: 100%;
			.material-icons {
				transform: translateX(100%);
			}
		}
	}
}

// show more button
.iapp-show-more-trigger {
	min-width: 164px;
}

// edit reg button 
.edit-reg-btn {
	margin-bottom: 0.5rem;
}

// Save links
.action-links {
	.save-this {
		color: map-get($cyan, "darken-1") !important;
		opacity: 1;
		transition: opacity 0.28s ease !important;
	}
	.saved {
		color: map-get($light-blue, "accent-4") !important;
		opacity: 0;
		transition: opacity 0.28s ease !important;
	}
	&.is-saved {
		.save-this {
			opacity: 0;
		}
		.saved {
			opacity: 1;
		}
	}
}

// Green button
a {
	&.btn {
		&.green {
			color: #FFF !important;
			text-decoration: none;
			height: auto;
		}
	}
}

.herolink-button {
	width: 16rem;
	margin: 0px 10px 5px 10px; 
	padding: 0;
}

@media only screen and (max-width: 425px) {
  .herolink-button {
    margin: 0px 10px 10px 10px; 
  }
}