// --------------------------------------------
// --------------------------------------------
//
// Person
//
// --------------------------------------------
// --------------------------------------------

$person-bp: $medium;

$person-bp-lg: $large;


.l-person {
	@media(max-width: $person-bp) {
		> .art-bd {
			max-width: 100px;
			float: left;
			margin-right: 2em;
			margin-bottom: 1em;
		}
	}

	@media(min-width: $person-bp) {
		@include pair(100px, $space-grid);
		> .art-bd {
			max-width: auto;
			float: none;
			margin-right: 0;
			margin-bottom: 0;
		}
	}
}


.person-mod {
	a {
		@extend %alternate-link--underline;
	}
}

.articles-by {
	margin-top: 2em;
	li {
		margin-bottom: 1em !important;
		padding-bottom: 1em !important;
	}
}


.person-name-lg {
	@extend %h2;
	color: $black-40;
	margin-bottom: 0.75em;
	text-decoration:none;
	display:block;
}

.person-content-block { }

.person-meta-block {
	text-align: center;
	margin-top: 1em;
}

.person-meta-item {
	position: relative;
	margin-bottom: 1.25em;
	&:first-child {
		padding-bottom: 1em;
		&:after {
			content: '';
			position: absolute;
			left: 25%;
			right: 25%;
			bottom: -0.25em;
			height: 4px;
			background-color: $bg-med;
		}
	}
}


// --------------------------------------------
// --------------------------------------------
//
// Article Bio
//
// --------------------------------------------
// --------------------------------------------

.person-mod { }


.person-content-block { }

.l-person {
	@extend %l-pair-2;
}


.person-name-lg {
	@extend %h2;
	color: $black-40;
	margin-bottom: 0.75em;
}

.aside-headshot {
	display: none;
	@media(min-width: $large) {
		display: block;
		width: 60%;
		margin: 4em auto;
		.art-bd {
			margin-bottom: 1em;
		}
	}
}
