.left-sidebar {
	@extend .grey, .lighten-4;
	position: relative;
	padding-right: 0 !important;
	display: flex;
	flex: 1;
	flex-direction: column;
	&:after {
		content: "";
		position: absolute;
		top: -1.1em;
		right: 0;
		bottom: -3em;
		left: -99999px;
		background-color: inherit;
		z-index: -1;
	}
	.collection {
		border: none;
		.collection-item {
			background-color: transparent;
			border-bottom: none;
			transition: background-color .28s ease;
			a {
				@extend .grey-text, .text-darken-4;
				display: block;
				width: 100%;
				height: 100%;
				transition: color .28s ease;
			}
			&:hover,
			&.active {
				background-color: map-get($grey, "lighten-1");
				a {
					color: #fff !important;
				}
			}
		}
		& + .collection {
			border-top: 1px solid map-get($grey, "lighten-2");
			margin-top: -1rem;
		}
	}
	.container {
		margin: 0 2rem 0 0;
		.card {
			&.editor-card {
				max-width: 13rem;
			}
			.card-head {
				font-size: 1.2rem;
			}
		}
	}
	.article-recent-comments-mod {
		margin: 0 2rem 0 0;
	}
}

.side-nav {
	width: 100%;
	max-width: 500px;
	z-index: 1000;
	strong {
		letter-spacing: 1px;
	}
	.nav-title {
		@extend .grey, .darken-1;
		.nav-collapse {
			@extend .white-text;
			position: relative;
			font-weight: bold;
			font-size: 1.2rem;
			letter-spacing: 1px;
			&:hover {
				background-color: inherit;
			}
			i {
				position: relative;
				color: inherit;
				z-index: 1;
			}
		}
	}
	.nav-open-trigger {
		@extend .cyan, .darken-1;
		a {
			@extend .white-text;
			position: relative;
			font-style: italic;
			&:hover {
				background-color: inherit;
			}
			i {
				position: relative;
				color: inherit;
				animation-duration: 1.5s;
				animation-name: pulse-big;
				animation-iteration-count: infinite;
				z-index: 1;
			}
		}
	}
	.back-link {
		@extend .orange, .lighten-3;
		a {
			position: relative;
			font-style: italic;
			&:hover {
				background-color: inherit;
			}
			i {
				position: relative;
				color: inherit;
				z-index: 1;
			}
		}
	}
	.nav-item {
		overflow: hidden;
		border-bottom: 1px dashed map-get($blue-grey, "lighten-4");
		&.active {
			a {
				i {
					@media #{$medium-and-down} {
						color: map-get($green, "base");
					}
				}
			}
		}
		a {
			@media #{$medium-and-down} {
				width: 100%;
				text-align: left;
				font-size: 1rem;
				i {
					position: relative;
					font-size: 1.85rem;
					z-index: 1;
					&.fa {
						font-size: 30px;
						width: 22px;
						text-align: center;
						&.fa-newspaper-o {
							font-size: 24px;
						}
					}
					&.right {
						margin-right: 0;
					}
				}
			}
			&:hover {
				background-color: inherit;
			}
		}
		&.highlight-item {
			border-bottom: none;
			a {
				&:before {
					content: "";
					position: absolute;
					right: 0;
					width: 3.25rem;
					height: 100%;
					background-color: map-get($grey, "darken-4");
					opacity: 0.15;
				}
			}			
		}
		&:last-child,
		&.no-border {
			border-bottom: none;
		}
		&.checkout-btn-container {
			.checkout-btn {
				.cart-count {
					position: absolute;
					right: 16px;
				    margin-top: -4px;
				    font-size: 0.75rem;
				    width: 18px;
				    text-align: center;
				    font-weight: bold;
				    z-index: 2;
				}
			}
		}
		&.lang-select-mobile {
			border-bottom: none;
			.collapsible-accordion {
				min-height: 0;
				.collapsible-header {
					background-color: map-get($blue-grey, "lighten-2");
					color: #fff;
				}
				.collapsible-body {
					padding: 0 16px !important;
					ul.no-marg {
						margin-left: -56px !important;
						margin-right: -16px !important;
						.lang-option-mobile {
							list-style: none;
							height: 48px;
							background-color: map-get($blue-grey, "lighten-5");
							a {
								color: map-get($grey, "darken-4") !important;
								text-decoration: none;
								&:before {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
	.nav-search {
		@extend .card, .blue-grey, .lighten-4;
		height: 50px;
		margin: 0.5rem;
		label {
			top: -0.25em;
			.material-icons {
				@extend .grey-text, .text-darken-4;
			}
		}
		.nav-search-close {
			display: none;
		}
	}
}

.article-sidebar {
	@extend .grey, .lighten-4;
	position: relative;
	display: flex;
	flex: 1;
	flex-direction: column;
	@media #{$large-and-up} {
		&:after {
			content: "";
			position: absolute;
			top: -1rem;
			left: -4rem;
			bottom: -3em;
			right: -99999px;
			background-color: inherit;
			z-index: -1;
		}
	}
}

.related-posts-mod,
.article-recent-comments-mod {
	font-family: $serif;
	ul {
		li {
			position: relative;
			font-size: 1.2rem;
			margin-bottom: 1rem;
			a {
				display: block;
			}
			i {
				position: absolute;
				left: -2.6rem;
				top: 0.5rem;
				line-height: 1rem;
			}
			.comment {
				display: block;
				padding-bottom: 2rem;
			}
		}
	}
	.card-body {
		font-family: $serif;
	}
	.card-action {
		a {
			font-family: $sans;
		}
	}
}