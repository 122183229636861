.tabs {
	.tab {
		a {
			color: $secondary-color;
			opacity: 0.7;
			transition: all .28s ease;
			&.active,
			&:hover {
				color: $secondary-color;
				opacity: 1;
			}
		}
	}
	.indicator {
		background-color: $secondary-color;
	}
}

.store-search-tabs {
	overflow-x: hidden;
	.tab {
		a {
			color: map-get($cyan, "darken-1");
			opacity: 1;
			transition: all .28s ease;
			&.active,
			&:hover {
				color: map-get($cyan, "darken-1");
				opacity: 1;
			}
		}
	}
	.indicator {
		background-color: map-get($cyan, "darken-1");
	}
}