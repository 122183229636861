// Allows for custom color badges in collapsibles instead of just collections
.collapsible-header {
	font-family: $serif;
	&.custom-badge{
		display: flex;
		justify-content:space-between;
		.bdg {
			background-color: $primary-color;
			color: white;
			margin: 10px 0;
			padding: 0 6px;
			min-width: 3em;
			text-align: center;
			font-weight: 300;
			font-size: 0.8rem;
			line-height: inherit;
			box-sizing: border-box;
			border-radius: 2px;
			height: 28px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.rotate-on-active {
		transition: transform .28s ease;
	}
	&.active {
		.rotate-on-active {
			transform: rotate(-180deg);
		}
		& + .collapsible-body {
			padding-bottom: 20px !important;
		}
	}
}

.collapsible-body {
	font-family: $serif;
	padding: 0 20px !important;
	p {
		padding: 20px 5px 0;
	}
	h1 {
 	    font-size: 1.25rem;
 	    margin-bottom: 0;
 	    padding-bottom: 0.5em;
 	    border-bottom: 1px solid #d9dadb;
    }
	ul, ol {
		font-size: inherit;
		margin-left: 20px;
		margin-bottom: 0;
		padding-right: 20px;
		li {
			font-size: inherit;
			font-family: $serif;
			margin-left: 20px;
		}
	}
	ul {
		li {
			list-style: disc;
			li {
				list-style: circle;
				li {
					list-style: square;
				}
			}
			ul {
				margin-top: 0;
			}
		}
	}
	ol {
		margin-top: 20px;
		li {
			list-style: decimal !important;
			li {
				list-style: lower-latin !important;
				li {
					list-style: lower-roman !important;
				}
			}
			ol {
				margin-top: 0;
			}
		}
	}
	a {
		@extend .default-link;
	}
	.section-text-wrapper + .collapsible.no-marg {
		margin-top: 20px !important;
	}
}

.collapsible-li {
	list-style: none !important;
	margin-left: 0 !important;
}

.collapsible-nested {
	.collapsible-header {
		font-weight: bold;
		text-transform: uppercase;
	}
	.collapsible-body {
		.collapsible-header {
			color: map-get($grey, "darken-2");
			text-transform: none;
		}
		.collapsible-body {
			.collapsible-header {
				font-style: italic;
				font-weight: normal;
				color: map-get($grey, "darken-3");
			}
		}
	}
}

.no-min-height {
	min-height: initial;
}

.ss-expanded-header {
	position: relative;
	.expanded-icon-container {
		cursor: pointer;
		position: absolute;
		top: 2.25em;
	}
}


/*Onine conference accordion*/
.virtualLi {
	margin-bottom: 1em;
}

.virtualHeader {
	font-size: 15px;
	text-transform: none;
	padding-left: 0px;
	padding-right: 7px;
}

.virtualChevron {
	float: left;
	height: 100%;
	margin-right: 0px;
	margin-top: -1.5%;
}

.virtualP {
	font-size: 15px;
	text-transform: none;
	font-weight: bold !important;
	font-family: "Tabac Sans",Helvetica,Arial,sans-serif;
}

.virtualHref {
	color: #4caf50;
}

.virtualHrefOnline {
	color: #4caf50 !important;
	float: right;
	margin-top: 5px;
}

.virtualSpan {
	float: right;
	font-weight: normal;
	padding-right: 5px;
}

.virtCollP {
	font-family: "Tabac Sans",Helvetica,Arial,sans-serif;
	font-size: 15px;
}

@media screen and (max-width: 768px) {

	.virtualHeader {
		padding-right: 6px;
	}

	.virtualSpan {
		float: none !important;
		display: block;
    	text-align-last: center;
    	padding-top: 5px;
	}

	.virtualHrefOnline {
		color: #4caf50;
		float: none;
	}

	.virtualChevron {
		margin-top: 0;
	}
}

@media screen and (max-width: 320px) {
	.virtualChevron {
		margin-top: 20px;
	}
}