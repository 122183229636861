.icons-mega {
	position: absolute;
    width: 24px;
    height: 44px;
    margin-left: 18px;
    left: 10px;
    display: inline-block;
    vertical-align: middle;
}

.circle-mega {
	width: 45px !important;
	margin-left: 7px !important;
	border-radius: 50% !important;
}

.card {
	p, a, li {
		.material-icons {
			transform: translateY(2px);
		}
	}
}


// --------------------------------------------
// --------------------------------------------
//
// Social
//
// --------------------------------------------
// --------------------------------------------


.social-list-items {
	@extend %list-items--inline;
	list-style: none;
}

.social-list-item {
	@extend %list-item--inline;
	text-indent: -9999em;
	border-radius: 50%;
	overflow: hidden;
	margin-left: 7px;
	margin-bottom: 0;
}

.social-list-link {
	position: relative;
	background-color: darken($hdr-default-color, 15%);
	opacity: 0.9;
	display: block;
	&:hover {
		opacity: 1;
	}
	.icons-sm & {
		width: 25px;
		height: 25px;
	}

	.icons-med & {
		width: 30px;
		height: 30px;
	}

	.icons-lg & {
		width: 50px;
		height: 50px;
	}
}

.icon, %icon {
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: contain;
	width: 15px;
	height: 15px;
	position: absolute;
	top: 5px;
	left: 5px;

	@each $icon in $icons {
		.icon-#{$icon} & {
			background-image: url(#{$svg-icon-path}#{$icon}_full.svg);
			.no-svg & { background-image: url(#{$png-icon-path}#{$icon}_med.png)}
		}
	}

	@each $session in $sessions {
		.icon-#{nth($session, 1)} & {
			background-image: url(#{$svg-icon-path}#{nth($session, 1)}_full.svg);
			.no-svg & { background-image: url(#{$png-icon-path}#{nth($session, 1)}_med.png)}
		}
	}

	.icon- & {
		background-image: url(#{$svg-icon-path}articles_full.svg);
		.no-svg & { background-image: url(#{$png-icon-path}articles_med.png)}
	}

	.icons-sm &, &.icon-sm {	}

	.icons-med-social &, &.icon-med-social {
		width: 20px;
		height: 20px;
	}

	.icons-lg-social &, &.icon-lg-social {
		width: 30px;
		height: 30px;
		top: 10px;
		left: 10px;
	}

	&.icon-tz {
		width: 38px;
		height: 38px;
		top: 10px;
		left: 10px;
	}
}

.mega-modal {
	.icons-mega {
		.icon {
			top: 0;
		}
		&.circle-mega {
	 		.icon {
	 			left: 9px;
			 	width: 28px;
		 		height: inherit;
	 		}
		}
	}
}