// --------------------------------------------
// --------------------------------------------
//
// Search Form
//
// --------------------------------------------
// --------------------------------------------

.member-search-form {
	position: relative;
	.nav-search-block & {
		// position: absolute;
		// width: 100%;
	}
	@media(min-width: $nav-bp-lg) {
		text-align: center;
	}
}

.fip-search-form {
	text-align: left;
}
.fip-search-form:before {
	content: '\f002';
	font-family: FontAwesome;
	font-size: 2em;
	top: 1px;
	right: 8px;
	position: absolute;
    z-index: 2;
	color: #a9a9a9;
}

.member-search-input {
	border: 1px solid #d9d9d9;
	-webkit-appearance: none;
	-webkit-border-radius: 0;
	border-radius: 0;
	outline: 0;
	padding: 0 0.85em;
	text-transform: uppercase;
	height: 35px;
	width: 85%;
	margin: 0;
	@media(min-width: $nav-bp-sm) {
		width: 90%;
	}
	@media(min-width: $nav-bp-lg) {
		width: 95%;
	}
	.nav-search-block & {
		width: 87%;
		@media(min-width: $nav-bp-sm) {
			width: 92%;
		}
		@media(min-width: $nav-bp-med) {
			width: 95%;
		}
		@media(min-width: $nav-bp-lg) {
			width: 56.8888em;
			// margin-top: 1.2em;
		}
	}
}

.fip-search {
	@extend .member-search-input;
	width: 100%;
}

.member-search-submit {
	@extend %button;
	position: relative;
	text-indent: -9999em;
	width: 35px;
	height: 35px;
	margin-bottom: 0;
	.nav-search-block & {

		@media(min-width: $nav-bp-lg) {
			// position: absolute;
			// margin-top: -2.7em;
			// right: -59.66666em;
		}
	}

	.icon.icon-lg {
		top: 3px;
		left: 3px;
	}
}