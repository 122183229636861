// --------------------------------------------
// --------------------------------------------
//
// Default Static Page Styles
//
// --------------------------------------------
// --------------------------------------------

.static-page {
	h1 {
		@extend %page-head-lg;
	}
	h2 {
		@extend %page-head-lg;
		&.subsection-hdr-blue-bold {
			color: #0c7ec0;
			font-size: 1.8em;
		}
	}
	h3 {
		@extend %subsection-hdr-bold;
	}
	h4 {
		@extend %page-head-med;
	}
	p {
		@extend %page-text-med;
		&.centered {
			text-align: center;
		}
		&.small {
			font-size: 1.076923077em;
			line-height: 1.571428571em;
		}
		&.mug-text {
			font-size: 1.076923077em;
			line-height: 1.571428571em;
		}
	}
	p.page-text-sm {
		@extend %page-text-sm;
	}
	a {
		@extend %alternate-link--underline;
			&.button {
			@extend %button-lg--fancy;
			margin-top: 2em;
			text-align: center;
		}
		&.ghost-button {
			text-decoration: none !important;
		}
	}
	ul {
		@extend %page-text-med;
		@extend %bulleted;
		&.small {
			font-size: 1.076923077em;
			line-height: 1.571428571em;
		}
		ul {
			font-size:inherit;
		}
		ol {
			@extend %numbered;
			font-size:inherit;
		}
	}
	ol {
		@extend %page-text-med;
		margin-left: 1.55em;
		&.small {
			font-size: 1.076923077em;
			line-height: 1.571428571em;
		}
		ul {
			font-size:inherit;
		}
		ol {
			font-size:inherit;
		}
	}
	td, %td {
		@extend %page-text-med;
		height: 2em;
		padding: 0 1em;
		vertical-align: middle;
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
		&.price {
			text-align: right;
		}
	}
	th {
		@extend %td;
		font-weight: bold;
	}
	img {
		max-width: 100%;
		height: auto;
	}
	blockquote {
		margin: 20px 0;
		padding: 0.5rem;
		padding-left: 1.5rem;
		border-left: 5px solid #00acc1;
		background-color: #eceff1;
	}

	.inter-block--quote {
		blockquote {
			p {
				color: #fff;
				font-size: 1em;
				&.inter-quote-attr {
					font-size: 0.5em;
				}
			}
		}
	}
	.table-mod {
		ul, ol {
			font-size: 1em;
			margin-top: -0.75em;
			li {
				font-size: 1em;
				margin-bottom: 0.5em;
			}
		}
		&.table-expandable {
			p, li {
				@extend .page-text-sm;
			}
			.table-hdr {
				padding-bottom: 0 !important;
			}
		}
	}
	.button-lg,
	.button-lg--fancy {
		text-decoration: none;
		color: #fff;
		font-size: 1em;
		&:hover {
			text-decoration: none;
		}
	}
}

// Specific styles for Sales landing pages

.sales-landing {
	padding-bottom: 0;
	.client-logo {
		max-height: 150px;
	}
	.grid-3 {
		margin-top: 4em;
	}
	.grid-2-sm {
		margin: 4em auto 0; 
		max-width: 550px;
		.media-block {
			width: auto;
			&:first-child {
				@media(min-width: $medium) {
					padding-left: 1.5em;
				}
			}
		}
	}
	.l-13--center {
		padding-left: 2em;
		padding-right: 2em;
		@media(min-width: $large) {
			padding-left: 0;
			padding-right: 0;
		}
	}
	.cta-section {
		background-color: $bg-lt;
		padding: $space-grid--half 0 $space-grid;
		margin-bottom: -$space-grid;
		@media(min-width: $large) {
			padding: $space-grid 0 $space-grid*2 0;
		}
		.section-hdr-lg {
			color: $green-main;
		}
	}
	.compound-block--top + .cta-section {
		background-color: #fff;
		.l-13--center {
			background-color: #fff;
			padding-top: $space-grid--half;
			@media(min-width: $large) {
				margin-top: -$space-grid * 2;
				padding-top: $space-grid;
			}
		}
	}
	.page-mod {
		&:last-child,
		&:last-of-type {
			.compound-block--top {
				padding-bottom: $space-grid*2;
			}
			.compound-block--bottom {
				padding-bottom: 62.5px;
			}
		}
	}
}


// Specific styles for the Case Studies

.case-study {
	blockquote {
		font-color: #000;
		p {
			color:#000;
		}
		footer {
			font-style: normal;
			font-size: .82em;
		}
	}
	.img {
		float: left;
		border-radius: 50%;
  		margin-right: 20px;
	}
}

// Specific styles for the nymity workbook tool in the RC

.nymity-workbook-wrapper {
	.hide {
		visibility: hidden;
	}
	.visible {
		visibility: visible;
	}
	text-align: center;
	padding: 3em 0;
	a img {
		max-width: 100%;
		@media (min-width:$medium) {
			display: none;
		}
	}
	.social-list-items {
		margin-top: 1em;
		margin-bottom: 1em;
		text-align: center;
		@media(min-width: $large) {
			text-align: left;
		}
		.icon-comment,
		.icon-print {
			display: none;
		}
		.icon-facebook {
			@media(max-width: $large) {
				margin-left: 0;
			}
		}
	}
	.print-v {
		text-align: center;
		display: block;
		text-align: center;
		margin: 1em;
		text-decoration: none;
		color: $link-color;
		&:hover {
			text-decoration: underline;
		}
		&:before {
			content: "\f019";
			font-family: FontAwesome;
			margin-right: 0.5em;
		}
		@media(min-width: $medium) {
			text-align: right;
		}
	}
	.nymity-container {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex; 
		-webkit-align-items: center;
		-ms-flex-align: center; 
		align-items: center; 
		justify-content: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		background-repeat: no-repeat;
		background-position: center center; 
		@media(min-width: $medium) {
			background-image: url('/media/images/publications/nymity-bg-web.jpg');
			background-size: cover; 
			height: 100%;
			padding: 2em 0;
			position: relative;
			height: 692px;
			z-index: 10;
		}
		.flex {
			display: -webkit-box;
			display: -moz-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: center;
			-ms-flex-align: center; 
			align-items: center; 
		} 
		.circle {
			margin: 0 auto; 
			height: 185px; 
			width: 185px; 
			@extend .flex; 
			justify-content: center;
			-ms-flex-pack: center;
			-webkit-justify-content: center; 
			border-radius: 50%; 
			box-shadow: -10px 10px 30px #BDBDBD;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
		}
		p.title {
			color: #ffffff;
			line-height: 1.2em;
			@media (min-width:$medium) {
				margin: 0 1em;
				max-width: 165px;
			} 
		}
		ul > li:before {
			font-family: FontAwesome;
			content: "\f111";
			font-size: 0.4em;
			vertical-align: middle;
			padding-right: 0.75em;
		}
		ul > li > a {
			color: #ffffff;
		}
		.circle-txt {
			margin: 0 1em; 
			color: #ffffff;
		}
		.inner-container {
			display:none;
			@media (min-width: $medium) {
				display: block;
				position:relative;
				height: 640px;
			}
			align-self: center; 
			width: 100%;
			background-image: none;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			.absolute {
				position:absolute;
				left:0px;
				top:0px;
				bottom:0px;
				right:0px;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover; 
				opacity:0;
			}
			.nymityExcel {
				z-index:-4; 
				background-image:url('/media/images/publications/nymity-bg-new-2.png');
				@extend .absolute;
			}
			.firstArrow {
				z-index:-3; 
				background-image:url('/media/images/publications/first-arrow.png');
				@extend .absolute;
			}
			.secondArrow {
				z-index:-2; 
				background-image:url('/media/images/publications/second-arrow.png');
				@extend .absolute;
			}
			.thirdArrow {
				z-index:-1; 
				background-image:url('/media/images/publications/third-arrow.png');
				@extend .absolute;
			}
			.privacy-management {
				position:absolute;
				left:0px;
				top:201px;
				bottom:0px;
				right:0px;
				z-index: -1;
				opacity: 0;
				p {
					font-size: 1.3em;
					text-transform: uppercase;
					font-weight: bold;
				}
			}
			.flex-row {
				clear: left; 
				width: 100%;
				.top-left {
					float: left; 
					margin: 0 auto; 
					height: 185px; 
					width: 25%;
					@extend .flex;
					-webkit-flex-direction: row-reverse;
					-ms-flex-direction: row-reverse;  
					flex-direction: row-reverse;
					.start-here {
						color: #71BF4B;
						font-size: 1.384615385em;
					}
				}
				.top-middle {
					width: 50%; 
					text-align: center; 
					float: left;
					div {
						background: url('/media/images/publications/nymity-circle-top.png') center center no-repeat;
						@extend .circle;
					}
				}
				.top-right {
					float: left; 
					margin: 0 auto; 
					height: 185px; 
					width: 25%; 
					@extend .flex;
					-webkit-flex-direction: row;
					-ms-flex-direction: row; 
					flex-direction: row;
				}
			}
			.flex-row.middle {
				height: 270px; 
				@extend .flex;
				background-position: center center;
				background-repeat: no-repeat;
				.left {
					float: left; 
					width: 40%; 
					text-align: center;
					div {
						background: url('/media/images/publications/nymity-circle-left.png') center center no-repeat;
						@extend .circle;
					}
				}
				.middle {
					float: left; 
					width: 20%; 
					text-align: center;
					div {
						margin: 0 auto; 
						height: 185px; 
						@extend .flex; 
						justify-content: center;
						-ms-flex-pack: center;
						-webkit-justify-content: center;
						div.title {
							width: 100%;
						}
						div {
							p {
								font-size: 1.1em;
								padding: 1em;
								border: 2px solid $green-main; 
								margin-bottom: 0;
								color: $green-main;
								transition: all 200ms ease;
								background: rgba(255, 255, 255, 0.8);
								width: 100%;
								a {
									text-decoration: none;
									color: inherit; 
								}
							}
							p:hover {
								color: #fff;
								background-color: $green-main !important;
							}
						}
					}
				}
				.right {
					float: left; 
					width: 40%; 
					text-align: center;
					div {
						background: url('/media/images/publications/nymity-circle-right.png') center center no-repeat;
						@extend .circle;
					}
				}
			}
			.flex-row.bottom {
				.middle {
					clear: left; 
					width: 100%; 
					text-align: center;
					div {
						background: url('/media/images/publications/nymity-circle-bottom.png') center center no-repeat;
						@extend .circle;
					}
				}
			}
		}
	}
}


// Specific styles for the WLP infographic in the RC

.wlp-graphic-wrapper {
	padding: 3em 0;
	.social-list-items {
		margin-top: 1em;
		margin-bottom: 1em;
		text-align: center;
		@media(min-width: $large) {
			margin-bottom: -3em;
			text-align: left;
		}
		.icon-comment,
		.icon-print {
			display: none;
		}
		.icon-facebook {
			@media(max-width: $large) {
				margin-left: 0;
			}
		}
	}
	.print-v {
		display: block;
		text-align: right;
		margin: 1em;
		text-decoration: none;
		color: $link-color;
		&:hover {
			text-decoration: underline;
		}
		&:before {
			content: "\f019";
			font-family: FontAwesome;
			margin-right: 0.5em;
		}
		@media(max-width: $large) {
			display: none;
		}
	}
	.wlp-graphic {
		display: block;
		font-size: 1.5em;
		.brk {
			display: none;
			@media(min-width: $large) {
				display: inline;
			}
		}
		.spc {
			display: inline;
			@media(min-width: $large) {
				display: none;
			}
		}
		.hidden {
			@media(min-width: $large) {
				visibility: hidden;
			}
		}
		.visible {
			@media(min-width: $large) {
				visibility: visible;
			}
		}
		.up-white {
			color: #fff;
			text-transform: uppercase;
			font-size: 1em;
			letter-spacing: 0.05em;
			font-weight: bold;
			@media(min-width: $large) {
				font-size: 1.2em;
			}
		}
		.white {
			color: #fff;
			font-weight: $sans-weight-light;
		}
		.lt-orange {
			color: #ffe3d9;
			text-transform: uppercase;
			font-size: 1em;
			letter-spacing: 0.05em;
			font-weight: $sans-weight-light;
			@media(min-width: $large) {
				font-size: 1.2em;
			}
		}
		.up-yellow {
			color: #ffc63f;
			text-transform: uppercase;
			font-size: 1em;
			letter-spacing: 0.05em;
			font-weight: bold;
			@media(min-width: $large) {
				font-size: 1.2em;
			}
		}
		.yellow {
			color: #ffc63f;
			font-weight: $sans-weight-light;
		}
		.up-orange {
			color: #fc5b4c;
			text-transform: uppercase;
			font-size: 1em;
			letter-spacing: 0.05em;
			font-weight: bold;
			@media(min-width: $large) {
				font-size: 1.2em;
			}
		}
		.orange {
			color: #fc5b4c;
			font-weight: $sans-weight-light;
		}
		.wlp-block {
			padding: 1em;
			position: relative;
			@media(min-width: $large) {
				padding: 2em;
			}
			&.wlp-hdr {
				background-color: #61bd5a;
				.iapp-logo {
					width: 60px;
					margin-bottom: 1.5em;
				}
				h1 {
					font-size: 1em;
					color: #fff;
					text-align: center;
					font-weight: $sans-weight-light;
					letter-spacing: 0.05em;
					@media(min-width: $large) {
						font-size: 1.2em;
					}
					span {
						display: block;
						margin-top: 0.05em;
						text-transform: uppercase;
						font-weight: bold;
						font-size: 1.35em;
						@media(min-width: $large) {
							font-size: 1.55em;
						}
					}
				}
			}
			&.wlp-headlines {
				background-color: #003e52;
				background-image: url(/wp-content/themes/iapp/static/img/wlp-graphic/news.jpg);
				background-repeat: no-repeat;
				background-size: cover;
				background-position: 50%;
				z-index: 0;
				padding: 4em 2em 2em;
				&:after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: #003e52;
					opacity: 0.85;
					z-index: -1;
				}
				p {
					margin-bottom: 2em;
				}
				.ind {
					@media(min-width: $large) {
						padding-left: 2em;
					}
				}
			}
			&.wlp-field {
				color: #003e52;
				background-color: #fc5b4c;
				font-size: 1.2em;
				@media(min-width: $large) {
					font-size: 1.5em;
				}
				p {
					font-weight: $sans-weight-light;
					line-height: 1.5em;
				}
			}
			&.wlp-numbers {
				color: #fff;
				background-color: #003e52;
				overflow: hidden;
				p {
					clear: both;
					margin-bottom: 1em;
					font-size: 1.2em;
					font-weight: $sans-weight-light;
				}
				.center-img {
					@media(max-width: $large) {
						display: block;
						text-align: center;
						&.charts {
							width: 245px;
							margin: 0 auto;
						}
					}
				}
				.salary {
					margin-top: 4em;
					@media(min-width: $large) {
						margin-top: 7em;
					}
				}
				.ed {
					margin-top: 2em;
					@media(min-width: $large) {
						margin-top: -6em;
					}
				}
				.white {
					strong {
						letter-spacing: 0.05em;
					}
				}
				.flt-rt {
					@media(min-width: $large) {
						float: right;
						text-align: right;
					}
				}
				.cols, .col {
					clear: both;
					overflow: hidden;
				}
				.cols {
					margin-bottom: 2em;
				}
				.col {
					margin-bottom: 1em;
				}
				.col-40 {
					@media(min-width: $large) {
						width: 40%;
						float: left;
					}
				}
				.col-60 {
					@media(min-width: $large) {
						width: 60%;
						float: left;
					}
				}
				.rt {
					@media(min-width: $large) {
						float: right;
					}
				}
				.diploma {
					margin-left: -0.75em;
				}
				.male-books, .female-books {
					clear: both;
					overflow: hidden;
					.books {
						float: left;
						padding-top: 0.5em;
						margin-left: 2px;
						@media(max-width: $large) {
							width: 50%;
						}
						.book {
							margin: -2px;
						}
						.fm-end {
							margin-top: -3px;
						}
					}
					.pct {
						float: right;
						clear: none;
						margin-bottom: 0;
						margin-top: 0.65em;
						@media(min-width: $large) {
							float: left;
						}
					}
				}
				.bags {
					.money-bag-line {
						display: none;
						@media(min-width: $large) {
							display: block;
							margin-bottom: 0.075em;
						}
						&:first-child {
							margin-top: 1.5em;
						}
						.money-bag {
							margin-right: -6px;
						}
					}
					p {
						font-size: 0.9em;
					}
					.up-orange, .up-yellow {
						margin-bottom: 0.2em;
						font-size: 1.2em;
					}
				}
				.trees {
					clear: both;
					text-align: center;
					padding: 1em 0 3em;
					overflow: hidden;
					.tree {
						max-height: 260px;
					}
					.tree-number {
						display: block !important;
						clear: both;
						margin-top: -1.9em;
						margin-bottom: 0.75em;
						font-size: 5.3em;
						font-weight: bold;
					}
					.tree-label {
						font-size: 2.2em;
						font-weight: bold;
					}
					.female {
						color: #fc5b4c;
					}
					.male {
						color: #ffc63f;
					}
				}
				.col-50 {
					@media(min-width: $large) {
						float: left;
						width: 50%;
					}
				}
				strong {
					letter-spacing: 0.05em;
				}
			}
			&.wlp-cert {
				background-image: url(/wp-content/themes/iapp/static/img/wlp-graphic/wlp-cert.png);
				background-repeat: no-repeat;
				background-size: cover;
				overflow: hidden;
				font-size: 1.3em;
				.seals {
					@media(max-width: $large) {
						display: block;
						text-align: center;
					}
					.seal {
						width: 30%;
						@media(min-width: $small) {
							width: 75px;
							margin-right: 0.5em;
							&:last-child,
							&:last-of-type {
								margin-right: 0;
							}
						}
					}
				}
				.right-img {
					@media(min-width: $large) {
						float: right;
						margin-top: -1em;
						margin-right: -0.5em;
						margin-bottom: -1em;
						margin-left: 1em;
					}
				}
				p {
					line-height: 1.4em;
					margin-top: 2em;
					@media(min-width: $large) {
						max-width: 60%;
						float: right;
						text-align: right;
					}
					.ind {
						@media(min-width: $large) {
							display: block;
							margin-left: 1em;
						}
					}
					.up-yellow {
						display: block;
						@media(min-width: $large) {
							margin-left: -1em;
						}
					}
				}
			}
			&.wlp-gap {
				color: $black-80;
				font-size: 1.4em;
				font-weight: $sans-weight-light;
				border-left: 1px solid #efefef;
				border-right: 1px solid #efefef;
				p {
					margin-bottom: 1em;
					@media(min-width: $large) {
						max-width: 80%;
					}
				}
				span {
					text-transform: uppercase;
					font-weight: bold;
				}
				.chart-2 {
					max-width: 100%;
					@media(min-width: $large) {
						margin-left: 2em;
					}
				}
			}
			&.wlp-ftr {
				font-size: 0.8em;
				color: $green-main;
				font-weight: $sans-weight-light;
				border-left: 1px solid #efefef;
				border-right: 1px solid #efefef;
				border-bottom: 1px solid #efefef;
				padding-bottom: 6em;
				.right-ind {
					margin-bottom: 2em;
					@media(min-width: $large) {
						margin-right: 6em;
					}
				}
				.flt-right {
					text-align: center;
					@media(min-width: $large) {
						float: right;
						text-align: right;
						margin-right: 2em;
					}
					a {
						color: $green-main;
						text-decoration: none;
						text-transform: uppercase;
						font-weight: bold;
					}
				}
			}
		}
	}
}

.sections-wrapper {
	.page-mod {
		clear:both;
	}
	// Type Custom Styles
	.subsection-hdr-bold {
		color: white;
		padding: 1em;
		background-color: #8cc073;
		text-align: left;
	}
	blockquote {
		font-size: 1.25em;
		line-height: 1.75em;
		background-color: #eeeeee;
		padding: 1em;
		margin-bottom: 1em;
	}
	.static {
		h4 {
			font-size: 1.5em;
			margin-bottom: 0.75em;
		}
		p {
			@extend %page-text-sm;
		}
		a {
			@extend %alternate-link--underline;
		}
		a.button-lg--fancy {
			color: #fff!important;
			text-decoration: none;
		}
	}

	// Media Custom Styles
	.headshot {
		float: left;
		max-width: 115px;
		border-radius: 50%;
		overflow: hidden;
		height: auto;
	}
	p.video {
		margin: 0;
	}

	// Styles for actual content blocks
	.content {
		padding: 1em;
		border: 2px solid #efefef;
		background-color: #fff;
		margin-bottom: 1em;
		width: 100%;
	}

	// Half Column Layout
	.half {
		.content-1 {
			@media (min-width: $medium) {
				width: 48.5%;
				max-width: 48.5%;
				float: left;
			}
		}
		.content-2 {
			@extend .content-1!optional;
			@media (min-width: $medium) {
				float: right;
			}
		}
		.content-3 {
			@extend .content-2!optional;
		}
	}

	// 60/40 Layout
	.sixty_40 {
		.content-1 {
			@media (min-width: $medium) {
				float: left;
				width: 55%;
				max-width: 55%;
				margin-right: 3.33333%;
			}
		}
		.content-2 {
			@media (min-width: $medium) {
				float: right;
				width: 41.66666%;
				max-width: 41.66666;
				margin-right: 0;
			}
		}
		.content-3 {
			clear: right;
			@extend .content-2!optional;
		}
	}

	// 40/60 Layout
	.forty_60 {
		.content-1 {
			@media (min-width: $medium) {
				float: left;
				width: 41.66666%;
				max-width: 41.66666;
				margin-right: 3.33333%;
			}
		}
		.content-2 {
			@media (min-width: $medium) {
				float: right;
				width: 55%;
				max-width: 55%;
				margin-right: 0;
			}
		}
		.content-3 {
			clear: none;
			@extend .content-2!optional;
		}
	}

	// Resource/News Custom Styles
	.small-stream {
		.tz {
			padding-left: 0;
			margin-bottom: 0;
	    	padding-bottom: 0;
			.tz-head-date {
				text-align: right;
			}
			.tz-head-med {
				font-size: 1.25em;
			}
			.tz-saveable-block {
				top: -13px;
				left: -2em;
				@media (min-width: $medium) {
					left: -10px;
					top: -13px;
				}
			}
			.art-bd {
				max-width: 90px !important;
				left: 0 !important;
				.hex-lg {
					margin-left: 30px;
					&:before {
						content: "";
						position: absolute;
						top: 0;
						bottom: 0;
						width: 0;
						height: 0;
					}
				}
			}
			.txt-bd {
				padding-left: 0;
				margin-left: 0;
			}
		}
		.resource-tz {
			min-height: 5.8em;
		}
	}
	
	// Left Column styles
	.left-col {
		width: 100%;
		@media (min-width: $medium) {
			clear: both;
			float: left;
			width: 48.5%;
			max-width: 48.5%;
			margin-bottom: 1em;
		}
	    & > .content {
	    	opacity: 0;
	    }
	}
	
	// Right Column styles
	.right-col {
		@extend .left-col;
		@media (min-width: $medium) {
			float: right;
			clear: right;
		}
	}
	
	// Upcoming Events Custom Styles
	.conference-tz-media {
		margin-bottom: 1em;
		&:last-child {
			margin-bottom: 0;
		}
		.txt-bd {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			padding-left: 2em;
			padding-right: 2em;
			padding-top: 4.7em;
			color: #fff;
			z-index: 1;
			a, p {
				color: #fff;
			}
		}
	}
	.upcoming_events {
		text-align: center;
		p:first-of-type {
			text-align: left;
		}
		img {
			padding-bottom: 1em;
			max-width: 100%;
			float: none;
			border-radius: 0%;
		}
	}
}
