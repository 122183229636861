@import "../../variables/color-var";
@import "../../variables/type-var";

.ng-modal {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  
  .ng-modal-overlay {
    position:absolute;
    z-index:9999;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:#000000;
    opacity: 0.8;
  }



  .ng-modal-dialog {
    display: flex;
    flex-direction: column;
    z-index:10000;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
  
    background-color: $bg-med;
    box-shadow: 4px 4px 80px #000;
    -webkit-box-shadow: 4px 4px 80px #000;
    -moz-box-shadow: 4px 4px 80px #000;
    -ms-box-shadow: 4px 4px 80px #000;
    -o-box-shadow: 4px 4px 80px #000;
  }

  .ng-modal-dialog-content {
    overflow: hidden;
    height: 100%;
    padding: 5px;
  }
  .ng-modal-close {
    position: absolute;
    right: 5px;
    cursor: pointer;
    font-size: $base-reg;
  }
  .ng-modal-close-x {
    font-weight: bold;
    font-family: Arial, sans-serif;
  }
  .ng-modal-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: $sans-weight-bold;
    font-size: $base-med;
    color: #fff;
    background-color: $green-main;
    padding: 5px 0 5px 15px;
  }
  .ng-modal-help-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

  .ng-modal-collapsible {
    display: inline-block;
      overflow-y: scroll;
      max-height: 0;
      transition: all 1s;
      -webkit-transition: all 1s;        
      -moz-transition: all 1s;        
      -o-transition: all 1s;
  }
  
  .ng-modal-collapsible.showEpubHelp, .ng-modal-collapsible.showKindleHelp {
    max-height: 575px;
  }

  @media screen and (max-width: 1052px) {
    .ng-modal-dialog {
      display: flex;
      flex-direction: column;
      position: absolute;
      z-index: 9999;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
    
      background-color: $bg-med;
      box-shadow: 4px 4px 80px #000;
      -webkit-box-shadow: 4px 4px 80px #000;
      -moz-box-shadow: 4px 4px 80px #000;
      -ms-box-shadow: 4px 4px 80px #000;
      -o-box-shadow: 4px 4px 80px #000;
    }
    .ng-modal-dialog-content {
      overflow-y: scroll;
      height: 100%;
      padding: 5px;
    }

    .ng-modal-collapsible {
      display: inline-block;
      max-height: 0;
      overflow-y: scroll;
      transition: all 1s;
      -webkit-transition: all 1s;
      -moz-transition: all 1s;
      -o-transition: all 1s;
    }

    .ng-modal-collapsible.showEpubHelp, .ng-modal-collapsible.showKindleHelp {
      
    }
  }