// --------------------------------------------
// --------------------------------------------
//
// Custom media queries
//
// --------------------------------------------
// --------------------------------------------


$xlarge-screen-up: 1401px !default;
$xlarge-screen: 1400px !default;

$xlarge-and-up: "only screen and (min-width : #{$xlarge-screen-up})" !default;

@media #{$xlarge-and-up} {
  .container {
    width: 85%;
  }
}

body {
	padding-top: 56px;
	@media #{$large-and-up} {
		padding-top: 100px;
	}
}

#env-indicator {
	margin: 0;
    bottom: 0;
    right: 75px;
    font-size: 30px;
    color: #fff;
    position: fixed;
    display: block;
    z-index: 99999999;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 5px 20px;
	@media #{$medium-and-down} {
		display: none !important;
	}
	&.production {
        background-color: red;
    }
    &.staging {
        color: #808285;
        background-color: yellow;
    }
    &.dev {
        background-color: green;
    }
}

// hides angular
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}


// Table styles

.table-column-hdr {
	@extend .grey-text, .text-darken-3;
	font-size: 1rem;
	font-weight: bold;
	&.sort-trigger {
		position: relative;
		cursor: pointer;
		i {
			position: absolute;
			margin-left: 0.5rem;
			color: map-get($grey, "lighten-2");
			transform: translateY(0.45rem);
			transition: color .28s ease;
			&:nth-child(1) {
				margin-top: -0.45rem;
			}
			&.fa-active {
				color: map-get($green, "base");
			}
		}
	}
}

.collection {
	&.card {
		border-top: none;
	}
	&.key {
		.collection-header {
			padding: 1rem;
			font-size: 18px;
			font-weight: bold;
		}
		.collection-item {
			padding: 0.5rem;
			font-size: 13px;
		}
	}
	.collection-item {
		&.active {
			background-color: map-get($grey, "base");
			color: #fff !important;
		}
	}
}

// Pagination

.pagination {
	li {
		&.active {
			@extend .cyan, .darken-1;
		}
	}
}

.list-pagination {
	background-color: #fff;
}

.comment-count {
	@extend .badge, .new;
	position: absolute;
    right: initial;
    margin-top: 0.5rem;
    margin-left: 0 !important;
	font-weight: 300;
    font-size: 0.8rem;
    color: #fff !important;
    border-radius: 2px;
    background-color: map-get($grey, "base") !important;
}

// Video embeds 

.video-container + .video-container,
iframe + iframe {
	margin-top: 2rem;
}

// Images with captions

p {
	> .size-full { width: auto; max-width: 100%; height: auto; }
	> .alignright { 
		margin-bottom: 1em; 
		@media #{$large-and-up} {
			float: right; 
			margin-left: 1em; 
		}
	}
	> .alignleft { 
		margin-bottom: 1em; 
		@media #{$large-and-up} {
			float: left; 
			margin-right: 1em; 
		}
	}
	> img { margin-bottom: 1em; }
}

.wp-caption {
	display: inline-block;
	overflow: hidden;
	&.size-full { width: 100%; max-width: 100%; height: auto; }
	&.alignright { width: 100%; float: right; margin-left: 1em; margin-bottom: 1em; }
	&.alignleft { width: 100%; float: left; margin-right: 1em; margin-bottom: 1em; }
}

.wp-caption-text {
	@include sans;
	@extend .grey, .lighten-4;
	font-size: 0.85rem !important;
	padding: 0.85em 0.5em;
	margin: 0 10px 0 0;
}


.cap-image-right,
.cap-image-left {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1em;
	@media(min-width: 300px) {
		float: left;
		max-width: 100%;
		height: auto;
		margin-right: 2em;
		margin-left: 0;
	}
}


.cap-caption {
	@include sans;
	font-size: 0.85em;
	line-height: 1.35em;
	margin-top: 0.85em;
}

// Search
.badge-search {
	@extend .badge, .new, .grey; 
	width: 33%;
	margin-left: 0 !important;
}

// Static pages
.static-page {
	.inter-block--quote {
		blockquote {
			p {
				color: #fff;
				font-size: 1em;
				&.inter-quote-attr {
					font-size: 0.5em;
				}
			}
		}
	}
}

/* SS20 mobile styling */
@media screen and (min-width:769px) {
	#hidRelease {
		visibility: hidden;	
	}	
}

@media screen and (max-width: 768px) {
	#pdfMobile {
		visibility: hidden;
	}
	#newLow {
		visibility: hidden;
	}
	#playMobile {
		position: absolute;	
	}
	#hidSpan {
		visibility: hidden; 
		position: relative;
	}
	#releaseSpan {
		padding-right: 300px;	
	}
}

@media screen and (max-width: 750px) {
	#releaseSpan {
		padding-right: 285px;	
	}
}

@media screen and (max-width: 733px) {
	#releaseSpan {
		padding-right: 140px;	
	}
}

@media screen and (max-width: 600px) {
	#releaseSpan {
		padding-right: 140px;	
	}
}

@media screen and (max-width: 596px) {
	#releaseSpan {
		padding-right: 75px;	
	}	
}

@media screen and (max-width: 500px) {
	#releaseSpan {
		padding-right: 15px;	
	}
}

@media screen and (max-width: 425px) {
	#pdfMobile {
		visibility: hidden;
	}
	#newLow {
		visibility: hidden;
	}
	#playMobile {
		position: absolute;	
	}
	#hidSpan {
		visibility: hidden; 
		position: relative;
	}
	#releaseSpan {
		padding-right: 0px;	
	}
}

@media screen and (max-width: 405px) {
	#newLow {
		position: absolute !important;
	}
	#releaseSpan {
		padding-right: 55px;	
	}
	#hidSpan{
		visibility: hidden; 
		position: absolute;
	}
	.titleMobile {
		padding-left: 60px;
	}
}

@media screen and (max-width: 375px) {
	#newLow {
		position: absolute !important;
	}
	#releaseSpan {
		padding-right: 55px;	
	}
	#hidSpan{
		visibility: hidden; 
		position: absolute;
	}
	.titleMobile {
		padding-left: 60px;
	}
}

@media screen and (max-width: 374px) {
	.titleMobile {
		padding-left: 60px !important;
	}
	#arrowMobile {
		position: absolute !important;
	}
}

@media screen and (max-width: 320px) {
	.titleMobile {
		margin-left: 30px !important;
	}
	#arrowMobile {
		position: absolute !important;
	}
	#releaseSpan {
		padding-right: 10px;	
	}
}