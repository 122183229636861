// --------------------------------------------
// --------------------------------------------
//
// Icon types
//
// --------------------------------------------
// --------------------------------------------
//

.icon, %icon {
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: contain;
	width: 15px;
	height: 15px;
	position: absolute;
	top: 5px;
	left: 5px;

	@each $icon in $icons {
		.icon-#{$icon} & {
			background-image: url(#{$svg-icon-path}#{$icon}_full.svg);
			.no-svg & { background-image: url(#{$png-icon-path}#{$icon}_med.png)}
		}
	}

	@each $session in $sessions {
		.icon-#{nth($session, 1)} & {
			background-image: url(#{$svg-icon-path}#{nth($session, 1)}_full.svg);
			.no-svg & { background-image: url(#{$png-icon-path}#{nth($session, 1)}_med.png)}
		}
	}

	@each $resource in $resources {
		.icon-#{nth($resource, 1)} & {
			background-image: url(#{$svg-icon-path}#{nth($resource, 1)}_full.svg);
			.no-svg & { background-image: url(#{$png-icon-path}#{nth($resource, 1)}_med.png)}
		}
	}

	.icon- & {
		background-image: url(#{$svg-icon-path}articles_full.svg);
		.no-svg & { background-image: url(#{$png-icon-path}articles_med.png)}
	}


	.icons-sm &, &.icon-sm {	}

	.icons-med &, &.icon-med {
		width: 20px;
		height: 20px;
		left: 0px;
	}

	.icons-med-social &, &.icon-med-social {
		width: 20px;
		height: 20px;
	}

	.icons-lg &, &.icon-lg {
		width: 30px;
		height: 30px;
		top: 12px;
		left: 0px;
	}

	.icons-lg-social &, &.icon-lg-social {
		width: 30px;
		height: 30px;
		top: 10px;
		left: 10px;
	}

	&.icon-tz {
		width: 38px;
		height: 38px;
		top: 10px;
		left: 10px;
	}


	.menu-icons &, &.icon-menu { }

	// For icons in the mega menu
	.icons-mega & {
		width: 35px;
		height: 45px;
		left: 6px;
		top: 0;
	}

	// Repositions icons for hex items in mega menu
	.icons-mega.hex-mega & {
		left: 0px;
		width: 24px;
		height: 42px;
	}

	.icons-pill &, &.icon-pill {
		width: 25px;
		height: 25px;
		left: 12px;
		top: 5px;
	}
}



// --------------------------------------------
// --------------------------------------------
//
// IAPP Jobs Icon
//
// --------------------------------------------
// --------------------------------------------

.tz-iapp-job {
	i {
		background-image: url(#{$svg-icon-path}job_full.svg);
		.no-svg & { background-image: url(#{$png-icon-path}job_med.png)};
	}
}



// --------------------------------------------
// --------------------------------------------
//
// Icon overlay
//
// --------------------------------------------
// --------------------------------------------

.overlay-icon {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 0;
	right: 0;
}


// --------------------------------------------
// --------------------------------------------
//
// Fancy font awesome styles
//
// --------------------------------------------
// --------------------------------------------


.fa-fancy--circle {
	display: table-cell !important;
	width: 3em;
	height: 3em;
	vertical-align: middle;
	text-align: center;
	font-size: 1.5em;
	border: 5px solid $black-10;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}

.icon-header {
	display: inline-block;
	margin-right: 1em;
	margin-bottom: -1em;
}

.sg-mug-icon {
	font-size: 2em;
	margin-bottom: 0;
	.fa-edit {
		color: $green-main;
	}
	.fa-image {
		color: $swatch-6;
	}
	.fa-laptop {
		color: $link-color;
	}
}


// --------------------------------------------
// --------------------------------------------
//
// Casebook
//
// --------------------------------------------
// --------------------------------------------


.casebook-social {
	text-align: center;
	.social-list-item {
		position: relative;
		z-index: 1000;
	}
	.brand-comment {
		display: none;
	}
}


// --------------------------------------------
// --------------------------------------------
//
// Session icons
//
// --------------------------------------------
// --------------------------------------------


.breakout-sessions {
	.hex-sm {
		.icon {
			top: 4px;
			left: -1px;
			z-index: 1000;
		}
		&.icon-traditional-breakout {
			.icon {
				top: 5px;
				left: 0px;
			}
		}
		&.icon-training-and-certification {
			.icon {
				top: 5px;
				left: -2px;
			}
		}
		&.icon-advanced-topics-in-privacy {
			.icon {
				top: 5px;
			}
		}
		&.icon-conversations-in-privacy {
			.icon {
				width: 16px;
				left: -2px;
			}
		}
		&.icon-conversations-at-psr {
			.icon {
				width: 16px;
				left: -2px;
			}
		}
		&.icon-networking {
			.icon {
				width: 17px;
				height: 16px;
				left: -3px;
			}
		}
		&.icon-general-session {
			.icon {
				width: 17px;
				height: 16px;
				top: 3px;
				left: -3px;
			}
		}
		&.icon-conversations-in-privacy {
			.icon {
				width: 22px;
				height: 22px;
				top: 1px;
				left: -5px;
			}
		}
		&.icon-conversations-at-psr {
			.icon {
				width: 22px;
				height: 22px;
				top: 1px;
				left: -5px;
			}
		}
		&.icon-the-little-big-stage {
			.icon {
				width: 17px;
				height: 17px;
				top: 4px;
				left: -3px;
			}
		}
		&.icon-privacy-tech-vendor-showcase {
			.icon {
				width: 15px;
				height: 15px;
				top: 4px;
				left: -1px;
			}
		}
        &.icon-spotlight-stage {
			.icon {
				width: 17px;
				height: 17px;
				top: 4px;
				left: -2px;
			}
		}
	}
	.hex-med {
		.icon {
			z-index: 1000;
			width: 20px;
			height: 20px;
			top: 6px;
			left: -1px;
		}
		&.icon-traditional-breakout {
			.icon {
				top: 7px;
				left: 0px;
			}
		}
		&.icon-conversations-in-privacy {
			.icon {
				width: 30px;
				height: 30px;
				top: 1px;
				left: -6px;
			}
		}
		&.icon-conversations-at-psr {
			.icon {
				width: 30px;
				height: 30px;
				top: 1px;
				left: -6px;
			}
		}
		&.icon-networking {
			.icon {
				left: -2px;
			}
		}
		&.icon-general-session {
			.icon {
				height: 21px;
				width: 21px;
				top: 5px;
				left: -2px;
			}
		}
	}
}
.trans-slow {
	-webkit-transition:0.4s;
    -moz-transition:0.4s;
    -ms-transition:0.4s;
    -o-transition:0.4s;
    transition:0.4s;
}