// --------------------------------------------
// --------------------------------------------
//
// Comments
//
// --------------------------------------------
// --------------------------------------------

.comments-items {
	@extend %list-items;
}


.comment-item {
	@extend %list-item;
	margin-bottom: 1.15em;
	border-bottom: $bdr-thin-lt;
}


.comment-meta {
	@extend %page-text-sm;
	margin-bottom: 0;
	color: $black-50;
}


.comment-author {
	@include sans(bold);
	color: $black-80;
	margin-right: 0.25em;
}


.comment-email {
	margin-right: 0.25em;
}

.comment-date { }


.comment-bullet {
	font-size: 1.25em;
	line-height: 0;
	color: $black-30;
	margin-right: 0.25em;
	position: relative;
	top: 2px;
}


.comment-content {
	@extend %page-text-med;
	color: $black-60;
}

.comment-link {
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}


// --------------------------------------------
// --------------------------------------------
//
// Comment Form
//
// --------------------------------------------
// --------------------------------------------

.comment-respond-mod {
	margin-bottom: $space-grid--half;
}


.comment-controls-block {
	display: none;
	@include pie-clearfix;
	.comment-is-active & {
		display: block;
	}
}

.logged-in-as {
	@include sans(bold);
	font-size: 1.25em;
	margin-bottom: 0.25em;
}

.logged-in-link {
	color: $black-90;
	text-decoration: none;
}

.form-allowed-tags {
	@media(min-width: $medium) {
		float: left;
		max-width: 65%;
	}
}

.form-submit {
	@media(min-width: $medium) {
		float: right;
	}
}

.comments-code {
	color: $black-30;
	font-size: 0.85em;
}


.input-textarea {
	.comment-is-active & {
		height: 150px;
	}
}
