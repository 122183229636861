// --------------------------------------------
// --------------------------------------------
//
// Search Form
//
// --------------------------------------------
// --------------------------------------------

.search-form {
	position: relative;
	width: 100%;
	.nav-search-block & {
		position: absolute;
		width: 100%;
		@media(min-width: $nav-bp-lg) {
			position: relative;
		}
		@media(min-width: $nav-bp-xlg) {
			text-align: center;
		}
	}
}


.search-input {
	border: 1px solid $bg-med;
	-webkit-appearance: none;
	-webkit-border-radius: 0;
	border-radius: 0;
	outline: 0;
	padding: 0 0.85em;
	text-transform: uppercase;
	height: 35px;
	width: 85%;
	margin: 0;
	@media(min-width: $nav-bp-sm) {
		width: 90%;
	}
	@media(min-width: $nav-bp-lg) {
		width: 93%;
	}
	.nav-search-block & {
		width: 75%;
		@media(min-width: $nav-bp-sm) {
			width: 92%;
		}
		@media(min-width: $nav-bp-med) {
			width: 95%;
		}
		@media(min-width: $nav-bp-lg) {
			width: 56.8888em;
			// margin-top: 1.2em;
		}
	}
}

.search-submit {
	@extend %button;
	position: relative;
	text-indent: -9999em;
	width: 35px;
	height: 35px;
	margin-bottom: 0;
	.nav-search-block & {

		@media(min-width: $nav-bp-lg) {
			// position: absolute;
			// margin-top: -2.7em;
			// right: -59.66666em;
		}
	}

	.icon.icon-lg {
		top: 3px;
		left: 3px;
	}
}




// --------------------------------------------
// --------------------------------------------
//
// Instant Search Results
//
// --------------------------------------------
// --------------------------------------------

.instant-search-mod {
	position: relative;
	@include pie-clearfix;
}

.instant-search-results {
	padding: 0 1.5em;
	background-color: #fff;
	border: $nav-bdr;
	position: absolute;
	top: 40px;
	width: 98%;
	@media(min-width: $nav-bp-lg) {
		width: 60em;
		top: 63px;
	}
	.js-instant-search & {
		display: block;
	}
}

.instant-search-content {
	border-top: 4px solid $green-main;
	position: relative;
	top: -4px;
	padding: 1em 0;
	&:before {
			content: '\0020';
			width: 0;
			height: 0;
			position: absolute;
			top: -3px;
			left: 50%;
			z-index: 1;
			background-color: transparent;
			border-top: 9px solid $green-main;
			border-right: 9px inset transparent;
			border-bottom: 9px inset transparent;
			border-left: 9px inset transparent;
		}
}

.instant-content-top {
	text-align: center;
	@include pie-clearfix;
	&.results {
		border-bottom: $bdr-thin-lt;
	}
}

.instant-content-bottom { }

.live-search-text {
	@extend %page-text-sm;
	color: $black-40;
	display: inline-block;
}

.live-search-term {
	color: black;
}

.instant-submit {
	@extend %button-secondary;
	display: inline-block;
	margin-left: 5px;
	margin-bottom: 0;
	border-radius: 0.5em;

}


.search-head-sm {
	@extend %tz-head-sm;
	margin-bottom: 0.1em;
}

.results-link-nav {
	padding: 0.65em 0.35em;
	text-decoration: none;
	display: block;
	&:hover {
		background-color: $bg-lt;
	}
}

.search-description-text {
	@extend %page-text-sm;
	margin-bottom: 0;
}

.results-items { }

.results-item {
	border-bottom: $bdr-thin-lt;
	&:last-child {
		margin: 0;
		padding: 0;
		border: 0;
	}
}

.child-search-results {
	.list-item {
		margin-left: 4em;
		&:before {
			font-family: FontAwesome;
			content: "\f101";
			position: absolute;
			margin-left: -2em;
			color: $black-40;
		}
	}
}



// --------------------------------------------
// --------------------------------------------
//
// Date picker
//
// --------------------------------------------
// --------------------------------------------


.date-picker {
	.horz-block {
		display: inline-block;
		float: left;
		margin-right: 3em;
		margin-top: 1em;
		&:last-child {
			margin-right: 0;
		}
		.sort-link {
			@extend %page-text-sm;
			color: $link-color;
			text-decoration: none;
			padding: 0 0.5em;
			&:last-child {
				padding-right: 0;
			}
			&.active {
				color: $black-80;
				font-weight: bold;
			}
		}
		span {
			display: none;
			@media(min-width: $medium) {
				display: inline-block;
			}
		}
	}
	.date-filter {
		width: 120px;
		border: none;
		background-color: $bg-lt;
		padding: 0.3em;
		margin-left: 0;
		margin-right: 0.4em;
		&:last-child {
			margin-left: 0;
		}
		@media(min-width: $medium) {
			margin-left: 0.4em;
		}
	}
	.date-clear, .date-clear i {
		color: $black-80;
	}
}

.pika-prev {
	text-indent: 0 !important;
}

.pika-prev:before {
	float: left;
	content: "\25C0";
}

.pika-next:before {
	float: right;
	content: "\25b6";
}



// --------------------------------------------
// --------------------------------------------
//
// Member Directory
//
// --------------------------------------------
// --------------------------------------------


.search-by {
	display: inline-block;
	padding: 0.5em 1.25em;
	border: 1px solid #d9d9d9;
	border-bottom: none;
	label {
		color: $black-30;
	}
	span.fip {
		margin-left: 1em; 
		color: #808285; 
		cursor: pointer;
	}
}



// --------------------------------------------
// --------------------------------------------
//
// RC Search
//
// --------------------------------------------
// --------------------------------------------


.rc-search {
	.refine {
		padding-bottom: 1em;
		@media(min-width: $medium) {
			float: left;
			max-width: 25em;
			padding-bottom: 0;
		}
		.refine-selector {
			margin-bottom: 0.5em;
			@media(min-width: $medium) {
				display: table;
				height: 34px;
				float: left;
				&:nth-child(2) {
					margin-left: 1em;
				}
			}
			.refine-select {
				@media(min-width: $medium) {
					display: table-cell;
					vertical-align: middle;
				}
				select {
					width: 100%;
					margin-left: 0;
					border: 0;
					padding: 0.5em;
					height: 30px;
					background-color: #f7f7f7;
					-webkit-appearance:none;
					@media(min-width: $medium) {
						max-width: 10em;
					}
					// option[value="7"],
					// option[value="8"],
					// option[value="16"] {
					// 	display: none;
					// }
				}
			}
		}
	}
	.rc-search-field {
		@media(min-width: $large) {
			float: right;
			width: 100%;
		}
		@media(min-width: $x-large) {
			width: 65%;
		}
		.search-input {
			float: left;
		}
		.search-submit {
			float: right;
		}
	}
}

.rc-search-results {
	.tz {
		.txt-bd {
			.tz-bd-text {
				a {
					text-decoration: none;
					color: $green-main;
					padding-left: 0.5em;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

