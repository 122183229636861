.leadgen-hdr {
	.site-nav {
		display: none;
	}
	.hdr {
		.hdr-content-mod {
			margin-top: -6em;
			.hdr-block--title {
				text-align: center;
				@media(min-width: $large) {
					text-align: left;
				}
				.hdr-h1-link, .hdr-tagline {
					visibility: hidden;
				}
				img {
					max-height: 200px;
					margin: 2em auto;
					@media(min-width: $large) {
						float: left;
						margin: 0 3em 0 0;
					}
				}
				.hdr-h1 {
					@media(min-width: $large) {
						font-size: 4em;
						padding-top: 0.6em;
					}
				}
			}
		}
	}
	&.cipt-blue {
		.hdr {
			background-color: $swatch-8a;
		}
	}
}

.ppc-logo-white {
	position: absolute;
	z-index: 10;
	top: 2em;
	right: 5%;
	width: 4em;
	@media(min-width: $medium) {
		width: 6em;
	}
	@media(min-width: $large) {
		width: 10em;
	}
}

.leadgen {

	padding: 2em;
	@media(min-width: $large) {
		padding: 4em 0;
	}

	h2 {
		@extend %tz-head-xlg;
		text-transform: uppercase;
		color: $green-main;
		text-align: center;
		@media(min-width: $large) {
			font-size: 4.5em;
			line-height: 1.1em;
			float: right;
			text-align: left;
			max-width: 54%;
			margin-left: 10%;
			padding-top: 0.3em;
			margin-bottom: 0.5em;
		}
	}

	h3.page-head {
		@extend %tz-head-xlg;
		color: $green-main;
		text-align: center;
		margin-bottom: 1em;
		@media(min-width: $large) {
			font-size: 3.6em;
			line-height: 1.1em;
			text-align: left;
		}
	}

	.hero-img {
		margin-bottom: 2em;
		text-align: center;
		@media(min-width: $large) {
			max-width: 36%;
		}
		img {
			max-width: 100%;
			margin-top: 2em;
			@media(min-width: $large) {
				margin-top: 0;
			}
		}
	}

	.hero-txt {
		@media(min-width: $large) {
			max-width: 50%;
			margin-right: 4%;
			float: left;
		}
	}

	.callout-box {
		margin-top: 2em;
		h3 {
			@extend %page-head-med;
			margin-top: 1em;
			margin-bottom: 1em;
			color: $black-40;
		}
		.fa-ul {
			margin-left: 1.5em;
			.fa-li {
				top: 0.28em;
				color: $green-main;
			}
		}
		li {
			margin-bottom: 1em;
		}
	}

	.three-col-list {
		overflow: hidden;
		margin-left: 0 !important;
		li {
			@media(min-width: $large) {
				width: 26%;
				float: left;
				margin: 0 4%;
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	form {
		background-color: $black-30;
		color: #fff;
		padding: 2em 3em;
		@media(min-width: $large) {
			float: right;
			max-width: 60%;
		}
		h4 {
			@extend %tz-head-lg;
			color: #fff;
		}
		.page-text-med {
			color: #e6e6e6;
		}
		.page-text-sm {
			color: #fff;
		}
		.form-block {
			border-bottom: none;
			margin: 0;
		}
		.button-lg--fancy {
			font-size: 2em;
		}
		a {
			color: #fff;
			@media(min-width: $large) {
				float: right;
			}
		}
	}
}

.thanks-head {
	color: $green-main;
	font-size: 3em;
	@media(min-width: $large) {
		font-size: 6em;
	}
}

.error-head {
	color: $red-lt;
	font-size:3em;
	@media(min-width: $large) {
		font-size: 6em;
	}
}

.thanks-text {
	font-size: 1.6em;
	@media(min-width: $large) {
		font-size: 2.8em;
	}
}

.ppc-form-bottom {
	clear: both;
	margin-top: 2em;
	@media(min-width: $large) {
		float: right;
		margin-top: -10em;
		max-width: 44%;
	}
}