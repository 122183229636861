.jvectormap-label {
    position: absolute;
    display: none;
    border: solid 1px #CDCDCD;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background: $black-80;
    color: #fff;
    font-family: sans-serif, Verdana;
    font-size: smaller;
    padding: 3px;
}

.jvectormap-zoomin, .jvectormap-zoomout {
    position: absolute;
    left: 1em;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background: #f2f2f2;
    padding: 0.5em;
    color: $black-80;
    cursor: pointer;
    line-height: 0.5em;
    text-align: center;
    width: 1.7em;
    height: 1.7em;
}

.jvectormap-zoomin {
    top: 1em;
}

.jvectormap-zoomout {
    top: 3em;
}

.map {
    height: 25em;
    @media (min-width: $medium) {   
        height: 40em;
    }
    @media (min-width: $large) {   
        height: 480px;
        &#act-map {
            height: 500px;
        }
    }
}

.state-tag {
    float: left; 
    margin-right: 1em; 
    margin-left: 0 !important;
}

.act-tag {
    @extend %button-secondary-sm;
}

.act-ast {
    font-size: 0.66em;
    color: $black-80;
}

.act-content-area {
    min-height: 800px;
}

.act-key {
    float: right;
    margin-top: -1em;
    .act-swatch {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: #98b1c0;
        margin-right: 1.5em;
        margin-bottom: -5px;
    }
}

.default-act {
    border: 4px solid #efefef;
    padding: 1em 1em 0;
    overflow: hidden;
    article {
        margin-bottom: 2em;
        padding: 1em;
        background-color: #e6e6e6;
        ol.numbered {
            li {
                margin-bottom: 0.5em;
                ul.bulleted {
                    margin-top: 0.5em;
                    li {
                        font-weight: 400;
                        line-height: 1.3em;
                    }
                }
            }
        }
    }   
}

.rules {
    border: 4px solid #efefef;
    padding: 1em 1em 0;
    overflow: hidden;
    .rules-header {
        overflow: hidden;
        margin-bottom: 1em;
    }
    .rule {
        overflow: hidden;
        margin-bottom: 1em;
        article {
            width: 49%;
            float: left;
            margin-right: 2%;
            margin-bottom: 2em;
            margin-top: -0.5em;
            padding: 1em;
            background-color: #e6e6e6;
            &:last-child {
                margin-right: 0;
                margin-top: 0;
                padding: 0.5em 0 0;
                background-color: #fff;
            }
            ol.numbered {
                li {
                    margin-bottom: 0.5em;
                    ul.bulleted {
                        margin-top: 0.5em;
                        li {
                            font-weight: 400;
                            line-height: 1.3em;
                        }
                    }
                }
            }
        }
    }
}