// --------------------------------------------
// --------------------------------------------
//
// Responsive Tables
//
// --------------------------------------------
// --------------------------------------------

table.responsive {
	th {
		font-weight: bold;
		padding: 9px 10px;
		text-align: left;
		vertical-align: top;
		font-size: 1.076923077em; 		// 14
		line-height: 1.571428571em;
	}
	td {
		padding: 9px 10px;
		text-align: left;
		vertical-align: top;
		font-size: 1.076923077em; 		// 14
		line-height: 1.571428571em;
	}
	@media (max-width: 767px) {
		border-bottom: 1px solid #ccc;
		min-height: 140px;
		&.responsive {
			margin-bottom: 0;
			th, td {
				position: relative;
				white-space: nowrap;
				overflow: hidden;
				height: 54px;
				vertical-align: middle;
				&:first-child {
					display: none;
				}
			}
			&.pinned {
				td {
					display: none;
				}
			}
		}
	}
}

.pinned {
	@media (max-width: 767px) {
		position: absolute; 
		left: 0; 
		top: 0; 
		background: #fff; 
		width: 35%; 
		overflow: hidden; 
		overflow-x: scroll; 
		border-right: 1px solid #ccc; 
		border-left: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
		table {
			border-right: none;
			border-left: none;
			width: 100%;
			th, td {
				white-space: nowrap;
				text-align: left;
				height: 54px;
				vertical-align: middle;
				font-size: 1.076923077em; 		// 14
				line-height: 1.571428571em;
				padding-left: 10px !important;
			}
		}
		th:first-child {
			padding-left: 0;
		}
		td:last-child {
			border-bottom: 0;
		}
	}
}

div.table-wrapper {
	@media (max-width: 767px) {
		position: relative; 
		margin-bottom: 20px; 
		overflow: hidden; 
		border-right: 1px solid #ccc;
		div.scrollable {
			margin-left: 35%;
			overflow: scroll; 
			overflow-y: hidden;
		}
	}
}




.reg-table {
	width: 100%;
	td {
		min-width: 80px;
		padding: 10px 5px;
	}
}

.bordered {
	tr {
		border-bottom: 1px solid #e6e6e6;
		td {
			padding-top: 1em;
			padding-bottom: 1em;
		}
	}
}



// --------------------------------------------
// --------------------------------------------
//
// UI Responsive Tables
//
// --------------------------------------------
// --------------------------------------------

.table-mod {
	margin-bottom: 6em;
}


.table {
	width: 100%;
	display: table;
}

.thead { }

.tbody { }

.row-spacer {
	border-top: 1em solid transparent;
	display: block;
}

.th-row {
	border-top: $bdr-thin-lt;
	border-bottom: $bdr-thin-lt;
	width: 100%;
	float: left;
}

.td-row {
	border-bottom: $bdr-thin-lt;
	clear: both;
	overflow: hidden;
	padding: 3em 0 1em;
	&:last-child {
		border-bottom: none;
	}
	@media(min-width: $x-large) {
		float: left;
		width: 100%;
		padding: 0;
	}
}


.table-th {
	@extend %subsection-hdr-bold;
	color: $link-color;
	text-align: left;
	padding: 1em 1em 0 0;
	text-transform: uppercase;
	display: none;
	@media(min-width: $x-large) {
		display: table-cell;
		float: left;
	}
}

.table-td {
	font-size: 1.2em;
	line-height: 1.2em;
	margin-bottom: 2em;
	clear: both;
	float: left;
	width: 100%;
	@media(min-width: $x-large) {
		clear: none;
		display: table-cell;
		padding: 0.75em 1em 0 0;
		margin-bottom: 1em;
	}
	a {
		color: $link-color;
	}
}

.table-th, .table-td {
	@media(min-width: $x-large) {
		&.one-tenth {
			width: 10%;
		}
		&.two-tenths {
			width: 20%;
		}
		&.three-tenths {
			width: 30%;
		}
		&.four-tenths {
			width: 40%;
		}
		&.five-tenths {
			width: 50%;
		}
		&.six-tenths {
			width: 60%;
		}
		&.seven-tenths {
			width: 70%;
		}
		&.eight-tenths {
			width: 80%;
		}
		&.nine-tenths {
			width: 90%;
		}
		&.one-twelvth {
			width: 8.333333333333%;
		}
		&.two-twelvths {
			width: 16.666666666667%;
		}
		&.three-twelvths {
			width: 25%;
		}
		&.four-twelvths {
			width: 33.333333333333%;
		}
		&.five-twelvths {
			width: 41.666666666667%;
		}
		&.six-twelvths {
			width: 50%;
		}
		&.seven-twelvths {
			width: 58.333333333333%;
		}
		&.eight-twelvths {
			width: 66.666666666667%;
		}
		&.nine-twelvths {
			width: 75%;
		}
		&.ten-twelvths {
			width: 83.333333333333%;
		}
		&.eleven-twelvths {
			width: 91.666666666667%;
		}
		&.full-width {
			width: 100%;
		}
	}
}

.table-td,
.profile-text-lg {
	@extend %page-text-med;
}

.mob-label {
	@extend %subsection-hdr-gray;
	margin-bottom: 0.3em;
	display: block;
	@media(min-width: $x-large) {
		display: none;
	}
}
.mob-hide {
	display: none;
	@media(min-width: $x-large) {
		display: block;
	}
}

.sched-btn {
	@media(min-width: $x-large) {
		position: absolute;
		left: 1050px;
		width: 10%;
	}
}

.cbt-schedule {
	.close-trigger {
		color: $link-color;
		text-decoration: none;
		float: right;
		&:hover {
			color: red;
		}
	}
}

.table-mod-style2 {
	.table {
		margin-top: 2em;
		.thead {
			.th-row {
				border: none;
				padding: 0.75em 1em 0 0.5em;
				.table-td {
					.table-hdr {
						display: block;
						width: 100%;
						text-align: center;
						font-weight: bold;
						font-size: 1.5em;
						line-height: 1.3em;
						padding-bottom: 1em;
					}
					&:first-child {
						border-bottom: 4px solid #efefef;
					}
				}
			}
		}
		.tbody {
			.td-row {
				border-bottom: none;
				padding: 0.75em 1em 0 0.5em;
				&:nth-child(even) {
					background-color: #f2f2f2;
				}
			}
		}
	}
}

.table-expandable {
	.table {
		margin-top: 4em;
		.td-row {
			position: relative;
			height: 250px;
			overflow: hidden;
			padding-bottom: 1em;
			&:before {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 10000;
				/* IE9 SVG, needs conditional override of 'filter' to 'none' */
				background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjY2JSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
				background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%); /* FF3.6+ */
				background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,1))); /* Chrome,Safari4+ */
				background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%); /* Chrome10+,Safari5.1+ */
				background: -o-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%); /* Opera 11.10+ */
				background: -ms-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%); /* IE10+ */
				background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%); /* W3C */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-8 */
			}
			&:after {
				font-family: "FontAwesome";
				font-size: 2em;
				content: "\f055";
				color: $black-30;
				position: absolute;
				left: 50%;
				margin-left: -1em;
				top: 220px;
				z-index: 10000;
				display: block;
				cursor: pointer;
			}
			&.expanded {
				height: auto;
				overflow: visible;
				&:before {
					display: none;
				}
				&:after {
					content: "\f056";
					top: auto;
					bottom: 0;
					margin-bottom: -1em;
				}
			}
		}
	}
}

#PrivacyCoreTable {
	.fip-search {
		font-size: 1.2em;
	}
}
.core-table {
	width: 100%;
	cursor: pointer;
	overflow: hidden;
	display: table;
	@media(min-width: $medium) {
		font-size: 1.231em;
		line-height: 1.555555556em;
		font-weight: $sans-weight-light;
	}
	.thead-core {
	    background-color: #4e565b;
	    border-top: 4px solid #cccccc;
	    border-bottom: 4px solid #cccccc;
	    @media(min-width: $medium) {
	    	padding: 1.2em;
	    }
	}
	.th-title {
		color: #fff;
		border:none !important;
		padding: 1.2em 0;
	}
	.row-core {
		border-top: 1px solid #e9eaea;
		border-bottom: 1px solid #e9eaea;
		opacity: 1;
		transition: opacity .5s linear all;
		.column-core {
			padding: .5em 0;
			@media(min-width: $desktop) {
				padding: 1em 1em 1em .5em;
			}
				.privacy-core-item {
					color: #444;
					text-decoration: none !important;
					
				}
				.fa-minus-circle {
					color: #fab150; 
					font-size:1.2em;
					&:hover {
						opacity: 1;
	    				transform: scale(1.1);
	    				@media(min-width: $desktop) {
	    					transform: scale(1.2);
	    				}
					}
				}
				.fa-plus-circle {
					color: $green-main; 
					font-size:1.2em;
					&:hover {
						opacity: 1;
	    				transform: scale(1.1);
	    				@media(min-width: $desktop) {
	    					transform: scale(1.2);
	    				}
					}
				}

		}
	}
	.ng-hide {
		 max-height: 0;
	}
	.desc-core {
		padding-top: 1em; 
		padding-bottom: 1em; 
		width: 100%;
    	padding-left: 1em;
    	max-height: 1000px;
		transition: max-height 0.5s ease-in-out; 
    	@media(min-width: $desktop) {
			padding-left: 4em;
		}
			.bulleted > li { 
				color:#808285;
				line-height: 2em;
				font-size: 0.92307692307692em !important;
				font-weight: 400 !important;
			}
	}
}

.dpo-ready-wrapper {
	.dpo-image-fallback {
		max-width: 100%;
		margin-bottom: 2em;
		@media (min-width: $x-large) {
			display: none;
		}
	}
	.dpo-infographic {
		width: 1024px;
		height: 675px;
		margin: 0 auto;
		margin-bottom: 2em;
		@media (max-width: $x-large) {
			display:none;
		}
		.two-twelvths {
			width:16.666666666667%;
			float:left;
			position:relative;
			height:100%;
			.dpo-woman {
				max-width: 100%;
				z-index: 2;
				position: absolute;
				bottom: 318px;
				right: -50px;
				opacity: 0;
			}
			.quote-container {
				position: absolute;
				z-index: 1;
				left: 0;
				top: 108px;
				.quote {
					padding: 0.5em;
				}
				.byline {
					padding:0.5em; 
					color: $green-main;
				}
			}
			.pillar-link {
				cursor: pointer;
			}
			.pillar-text-container {
				position: absolute;
				top: 62%;
				padding: 0 1em;
				z-index: 1;
				pointer-events:none;
				transition: all .2s linear;
				.pillar-title {
					font-size: 1.5em;
					margin-bottom: 1.3em;
					color: white;
					position: relative;
					left: initial;
				}
				.pillar-text {
					font-size: 1em;
					color: white;
					position: relative;
					left: initial;
					margin-right: 20px;
				}
			}
			.pillars {
				max-width: 100%;
				z-index:1;
				position: absolute;
				bottom: 0;
				transition: all .2s linear;
			}
			.div-slide-up {
				height: 100%; 
				width: auto; 
				background-color: white; 
				z-index: 3;
				pointer-events: none;
				position: relative;
			}
		}
	}
	.dpo-columns {
		clear:both;
		margin-top:2em;
		.twenty-percent {
			width:20%;
			float:left;
			@media (max-width: $large) {
				width: 50%;
				display: none;
				&.sticky {
					display: block;
				}
				&.active {
					display: block;
				}
			}
			.col-fixed-hd {
				width: 100%; 
				height:117px;
				border-bottom: 2px solid #dedede;
				position: relative;
				.col-fixed-sub-hd {
					position: absolute;
					bottom: 0;
					width: 100%;
    				font-weight: 400;
				}
				p {
					padding: 0.5em;
					color: #0c7ec0;
    				text-transform: uppercase;
    				font-weight: 700;
    				font-size: 1.1em;
    				text-align: center;
				}
			}
			.col-fixed-txt {
				height:40px;
				width:100%;
				display: table;
				border-bottom: 1px solid #dedede;
				i, span {
					display: table-cell;
					vertical-align: middle;
					.button-sm {
						margin-bottom: 0;
    					box-shadow: #a2a2a2 2px 2px 2px;
						font-size: 1em;
					}
				}
				i {
					font-size: 1.8em;
				}
			}
			.col-fixed-block {
				width:100%;
				text-align: center; 
				position: relative;
				.fixed-block-cell {
					display:table-cell;
					vertical-align:middle;
				}
				&:before {
					content: "OR";
					position: absolute;
					z-index: 2;
					top: 31px;
					right: 21%;
					font-size: 1.25em;
				}
				&:after {
					content: "]";
					position: absolute;
					z-index: 1;
					top: 25%;
					right: 35%;
					bottom: 14px;
					color: $green-main;
					font-size: 2.8em;
				}
			}
			&:nth-of-type(2n) {
				background-color:#f2f2f2;
			}
			&.fancy {
				border: $orange solid 2px;
				.col-fixed-hd {
					height: 99px;
					&:before {
						width: 45px;
						height: 45px;
						position: absolute;
						top: 31px;
    					left: -16px;
						content: " ";
						background: url('https://iapp.org/media/images/certification/bestValueOrange-01-01.png') no-repeat 0 0;
						background-size: 100%;
					}
				}
				.col-fixed-txt {
					&:last-child{
						border: none;
					}
				}
			}
		}
	}
	.nav-dpo-table{
		padding: 2em;
		clear: both;
		.chev-navigation {
			width: 50%;
			float:left; 
			text-align:center;
			span {
				color: $green-main;
				&.disabled {
					color: #dedede;
				}
			}
		}
		@media (min-width:$large) {
			display:none;
		}
	}
}
