// --------------------------------------------
// --------------------------------------------
//
// Event tease type
//
// --------------------------------------------
// --------------------------------------------

.event-tz-head {
	@extend %page-text-lg;
	@include sans(bold);
	color: $link-color;
	margin-bottom: 0.25em;
}


.event-tz-text-lg {
	@extend %page-text-lg;
	margin-bottom: 0.25em;
}


.event-tz-text-med {
	@extend %page-text-med;
	margin-bottom: 0.25em;
}

.event-tz-text-sm {
	@extend %page-text-sm;
	margin-bottom: 0.25em;
}



// --------------------------------------------
// --------------------------------------------
//
// Event teases
//
// --------------------------------------------
// --------------------------------------------

%event-block {
	margin-bottom: 2em;
}

.event-tz-mod { }

.event-head-block {
	@extend %event-block;
}

.event-info-block {
	@extend %event-block;
}

.event-register-block {
	@extend %event-block;
}


.event-controls-block {
	@include pie-clearfix;
	border-bottom: $bdr-thin-lt;
	border-bottom-width: 2px;
}

.event-register-form {
	@extend %event-block;
	&.is-active {
		display: block;
	}
}

.event-tabs {
	margin: 0.9em 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    background-color: #efefef;
    @media (min-width: $large) {
    	padding-top: 1em;
    	display: block;
    	width: 85%;
    	float:right;
    	background-color: inherit;	
    }
    @media (min-width: $x-large) {
    	width: 90%;
    }
	@media (min-width: $large-screen) {
		width: 100%;
    	float: none;
    }
    .event-tab {
    	padding: 0.6em 0.75em;
    	@media (min-width: $large) {
    		padding: 1em 0.75em;	
    	}
    	border: rgb(239, 239, 239) solid 4px;
    	border-bottom: 0;
    	background-color: rgb(239, 239, 239);
    	cursor: pointer;
    	position: relative;
    	display: inline;
    	&.active {
    		background-color: #fff;
    		padding-bottom: 0.9em;
    		@media (min-width: $large) {
    			padding-bottom: 1.2em;
    		}
		}
	}
}


