// --------------------------------------------
// --------------------------------------------
//
// Gallery layout
//
// --------------------------------------------
// --------------------------------------------


.gallery {
	.art-bd { 
		margin: 0 auto;
		padding-bottom: 4em; 
	}
	@media(min-width: 400px) and (max-width: $grid-bp-sm) {
		@include media-grid(2, $space-grid);
	}
	@media(min-width: $grid-bp-sm) {
		@include media-grid(4, $space-grid);
	}
	img {
		max-width: 100%;
	}
}