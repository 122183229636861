footer {
	position: relative;
	padding-top: 0.75rem;
	z-index: 20;
	h5 {
		@include sans(regular);
	}
	.ftr-list-item {
		float: left;
		margin-right: 2rem;
		width: auto;
		font-size: inherit;
	}
	.footer-copyright {
		.container {
			.row {
				margin-bottom: 0;
			}
		}
	}
	.footer-social-list {
		float: right;
		text-align: left;
		padding: 0;
		margin: -0.75rem 0;
		li {
			float: left;
			margin-right: 2rem;
			a {
				color: #fff;
				font-size: 2rem;
				i {

				}
			}
		}
	}
	.language-ftr {
		clear: left;
		padding-top: 1em;
		@media #{$medium-and-down} {
			text-align: center;
		}
		.dropdown-trigger {
			border: 1px solid #fff;
		}
		.dropdown-content {
			background-color: map-get($blue-grey, "darken-3");
			li {
				a {
					color: #fff;
					text-transform: uppercase;
					font-family: "Tabac Sans";
					letter-spacing: 0.05em;
					&:hover {
						background-color: map-get($blue-grey, "darken-4");
					}
				}
			}
		}
	}
}