.calForm {
	margin: 13px 0 0 0;
}

.calendarList {
	float: right;
	width: 70%;
	border-top: 1px solid #CBD0D3;
}

.calendarEvent {
	border-bottom: 1px solid #CBD0D3;
	padding-bottom: 10px;
	line-height: 1.8rem;
    letter-spacing: .02em;
}

.calendarLink {
	text-decoration: none;
}

.calSpanOuter {
	font-size: 13px;
	cursor: default;
}

.calFontSize {
	font-size: 15px;
	cursor: default;
}

.calDetails {
	font-size: 15px;
	cursor: default;
	line-height: 1.5;
	color: #464547;
}

.calDetailsMobile {
	display: none;
}

.calTitleHover {
	font-size: 15px;
}

.calTitleHover:hover {
	font-size: 15px;
	text-decoration: underline;
}

.calFilterDiv {
	float: left;
	width: 25%;
	border: 1px solid #D9D9D9;
	box-shadow: 0px 3px 6px #00000029;
	padding: 0 15px;
}

.calFilterHead {
	font-size: 18px;
}

.calClearAll {
	color: #017698;
	float: right;
	font-size: 13px;
	border: none;
	background: none;
}

.calMobileFilter {
	display: none;
}

.calInput {
	width: 15px !important;
	height: 30px !important;
	cursor: pointer;
}

.calLabel {
	font-size: 15px;
	padding-left: 10px;
	cursor: default;
}

.calDate {
	font-size: 12px;
	color: #343638;
}

.calDateStart {
	width: 100%;
	height: 38px;
	color: #707070;
	margin-bottom: 10px;
}

.calDateEnd {
	width: 100%;
	height: 38px;
	color: #707070;
	margin-bottom: 10px;
}

.cpe {
	margin: 0 0 13px 0;
}

.closeP {
	display: none;
}

.sorryMsgShow {
	display: contents;
	width: 100%;
	border: none;
	padding-top: 10px;
}

.hideList {
	display: none;
}

.showList {
	display: contents;
}

.soldout-badge,
.multiday-badge,
.pricing-badge
{
	display:inline;
	color:#fff;
	font-size:0.7rem;
	font-family:"Tabac Sans",Helvetica,Arial,sans-serif;
	letter-spacing:0.025em;
	white-space: nowrap;
	border-radius:3px;
	margin-left:2px;
	margin-right:2px;
	padding:0.15em 5px 0.15em 5px;
	margin-top:5px;
	cursor: default;
}

.soldout-badge {
	background-color:#FF8A80;
}

.multiday-badge {
	background-color:#20BEA8;
}

.pricing-badge
 {
	background-color:#8C8C8C;
}

@media only screen and (max-width: 600px) {
	.calIntro {
		padding-bottom: 20px;
	}
	.soldout-badge,
	.multiday-badge,
	.pricing-badge
	  {
		width:-webkit-fit-content;
		width:-moz-fit-content;
		width:fit-content;
		margin-right:auto;
		margin-left:auto
	}
	.calendarList {
		float: none;
		width: 100%;
		display: inline-block;
	}

	.calMobile {
	    width: 100%;
		border: 1px solid #D9D9D9;
		box-shadow: 0px 3px 6px #00000029;
		padding: 0 15px;
  	}

  	.calFilterDiv {
		display: none;
	}

	.calMobileFilter {
		display: block;
		color: #017698;
		float: right;
		font-size: 13px;
		border: none;
		background: none;
		padding-bottom: 5px;
	}

	.closeP {
		display: block;
		border-top: 1px solid #D9D9D9;
	}

	.calClose {
		border: none;
		margin-bottom: 0;
		background-color: unset;
		padding-top: 15px;
		width: 100%;
		color: #464547;
		font-size: 16px;
	}

	.calDetails {
		display: none;
	}

	.calDetailsMobile {
		display: contents;
		font-size: 15px;
		cursor: default;
		line-height: 1.5;
		color: #464547;
	}
}