

// --------------------------------------------
// --------------------------------------------
//
// Site margin
//
// --------------------------------------------
// --------------------------------------------


$site-margin: 2.25em;

$site-margin--half: $site-margin/2;




// --------------------------------------------
// --------------------------------------------
//
// Site width
//
// --------------------------------------------
// --------------------------------------------

$max-width: 		84.615384615em; 		// 1100px
