table {
	&.mobile-table {
		overflow-x:auto;
		overflow-y:hidden;
		width:100%;
		padding: 0;
    	@media #{$medium-and-down} {
    		height: 100%;
    		background-color: map-get($blue-grey, "lighten-4");
    	}
		.collapsible {
			@media #{$medium-and-down} {
				display: table-cell;
				white-space: normal;
				padding: 0.5em;
    			// height:200px;
				height: 100%;
    			.collapsible-header {
    				display: flex!important;
    				flex-direction: column;
    				width: 200px;
    				justify-content: space-between;
    				height: 100%;
    				box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.3);
    				background-color: #ffffff!important;
    				td {	
						&:first-of-type {
							padding-top: 0.4rem;
						}
    				}
    				.provider {
    					text-align: left;
    					height: initial;
    				}
    				.mobile-buttons {
    					display: flex;
    					width: 100%;
    					justify-content: space-between;
    					.btn {
    						flex: 1;
    						padding: 0 2rem;
    						@media #{$small-and-down} {
								padding: 0 2.5rem;
    						}
    					}
    				}
    			}
			}
			@media only screen and (min-width : $medium-screen) and (max-width : $large-screen) {
				.checkout-btn {
					display: block;
					float: right;
					&:first-of-type{
						margin-bottom: 0.5em;
					}
				}
			}

			td {
				@media #{$medium-and-down} {
					&:before {
						content: attr(data-label);
						float: left;
						text-transform: uppercase;
						font-weight: bold;
						width: 100%;
					}
				}
			}
		}
		&.card-view {
			// for card view need to add class
			overflow-x: auto;
			.collapsible {
				@media #{$small-and-down} {
					height: inherit;
				}
				display: block;
			}
			.collapsible-header {
				width: 100%;
				.mobile-buttons {
					justify-content: flex-start;
					.btn {
						margin-right: 1em;
					}
				}
			}
		}
		&.dbl-card-view {
			// Another option for two by items
			overflow-x: auto;
			.collapsible {
				width: 50%;
				float: left;
				&:nth-of-type(odd) {
					clear: left;
				}
				@media #{$small-and-down} {
					font-size: 0.75em;
					height: inherit;
				}
				.collapsible-header {
					width: 100%;
					.mobile-buttons {
						.btn {
							padding: 0 2rem;
							@media #{$medium-and-up} {
								padding: 0 3rem;
							}
						}
					}
				} 
			}
			@media #{$large-and-up} {
				overflow-x: initial;
				.collapsible {
					width: 100%;
					float: none;
				}
				.collapsible-header {
					width: inherit;
				}
			}
    	}
	}
}
.table-nav {
	border-bottom: 1px solid map-get($grey, "lighten-3");
	display: flex;
    flex-direction: row;
    align-items: center;
    .filters {
    	margin-left: 0.5em;
    	cursor: pointer;
    }
    a {
    	text-decoration: underline;
    }
	.filter-wrapper {
		justify-content: flex-end;
	}
}
.store-table-separator {
	@media #{$small-and-down} {
		width: 100%;
		text-align: left;
	}
	padding: 0.5em!important;
	text-align: center;
	@extend .blue-grey, .lighten-4, .blue-grey-text, .text-darken-2;
	font-weight: bold;
	text-transform: capitalize;
}

// Slide indicator for mobile
@keyframes slide {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-15px);
  }
  60% {
    transform: translateX(-7px);
  }
}

.scroll-indicator {
	margin: 0;
	text-align: right;
	position: absolute;
    bottom: 0;
    right: 0;
    padding: 0;
	i {
		animation: slide 2s infinite;
		font-size: 20px;
		margin-top: -8px;
		margin-bottom: -24px;
		margin-right: -10px;
		position: absolute;
		bottom: 16px;
		z-index: 1003;
		@media #{$large-and-up} {
			display: none;
			animation: none;
		}
	}
}

// Used for pricing details modal on conferences
.pricing-details-table {
	border-collapse: separate;
    border-top: 1px solid #cccccc;
	thead {
		border: none;
	}
	@media #{$medium-and-down} {
		td, th {
			padding: 5px;
			&:first-of-type {
				padding-top: 5px;
			}
		}
		tbody {
			&:first-child {
				display: block;
				white-space: normal;
				tr {
					display: block;
					td {
						&:first-of-type {
							padding-bottom: 0!important;
						}
					}
				}
			}
		}
	}
	@media #{$large-and-up} {
		.main-tbl-head {
			&:after {
				content: ' '; 
				display: block;
			}
		}
	}
}

.responsive-table-hdr {
	@extend .hide-on-large-only, .center, .white-text, .blue-grey, .section;
	margin-top: 1em;
	.material-icons {
		@extend .animated, .infinite, .slide-in-right;
		position: absolute; 
		top: 0.33em;
	} 
}